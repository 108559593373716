import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Flux} from '../../models/api/flux.model';

@Injectable({
  providedIn: 'root'
})
export class FluxService {

  urlRessource = environment.url_api_litige+"/flux";

  constructor(private http: HttpClient) {

  }

  getFlux(): Observable<Flux[]> {
    return this.http
      .get<Flux[]>(`${this.urlRessource}`).pipe(map((observe) => observe.map(function (observe) {
        return new Flux().deserialize(observe);
      })))
  }

}
