import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpUtil} from '../../utils/http.util';


@Injectable({providedIn: 'root'})
export class UserService {

  urlRessource = environment.url_api_litige + '/users';

  constructor(private http: HttpClient) {
  }

  getUsers(filter: {} = {}): Observable<User[]> {
    return this.http
      .get<User[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((users) => users.map((user) => {
          return new User().deserialize(user);
      })));
  }

  getUsersPoleLitige(filter: {} = {}): Observable<User[]> {
    return this.http
      .get<User[]>(`${this.urlRessource}/litige`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((users) => users.map((user) => {
        return new User().deserialize(user);
      })));
  }


  getUser(id: number): Observable<User> {
    return this.http
      .get<User>(`${this.urlRessource}/${id}`)
      .pipe(map((user) => {
        return new User().deserialize(user);
      }));
  }

  getUserPortefeuille(id: number): Observable<User> {
    return this.http
      .get<User>(`${this.urlRessource}/${id}/portefeuille`)
      .pipe(map((user) => {
        return new User().deserialize(user);
      }));
  }

  updateUserPortefeuille(id: number, portefeuille: {}): Observable<any> {
    return this.http.put(`${this.urlRessource}/${id}`, portefeuille)
      .pipe(map((ret) => {
        return new User().deserialize(ret);
      }));
  }

}
