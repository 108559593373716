<div class="modal-header text-center">
  <h4 class="modal-title w-100">
    Modification du litige : {{litige.id}}
    <button *ngIf="annuaireItem" class="btn btn-agediss" style="float: right" [ladda]="" (click)="openAnnuaire(modal_show_annuaire)">Fiche client</button>
  </h4>
</div>
<div class="modal-body">
  <app-edit-litige [litige]="litige" (submited)="activModal.close($event)"
                   (canceled)="activModal.close()">
  </app-edit-litige>
</div>

<ng-template #modal_show_annuaire let-modal>
  <app-annuaire-editor [itemClientId]="litige?.client?.id" [viewOnly]="true" [context]="viewContext"></app-annuaire-editor>
</ng-template>
