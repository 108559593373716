import {Component, OnDestroy, OnInit} from '@angular/core';
import {Litige} from '../../shared/models/api/litige.model';
import {LitigeService} from '../../shared/services/api/litige.service';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {StatService} from '../../shared/services/api/stat.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {View} from '../../shared/models/view.model';


@Component({
  selector: 'tableau-de-bord',
  templateUrl: './tableau-de-bord.html'
})

export class TableauDeBord implements OnInit, OnDestroy {

  public view: View;

  constructor(private litigeSvc: LitigeService, private statSvc: StatService, private authSvc: AuthenticationService) {
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnDestroy(): void {

  }

}
