import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'contextConversation'})
export class ContextConversationPipe implements PipeTransform {
  transform(value: string, short: boolean=false): string {
    switch (value) {
      case 'DO|LI':
      case 'LI|DO' :
        if(short){
          return 'DO';
        }else{
          return 'donneur d\'ordre <=> service litige';
        }
      case 'LI|PF':
      case 'PF|LI':
        if(short){
          return 'PF';
        }else{
          return 'plateforme <=> service litige';
        }
      default:
        return '';
    }

  }
}
