import { default as VersionInfo } from  '../../package.json';

export const environment = {
  production: true,
  VERSION: VersionInfo.version,
  urlsso: 'sso.agediss.com',
  url_api_litige: 'api-litige.agediss.com',
  application: 'LITIGE',
  url_sharepoint: 'https://girardagediss.sharepoint.com/:w:/r/sites/Process/_layouts/15/Doc.aspx?sourcedoc=%7BB0F31868-EEA7-4A56-A756-8FCA3E017CA1%7D&file=GUI-SRC-003%20-%20Guide%20utilisation%20Module%20Litiges%20-%20Vision%20si%C3%A8ge.docx&action=default&mobileredirect=true',
  url_dts: 'https://www.imf.org/external/np/fin/data/param_rms_mth.aspx',
  domain : '.agediss.com',
  sentry_dsn:"https://87759421366c4d8d83198abc2ddc3c08@sentry.agediss.com/5",
  sentry_env: 'prod'
};
