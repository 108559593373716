<div *ngIf="loading" class="row col-12" style="height: 310px">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Affectation de la facture <span *ngIf="factureId">{{factureId}}</span></h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="factureForm" class="">
      <div class="row">
        <div class="col-md-8">
          <app-file-preview [pieceJointe]="pieceJointe" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile"></app-file-preview>
        </div>
        <div class="col-md-4">
          <div class="row " style="padding-bottom: 5%">
            <div class="col-md-4">
              <label>Type de facture </label>
            </div>
            <div class="col-md-8">
              <ng-select (change)="loadOtherFacture($event.code)"
                         [clearable]="false"
                         [items]="typesFacture"
                         [loading]="loadTypeFacture"
                         [selectOnTab]="true"
                         bindLabel="libelle"
                         bindValue="id"
                         formControlName="typeFacture"
                         placeholder="Choisir">
              </ng-select>
              <div
                *ngIf="factureForm.get('typeFacture').invalid && (factureForm.get('typeFacture').dirty || formSubmit)"
                class="invalid-tooltip-selector">Veuillez sélectionner un type de facture !
              </div>
            </div>
          </div>
          <div class="row " style="padding-bottom: 5%">
            <div class="col-md-4">
              <label for="numeroInterne">Numéro interne</label>
            </div>
            <div class="col-md-8">
              <input id="numeroInterne"
                [ngClass]="{'is-invalid' : factureForm.get('numeroInterne').invalid && (factureForm.get('numeroInterne').dirty || formSubmit)}  "
                class="form-control"
                formControlName="numeroInterne"
                lang="en-150"
                type="number"/>
              <div
                *ngIf="factureForm.get('numeroInterne').invalid && (factureForm.get('numeroInterne').dirty || formSubmit)"
                class="invalid-tooltip">Veuillez saisir un numéro Interne !
              </div>
            </div>
          </div>
          <div class="row " style="padding-bottom: 5%">
            <div class="col-md-4">
              <label for="numeroClient">Numéro client</label>
            </div>
            <div class="col-md-8">
              <input id="numeroClient"
                [ngClass]="{'is-invalid' : factureForm.get('numeroClient').invalid && (factureForm.get('numeroClient').dirty || formSubmit)}"
                class="form-control"
                formControlName="numeroClient"
                lang="en-150"/>
              <div
                *ngIf="factureForm.get('numeroClient').invalid && (factureForm.get('numeroClient').dirty || formSubmit)"
                class="invalid-tooltip">Veuillez saisir un numéro Client !
              </div>
            </div>

          </div>
          <div class="row " style="padding-bottom: 5%">
            <div class="col-md-4">
              <label for="montant">Montant </label>
            </div>
            <div class="col-md-8">
              <input id="montant"
                [ngClass]="{'is-invalid' : factureForm.get('montant').invalid && (factureForm.get('montant').dirty || formSubmit)}"
                class="form-control"
                formControlName="montant"
                lang="en-150"
                type="number"/>
              <div *ngIf="factureForm.get('montant').invalid && (factureForm.get('montant').dirty || formSubmit)"
                   class="invalid-tooltip">Veuillez saisir un montant !
              </div>
            </div>
          </div>
          <div class="row " style="padding-bottom: 5%">
            <div class="col-md-4">
              <label>
                <span *ngIf="factureForm.get('typeFacture').value == 4"> Associer factures </span>
                <span *ngIf="factureForm.get('typeFacture').value != 4"> Associer avoirs </span>
                <i *ngIf="factureForm.get('typeFacture').value == 4" class="fa fa-info-circle"
                   ngbTooltip="Lier l'avoir aux factures qu'il annule"></i>
                <i *ngIf="factureForm.get('typeFacture').value != 4" class="fa fa-info-circle"
                   ngbTooltip="Lier factures a l'avoir"></i>
              </label>
            </div>
            <div class="col-md-8">
              <ng-select [clearable]="false"
                         [items]="otherFacture"
                         [loading]="loadTypeFacture"
                         [selectOnTab]="true"
                         appendTo="body"
                         bindLabel="numero_interne"
                         bindValue="id"
                         class="auto-grow"
                         formControlName="otherFactures"
                         multiple="true"
                         placeholder="Choisir">
                <ng-template let-item="item" let-search="searchTerm" ng-option-tmp>
                  <div
                    ngbTooltip="{{item.numero_interne }} | {{item.numero_client }} | {{item.montant | currency :'EUR': '€'}}">{{item.numero_interne }}
                    | {{item.numero_client }} | {{item.montant | currency :'EUR': '€'}}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="close()" class="btn bg-black-lighter text-white" type="button">Fermer</button>
    <button (click)="submit()" [ladda]="loadingBtn" class="btn btn-agediss text-white" data-style="zoom-in"
            type="button">Enregistrer
    </button>
  </div>
</div>
