import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {Litige} from "../../shared/models/api/litige.model";
import {LitigeService} from "../../shared/services/api/litige.service";

@Component({
  selector: 'imputations',
  templateUrl: './imputations.html'

})
export class ImputationsPage implements OnDestroy, OnInit {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public displayInputFilter = [
    // new FieldParametersModel().deserialize({
    //   input: 'statut',
    //   show: false
    // })
  ];
  public displayColumn = [
    'id',
    'numeroAvis',
    'client',
    'numeroCommande',
    'destinataireNom',
    'plateforme',
    'statut',
    'traitePar',
    'montantValide',
    'imputer',
  ];

  public loadingTableRetrocession: boolean = false;
  public litigesRetrocedable: Litige[];

  constructor(
    private litigesSvc: LitigeService,
    // private authSvc: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.loadDate();
  }

  ngOnDestroy(): void {
  }

  onSubmit(filter: FilterMultiCritereModel) {
    //récupère les infos du filtre
    this.filter = filter;
    //applique les changements
    this.litigesSvc.getLitigeImputable(this.filter).subscribe(litiges => this.litigesRetrocedable = litiges);
  }

  loadDate(){
    this.loadingTableRetrocession = true;

    this.litigesSvc.getLitigeImputable(this.filter).subscribe(litiges => {
      this.litigesRetrocedable = litiges;
      this.loadingTableRetrocession = false;
    });
  }
}

