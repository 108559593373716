<div class="row" *ngIf="view.hasDroit('PORTEFEUILLE_EDIT')">
  <div class="col-3">
    <div class="col-12">
      <div class="text-black pointer-cursor action" (click)="addUser(modal_choose_user)"><i class="fa fa-plus"></i></div>
      <panel title="Portefeuilles" noButton="true" bodyClass="no-padding" class="text-center">

        <input class="search shadow" placeholder="Rechercher ..." [(ngModel)]="searchTerm"/>

        <div class="overflow-scroll" style="height: 82vh">
          <table mat-table [dataSource]="portefeuilles" multiTemplateDataRows
                 class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8
                 text-center pointer-cursor">
            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef class="px-10">Client</th>
              <td mat-cell *matCellDef="let element" (click)="selected(element.id)"
                  class="pointer"
                  [hidden]="searchTerm.length && !element.fullname.toLowerCase().includes(searchTerm.toLowerCase())"
              >
                {{element.fullname}}
              </td>
            </ng-container>

            <ng-container matColumnDef="noData">
              <td mat-footer-cell *matFooterCellDef>
                Aucun résultat
              </td>
            </ng-container>
            <ng-container matColumnDef="loading">
              <td mat-footer-cell *matFooterCellDef>
                <i class="text-grey">Chargement en cours </i> <i class="fas fa-spin fa-circle-notch"></i>
              </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: ['client']"></tr>
            <tr mat-footer-row *matFooterRowDef="['noData']"
                [hidden]="portefeuilles && portefeuilles.length > 0 || loadingPortefeuilles"></tr>
            <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loadingPortefeuilles"></tr>
          </table>
        </div>
      </panel>
    </div>
  </div>

  <div class="col-9">
    <app-portefeuille-editor  [userId]="currentUserId"
                              [clientsSelect]="clients"
                              [compteClientsSelect]="compteClients">
    </app-portefeuille-editor>
  </div>

</div>

<ng-template #modal_choose_user let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Ajouter un utilisateur</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <ng-select [items]="users"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="fullname"
                     [clearable]="false"
                     (change)="newUser($event)"
                     placeholder="Choisir">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</ng-template>
