import {Deserializable} from '../interfaces/deserializable.interface';

export class Notif implements Deserializable {

  public id: number;
  public numero_avis: string | number;
  public destinataire_context: string | number;
  public destinataire_context_id: string;
  public application_id: string;
  public viewed?: boolean;
  public viewed_all?: boolean;
  public libelle: string;
  public emetteur_libelle: string;
  public emetteur_context: string;
  public created_at?: Date;
  public link?: string;
  public pending?: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
