import {Deserializable} from '../interfaces/deserializable.interface';
import {Imputation} from './imputation.model';
import {Facture} from './facture.model';

export class Oda implements Deserializable {

  public id: number;
  public before_imputation?: Imputation;
  public after_imputation?: Imputation;
  public facture: Facture;
  public sens_comptable: boolean;
  public commentaire?: string;
  public done_date?: Date;
  public code?: string;
  public montant: number;
  public added: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.before_imputation = (input.before_imputation) ? new Imputation().deserialize(input.before_imputation) : null;
    this.after_imputation = (input.after_imputation) ? new Imputation().deserialize(input.after_imputation) : null;
    this.facture = (input.facture) ? new Facture().deserialize(input.facture) : null;
    return this
  }

}
