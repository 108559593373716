import {Component, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Litige} from '../../../shared/models/api/litige.model';
import {LitigeService} from '../../../shared/services/api/litige.service';
import {StatService} from '../../../shared/services/api/stat.service';

@Component({
  selector: 'app-siege-view',
  templateUrl: './siege-view.component.html',
  styleUrls: ['./siege-view.component.scss']
})
export class SiegeViewComponent implements OnInit {

  public litiges: Litige[];
  public hiddenColumns = ['instruction', 'evenement', 'approche', 'entite'];
  public loading: boolean = false;
  public nbLitigeATraiter: number;
  public nbLitigeEncours: number;
  public nbContestationsDo: number;
  public nbJustificatifATraiter: number;
  public nbContestationsPlateforme: number;
  public statsPf: any[];
  public statsPfLoading: boolean = false;

  constructor(private litigeSvc: LitigeService, private statSvc: StatService) {
  }

  ngOnInit() {
    this.loading = true;
    const sub = this.litigeSvc.getLitiges(<FilterMultiCritereModel> {
      limit: 10,
      orderChamp: 'id',
      orderDirection: 'DESC'
    }).subscribe((litiges) => {
      this.litiges = litiges;
      this.loading = false;
    });


    const sub1 = this.statSvc.getNbLitige(<FilterMultiCritereModel> {statutCode: 'LIT_0'}).subscribe((nbLitige) => {
      this.nbLitigeATraiter = nbLitige;
      sub1.unsubscribe();
    });
    const sub2 = this.statSvc.getNbLitige(<FilterMultiCritereModel> {statutCode: 'LIT_2'}).subscribe((nbLitige) => {
      this.nbLitigeEncours = nbLitige;
      sub2.unsubscribe();
    });

    const sub3 = this.statSvc.getNbContestation(<FilterMultiCritereModel> {
      contestataire: 'DO',
      statutCode: 'CON_0'
    }).subscribe((nbContestations) => {
      this.nbContestationsDo = nbContestations;
      sub3.unsubscribe();
    });

    const sub4 = this.statSvc.getNbContestation(<FilterMultiCritereModel> {
      contestataire: 'PF',
      statutCode: 'CON_0'
    }).subscribe((nbContestations) => {
      this.nbContestationsPlateforme = nbContestations;
      sub4.unsubscribe();
    });

    const sub5 = this.statSvc.getNbJustificatifATraite().subscribe((nbJustificatifATraiter) => {
      this.nbJustificatifATraiter = nbJustificatifATraiter;
      sub5.unsubscribe();
    });

    this.statsPfLoading = true;
    const sub6 = this.statSvc.getStatNbLitigePf().subscribe((stats) => {
      this.statsPf = stats;
      this.statsPfLoading = false;
    },()=>{
      this.statsPfLoading = false;
    });

  }


  ngOnDestroy(): void {
  }

}

