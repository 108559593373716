<div class="modal-header">
    <h4 class="modal-title align-middle">Création d'un litige</h4>
</div>
<div class="modal-body" style="min-height: 150px">
    <div *ngIf="loadingStep1" class="spinner"></div>
    <div *ngIf="!loadingStep1">
        <!-- DEBUT Sélecteur pour le contexte-->
        <div class="row mb-2">
            <div class="col-md-2"></div>
            <div class="col-md-4 text-left"><label> Type de litige</label></div>
            <div class="col-md-4">
                <ng-select [items]="typeLitigeList"
                           [selectOnTab]="true"
                           bindValue="code"
                           bindLabel="code"
                           [clearable]="false"
                           placeholder="Choisir"
                           (change)="changeSelect($event)">
                </ng-select>
            </div>
        </div>
        <!-- FIN Sélecteur pour le contexte-->

        <!-- DEBUT du contexte B2C-->
<!--        <div *ngIf="currentContext == 'B2C' && currentContext!=null">-->
<!--                <app-create-litige-b2c></app-create-litige-b2c>-->
<!--        </div>-->
        <!-- FIN du contexte B2C-->

        <!-- DEBUT du contexte B2B & STOCK-->
        <div *ngIf="currentContext != 'B2C' && currentContext!=null">
            <app-create-litige-b2b-stock [typeLite]="currentTypeLitige"
                                         (EventSubmited)="onSubmit($event)"></app-create-litige-b2b-stock>
        </div>
        <!-- FIN du contexte B2B & STOCK-->

    </div>
</div>
<div class="modal-footer text-center">
    <button type="button" class="btn bg-black-lighter text-white" (click)="close()">Annuler</button>
</div>
