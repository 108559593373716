<!-- DEBUT du contexte B2C-->
<div class="row mb-2">
    <div class="col-md-4"><label> Rechercher un BL </label></div>
    <div class="col-md-8">
        <ng-select [items]="listBl"
                   bindValue="bl"
                   bindLabel="bl"
                   [selectOnTab]="true"
                   [clearable]="false"
                   (change)="initForm($event)"
                   placeholder="Choisir">
        </ng-select>
    </div>
</div>

<div *ngIf="!loadingForm"></div>
<div *ngIf="loadingForm">
    <form [formGroup]="b2cForm">
        <div class="jumbotron">
            <div class="row mb-2">
                <div class="col-md-3"><label>N° Commande</label></div>
                <div class="col-md-3"><input class="form-control" formControlName="bl"></div>

                <div class="col-md-3"><label>Adresse</label></div>
                <div class="col-md-3"><input class="form-control" formControlName="adresse"></div>
            </div>
            <div class="row mb-2">
                <div class="col-md-3"><label>Date</label></div>
                <div class="col-md-3"><input class="form-control" formControlName="date"></div>

                <div class="col-md-3"><label>Code Postal</label></div>
                <div class="col-md-3"><input class="form-control" formControlName="codePostal"></div>
            </div>
            <div class="row mb-2">
                <div class="col-md-3"><label>Destinataire</label></div>
                <div class="col-md-3"><input class="form-control" formControlName="destinataire"></div>

                <div class="col-md-3"><label>Ville</label></div>
                <div class="col-md-3"><input class="form-control" formControlName="ville"></div>
            </div>
        </div>


        <app-table-article
                [displayedColumns]="displayedColumns"
                [data]="articles">
        </app-table-article>
    </form>
</div>
<!-- FIN du contexte B2C-->

