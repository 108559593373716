import {Injectable} from '@angular/core';
import {Contestation} from "../../models/api/contestation.model";
import {FilterMultiCritereModel} from "../../models/filters/filterMultiCritere.model";
import {Observable} from "rxjs";
import {HttpUtil} from "../../utils/http.util";
import {map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Conversation} from "../../models/api/conversation.model";
import {PieceJointe} from "../../models/api/piece-jointe.model";
import {Statut} from "../../models/api/statut.model";
import {User} from "../../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class ContestationService {

  urlRessource = environment.url_api_litige + "/contestations";


  constructor(private  http: HttpClient) {
  }

  getContestations(filter: FilterMultiCritereModel = null): Observable<Contestation[]> {
    return this.http
      .get<Contestation[]>(this.urlRessource, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((contestations) => contestations.map((contestation) => {
        return new Contestation().deserialize(contestation);
      })));
  }


  getContestation(id: number): Observable<Contestation> {
    return this.http
      .get<Contestation>(`${this.urlRessource}/${id}`)
      .pipe(map((contestation) => {
        return new Contestation().deserialize(contestation);
      }));
  }


  getConversation(id: number): Observable<Conversation> {
    return this.http
      .get<Conversation>(`${this.urlRessource}/${id}/conversation`)
      .pipe(map((conversation) => {
        return new Conversation().deserialize(conversation);
      }));
  }


  getPieceJointes(id: number): Observable<PieceJointe[]> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessource}/${id}/piece-jointes`,{headers: new HttpHeaders().set('nocache', 'true')})
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointes) => {
        return new PieceJointe().deserialize(pieceJointes);
      })));
  }

  postConveration(id: number, conv: Conversation): Observable<Conversation> {
    return this.http
      .post<Conversation>(`${this.urlRessource}/${id}/conversation`, conv)
      .pipe(map((conversation) => {
        return new Conversation().deserialize(conversation);
      }));
  }

  /**
   * @return Observable
   */
  updateContestation(contestation: Contestation): Observable<Contestation> {
    return this.http
      .put(environment.url_api_litige + `/contestations/${contestation.id}`, contestation)
      .pipe(map((contestation) => {
        return new Contestation().deserialize(contestation);
      }));
  }

  refusContestation(contestationId: number, message: string): Observable<Statut> {
    return this.http
      .put<Statut>(`${this.urlRessource}-contestation/${contestationId}/refuse`, {message})
      .pipe(map((statut) => {
        return new Statut().deserialize(statut);
      }));
  }

  validateContestation(contestationId: number): Observable<Statut> {
    return this.http.put<Statut>(`${this.urlRessource}-contestation/${contestationId}/validate`, {})
      .pipe(map((statut) => {
          return new Statut().deserialize(statut);
        }));
  }

  /**
   * cette requete permet d'affecter un utilisateur au litige passé en parametre.
   * **** ATTENTION ** Si aucun utilisateur est passé en paramètre, l'utilisateur qui effectue la requete ce verra
   * affecter le litige.
   *    *
   * @param contestationId
   * @param userId
   */
  affectUserToContestation(contestationId: number, userId?: number): Observable<{ user: User, statut: Statut }> {
    return this.http.put<{ user: User, statut: Statut }>(`${this.urlRessource}/${contestationId}/affecte-user`, {userId})
      .pipe(map(response => {
        return {
          user: new User().deserialize(response.user),
          statut: new Statut().deserialize(response.statut)
        };
      }));
  }
}
