<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title w-100">Valide en masse - facture n°{{factureId}}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="valideEnMasseForm">
      <label for="data">Saisissez une liste de BL</label>
      <textarea id="data" class="form-control" [readOnly]="this.showAnomalie == true"
                [ngClass]="{'is-invalid':f.valideEnMasse.invalid && (f.valideEnMasse.dirty || formSubmit) }"
                cols="4" rows="10" formControlName="valideEnMasse"></textarea>
      <div *ngIf="f.valideEnMasse.invalid && (f.valideEnMasse.dirty || formSubmit)"
           class="ml-3 text-center invalid-tooltip">
        Vous devez saisir les numéros de bl souhaités!
      </div>
    </form>
  </div>
  <div class="modal-body" *ngIf="showAnomalie == true">
    <div class="dropdown-divider"></div>
    <h5 class="text-primary">Bl non bloquant</h5>
    <div class="row my-3">
      <div class="col-12">
        <mat-accordion id="first-accordion">
          <mat-expansion-panel hideToggle [disabled]="clickButton" (click)="clickButton=false">
            <mat-expansion-panel-header>
              <mat-panel-title class="col-11 font-weight-bold text-primary">
                Bl statut invalid ({{valideEnMasse['invalid'].length || 0  }})
              </mat-panel-title>
              <mat-panel-description class="col-1" *ngIf="valideEnMasse['invalid'].length != 0">
                <button data-style="zoom-in" class="btn btn-copy bg-black-lighter text-white"
                        placement="left"
                        container="body"
                        ngbTooltip="copier"
                        (click)="onCopyData(valideEnMasse['invalid'],false,'numeroAvis')">
                  <i class="fas fa-copy"></i>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul class="m-0">
              <li class="text-color" *ngIf="valideEnMasse['invalid'].length == 0">
                {{noDataText}}
              </li>
            </ul>
            <mat-grid-list *ngIf="valideEnMasse['invalid'].length !== 0" cols="5" rowHeight="20px" class="valide-en-masse-color">
              <mat-grid-tile *ngFor="let invalid of valideEnMasse['invalid'] | keyvalue; let j = index">{{invalid.value.numeroAvis}}</mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <mat-accordion id="second-accordion">
          <mat-expansion-panel hideToggle [disabled]="clickButton" (click)="clickButton=false">
            <mat-expansion-panel-header>
              <mat-panel-title class="col-11 font-weight-bold text-primary">
                Bl non conforme (le compte client est différent de la facture)
                ({{valideEnMasse['errorCompteClient'].length || 0 }})
              </mat-panel-title>
              <mat-panel-description class="col-1" *ngIf="valideEnMasse['errorCompteClient'].length != 0">
                <button data-style="zoom-in" class="btn btn-copy bg-black-lighter text-white"
                        placement="left"
                        container="body"
                        ngbTooltip="copier"
                        (click)="onCopyData(valideEnMasse['errorCompteClient'],false,'numeroAvis')">
                  <i class="fas fa-copy"></i>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul class="m-0">
              <li class="text-color" *ngIf="valideEnMasse['errorCompteClient'].length == 0">
                {{noDataText}}
              </li>
            </ul>
            <mat-grid-list *ngIf="valideEnMasse['errorCompteClient'] .length != 0" cols="5" rowHeight="20px" class="valide-en-masse-color">
              <mat-grid-tile *ngFor="let items of valideEnMasse['errorCompteClient'] | keyvalue; let j = index">{{items.value.numeroAvis}}</mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="dropdown-divider"></div>
    <h5 class="text-danger">Bl bloquant</h5>
    <div class="row my-3">
      <div class="col-12">
        <mat-accordion id="third-accordion">
          <mat-expansion-panel hideToggle [disabled]="clickButton" (click)="clickButton=false">
            <mat-expansion-panel-header>
              <mat-panel-title class="col-11 font-weight-bold text-danger">
                Bl inexistant
                ({{valideEnMasse['not_found'].length || 0 }})
              </mat-panel-title>
              <mat-panel-description class="col-1" *ngIf="valideEnMasse['not_found'].length != 0">
                <button data-style="zoom-in" class="btn btn-copy bg-black-lighter text-white"
                        placement="left"
                        container="body"
                        ngbTooltip="copier"
                        (click)="onCopyData(valideEnMasse['not_found'], false,'numeroAvis')">
                  <i class="fas fa-copy"></i>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul class="m-0">
              <li class="text-color" *ngIf="valideEnMasse['not_found'].length == 0">
                {{noDataText}}
              </li>
            </ul>
            <mat-grid-list *ngIf="valideEnMasse['not_found'].length != 0" cols="5" rowHeight="20px" class="valide-en-masse-color">
              <mat-grid-tile *ngFor="let items of valideEnMasse['not_found'] | keyvalue; let j = index">{{items.value.numeroAvis}}</mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <mat-accordion id="fourth-accordion">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title class="col-11 font-weight-bold text-danger">
                Bl avec plusieurs litiges ({{nbLitigeDoublons}}), Veuillez
                cocher ceux que vous souhaitez garder.
              </mat-panel-title>
              <mat-panel-description class="col-1" *ngIf="valideEnMasse['doublon'].length != 0">
                <button data-style="zoom-in" class="btn btn-copy bg-black-lighter text-white"
                        placement="left"
                        container="body"
                        ngbTooltip="copier"
                        (click)="onCopyData(valideEnMasse['doublon'], true)">
                  <i class="fas fa-copy"></i>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul class="m-0">
              <li class="text-color" *ngIf="valideEnMasse['doublon'].length == 0">
                {{noDataText}}
              </li>
              <li *ngIf="valideEnMasse['doublon'].length != 0">
                <div *ngFor="let doublon of valideEnMasse['doublon'] | keyvalue">
                  <div class="row m-0">
                    <div class="col-12">
                      <label>{{doublon.key}}</label>
                    </div>
                  </div>
                  <div *ngFor="let item of doublon.value | keyvalue; let i = index">
                    <div class="row m-0">
                      <div class="col-12 pl-4 m-0">
                        <mat-checkbox [color]="'warn'" (change)="onCheckboxChange($event, item.value)">
                          <ng-template #titleLitige>
                            <div>
                              <p class="popover-content m-0 p-1"
                                 [innerHTML]="'<strong>Motif:</strong> '+ item.value.motif"></p>
                            </div>
                          </ng-template>
                          <span class="valide-en-masse-color"
                                placement="top"
                                container="body"
                                [ngbPopover]="titleLitige" triggers="mouseenter:mouseleave"
                                [innerHTML]="getTitleLitige(item.value)"></span>
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="dropdown-divider"></div>
    <div class="row my-3">
      <div class="col-12">
        <mat-accordion id="fifth-accordion">
          <mat-expansion-panel hideToggle [disabled]="clickButton" (click)="clickButton=false">
            <mat-expansion-panel-header>
              <mat-panel-title class="col-11 font-weight-bold text-success">
                Bl qui seront importés ({{valideEnMasse['valid'].length || 0}})
              </mat-panel-title>
              <mat-panel-description class="col-1" *ngIf="valideEnMasse['valid'].length != 0">
                <button data-style="zoom-in" class="btn btn-copy bg-black-lighter text-white"
                        placement="left"
                        container="body"
                        ngbTooltip="copier"
                        (click)="onCopyData(valideEnMasse['valid'], false,'numeroAvis')">
                  <i class="fas fa-copy"></i>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul class="m-0">
              <li class="text-color" *ngIf="valideEnMasse['valid'].length == 0">
                {{noDataText}}
              </li>
            </ul>
            <mat-grid-list *ngIf="valideEnMasse['valid'].length != 0" cols="5" rowHeight="20px"
                           class="valide-en-masse-color">
              <mat-grid-tile *ngFor="let valid of valideEnMasse['valid'] | keyvalue; let j = index">
                {{valid.value.numeroAvis}}
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn bg-black-lighter text-white" (click)="activeModal.close()">Fermer</button>
    <button *ngIf="showAnomalie == true" type="button" class="btn bg-primary text-white" (click)="retour()">Retour
    </button>
    <button *ngIf="showAnomalie == true" [ladda]="loadingBtnSubmit" type="button" class="btn btn-agediss text-white"
            (click)="submit()" data-style="zoom-in">Importer
    </button>
    <button *ngIf="showAnomalie == false" [ladda]="loadingBtn" type="button" class="btn btn-agediss text-white"
            data-style="zoom-in" (click)="checkValideEnMasse()">Executer
    </button>
  </div>
</div>


