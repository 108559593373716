import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {Subscription} from 'rxjs';
import {FactureService} from '../../../shared/services/api/facture.service';
import {FormControl, FormGroup} from '@angular/forms';
import {ImputationService} from '../../../shared/services/api/imputation.service';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'imputationsTabBord',
  templateUrl: './imputation-tableau-de-bord.html',
  styleUrls: ['./imputation-tableau-de-bord.scss']
})


export class ImputationTableauDeBord implements OnDestroy, OnInit {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterForm = new FormGroup({
    numeroInterne: new FormControl(),
    isDone: new FormControl(false)
  });
  public loading: boolean;
  public activModal: NgbModalRef;
  public factures: any[];
  private subscriptions: Subscription[] = [];
  public limit = 10;


  constructor(private factureSvc: FactureService,
              private authSvc: AuthenticationService,
              private toastSvc: ToastrService,
              private imputationSvc: ImputationService
  ) {
  }


  ngOnInit(): void {
    this.filter = this.filterForm.getRawValue();
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.subscriptions.push(this.factureSvc.getFacturesImputation(this.filter).subscribe(
      (factures) => {
        Object.keys(factures).forEach(num_interne => {
          Object.keys(factures[num_interne]).forEach(date_litige => {
            return factures[num_interne][date_litige].map(function(oo) {
              oo.loading = false;
              return oo;
            });
          });
        });
        this.factures = factures;
        this.loading = false;
      }, () => {
        this.loading = false;
      }));

  }

  onSubmit() {
    //récupère les infos du filtre
    this.filter = this.filterForm.getRawValue();
    this.loadData();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  reset(): void {
    this.filterForm.reset();
  }

  checkImputation(line) {
    line.loading = true;
    console.log(line);
    this.imputationSvc.checkImputationsToCompta(line).subscribe(() => {
        line.loading = false;
        line.done_date = new Date();
        this.toastSvc.info("ligne d'imputation validée ",'Information');
      },
      () => {
        line.loading = false;
      });
  }

  uncheckImputation(line) {
    line.loading = true;
    this.imputationSvc.uncheckImputationsToCompta(line).subscribe(() => {
        this.toastSvc.warning("ligne d'imputation invalidée ",'Information',{});
        line.loading = false;
        line.done_date = null;
      },
      () => {
        line.loading = false;
      });
  }

  getLenght(obj) {
    return Object.keys(obj).length;
  }

  getType(index){
    for (const [key, value] of Object.entries(this.factures[index])) {
      return value[0].code
    }
  }
}



