import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Conversation} from '../../../../shared/models/api/conversation.model';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {ConversationService} from '../../../../shared/services/api/conversation.service';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-table-conversation',
  templateUrl: './table-conversation.component.html',
  styleUrls: ['./table-conversation.component.scss']
})
export class TableConversationComponent implements OnInit, OnChanges {

  public dataSorted: MatTableDataSource<Conversation>;

  @Input() data: Conversation[];
  @Input() displayedColumns: string[] = ['id', 'contexte', 'dateCreation', 'dateModification', 'message', 'action'];
  @Input() noRecordText = 'Aucune conversation';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading: Boolean;
  @Input() showAddConversationBtn: boolean = false;
  @Input() showDeleteConversationBtn: boolean = false;

  @Output() onAdd: EventEmitter<Conversation> = new EventEmitter();
  @Output() onDeleted: EventEmitter<number> = new EventEmitter();
  @Output() onSelectConverstation: EventEmitter<number> = new EventEmitter();

  constructor(
    private conversationSvc: ConversationService,
    private toastSvc: ToastrService
  ) {
  }

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
  }

  /**
   * LifeHook : à chaque changement de value de conversation on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  deleteConversation(idConversation: number, index: number) {

    Swal.fire({
      title: 'Attention !',
      text: 'Etes-vous sur de vouloir supprimer le a conversation ',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui !'

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSorted.data[index].pending = true;

        //service pour supprimer le fichier
        const filesSub = this.conversationSvc.deleteConversation(idConversation).subscribe(
          () => {
            this.toastSvc.info('la converstation à été supprimé');
            this.dataSorted.data[index].pending = false;
            this.dataSorted.data.splice(index, 1);
            this.dataSorted = new MatTableDataSource(this.dataSorted.data);
          },
          () => {
            this.dataSorted.data[index].pending = false;
            this.toastSvc.error('la conversation n\'à pas pu etre supprimé');
          },
        );
      }

    });
  }

}
