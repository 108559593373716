import {Injectable} from '@angular/core';
import {Pole} from "../../models/api/pole.model";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoleService {


  urlRessource = environment.url_api_litige+"/poles";
  constructor(private http: HttpClient) {

  }

  getPoles(): Observable<Pole[]> {
    return this.http
      .get<Pole[]>(`${this.urlRessource}`).pipe(map((poles) => poles.map(function (pole) {
        return new Pole().deserialize(pole);
      })))
  }

}
