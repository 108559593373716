import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Imputation} from '../../../../shared/models/api/imputation.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {Retrocession} from '../../../../shared/models/api/retrocession.model';

@Component({
  selector: 'app-table-retrocession',
  templateUrl: './table-retrocession.component.html',
  styleUrls: ['./table-retrocession.component.scss']
})
export class TableRetrocessionComponent implements OnInit {

  public dataSorted: MatTableDataSource<Retrocession>;
  @Input() data: Retrocession[];
  @Input() displayedColumns: string[] = [
    'id',
    'responsable',
    'montant',
    'type',
    'numeroFacture',
    'dateFacture'
  ];
  @Input() noRecordText = 'Aucune rétrocession';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = null;
  @Input() hiddenColumns: string[] = [];
  @Output() submited: EventEmitter<Boolean> = new EventEmitter<Boolean>();


  constructor() {
  }

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

}
