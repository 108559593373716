<!-- end page-header -->
<div class="row">
  <div class="col-4">
    <div class="row">
      <div class="col-12">  <panel noButton="true" class="text-center">
        <div header class="text-center">
          <strong> Litiges à analyser </strong>
        </div>
        <div class="panel-body text-center" style="overflow-x: auto; height: 190px;">
          <h1>
            <span [ngClass]="nbLitigeATraiter == null ? 'fa fa-circle-notch fa-spin': null ">{{nbLitigeATraiter}}</span> litige<span *ngIf="nbLitigeATraiter">s</span> </h1>
          <h1>à traiter </h1>
        </div>
      </panel></div>
      <div class="col-12">
        <panel noButton="true" class="text-center">
          <div header class="text-center">
            <strong> Contestations en attente du service litiges </strong>
          </div>
          <div class="panel-body text-center" style="overflow-x: auto; height: 190px;">
            <h1><span [ngClass]="!nbContestationsPlateforme ? 'fa fa-circle-notch fa-spin':null">{{nbContestationsPlateforme}}</span> contestation<span *ngIf="nbLitigeATraiter">s</span> </h1>
            <h1> en cours</h1>
          </div>
        </panel>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-12">

      </div>
      <div class="col-12">

      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-12">

      </div>
    </div>
  </div>
</div>

