<panel noButton="true" bodyClass="no-padding" class="text-center">
  <div class="row" header>
    <div class="col-12">
      <h5 class="p-t-5 text-white">
        Fiche client : <i><b>{{clientName}}</b></i>
        <button *ngIf="!viewOnly && clientName.length" class="btn btn-default ml-4" style="float: right"
                (click)="export()">Exporter
        </button>
      </h5>
      <span>Conditions de prise en charge litige</span>
    </div>
  </div>

  <div *ngIf="loadingAnnuaire" style="height: 70px" class="row col-12">
    <span class="spinner"></span>
  </div>
  <div *ngIf="!loadingAnnuaire" style="max-height: 80vh" class="overflow-scroll">
    <form class="m-20" style="text-align: left" [formGroup]="annuaireForm">
      <div class="row p-t-10">
        <div class="col-xl-6">
          <!-- compteClient -->
          <div class="row form-group" *ngIf="!!annuaireItem.compte_client?.id || itemId === 'group'">
            <label class="col-md-4 col-form-label" id="compte_client">Groupe Clients </label>
            <div class="col-md-8">
              <ng-select *ngIf="!annuaireItem.id"
                         [items]="compteClientsSelect"
                         [selectOnTab]="true"
                         bindValue="id"
                         bindLabel="libelle"
                         [clearable]="false"
                         labelForId="compte_client"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         formControlName="compte_client">
              </ng-select>
              <div *ngIf="!!annuaireItem.id" style="margin-top: 8px">
                {{annuaireItem.compte_client?.libelle}}
              </div>
            </div>
          </div>
          <!-- client -->
          <div class="row form-group" *ngIf="!!annuaireItem.client?.id || itemId === 'client'">
            <label class="col-md-4 col-form-label" id="client">Client </label>
            <div class="col-md-8">
              <ng-select *ngIf="!annuaireItem.id"
                         [items]="clientsSelect"
                         [selectOnTab]="true"
                         bindValue="id"
                         bindLabel="libelle"
                         [clearable]="false"
                         labelForId="client"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         formControlName="client">
              </ng-select>
              <div *ngIf="!!annuaireItem.id" style="margin-top: 8px">
                {{annuaireItem.client?.libelle}}
              </div>
            </div>
          </div>
          <!-- type_client -->
          <div class="row form-group">
            <label class="col-md-4 col-form-label" id="type_client">Type client </label>
            <div class="col-md-8">
              <ng-select [items]="typesClientOptions"
                         [selectOnTab]="true"
                         [clearable]="true"
                         labelForId="type_client"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         formControlName="typeClient">
              </ng-select>
            </div>
          </div>
          <!-- commercial -->
          <div class="row form-group">
            <label class="col-md-4 col-form-label">Commercial </label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="commercial" [readOnly]="viewOnly"/>
            </div>
          </div>
          <!-- internationnal -->
          <div class="row form-group">
            <label class="col-md-6 col-form-label" id="internationalLabel">Client international </label>
            <div class="col-md-6">
              <div class="switcher" *ngIf="!viewOnly">
                <input type="checkbox" name="international" [readonly]="viewOnly"
                       id="international" formControlName="international">
                <label for="international"></label>
              </div>
              <button *ngIf="viewOnly" class="btn btn-icon" [ngClass]="{
                'btn-green': annuaireItem.config?.international,
                'btn-danger': !annuaireItem.config?.internatinnal}">
                <i class="fa fa-2x" [ngClass]="{
                'fa-check-square': annuaireItem.config?.international,
                'fa-times': !annuaireItem.config?.international}">
                </i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-6">
          <!-- plateforme -->
          <div class="row form-group">
            <label class="col-md-6 col-form-label" id="plateforme">Agence de rattachement </label>
            <div class="col-md-6">
              <ng-select *ngIf="!viewOnly"
                         [items]="plateformesSelect"
                         [selectOnTab]="true"
                         bindValue="id"
                         bindLabel="libelle"
                         [clearable]="true"
                         labelForId="plateforme"
                         placeholder="Choisir"
                         formControlName="plateforme">
              </ng-select>
              <div *ngIf="viewOnly" style="margin-top: 8px">
                {{annuaireItem.plateforme?.libelle}}
              </div>
            </div>
          </div>
          <!-- mode de suivi -->
          <div *ngIf="contextSiege" class="row form-group">
            <label class="col-md-6 col-form-label">Mode de suivi </label>
            <div class="col-md-6">
              <input type="text" class="form-control" formControlName="modeDeSuivi"
                     triggers="mouseenter:mouseleave"
                     popoverTitle="Mode de suivi"
                     container="body"
                     [readonly]="viewOnly"
                     [ngbPopover]="itemModeDeSuivi"/>
            </div>
          </div>
          <!-- assistant commercial -->
          <div class="row form-group">
            <label class="col-md-6 col-form-label">Assistant commercial </label>
            <div class="col-md-6">
              <input type="text" class="form-control" formControlName="assistantCommercial" [readonly]="viewOnly"/>
            </div>
          </div>
        </div>
      </div>

      <!-- contacts -->
      <div *ngIf="contextSiege" class="row p-t-10 block-yellow" formArrayName="contacts">
        <div class="col-12 text-center p-b-5">
          Contacts
          <span class="pointer-cursor" *ngIf="!viewOnly"><i class="fa fa-plus-circle" (click)="addContact()"></i></span>
        </div>
        <div class="row col-12" style="padding-left: 10px; padding-right: 10px; margin: 0">
          <label class="col-3 col-form-label">Nom </label>
          <label class="col-3 col-form-label">Fonction </label>
          <label class="col-3 col-form-label">Email </label>
          <label class="col-3 col-form-label">Téléphone </label>
        </div>
        <div *ngFor="let contact of contactsFormArray.controls; let i=index" class="col-12">
          <div [formGroupName]="i" class="row" style="margin: 0">
            <!-- nom -->
            <div class="col-3 form-group">
              <input type="text" class="form-control" formControlName="nom" [readonly]="viewOnly"/>
            </div>
            <!-- fonction -->
            <div class="col-3 form-group">
              <input type="text" class="form-control" formControlName="fonction" [readonly]="viewOnly"/>
            </div>
            <!-- email -->
            <div class="col-3 form-group">
              <input type="text" class="form-control" formControlName="email" [readonly]="viewOnly"/>
            </div>
            <!-- téléphone -->
            <div class="col-3 row form-group">
              <div class="col-10">
                <input type="text" class="form-control" formControlName="telephone" [readonly]="viewOnly"/>
              </div>
              <div class="col-2">
                <div class="text-white pointer action" (click)="removeContact(i)" *ngIf="!viewOnly">
                  <i class="fa fa-minus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- typologies de produits -->
      <div class="row p-t-10 block-green" formArrayName="typologiesProduits">
        <div class="col-12 text-center p-b-5">
          Typologies de produits
          <span class="pointer-cursor" *ngIf="!viewOnly"><i class="fa fa-plus-circle"
                                                            (click)="addTypologie()"></i></span>
        </div>
        <div class="row col-12" style="padding-left: 10px; padding-right: 10px; margin: 0">
          <label class="col-6 col-form-label">Nom </label>
          <label class="col-6 col-form-label">Commentaire </label>
        </div>
        <div *ngFor="let contact of typologiesFormArray.controls; let i=index" class="col-12">
          <div [formGroupName]="i" class="row" style="margin: 0">
            <!-- nom -->
            <div class="col-6 form-group">
              <ng-select [items]="typologiesProduitsOptions"
                         [selectOnTab]="true"
                         [clearable]="true"
                         [addTag]="true"
                         [addTagText]="'Autre : '"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         formControlName="nom">
              </ng-select>
            </div>
            <!-- commentaire -->
            <div class="col-6 row form-group">
              <div class="col-11">
                <input type="text" class="form-control" formControlName="commentaire" [readonly]="viewOnly"/>
              </div>
              <div class="col-1">
                <div class="text-white pointer action" (click)="removeTypologie(i)" *ngIf="!viewOnly">
                  <i class="fa fa-minus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- conditions de prise en charge -->
      <div class="row p-t-10 block-red" formGroupName="conditionsPriseEnCharge">
        <div class="col-12 text-center p-b-5">
          Conditions de prise en charge
        </div>
        <div class="col-xl-6">
          <!-- taux de prise en charge -->
          <div class="row form-group">
            <label class="col-md-6 col-form-label">Taux de prise en charge </label>
            <div class="input-group col-md-6">
              <input type="number" step="0.1" class="form-control" formControlName="taux" [readonly]="viewOnly"/>
              <div class="input-group-append">
                <span class="input-group-text bg-grey">%</span>
              </div>
            </div>
          </div>
          <!-- justificatif -->
          <div class="row form-group">
            <label class="col-md-6 col-form-label" id="justificatif">Justificatif </label>
            <div class="col-md-6">
              <ng-select [items]="justificatifsOptions"
                         [selectOnTab]="true"
                         [clearable]="true"
                         [addTag]="true"
                         [addTagText]="'Autre : '"
                         labelForId="justificatif"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         formControlName="justificatif">
              </ng-select>
            </div>
          </div>
          <!-- frais de transport -->
          <div *ngIf="contextSiege" class="row form-group">
            <label class="col-md-5 col-form-label">Frais de transport </label>
            <div class="col-md-7">
              <ng-select [items]="fraisDeTransportSelect"
                         [selectOnTab]="true"
                         [clearable]="true"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         bindValue="libelle"
                         bindLabel="libelle"
                         formControlName="fraisTransport">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <!-- limite de responsabilité -->
          <div *ngIf="contextSiege" class="row form-group">
            <label class="col-md-6 col-form-label">Limite de responsabilité </label>
            <div class="col-md-6">
              <ng-select [items]="limiteResponsabiliteOptions"
                         [selectOnTab]="true"
                         [clearable]="true"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         formControlName="limiteResponsabilite">
              </ng-select>
            </div>
          </div>
          <!-- commentaire justificatif -->
          <div *ngIf="contextSiege" class="row form-group">
            <label class="col-md-4 col-form-label">Commentaire </label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="commentaire"
                     triggers="mouseenter:mouseleave"
                     popoverTitle="Commentaire"
                     container="body"
                     [readonly]="viewOnly"
                     [ngbPopover]="itemJustificatifCommentaire"/>
            </div>
          </div>
          <!-- sav multicolis -->
          <div *ngIf="contextSiege" class="row form-group">
            <label class="col-md-5 col-form-label">SAV sur multicolis </label>
            <div class="col-md-7">
              <ng-select [items]="savMulticolisOptions"
                         [selectOnTab]="true"
                         [clearable]="true"
                         placeholder="Choisir"
                         [readonly]="viewOnly"
                         formControlName="savMulticolis">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-12 text-center m-b-5 flux-title">
          Flux Aller
        </div>
        <div class="col-xl-6" formGroupName="fluxAller">
          <div formGroupName="reception">
            <div class="text-center m-b-5 flux-subtitle">
              Réception
            </div>
            <!-- approche -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Approche </label>
              <div class="col-md-6">
                <input class="form-control" formControlName="approche" [readonly]="viewOnly"/>
              </div>
            </div>
            <!-- document -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Document de transport </label>
              <div class="col-md-6">
                <ng-select [items]="documentTransportOptions"
                           [selectOnTab]="true"
                           [clearable]="true"
                           placeholder="Choisir"
                           [readonly]="viewOnly"
                           formControlName="document">
                </ng-select>
              </div>
            </div>
            <!-- délai de réserves -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Délai de réserves </label>
              <div class="col-md-6">
                <input class="form-control" formControlName="delaiReserves"
                       [readonly]="viewOnly"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6" formGroupName="fluxAller">
          <div formGroupName="livraison">
            <div class="text-center m-b-5 flux-subtitle">
              Livraison
            </div>
            <!-- délai de réserves -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Délai de réserves </label>
              <div class="col-md-6">
                <input class="form-control" formControlName="delaiReserves"
                       [readonly]="viewOnly"/>
              </div>
            </div>
            <!-- commentaire -->
            <div class="row form-group">
              <label class="col-md-4 col-form-label">Commentaire </label>
              <div class="col-md-8">
                <input class="form-control" formControlName="commentaire"
                       triggers="mouseenter:mouseleave"
                       popoverTitle="Commentaire"
                       container="body"
                       [readonly]="viewOnly"
                       [ngbPopover]="itemAllerCommentaire"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 text-center m-b-5 flux-title">
          Flux Reverse
        </div>
        <div class="col-xl-6" formGroupName="fluxRetour">
          <div>
            <!-- Flux reverse -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Flux Reverse </label>
              <div class="col-md-6">
                <input class="form-control" formControlName="retour" [readonly]="viewOnly"/>
              </div>
            </div>
            <!-- délai de Reverse -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Délai de Reverse </label>
              <div class=" col-md-6">
                <input class="form-control" formControlName="delaiRetour" [readonly]="viewOnly"/>
              </div>
            </div>
            <!-- commentaire -->
            <div class="row form-group">
              <label class="col-md-4 col-form-label">Commentaire </label>
              <div class="col-md-8">
                <input class="form-control" formControlName="commentaire"
                       triggers="mouseenter:mouseleave"
                       popoverTitle="Commentaire"
                       container="body"
                       [readonly]="viewOnly"
                       [ngbPopover]="itemReverseCommentaire"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6" formGroupName="fluxRetour">
          <div>
            <!-- document -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Document de transport </label>
              <div class="col-md-6">
                <ng-select [items]="documentTransportOptions"
                           [selectOnTab]="true"
                           [clearable]="true"
                           placeholder="Choisir"
                           [readonly]="viewOnly"
                           formControlName="document">
                </ng-select>
              </div>
            </div>
            <!-- delai de reserve -->
            <div class="row form-group">
              <label class="col-md-6 col-form-label">Délai de réserves </label>
              <div class="col-md-6">
                <input type="text" class="form-control" formControlName="delaiReserves" [readonly]="viewOnly"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- transfert de propriété -->
      <div *ngIf="contextSiege" class="row p-t-10 block-blue" formGroupName="transfertPropriete">
        <div class="col-12 text-center p-b-5">
          Transfert de propriété
        </div>
        <div class="col-xl-6">
          <div class="row form-group">
            <label class="col-md-6 col-form-label">Transfert </label>
            <div class="switcher" *ngIf="!viewOnly">
              <input type="checkbox" name="transfert"
                     id="transfert" formControlName="transfert">
              <label for="transfert"></label>
            </div>
            <button *ngIf="viewOnly" class="btn btn-icon" [ngClass]="{
              'btn-green': annuaireItem.config?.transfertPropriete?.transfert,
              'btn-danger': !annuaireItem.config?.transfertPropriete?.transfert}">
              <i class="fa fa-2x" [ngClass]="{
                'fa-check-square': annuaireItem.config?.transfertPropriete?.transfert,
                'fa-times': !annuaireItem.config?.transfertPropriete?.transfert}">
              </i>
            </button>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="row form-group">
            <label class="col-md-4 col-form-label">Commentaire </label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="commentaire"
                     triggers="mouseenter:mouseleave"
                     popoverTitle="Commentaire"
                     container="body"
                     [readonly]="viewOnly"
                     [ngbPopover]="itemTransfertProprieteCommentaire"/>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-t-10">
        <div class="col-xl-12">
          <!-- particularités -->
          <div class="row form-group">
            <label class="col-md-3 col-form-label">Particularités </label>
            <div class="col-md-9">
              <input type="text" class="form-control" formControlName="particularites"
                     triggers="mouseenter:mouseleave"
                     popoverTitle="Particularités"
                     container="body"
                     [readonly]="viewOnly"
                     [ngbPopover]="itemParticularites"/>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</panel>

<div class="row m-10" *ngIf="!viewOnly">
  <div class="col-6 text-left">
    <button [ladda]="deletingItem" (click)="deleteItem()" [hidden]="loadingAnnuaire || !annuaireItem.id"
            class="btn btn-grey">Supprimer
    </button>
  </div>
  <div class="col-6 text-right">
    <button [ladda]="savingItem" (click)="submit()" [disabled]="loadingAnnuaire || annuaireForm?.pristine"
            class="btn btn-agediss">Enregistrer
    </button>
  </div>
</div>
