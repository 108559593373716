import {Component, OnDestroy, OnInit} from '@angular/core';
import {FactureService} from '../../../shared/services/api/facture.service';
import {Facture} from '../../../shared/models/api/facture.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalAffecterFactureComponent} from '../../../components/facture/modal/modal-affecter-facture/modal-affecter-facture.component';
import {ModalAttenteFactureComponent} from '../../../components/facture/modal/modal-attente-facture/modal-attente-facture.component';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {ModalFactureTemporaireComponent} from '../../../components/facture/modal/modal-facture-temporaire/modal-facture-temporaire.component';
import {ModalAffecterEditableFactureComponent} from '../../../components/facture/modal/modal-affecter-editable-facture/modal-affecter-editable-facture.component';


@Component({
  selector: 'facturesAffecter',
  templateUrl: './facturesAffecter.html'

})
export class FacturesAffecterPage implements OnDestroy, OnInit {

  public loadingTable = false;
  public displayedColumns = [
    'idPieceJointe',
    'client',
    'typeFacture',
    'date',
    'numeroInterne',
    'montant',
    'options',
    'affecter',
  ];
  public temporairesColumns = [
    'idPieceJointe',
    'client',
    'typeFacture',
    'date',
    'numeroInterne',
    'dateFinAttente',
    'motifAttente',
    'options',
    'affecter',
  ];
  public context = 'affecter';

  public showTemporaire = false;
  public facturesTemporaires: Facture[];
  public facturesNonTemporaires: Facture[];
  public subscriptions: Subscription[] = [];
  private activModal: NgbModalRef;

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();


  constructor(private factureSvc: FactureService, private modalSvc: NgbModal) {
  }


  ngOnInit() {
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  openModalAffectationFacture(event) {
    this.activModal = this.modalSvc.open(ModalAffecterFactureComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.componentInstance.facture =  event;
    this.activModal.result.then(
      (result) => {
        this.loadData();
      }, reason => {
      });
  }


  openModalAffectationEditableFacture(event) {
    this.activModal = this.modalSvc.open(ModalAffecterEditableFactureComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.componentInstance.factureId = event.id;
    this.activModal.result.then(
      (result) => {
        this.loadData();
      }, reason => {
      });
  }


  openModalEditFacture(event) {
    this.activModal = this.modalSvc.open(ModalAttenteFactureComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.componentInstance.factureId =  event.id;
    this.activModal.componentInstance.disabled =  true;
    this.activModal.result.then(
      (result) => {
        this.loadData();
      }, reason => {
      });
  }


  openModalFactureTemporaire(event) {
    this.activModal = this.modalSvc.open(ModalFactureTemporaireComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.componentInstance.factureId =  event.id;
    this.activModal.componentInstance.index =  event.index;
    this.activModal.componentInstance.disabled =  true;
    this.activModal.result.then(
      (result) => {
        this.facturesTemporaires.push(result.facture);
        this.facturesNonTemporaires.splice(result.index, 1);
        this.facturesNonTemporaires = this.facturesNonTemporaires.slice();
      }, reason => {
      });
  }


  onAffected(bool) {
    if (bool) {
      this.loadData();
    }
  }


  onSubmit(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
      console.log(filter);
    }
    this.loadData();

  }


  loadData() {
    this.loadingTable = true;
    this.subscriptions.push(this.factureSvc.getFacturesAffecter(this.filter).subscribe(factures => {
      this.facturesTemporaires = factures.filter((fact) => fact.is_temporaire);
      this.facturesNonTemporaires = factures.filter((fact) => !fact.is_temporaire);
      this.loadingTable = false;
    }));
  }



}

