import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {DatatableHelper} from "../../../../shared/helpers/datatable.helper";
import {Colis} from "../../../../shared/models/api/colis.model";

@Component({
  selector: 'app-table-colis',
  templateUrl: './table-colis.component.html',
  styleUrls: ['./table-colis.component.scss']
})
export class TableColisComponent implements OnInit {

  @Input() data: Colis[];
  @Input() displayedColumns: string[] = ['codeBarre'];
  @Input() noRecordText = 'Aucun Colis';
  @Input() loadingText = 'Chargements en cours <i class=\'fas fa-spin fa-circle-notch\'></i>'
  @Input() loading = false;

  @Output() selectColis: EventEmitter<{id : number, index: number}> = new EventEmitter<{id : number, index: number}>();

  public dataSorted: MatTableDataSource<Colis>;


  constructor() { }

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {




    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

}
