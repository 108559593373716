import {HttpParams} from '@angular/common/http';


export class HttpUtil {


  public static convertObjectToHttpParams(object) {
    if(!object ){
      return null
    }
    let target: HttpParams = new HttpParams();
    Object.keys(object).forEach((key: string) => {
      const value: string | number | boolean | Date | any = object[key];

      if (value instanceof Date) {
        target = target.append(key, value.toISOString());
      }else if (Array.isArray(value)){
        value.forEach((item) => {
          target = target.append(`${key.toString()}[]`, item);
        });
      }else if ((typeof value !== 'undefined') && (value !== null) && !(value instanceof Date)) {
        target = target.append(key, value.toString());
      }
    });
    return target;
  }

  public static extractFilenameInContentDisposition(contentDisposition: string): string {
    return contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .replace(/"/g,"")
      .trim();
  }

  /**
   *
   * @param url
   * @param addDot
   */
  public static getDomain(url,addDot = false) {
    let hostName = HttpUtil.getHostName(url);
    let domain = hostName;

    if (hostName != null) {
      var parts = hostName.split('.').reverse();

      if (parts != null && parts.length > 1) {
        domain = parts[1] + '.' + parts[0];

        if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
          domain = parts[2] + '.' + domain;
        }
      }
      if(addDot){
        domain = '.'+domain;
      }
    }

    return domain;
  }

  public static getHostName(url) {
    let match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    }
    else {
      return url;
    }
  }

  /**
   *
   * @param url
   */
  public static getUrlWithoutHost(url:string){
      return url.replace (/^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '$1')
  }
}
