import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {PieceJointe} from '../../models/api/piece-jointe.model';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {TypePieceJointe} from '../../models/api/type-piece-jointe.model';
import {HttpUtil} from '../../utils/http.util';
import {FilterMultiCritereModel} from "../../models/filters/filterMultiCritere.model";
import {PieceJointeGed} from "../../models/api/piece-jointe-ged.model";

@Injectable({
  providedIn: 'root'
})
export class PieceJointeService {

  urlRessourcePieceJointe = environment.url_api_litige + '/piece-jointes';
  urlRessourceTypePieceJointe = environment.url_api_litige + '/type-piece-jointes';


  constructor(
    private http: HttpClient, private toastr: ToastrService) {
  }


  static getFile(fileId: number): PieceJointe {
    return;
  }

  static getLitigeFiles(litigeId: number): PieceJointe [] {
    return [];
  }


  getJustificatifs(filter: any): Observable<PieceJointe[]> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessourcePieceJointe}-justificatifs`, {params: HttpUtil.convertObjectToHttpParams(filter), headers: new HttpHeaders().set('nocache', 'true')})
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      })));
  }

  getPieceJointeComptable(): Observable<PieceJointe[]> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessourcePieceJointe}-comptable`,{ headers: new HttpHeaders().set('nocache', 'true')})
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      })));
  }

  getJustificatifsAffecter(filter: FilterMultiCritereModel = null): Observable<PieceJointe[]> {
    // let params = HttpUtil.convertObjectToHttpParams({statutCode: 'JUS_0', Affecter:true, refuser:false});
    const concatFilter = Object.assign({statutCode: 'JUS_0', Affecter: true, refuser: false}, filter);
    const params = HttpUtil.convertObjectToHttpParams(concatFilter);
    return this.http
      .get<PieceJointe[]>(`${this.urlRessourcePieceJointe}-justificatifs`, {
        params: params,
        headers: new HttpHeaders().set('nocache', 'true')
      })
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      })));
  }

  getNbJustificatif(): Observable<number> {
    const params = HttpUtil.convertObjectToHttpParams({statutCode: 'JUS_0', Affecter: true, refuser: false});
    return this.http.get<number>(`${this.urlRessourcePieceJointe}-justificatifs-nb`, {params: params});
  }

  getJustificatifsRefuser(filter: FilterMultiCritereModel = null): Observable<PieceJointe[]> {
    const concatFilter = Object.assign({Affecter: true, refuser: true, statutCode: "JUS_2"}, filter);
    const params = HttpUtil.convertObjectToHttpParams(concatFilter);
    return this.http
      .get<PieceJointe[]>(`${this.urlRessourcePieceJointe}-justificatifs`, {params: params})
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      })));
  }

  getJustificatif(id: number): Observable<PieceJointe> {
    return this.http
      .get<PieceJointe>(`${this.urlRessourcePieceJointe}-justificatifs/${id}`,{ headers: new HttpHeaders().set('nocache', 'true')})
      .pipe(map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      }));
  }

  getPieceJointe(id: number): Observable<PieceJointe> {
    return this.http
      .get<PieceJointe>(`${this.urlRessourcePieceJointe}/${id}`,{ headers: new HttpHeaders().set('nocache', 'true')})
      .pipe(map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      }));
  }


  getTypePieceJointe(isJustif = null, isComptable = null): Observable<TypePieceJointe[]> {
    let params = new HttpParams();

    if (isJustif !== null) {
      params = HttpUtil.convertObjectToHttpParams({isJustif: isJustif})
    }
    if (isComptable !== null) {
      params = HttpUtil.convertObjectToHttpParams({isComptable: isComptable})
    }
    return this.http
      .get<TypePieceJointe[]>(`${this.urlRessourceTypePieceJointe}`, {params: params})
      .pipe(map((typePieceJointe) => typePieceJointe.map((typePieceJointe) => {
        return new TypePieceJointe().deserialize(typePieceJointe);
      })));
  }


  downloadFile(fileId): Observable<{ data: Blob, filename: string }> {
    return this.http
      .get(this.urlRessourcePieceJointe + '/' + fileId + '/download',
        {
          responseType: 'blob',
          observe: 'response',
        }
      ).pipe(map(res => {
        if (!res.headers.get('content-disposition')) {
          throw "L'api ne renvoi pas le header 'content-disposition'";
        }
        return {
          filename: HttpUtil.extractFilenameInContentDisposition(res.headers.get('content-disposition')),
          data: res.body,
        };
      }))
  }


  validateJustificatif(pieceJointe: PieceJointe): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessourcePieceJointe}-justificatif/${pieceJointe.id}/validation`, pieceJointe)
      .pipe(map((response) => {
        return response.next;
      }));
  }

  updatePieceJointe(pieceJointe: PieceJointe): Observable<any> {
    return this.http.put<{ message: string }>(`${this.urlRessourcePieceJointe}/${pieceJointe.id}/update`, pieceJointe)
      .pipe(map((response) => {
        return response;
      }));
  }


  delete(fileId: number): Observable<PieceJointe> {
    return this.http.delete<PieceJointe>(`${this.urlRessourcePieceJointe}/${fileId}/delete`).pipe(map((piecejointe) => {
      return new PieceJointe().deserialize(piecejointe);
    }));
  }

  getFilePreviewGed(pieceJointeId: number): Observable<PieceJointeGed[]> {
    return this.http
      .get<PieceJointeGed[]>(`${this.urlRessourcePieceJointe}/${pieceJointeId}/visualisation-ged`)
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
        return new PieceJointeGed().deserialize(pieceJointe);
      })));
  }


  /**
   * Cette methode retourne l'url blob du fichier
   * @param pieceJointeId
   * @return string
   */
  getFilePreview(pieceJointeId: number): Observable<Blob> {
    return this.http
      .get(`${this.urlRessourcePieceJointe}/${pieceJointeId}/visualisation`, {responseType: "blob"})
      .pipe(
        map(response => {
            return response;
          }
        ));
  }


}
