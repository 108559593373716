<!-- begin Form -->
<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <!-- begin Form first row -->
  <div class="row">
    <div *ngIf="!hiddenInput['pole']" class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Pôle</span>
        <ng-select class="ng-select filter"
                   [items]="pole"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadPole"
                   [clearable]="true"
                   labelForId="pole"
                   placeholder="Choisir"
                   formControlName="pole">
        </ng-select>
      </div>
    </div>
  </div>
  <!-- end Form trhird row -->

  <!-- begin Form fourth row -->
  <div class="form-group row m-t-10">
    <div class="offset-5 col-2 p-r-0">
      <input class="btn btn-agediss btn-lg btn-block" type="submit" value="Filtrer">
    </div>
    <div class="px-0">
      <button type="button" class="btn btn-agediss btn-lg m-l-4"
              ngbTooltip="Effacer les infos selectionnées"
              placement="right"
              container="body"
              (click)="reset()">
        <i class='fa fa-times'></i>
      </button>
    </div>
  </div>
  <!-- end Form fourth row -->


</form>


