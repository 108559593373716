import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subscription} from 'rxjs';
import {FieldParametersModel} from "../../../shared/models/filters/field-parameters.model";
import {FilterMultiCritereModel} from "../../../shared/models/filters/filterMultiCritere.model";
import {FormControl, FormGroup} from "@angular/forms";
import {Plateforme} from "../../../shared/models/api/plateforme.model";
import {Statut} from "../../../shared/models/api/statut.model";
import {User} from "../../../shared/models/user.model";
import {Client} from "../../../shared/models/api/client.model";
import {AuthenticationService} from "../../../shared/services/authentication.service";
import {PlateformeService} from "../../../shared/services/api/plateforme.service";
import {UserService} from "../../../shared/services/api/user.service";
import {StatutService} from "../../../shared/services/api/statut.service";
import {ClientService} from "../../../shared/services/api/client.service";
import {LitigeService} from "../../../shared/services/api/litige.service";
import {FormfilterHelper} from "../../../shared/helpers/Formfilter.helper";


@Component({
  selector: 'app-filter-imputation',
  templateUrl: './filter-imputation.component.html',
  styleUrls: ['./filter-imputation.component.scss']
})
export class FilterImputationComponent implements OnInit, OnDestroy {

  @Input()
  displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public filterForm = new FormGroup({
    plateforme: new FormControl(),
    client: new FormControl(),
    pole: new FormControl(),
    origine: new FormControl(),
    statut: new FormControl(),
    motif: new FormControl(),
    createdAt: new FormControl(),
    responsable: new FormControl(),
    traitePar: new FormControl(),
    search: new FormControl()
  });

  hiddenInput = [];
  clients: Client[];
  plateformes: Plateforme[];
  statuts: Statut[];
  users: User[];

  private subscriptions: Subscription[] = [];


  /**
   *
   * @param litigesSvc
   * @param authSvc
   * @param clientSvc
   * @param plateformeSvc
   * @param statutSvc
   * @param userSvc
   */
  constructor(private litigesSvc: LitigeService, private authSvc: AuthenticationService,
              private clientSvc: ClientService, private plateformeSvc: PlateformeService,
              private statutSvc: StatutService, private userSvc: UserService) {
  }

  ngOnInit() {
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput,this.hiddenInput);
    this.subscriptions.push(this.userSvc.getUsers().subscribe(value => this.users = value));
    this.subscriptions.push(this.clientSvc.getClients().subscribe(value => this.clients = value));
    this.subscriptions.push(this.plateformeSvc.getPlateformes().subscribe(value => this.plateformes = value));
    this.subscriptions.push(this.statutSvc.getStatuts(StatutService.LITIGE).subscribe(value => this.statuts = value));
  }

  onSubmit() {
    //récupère les infos du filtre
    this.filter = this.filterForm.getRawValue();
    this.submitFilter.emit(this.filter);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
