<!-- begin page-header -->
<h1 class="page-header">Tableau de bord Administrateur

</h1>
<!-- end page-header -->

<div class="row">
  <div class="col-12">
    <button class="btn btn-agediss" (click)="downloadErrorLogImport()"> Voir les litige non importés lors de l'import quotidien.</button>
  </div>
</div>




