import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {FactureService} from '../../../shared/services/api/facture.service';
import {View} from '../../../shared/models/view.model';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Facture} from '../../../shared/models/api/facture.model';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {Litige} from '../../../shared/models/api/litige.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {PieceJointe} from '../../../shared/models/api/piece-jointe.model';
import {Imputation} from '../../../shared/models/api/imputation.model';
import {NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import * as Editor from '@ckeditor/ckeditor5-build-classic';
import {DateService} from "../../../shared/services/date.service";


@Component({
  selector: 'app-facture',
  templateUrl: './facture.html',
  styleUrls: ['./facture.scss']
})

export class FacturePage implements OnInit {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public loadingTableFactures = false;
  public facture: Facture;
  public files: PieceJointe[];
  public litiges: Litige[] = [];
  public imputations: any[] = [];
  public imputationsAvoir: Imputation[];
  public hiddenColumnImputation = [];
  public factureId: number;
  public subscriptions: Subscription[] = [];
  public view: View;
  public dateBap: NgbDateStruct;
  public noRecordTextPieceCOmptableAssocie = 'Pas de pièces comptables associées.';
  public enableSupBap = false;

  @ViewChild('datepicker', {static: false}) private input: NgbInputDatepicker;

  public displayInputFilter = [];
  public loadingPage = true;
  public loadingValideBap = false;
  public loadingRemoveBap = false;
  public dateBapPersisted = false;
  public loadingLitige = true;
  public loadingImputation = true;
  public loadingPiecesComptable = true;

  public loaderNote = false;
  public Editor = Editor;
  public EditorData = '';
  public EditorConfig = {
    language: 'fr', toolbar: [
      'heading',
      '|', 'bold', 'italic', 'blockQuote',
      '|', 'NumberedList', 'BulletedList',
      '|', 'Undo', 'Redo']
  };
  public enableEditorSaveButton = false;
  public notesSubscription: Subscription;


  public displayedColumnsAvoir: string[] = [
    'typeFacture',
    'facture',
    'montantFacture',
    'statutCalculer'
  ];
  public displayedColumnsLitiges: string[] = [
    'id',
    'numeroAvis',
    'referenceComplementaire',
    'numeroCommande',
    'statut',
    'type',
    'flux',
    'date',
    'montantFacture',
    'montantValideBis',
    'montantAvoir',
    'montantAvoirAttendu',
    'statutBapFacture'
  ];

  constructor(private factureSvc: FactureService,
              private router: Router,
              private authSvc: AuthenticationService,
              private toastSvc: ToastrService,
              private routeActive: ActivatedRoute,
              public _parserFormatter: NgbDateParserFormatter
  ) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {

    if (!this.factureId) {
      this.routeActive.params.subscribe((params) => {
        this.factureId = params.id;
        this.loadLitige();
      });
    } else {
      this.loadLitige();
    }
  }

  loadLitige() {
    this.litiges = [];
    this.subscriptions.push(this.factureSvc.getFacture(this.factureId).subscribe(
      (facture) => {
        this.facture = facture;
        this.dateBap = DateService.convertStringToDateStruct(facture.date_bap as string);
        if (!isNaN(this.dateBap.year)){
          this.enableSupBap = true;
          this.dateBapPersisted = true;
        }
        const litiges = [];
        this.facture.facture_litiges.forEach(value => {
          litiges.push(value.litige);
        });
        this.litiges = litiges;
      },
      (err) => {
        let msg = 'Vous n\'avez pas accès à cette resource.';
        if (err.error.message != null) {
          msg = err.error.message;
        }
        Swal
          .fire({titleText: msg})
          .then(
            () => {
              this.router.navigate(['/litiges']);
            }
          ).catch(() => {
          Swal.fire('Désolé.', 'L\'application a rencontrée une erreur.');
        });
      }, () => {
        this.loadImputation();
        this.loadingPage = false;
      }));
  }

  loadImputation() {
    this.loadingImputation = true;
    this.subscriptions.push(this.factureSvc.getFactureImputation(this.factureId).subscribe(
      factures => {
        this.imputations = factures;
        // Object.keys(factures).forEach(num_interne => {
        //   this.imputations = factures[num_interne];
        // });
      },
      (err) => {
        let msg = 'Vous n\'avez pas accès à cette resource.';
        if (err.error.message != null) {
          msg = err.error.message;
        }
        Swal
          .fire({titleText: msg})
          .then(
            () => {
              this.router.navigate(['/litiges']);
            }
          ).catch(() => {
          Swal.fire('Désolé.', 'L\'application a rencontrée une erreur.');
        });
      }, () => {
        this.loadPiecesComptable();
        this.loadingImputation = false;
      }));
  }

  loadPiecesComptable() {
    this.subscriptions.push(this.factureSvc.getFactureAvoirs(this.facture.id, this.facture.type_facture.code).subscribe(
      imputation => {
        this.imputationsAvoir = imputation;
      },
      (err) => {
        let msg = 'Vous n\'avez pas accès à cette resource.';
        if (err.error.message != null) {
          msg = err.error.message;
        }
        Swal
          .fire({titleText: msg})
          .then(
            () => {
              this.router.navigate(['/litiges']);
            }
          ).catch(() => {
          Swal.fire('Désolé.', 'L\'application a rencontrée une erreur.');
        });
      }, () => {
        this.loadingPiecesComptable = false;
      }));
  }

  changeDateBap(event) {
    this.dateBapPersisted = false;

  }

  dateBapFacture() {
    if (this.dateBapPersisted) {
      return;
    }
    this.loadingValideBap = true;
    this.persistBap(this.dateBap);
  }

  resetBap() {
    this.loadingRemoveBap = true;
    this.persistBap(null);
    this.enableSupBap = false;

  }

  persistBap(dateBap) {
    this.factureSvc.updateDateBap(this.factureId, dateBap).subscribe(() => {
        this.dateBap = dateBap;
        this.dateBapPersisted = true;
        this.loadingValideBap = this.loadingRemoveBap = false;

        // reload facture
        this.ngOnInit();
      },
      () => {
      });
  }

  openDatePicker() {
    this.input.toggle();
  }

  public onNotesChange({editor}: ChangeEvent) {
    this.EditorData = editor.getData();
    this.enableEditorSaveButton = true;
  }

  saveNotes() {
    this.loaderNote = true;
    if (this.notesSubscription) {
      this.notesSubscription.unsubscribe();
    }
    this.notesSubscription = this.factureSvc.updateNotes(this.factureId, this.facture.notes).subscribe(() => {
        this.toastSvc.info('Modifications enregistrées', 'Info', {progressBar: true});
        this.loaderNote = false;
        this.enableEditorSaveButton = false;
      },
      () => {
        this.toastSvc.error('Modifications non enregistrées', 'Info', {progressBar: true});
        this.loaderNote = false;
      });
  }


}
