import {Injectable} from '@angular/core';

@Injectable()
export class DatatableHelper {

  static compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  static compareString(a: number | string, b: number | string, isAsc: boolean) {
    if(isAsc){
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
    }else{
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
    }
  }
}
