import {Component, HostListener, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {SlimLoadingBarService} from 'ng2-slim-loading-bar';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import pageSettings from './config/page-settings';
import {User} from './shared/models/user.model';
import {AuthenticationService} from './shared/services/authentication.service';
import {NgSelectConfig} from '@ng-select/ng-select';
import {View} from './shared/models/view.model';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  public currentUser: User;
  public pageSettings;
  public pageHasScroll;
  public view: View;

  constructor(
    private titleService: Title,
    private slimLoadingBarService: SlimLoadingBarService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private config: NgSelectConfig) {


    this.config.notFoundText = 'Aucun éléments';

    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (window.innerWidth < 768) {
          this.pageSettings.pageMobileSidebarToggled = false;
        }
        if (e.url !== '/') {
          slimLoadingBarService.progress = 50;
          slimLoadingBarService.start();
        }
      }
      if (e instanceof NavigationEnd) {
        if (e.url !== '/') {
          setTimeout(() => {
            slimLoadingBarService.complete();
          }, 300);
        }
      }
    });
  }

  ngOnInit() {
    // page settings
    this.pageSettings = pageSettings;
    this.authenticationService.currentUser.subscribe(x => {
      if (x !== null && x != undefined) {
        this.currentUser = x;
      } else {

        this.authenticationService.logout();
      }
    });
    this.authenticationService.currentView.subscribe(x => {
      if (x !== null && x != undefined) {
        this.view = x;
      }
    });
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    this.pageHasScroll = top > 0;
  }

  // set page minified
  onToggleSidebarMinified(): void {
    this.pageSettings.pageSidebarMinified = !this.pageSettings.pageSidebarMinified;
  }

  // set page right collapse
  onToggleSidebarRight(): void {
    this.pageSettings.pageSidebarRightCollapsed = !this.pageSettings.pageSidebarRightCollapsed;
  }

  // hide mobile sidebar
  onHideMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }


  // hide right mobile sidebar
  onHideMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }


}
