import {Deserializable} from '../interfaces/deserializable.interface';
import {TypeFacture} from './type-facture.model';
import {Client} from './client.model';
import {PieceJointe} from './piece-jointe.model';
import {Litige} from './litige.model';
import {Imputation} from './imputation.model';
import {FactureLitige} from './factureLitige.model';

export class Facture implements Deserializable {

  public id: number;
  public type_facture: TypeFacture;
  public numero: string;
  public date: Date | string;
  public date_bap: Date | string;
  public montant: number;
  public etat: string;
  public numero_interne: string;
  public numero_client: string;
  public mois_inputation_comptable: Date | string;
  public created_at: Date;
  public updated_at: Date;
  public client: Client;
  public piece_jointe?: PieceJointe;
  public imputations?: Imputation;
  public litiges?: Litige[];
  public has_litige?: boolean;
  public is_temporaire?: boolean;
  public pending: boolean;
  public facture_litiges: FactureLitige[];
  public factures_asso: Facture[];
  public avoirs_asso: Facture[];
  public other_facture: Facture[];
  public statut_bap: string;
  public date_fin_attente: Date;
  public motif_attente: string;
  public notes: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.notes = !!input.notes ? input.notes : '';
    this.type_facture = (input.type_facture) ? new TypeFacture().deserialize(input.type_facture) : null;
    this.client = (input.client) ? new Client().deserialize(input.client) : null;
    this.piece_jointe = (input.piece_jointe) ? new PieceJointe().deserialize(input.piece_jointe) : null;
    return this;
  }

}
