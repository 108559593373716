import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {LitigeService} from '../../shared/services/api/litige.service';
import {Litige} from '../../shared/models/api/litige.model';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Subscription} from 'rxjs';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {ModalCreateLitige} from '../../components/litige/modal/modal-create-litige/modal-create-litige';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {View} from '../../shared/models/view.model';


@Component({
  selector: 'multiCritere',
  templateUrl: './multiCritere.html',
})

export class MultiCriterePage implements OnDestroy, OnInit {

  public limits = [5, 10, 25, 50];
  public limit = 200;
  public litiges: Litige[];
  private litigeSub: Subscription;
  public loading: boolean;
  public activModal: NgbModalRef;
  public loadingNbLitige: boolean;
  public nbLitige: number = 0;
  public view: View;
  public innerWidth: number;
  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  constructor(private litigesSvc: LitigeService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal,
              private router: Router
  ) {
  }

  onSubmit(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.loadData();
  }

  ngOnDestroy(): void {
    this.litigeSub.unsubscribe();
  }

  ngOnInit(): void {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  onSort(sort): void {
    this.filter.orderChamp = sort.active;
    this.filter.orderDirection = sort.direction;
    this.loadData();
  }

  onLoadFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.loadingNbLitige = true;
    // on ajout la limit de reponse
    this.filter.limit = this.limit;
    setTimeout(() => {
      this.litigeSub = this.litigesSvc.getLitiges(this.filter).subscribe(
        (litiges) => {
          this.litiges = litiges;
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
      delete this.filter.limit;
      this.litigeSub = this.litigesSvc.getNbLitiges(this.filter).subscribe(
        (litiges) => {
          this.nbLitige = litiges;
          this.loadingNbLitige = false;
        }, error => {
          this.loadingNbLitige = false;
        });
    }, 150);

  }

  openModalCreateLitige() {
    this.activModal = this.modalSvc.open(ModalCreateLitige, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.result.then(
      value => {
        if (value) {
          this.router.navigate(['/litiges', value]);
        }
      },
    );
  }
}



