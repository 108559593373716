import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from "rxjs";
import {TypeLitigeService} from "../../../../shared/services/api/typeLitige.service";
import {TypeLitige} from "../../../../shared/models/api/type-litige.model";

@Component({
  selector: 'app-modal-create-litige',
  templateUrl: './modal-create-litige.html',
  styleUrls: ['./modal-create-litige.scss']
})
export class ModalCreateLitige implements OnInit, OnDestroy {

  public typeLitigeList: TypeLitige[];
  public currentContext: any = null;
  public displayedColumns: string[] = ['checkbox', 'reference', 'libelle', 'nbcolis', 'volume', 'poids', 'options'];
  public loadingStep1: Boolean = false;
  private subscriptions: Subscription[] = [];
  public currentTypeLitige: TypeLitige = null;

  constructor(
    public activModal: NgbActiveModal,
    public typeLitigeSvc: TypeLitigeService,
  ) {
  }

  ngOnInit() {
    this.loadingStep1 = true;
    //permet de retirer des types Litiges
    var filter = {except:"B2C"};
    this.subscriptions.push(this.typeLitigeSvc.getTypeLitige(filter).subscribe(value => {
        this.typeLitigeList = value;
        this.loadingStep1 = false;
      }
    ));
  }

  ngOnDestroy(): void {
  }

  close() {
    this.activModal.close()
  }

  onSubmit(submitResult) {
    this.activModal.close(submitResult)
  }

  changeSelect(event) {
    this.currentContext = event.code;
    this.currentTypeLitige = event;
  }


}
