import {Deserializable} from '../interfaces/deserializable.interface';
import {Responsabilite} from './responsabilite.model';

export class Retrocession implements Deserializable {

  public id: number;
  public responsabilite: Responsabilite;
  public avoir: boolean;
  public montant: number;
  public numero_facture: number;
  public date_facture: number;



  deserialize(input: any): this {
    Object.assign(this, input);
    this.responsabilite = (input.responsabilite) ? new Responsabilite().deserialize(input.responsabilite) : null;

    return this;
  }

}
