<table
  mat-table
  [dataSource]="dataSorted"
  (matSortChange)="sortData($event)"
  matSort
  matSortActive="date" matSortDirection="desc"
  class="table table-hover table-bordered mat-elevation-z8 text-center"
>
  <ng-container matColumnDef="facture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>N° saisie pièce comptable</th>
    <td *matCellDef="let element" mat-cell>
      {{element?.facture?.numero_interne}}  <i class="fa fa-info-circle" container="body"
                                             ngbTooltip="facture client : {{element?.facture?.numero_client}}"
                                             placement="top"></i>
    </td>
  </ng-container>
  <ng-container matColumnDef="typeFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.facture?.type_facture?.libelle}}">
      {{element?.facture?.type_facture?.libelle}}
      <i class="fa fa-unlink text-red-darker" *ngIf="!element.asso_facture && !visueFacture" container="body"
         ngbTooltip="Cet avoir n'est pas lié à une facture !"
         placement="top"></i>
    </td>
  </ng-container>
  <ng-container matColumnDef="montantFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant total</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.facture?.montant | currency : 'EUR':'€'}}">
      {{element?.facture?.montant | currency : 'EUR':'€'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numeroAvis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> BL</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.numero_avis}}">
      {{element?.numero_avis}}
    </td>
  </ng-container>
  <ng-container matColumnDef="acteur">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Entité</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.acteur?.libelle}}">
      {{element?.acteur?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="montant">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant imputé</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.montant | currency : 'EUR':'€'}}">
      {{element?.montant | currency : 'EUR':'€'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Mois imputation</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.facture?.numero_interne | dateImputation}}">
      {{element?.facture?.numero_interne | dateImputation}}
    </td>
  </ng-container>
  <ng-container matColumnDef="statutCalculer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.statut_bap}}">
      {{element?.statut_bap }}  <i *ngIf="element.is_prescrit"  ngbTooltip="Prescrit" placement="right" class="fa fa-exclamation-triangle text-red-darker"></i>
    </td>
  </ng-container>
  <ng-container matColumnDef="version">
    <th *matHeaderCellDef mat-header-cell>
      <i class="fa fa-info-circle"></i>
    </th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="version : {{element?.version}}  traité le : {{element.done_date | date : 'dd-MM-yyyy'}}">
      <i class="fa fa-info-circle"></i>
    </td>
  </ng-container>
  <ng-container matColumnDef="litige">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Litige </th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.id}}" class="px-10">
      <a [routerLink]="['/','litiges',element?.litige?.id]" class="label label-info text-white">
        {{element?.litige?.id}}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading" [ngClass]="{'code-partenaires': row.acteur?.code == codePartenaires}"></tr>
  <tr
    mat-footer-row
    *matFooterRowDef="['noData']"
    [hidden]="dataSorted && dataSorted?.data?.length > 0 || loading"
  >
  </tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>

</table>
