import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '../../../shared/models/user.model';
import {LitigeService} from '../../../shared/services/api/litige.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {Observable, Subscription} from 'rxjs';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {Client} from '../../../shared/models/api/client.model';
import {Plateforme} from '../../../shared/models/api/plateforme.model';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {Statut} from '../../../shared/models/api/statut.model';
import {Origine} from '../../../shared/models/api/origine.model';
import {Pole} from '../../../shared/models/api/pole.model';
import {MotifService} from '../../../shared/services/api/motif.service';
import {ClientService} from '../../../shared/services/api/client.service';
import {OrigineService} from '../../../shared/services/api/origine.service';
import {PlateformeService} from '../../../shared/services/api/plateforme.service';
import {PoleService} from '../../../shared/services/api/pole.service';
import {StatutService} from '../../../shared/services/api/statut.service';
import {UserService} from '../../../shared/services/api/user.service';
import {RangeDatePickerComponent} from '../../shared/range-date-picker/range-date-picker.component';
import {CompteClient} from '../../../shared/models/api/compte-client.model';
import {View} from '../../../shared/models/view.model';
import {FilterService} from '../../shared/filter.service';

@Component({
  selector: 'app-filter-contestation',
  templateUrl: './filter-contestation.component.html',
  styleUrls: ['./filter-contestation.component.scss']
})

export class FilterContestationComponent implements OnInit, OnDestroy {

  @ViewChild('inputDate') inputDate: RangeDatePickerComponent;

  @Input() displayInput: FieldParametersModel[] = [];
  @Input() pageName: string ;

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();


  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public filterForm = new FormGroup({
    plateforme: new FormControl(),
    client: new FormControl(),
    comptesClients: new FormControl(),
    origine: new FormControl(),
    statut: new FormControl(),
    motif: new FormControl(),
    minDate: new FormControl(),
    maxDate: new FormControl(),
    createdAt: new FormControl(),
    traitePar: new FormControl(),
    pole: new FormControl(),
    search: new FormControl(),
  });

  hiddenInput = [];

  users: User[];
  clients: Client[];
  compteClients: CompteClient[];
  origines: Origine[];
  plateformes: Plateforme[];
  statuts: Statut[];
  public pole: Pole[];

  public subscriptions: Subscription[] = [];

  public loadPlateformes = false;
  public loadClient = false;
  public loadGroup = false;
  public loadOrigines = false;
  public loadUsers = false;
  public loadPole = false;
  public view: View;


  public minDate;
  public maxDate;


  constructor(private litigesSvc: LitigeService, private authSvc: AuthenticationService,
              private motifSvc: MotifService, private clientSvc: ClientService,
              private origineSvc: OrigineService, private plateformeSvc: PlateformeService,
              private poleSvc: PoleService, private statutSvc: StatutService, private userSvc: UserService,
              private filterSvc: FilterService) {
  }

  ngOnInit() {

    this.authSvc.currentView.subscribe(x => this.view = x);
    this.loadPlateformes = true;
    this.loadClient = true;
    this.loadGroup = true;
    this.loadOrigines = true;
    this.loadUsers = true;
    this.loadPole = true;

    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput, this.hiddenInput);
    this.subscriptions.push(this.plateformeSvc.getPlateformes().subscribe(value => {
      this.plateformes = value;
      this.loadPlateformes = false;
    }));

    this.subscriptions.push(this.clientSvc.getClients().subscribe(value => {
      this.clients = value;
      this.loadClient = false;
    }));

    this.subscriptions.push(this.clientSvc.getCompteClients().subscribe(value => {
      this.compteClients = value;
      this.loadGroup = false;
    }));

    if (this.view.context == '2') {
      this.hiddenInput = ['plateforme', 'origine', 'pole', 'traitePar', 'responsable', 'statut'];
    } else {
      this.subscriptions.push(this.origineSvc.getOrigines().subscribe(value => {
        this.origines = value;
        this.loadOrigines = false;
      }));
      this.subscriptions.push(this.userSvc.getUsers().subscribe(value => {
        this.users = value;
        this.loadUsers = false;
      }));
      this.subscriptions.push(this.poleSvc.getPoles().subscribe(value => {
        this.pole = value;
        this.loadPole = false;
      }));
      this.subscriptions.push(this.statutSvc.getStatuts(StatutService.CONTESTATION).subscribe(value => this.statuts = value));
    }

    this.getValueLocalstorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  onSubmit() {
    // récupère les infos du filtre
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'searchFilter', 'search');

    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'plateformeFilter', 'plateforme');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'clientFilter', 'client');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'comptesClientsFilter', 'comptesClients');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'origineFilter', 'origine');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'statutFilter', 'statut');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'motifFilter', 'motif');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'createdAtFilter', 'createdAt');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'traiteParFilter', 'traitePar');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'poleFilter', 'pole');

    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'minDateFilter', 'minDate');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'maxDateFilter', 'maxDate');

    this.filter = this.filterForm.getRawValue();
    this.submitFilter.emit(this.filter);
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  reset(): void {
    this.filterForm.reset();
    this.inputDate.reset();

  }

  getValueLocalstorage() {
    this.filterForm.get('search').setValue(localStorage.getItem('searchFilter'));

    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'plateformeFilter', 'plateforme');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'clientFilter', 'client');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'comptesClientsFilter', 'comptesClients');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'origineFilter', 'origine');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'statutFilter', 'statut');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'motifFilter', 'motif');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, this.pageName + 'createdAtFilter', 'createdAt');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'traiteParFilter', 'traitePar');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'poleFilter', 'pole', false);

    this.filterSvc.onCheckValueLocalStorage(this.filterForm, this.pageName + 'minDateFilter', 'minDate');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'this.pageName+maxDateFilter', 'maxDate');



    this.onDateLocalstorage(this.pageName + 'minDateFilter', this.pageName + 'maxDateFilter');

  }

  /**
   *   on affiche la date enregistrée dans le localstorage, dans l'input
   */
  onDateLocalstorage(minDateFilterName, maxDateFilterName) {
    if (localStorage.getItem(minDateFilterName)){
      this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
    }
    if (localStorage.getItem(maxDateFilterName)) {
      this.maxDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(maxDateFilterName));
    }
  }
}
