import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {Contestation} from '../../../../shared/models/api/contestation.model';
import {Statut} from '../../../../shared/models/api/statut.model';

@Component({
  selector: 'app-table-contestation',
  templateUrl: './table-contestation.component.html',
  styleUrls: ['./table-contestation.component.scss']
})
export class TableContestationComponent implements OnInit, OnChanges {

  public dataSorted: MatTableDataSource<Contestation>;
  @Input() data: Contestation[];
  @Input() displayedColumns: string[] = ['id',
    'numeroAvis',
    'numeroCommande',
    'date',
    'destinataireNom',
    'client',
    'contestataire',
    'statut',
    'traitePar',
    'voirDetail'];
  @Input() noRecordText = 'Aucune contestation';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() showAddButton: boolean = false;
  @Input() showDeleteButton: boolean = false;
  @Input() showConversationButton: boolean = false;
  @Input() sortingAPI: Boolean = true;
  @Input() litigeStatut: Statut = null;

  @Output() onSelectConverstation: EventEmitter<number> = new EventEmitter<number>();
  @Output() onAddContestation: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();

  public disabledButton: Boolean = false;

  ngOnInit() {

    this.dataSorted = new MatTableDataSource(this.data);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (this.litigeStatut && this.litigeStatut.code != 'LIT_2') {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {

    if (this.sortingAPI == true) {
      this.onSorted.emit(sort);
    } else {
      const data = this.data.slice();
      if (!sort.active || sort.direction === '') {
        this.dataSorted = new MatTableDataSource(data);
        return;
      }
      this.dataSorted = new MatTableDataSource(data.sort(
        (a, b) => {
          const isAsc = sort.direction === 'asc';
          if (sort.active in a) {
            return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
          }
          return 0;
        }));
    }

  }

}
