import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AgedissError} from '../models/agediss-error.model';
import {AgedissErrorBody} from '../models/agediss-error-body.model';
import Swal from 'sweetalert2';
import {CookieService} from "ngx-cookie-service";
import {urlWithProtocol} from "../utils/util";

export function checkApi(http: HttpClient, cookieSvc: CookieService): () => Promise<any> {

  function removeLoader() {
    let element = document.getElementById('page-loader');
    element.parentNode.removeChild(element);
  }

  function checkCookie() {
    if (!cookieSvc.check('_taged_rights_access') || !cookieSvc.check('_taged_view')) {
      return false;
    }
    return true;
  }

  // inj = new Inject
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {

      if(!checkCookie()){
        console.warn("vous n'avez pas les cookies requis");
        document.location.href = urlWithProtocol(environment.urlsso);
      }

      return http.get(environment.url_api_litige + '/check-connexion')
        .toPromise().then(
          success => {
            resolve(null);
          }
        )
        .catch((err: HttpErrorResponse) => {
          const log = new AgedissError();
          log.message = 'Une erreur est survenue lors du controle de l\'api : ' + environment.url_api_litige;
          log.name = 'INITIALIZE_APP';
          log.body = new AgedissErrorBody().deserialize(<AgedissErrorBody>{
            erreur: err,
          });
          console.error(log)

          // le token est expiré ou absent
          if (err.status === 401 && err.url.match(/refresh/gm) !== null) {
            document.location.href = urlWithProtocol(environment.urlsso);
          }

          if (err.status === 500 || err.status === 0) {
            removeLoader();
            Swal.fire({
              title: 'Attention',
              text: 'L\'application n\'a pas pu vous authentifier sur l\'api : ' + environment.url_api_litige + ' \n Veuillez contacter le service informatique',
              showCancelButton: false,
              icon: 'error',
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
            }).then(() => {
              document.location.href = urlWithProtocol(environment.urlsso);
            });
            reject(err);
          }


          // l'utilisateur n'est pas présent dans les utilisateur de l'application ou il n'a pas les droit d'accès nécéssaires.
          if (err.status === 401) {
            removeLoader();
            Swal.fire({
              title: 'Attention',
              text: 'Vous n\'avez pas accès à l\'application litige, vous allez etre redirigé vers la liste des applications',
              showCancelButton: false,
              icon: 'error',
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
            }).then(() => {
              document.location.href = urlWithProtocol(environment.urlsso);
            });
            reject(err);
          }
          // on laisse l'interceptor http interpréter le retour
          resolve(err);
        });
    });
  };

}
