import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {FactureService} from '../../../shared/services/api/facture.service';
import {View} from '../../../shared/models/view.model';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Facture} from '../../../shared/models/api/facture.model';
import {Subscription} from 'rxjs';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';


@Component({
  selector: 'app-factures',
  templateUrl: './factures.html',
  styleUrls: ['./factures.scss']
})
export class FacturesPageComponent implements OnInit {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public loadingTableFactures: boolean = false;
  public factures: Facture[];
  public subscriptions: Subscription[];
  public view: View;

  public displayInputFilter = [
    new FieldParametersModel().deserialize({
      input: 'motif',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'comptesClients',
      show: true
    }),
    new FieldParametersModel().deserialize({
      input: 'plateforme',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'responsable',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'statut',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'origine',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'client',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'date',
      show: true
    }),
    new FieldParametersModel().deserialize({
      input: 'typeLitige',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'searchFacture',
      show: true
    }),
    new FieldParametersModel().deserialize({
      input: 'traitePar',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'pole',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'search',
      show: false
    })
  ];


  constructor(private authSvc: AuthenticationService, private factureSvc: FactureService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
  }


  onSubmit(filter: FilterMultiCritereModel) {
    // récupère les infos du filtre
    this.filter = filter;
    this.loadData(new FilterMultiCritereModel().deserialize(this.filter));
  }


  loadData(filter: FilterMultiCritereModel) {
    this.filter.limit = 200;
    this.loadingTableFactures = true;
    this.factureSvc.getFactures(filter).subscribe(factures => {
      this.factures = factures;
      this.loadingTableFactures = false;
    });
  }


  onSort(sort): void {
    this.loadingTableFactures = true;
    this.filter.orderChamp = sort.active;
    this.filter.orderDirection = sort.direction;
    this.filter.statut = 3;
    // applique les changements
    this.factureSvc.getFactures(this.filter).subscribe((litiges) => {
      this.factures = litiges;
      this.loadingTableFactures = false;
    });
  }
}
