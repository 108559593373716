import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PieceJointe} from '../../../../shared/models/api/piece-jointe.model';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {ContestationService} from '../../../../shared/services/api/contestation.service';
import Swal from 'sweetalert2';
import {FileService} from '../../../../shared/services/file.service';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {View} from '../../../../shared/models/view.model';
import {PieceJointeGed} from "../../../../shared/models/api/piece-jointe-ged.model";

@Component({
  selector: 'app-modal-documents',
  templateUrl: './modal-documents.component.html',
})
export class ModalDocumentsComponent implements OnInit, OnChanges, OnDestroy {


  @Input() litigeId: number;
  @Input() contestationId: number;
  @Input() modalTitleText: string = 'Documents & photos';
  @Input() modalTitleClass: string;
  @Input() modalHeaderClass: string = 'text-center';
  @Input() configModal: { backdrop: string, keyboard: string };

  @Output() reCheckNbJustificatif: EventEmitter<any> = new EventEmitter();

  public loadingTable = false;
  public piecesJointes: PieceJointe[];
  public pieceJointe: PieceJointe;
  public piecesJointesGed: PieceJointeGed[];
  public pieceJointeSub: Subscription;
  public modalVisualisation: NgbModalRef;
  public loadingFile: boolean = false;
  public view: View;
  public showImage: boolean;
  public urlPreview: string;
  public currentType: number = null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];
  public subscriptions: Subscription[] = [];


  constructor(private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private config: NgbModalConfig,
              private fileSvc: PieceJointeService,
              private authSvc: AuthenticationService,
              private litigeService: LitigeService,
              private contestationSvc: ContestationService) {
    if (this.configModal) {
      Object.assign(this.config, this.configModal);
    }
  }

  ngOnInit() {

    this.authSvc.currentView.subscribe(x => this.view = x);

    if (!this.litigeId && !this.contestationId) {
      throw new Error('Vous devez passer un ID de LITIGE ou de CONTESTATION en paramètre du composant \'app-modal-document\'.');
    }

    if (this.litigeId && this.contestationId) {
      throw new Error('Vous devez passer seulement UN  "id" de litige OU de contestation en paramètre du composant \'app-modal-document\',');
    }

    this.getData();
  }

  openModalUpload(modal) {
    this.modalVisualisation = this.modalService.open(modal, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  openModalVisualisation(modal, selectFile: { id: number, filename: string, path: string }) {
    this.pieceJointe = new PieceJointe().deserialize({
      id: selectFile.id,
      libelle: selectFile.filename,
      path: selectFile.path
    });
    this.modalVisualisation = this.modalService.open(modal, {
      size: 'xl',
    });
    this.showPieceJointe(selectFile.id)
  }

  openModalEdition(modal, selectFile: { id: number, filename: string, path: string }) {
    this.pieceJointe = new PieceJointe().deserialize({
      id: selectFile.id,
      libelle: selectFile.filename,
      path: selectFile.path
    });

    this.modalVisualisation = this.modalService.open(modal, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.modalVisualisation.result.then(() => {
      this.refreshData();
      this.reCheckNbJustificatif.emit(this.litigeId)
    });
  }

  openModalAffectationPieceJointe(modal, selectFile: { id: number, index: number, filename: string, path: string }) {
    this.pieceJointe = new PieceJointe().deserialize({
      id: selectFile.id,
      libelle: selectFile.filename,
      path: selectFile.path
    });

    this.modalVisualisation = this.modalService.open(modal, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.modalVisualisation.result.then(() => {
      this.refreshData();
      this.reCheckNbJustificatif.emit(this.litigeId)
    });
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if (this.pieceJointe.path.startsWith('../var/')) {
      this.subscriptions.push(this.fileSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType = this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;

        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.modalVisualisation.close();
            this.loadingFile = false;

          });
        }
      ));
    } else {
      this.subscriptions.push(this.fileSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed
          this.loadingFile = false;
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.modalVisualisation.close();
            this.loadingFile = false;
          });

        }
      ));
    }
  }

  onUploaded(bool) {
    console.log(bool);
    if (bool) {
      this.refreshData();
    }
    this.modalVisualisation.close();
  }

  refreshData() {
    this.getData();
  }

  getData() {
    this.loadingTable = true;
    if (this.litigeId) {
      console.log('litige ', this.litigeId)
      this.pieceJointeSub = this.litigeService.getPieceJointes(this.litigeId).subscribe(
        (files) => {
          this.loadingTable = false;
          console.log(files);
          this.piecesJointes = files;
        }, () => {
          this.loadingTable = false;
        }
      );
    } else {
      console.log('contestation ', this.litigeId)
      this.pieceJointeSub = this.contestationSvc.getPieceJointes(this.litigeId).subscribe(
        (files) => {
          this.loadingTable = false;
          console.log(files);
          this.piecesJointes = files;
        }, () => {
          this.loadingTable = false;
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.pieceJointeSub) {
      this.pieceJointeSub.unsubscribe();
    }
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    })
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.litigeId) {
      this.litigeId = changes.litigeId.currentValue;
    }
  }

  download(fileId) {
    this.fileSvc.downloadFile(fileId).subscribe(res => {
        FileService.downloadDataToFile(res);
      },
      FileService.handleDownloadError
    );
  }

}
