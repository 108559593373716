import {Component, OnDestroy, OnInit} from '@angular/core';
import {AnnuaireService} from '../../shared/services/api/annuaire.service';
import {AnnuaireItem} from '../../shared/models/api/annuaire.model';
import {ClientService} from '../../shared/services/api/client.service';
import {Client} from '../../shared/models/api/client.model';
import {Plateforme} from '../../shared/models/api/plateforme.model';
import {PlateformeService} from '../../shared/services/api/plateforme.service';
import {AppEvent, EventQueueService} from '../../shared/services/event-queue.service';
import {forkJoin, Subscription} from 'rxjs';
import {CompteClient} from '../../shared/models/api/compte-client.model';
import Swal from 'sweetalert2';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {View} from '../../shared/models/view.model';
import {StatutService} from '../../shared/services/api/statut.service';
import {Statut} from '../../shared/models/api/statut.model';

@Component({
  selector: 'app-annuaire-page',
  templateUrl: './annuaire.html',
  styleUrls: ['./annuaire.scss']

})
export class AnnuairePageComponent implements OnDestroy, OnInit {

  public loadingAnnuaire = false;
  public annuaire: AnnuaireItem[];
  public clients: Client[];
  public compteClients: CompteClient[];
  public plateformes: Plateforme[];
  public statutsFraisTransport: Statut[];
  public currentItemId: any;
  public view: View;
  public searchTerm = '';

  private subscriptions: Subscription[] = [];
  private annuaireSubscription: Subscription;

  constructor(
    private annuaireSvc: AnnuaireService,
    private clientService: ClientService,
    private plateformeService: PlateformeService,
    private eventQueue: EventQueueService,
    private authSvc: AuthenticationService,
    private statutSvc: StatutService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(this.authSvc.currentView.subscribe(v => {
      this.view = v;
      if (this.view.hasDroit('ANNUAIRE_EDIT')) {
        this.loadOnInit();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.annuaireSubscription) {
      this.annuaireSubscription.unsubscribe();
    }
  }

  loadOnInit() {
    this.subscriptions.push(this.eventQueue.on(AppEvent.EventType.RELOAD_ANNUAIRE).subscribe(event => {
      this.loadAnnuaire(event.payload.currentItemId);
    }));

    this.subscriptions.push(forkJoin([
      this.statutSvc.getStatuts(StatutService.FRAIS_TRANSPORT),
      this.clientService.getClientsFaster(),
      this.clientService.getCompteClients(),
      this.plateformeService.getPlateformes()
    ]).subscribe(results => {
      this.statutsFraisTransport = results[0];
      this.clients = results[1];
      this.compteClients = results[2];
      this.plateformes = results[3];
      this.loadAnnuaire();
    }));

    this.loadAnnuaire();
  }

  selected(itemId: number) {
    this.currentItemId = itemId;
    this.searchTerm = '';
  }

  loadAnnuaire(itemId = null) {
    this.loadingAnnuaire = true;
    this.annuaireSubscription = this.annuaireSvc.getAnnuaire().subscribe(annuaire => {
      this.annuaire = annuaire;
      this.loadingAnnuaire = false;
      if (itemId) {
        this.currentItemId = itemId;
      }
      else if (this.annuaire.length > 0) {
        this.currentItemId = this.annuaire[0].id;
      }
      else {
        this.currentItemId = 'group';
      }
    });
  }

  newItem() {
    Swal.fire({
      title: 'Nouvelle fiche',
      text: '',
      showCancelButton: true,
      showDenyButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Groupe Clients',
      denyButtonText: 'Client',
      icon: 'question',
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentItemId = 'group';
      }
      else if (result.isDenied) {
        this.currentItemId = 'client';
      }
    });
  }
}
