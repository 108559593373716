import {Injectable} from '@angular/core';
import {Statut} from '../../models/api/statut.model';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../../environments/environment';
import {HttpUtil} from "../../utils/http.util";
import {Facture} from "../../models/api/facture.model";
import {TypeFacture} from "../../models/api/type-facture.model";


@Injectable({
  providedIn: 'root'
})
export class TypeFactureService {



  urlRessource = environment.url_api_litige + "/type-facture";

    constructor(private http: HttpClient) {

  }

  getTypeFacture(): Observable<TypeFacture[]> {
    return this.http
      .get<TypeFacture[]>(`${this.urlRessource}`).pipe(map((observe) => observe.map(function (observe) {
        return new TypeFacture().deserialize(observe);
      })))
  }

}
