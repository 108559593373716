import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {ContestationService} from '../../shared/services/api/contestation.service';
import {Contestation} from '../../shared/models/api/contestation.model';
import {FieldParametersModel} from '../../shared/models/filters/field-parameters.model';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {View} from '../../shared/models/view.model';

@Component({
  selector: 'contestations',
  templateUrl: './contestations.html'
})

export class ContestationsPage implements OnDestroy, OnInit {

  public loadingTableReclmationDO = false;
  public loadingTableReclmationPF = false;
  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterDO: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterPF: FilterMultiCritereModel = new FilterMultiCritereModel();
  public sub: Subscription;
  public reclamationDO: Contestation[];
  public reclamationPF: Contestation[];
  public displayInputFilter = [
    new FieldParametersModel().deserialize(
      {
        input: 'responsable',
        show: false
      }),
    new FieldParametersModel().deserialize({
      input: 'motif',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'statut',
      show: false
    })
  ];
  public displayedColumns: string[] = ['id',
    'numeroAvis',
    'numeroCommande',
    'date',
    'destinataireNom',
    'client',
    'contestataire',
    'statut',
    'traitePar'];
  public view: View;


  constructor(private contestationsSvc: ContestationService,
              private router: Router,
              private authSvc: AuthenticationService,
              private toastr: ToastrService) {
  }


  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }


  loadDataDO(filter: FilterMultiCritereModel) {
    this.loadingTableReclmationDO = true;
    this.contestationsSvc.getContestations(filter).subscribe(contestations => {
      this.reclamationDO = contestations;
      this.loadingTableReclmationDO = false;
    }, (error) => this.handleError(error));
  }


  loadDataPF(filter: FilterMultiCritereModel) {
    this.loadingTableReclmationPF = true;
    this.contestationsSvc.getContestations(filter).subscribe(contestations => {
      this.reclamationPF = contestations;
      this.loadingTableReclmationPF = false;
    }, (error) => this.handleError(error));
  }


  onSubmit(filter: FilterMultiCritereModel) {
    // récupère les infos du filtre
    this.filter = filter;
    const filterDO = {...filter};
    const filterPF = {...filter};
    filterDO.contestataire = 'DO';
    filterDO.statutCode = 'CON_0';
    filterPF.contestataire = 'PF';
    filterPF.statutCode = 'CON_0';
    // applique les changements
    this.loadDataDO(new FilterMultiCritereModel().deserialize(filterDO));
    this.loadDataPF(new FilterMultiCritereModel().deserialize(filterPF));
  }


  handleError(error) {
    if (error.status === 500){
      Swal.fire({
        title: 'Attention',
        html: 'Une est survenue lors de la recupération des contestations',
        icon: 'warning',
      });
    }else{
      this.toastr.warning('Une est survenue lors de la recupération des contestations', 'Attention');
    }
  }


  ngOnDestroy(): void {
  }


  onSortDO(sort){
    this.loadingTableReclmationDO = true;
    this.filterDO = this.filter;

    this.filterDO.orderChamp = sort.active;
    this.filterDO.orderDirection = sort.direction;
    this.filterDO.contestataire = 'DO';

    // applique les changements
    this.contestationsSvc.getContestations(
          new FilterMultiCritereModel().deserialize(this.filterDO)).subscribe((contestations) => {
          this.reclamationDO = contestations;
          this.loadingTableReclmationDO = false;
        });
  }


  onSortPF(sort){
    this.loadingTableReclmationPF = true;
    this.filterPF = this.filter;

    this.filterPF.orderChamp = sort.active;
    this.filterPF.orderDirection = sort.direction;
    this.filterPF.contestataire = 'PF';

    // applique les changements
    this.contestationsSvc.getContestations(new FilterMultiCritereModel().deserialize(this.filterPF)).subscribe((contestations) => {
      this.reclamationPF = contestations;
      this.loadingTableReclmationPF = false;
    });
  }




}
