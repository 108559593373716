<!-- bopenModalAddConversationegin page-header -->
<h1 class="page-header">Imputation</h1>
<!-- end page-header -->

<div class="alert alert-light fade show">
  <app-filter-imputation (submitFilter)="onSubmit($event)" [displayInput]="displayInputFilter"></app-filter-imputation>
</div>

<!-- begin table "Imputation" -->
<div class="row">
  <div class="col-12">
    <panel title="Imputation" noButton="true" bodyClass="no-padding" class="text-center">
      <div style="height: 300px;" class="overflow-scroll">
        <app-table-litige [loading]="loadingTableRetrocession" [noRecordText]="'Aucune imputations'" [displayedColumns]="displayColumn" [data]="litigesRetrocedable" (submited)="loadDate()" ></app-table-litige>
      </div>
    </panel>
  </div>
  <!-- end processing table "Imputations" -->
</div>

