<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">


  <!--ligne header-->
  <div class="row">
    <div class="col-3 p-t-10 text-left">
      Traité par <b *ngIf="litige.traite_par">{{ litige.traite_par?.getFullname()}}</b>
      <button (click)="affecteMe()" [ladda]="loadingBtnAffecteMe" data-style="zoom-in"
              *ngIf="!litige.traite_par  && view.hasDroit('POLE_LITIGE') && litige.type_litige.code !== TEMPORAIRE"
              class="btn btn-xs btn-primary">m'affecter le litige
      </button>
    </div>
    <div class="col-6">
      <h1 class="page-header p-t-5 text-center font-weight-bold"
          [ngClass]="{'bg-yellow': litige.type_litige.code === TEMPORAIRE}">
        Litige n°  {{litige.id}} / BL : {{litige.numero_avis}}</h1>
    </div>
    <div class="col-3 text-right">
      <h2>
        <span *ngIf="litige.date_prescrit" class="label font-weight-bold label-dark m-r-2" ngbTooltip="{{litige.date_prescrit | date : 'dd-MM-yyyy' }}">Prescrit</span>
        <span class="label font-weight-bold " [ngClass]="{
    'label-primary':litige?.statut.libelle ==='En cours d\'analyse',
    'label-green':litige?.statut.libelle ==='Accepté' || litige?.statut.libelle ==='Eligible' || litige?.statut.libelle ==='Validé',
    'label-danger':litige?.statut.libelle ==='Refusé',
    'label-warning':litige?.statut.libelle ==='En attente' || litige?.statut.libelle ==='en attente d\'approbation'
    }">{{litige?.statut?.libelle}}</span>
        </h2>
    </div>
  </div>

  <!--  ligne info commande et articles-->
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Commande" noButton="true" class="text-center">
        <div class="panel-body text-left" style="overflow-x: auto">
          <div class="row">
            <div class="col-9">
              <p class="ellipsis"><strong>Donneur d'ordre :</strong> {{litige.client?.libelle}} </p>
            </div>
            <div class="col-3">
              <button *ngIf="annuaireItem?.config?.international" class="btn btn-dark etiquette">D.O. International</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p><strong>Adresse : </strong>{{litige.destinataire_adresse}} {{ (litige.destinataire_ville)
                ? ' , ' : null}} {{litige.destinataire_ville | uppercase}}{{ (litige.destinataire_code_postal)
                ? ' , ' : null}} {{litige.destinataire_code_postal}}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <p><strong>Commande : </strong>{{litige.numero_commande}}</p>
              <p><strong>Destinataire : </strong>{{litige.destinataire_nom}} </p>
              <p><strong>Référence complémentaire : </strong>{{litige.reference_complementaire}}</p>
            </div>
            <div class="col-6">
              <p><strong>Plateforme : </strong>{{litige?.plateforme?.libelle}}</p>
              <p><strong>Point Retrait : </strong>{{litige?.point_retrait?.libelle}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <button *ngIf="annuaireItem" class="btn btn-agediss btn-block" (click)="openAnnuaire(modal_show_annuaire)">Fiche client</button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-agediss btn-block"
                      *ngIf="view.hasDroit('INFOS_EDIT') && litige.type_litige.code !== TEMPORAIRE"
                      (click)="openModalCommande()">Editer les infos
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-agediss btn-block" (click)="openModalDocument()"
                      *ngIf="litige.type_litige.code !== TEMPORAIRE">Voir les documents
                <span class="badge badge-light right"  *ngIf="nbNewJustif>0">{{nbNewJustif}}</span>
              </button>
            </div>
          </div>
        </div>
      </panel>
    </div> <!--infos commande -->
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Articles concernés par le litige" noButton="true" class="text-center"
             bodyClass="no-padding">
        <div class="overflow-scroll" style="height: 274px;">
          <app-table-article [loading]="loadingOther"
                             [showAddArticle]="view.hasDroit('ARTICLE_CREATE') && litige.type_litige.code !== TEMPORAIRE"
                             [showViewArticle]="view.hasDroit('ARTICLE_SHOW')"
                             [showEditArticle]="view.hasDroit('ARTICLE_EDIT')"
                             [showDeleteArticle]="view.hasDroit('ARTICLE_DELETE')"
                             (selectAddArticle)="openModalAddArticle(modal_add_articles, 'lg')"
                             (selectShowArticle)="openModalShowArticle(modal_visualisation_articles, 'lg', $event)"
                             (selectEditArticle)="openModalEditArticle(modal_edit_articles, 'lg', $event)"
                             [data]="articles">
          </app-table-article>
        </div>
      </panel>
    </div> <!--info articles en litige-->

    <!--ligne information litige-->

    <div class="col-md-12 col-lg-6 ligne-litige">
      <panel variants="danger" title="Litige" noButton="true" class="text-center">
        <div class="row">
          <div class="col-md-3">
            <label for="createdAt">Date
              <input type="text" id="createdAt" readonly [value]=" litige?.date_litige | date : 'dd-MM-yyy'"
                     class="form-control text-center">
            </label>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="origine">Origine
                <input type="text" id="origine" readonly [value]="litige?.origine?.libelle"
                       class="form-control text-center">
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Flux
                <input type="text" readonly [value]="litige?.flux?.libelle" class="form-control text-center">
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Statut
                <input type="text" readonly [value]="litige?.statut?.libelle" class="form-control text-center">
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Motif
                <input type="text" readonly [value]="litige?.motif_detail?.motif?.libelle"
                       class="form-control text-center">
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="m-b-0">Motif détaillé </label>
              <input type="text" readonly [value]="litige?.motif_detail?.libelle" class="form-control">

            </div>
          </div>
          <div class="col-md-3">
            <label>Montant validé
              <div class="input-group">
                <input type="text" readonly [value]="litige.montant_valide | currency:'EUR':''"
                       class="form-control text-center">
                <span class="input-group-append">
                   <span class="input-group-text bg-grey"> € </span>
                </span>
              </div>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="offset-4 col-4">
            <button class="btn btn-agediss  btn-block"
                    *ngIf="view.hasDroit('STATUT_EDIT') && litige.type_litige.code !== TEMPORAIRE"
                    (click)="openModalStatutLitige(modal_statut)">Mettre à jour le
              statut
            </button>
          </div>
        </div>
      </panel>
    </div> <!--infos litige-->
    <div class="col-md-12 col-lg-6 ligne-litige">
      <panel variants="danger" title="Litige" noButton="true" class="text-center">
        <div class="row">
          <div class="col-md-4">
            <label>Assurance
              <div class="input-group">
            <span class="input-group-prepend">
             <span class="input-group-text"
                   [ngClass]="{'bg-dark': !litige.has_assurance}"> {{litige.has_assurance | ouiNon}}</span>
            </span>
                <input type="text" readonly [value]="litige.montant_assurance || null"
                       class="form-control text-center">
                <span class="input-group-append">
                   <span class="input-group-text bg-grey"> € </span>
                </span>
              </div>
            </label>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Réparable
                <div class="input-group">
                  <div class="input-group-prepend">
                  <span class="input-group-text"
                        [ngClass]="{'bg-dark': !litige.is_reparable}">{{litige.is_reparable | ouiNon}}</span>
                  </div>
                  <input type="text" readonly [value]="litige.montant_reparation || null"
                         class="form-control text-center">
                  <span class="input-group-append">
                   <span class="input-group-text bg-grey"> € </span>
                </span>
                </div>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Dédommagement
                <div class="input-group">
                  <div class="input-group-prepend">
                  <span class="input-group-text"
                        [ngClass]="{'bg-dark': !litige.is_dedomagement}">{{litige.is_dedomagement | ouiNon}}</span>
                  </div>
                  <input type="text" readonly [value]="litige.montant_dedomagement | currency:'EUR':'' "
                         class="form-control text-center">
                  <span class="input-group-append">
                   <span class="input-group-text bg-grey"> € </span>
                </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Frais de transport<input type="text" class="form-control"
                                              [value]="litige?.statut_frais_transport?.libelle" readonly>
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Instruction de retour
                <input type="text" class="form-control" [value]="litige?.instruction_retour?.libelle || ''" readonly>
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <label>Montant réclamation
              <div class="input-group">
                <input type="text" readonly [value]="litige.montant_reclamation | currency:'EUR':''"
                       class="form-control text-center">
                <span class="input-group-append">
                   <span class="input-group-text bg-grey"> € </span>
                </span>
              </div>
            </label>
          </div>
          <div class="col-md-3">
            <label>Limite responsabilité
              <div class="input-group">
                <input type="text" readonly [value]="litige.montant_limit_responsabilite | currency:'EUR':''"
                       class="form-control text-center">
                <span class="input-group-append">
                     <span class="input-group-text bg-grey"> € </span>
                </span>
              </div>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="offset-4 col-4">
            <button class="btn btn-agediss btn-block"
                    *ngIf="view.hasDroit('LITIGE_EDIT') && litige.type_litige.code !== 'TEMPORAIRE'"
                    (click)="openModalModifLitige()">Modifier
              le litige
            </button>
          </div>
        </div>
      </panel>
    </div> <!--infos supplémentaires litige-->


    <!--ligne note et responsabiltés-->

    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Note" noButton="true" class="text-left" bodyClass="no-padding">
        <div>
          <button *ngIf="view.hasDroit('LITIGE_EDIT')" class="btn btn-agediss rounded-0 m-0 py-2"
                  [ladda]="loadingBtn" data-style="zoom-in"
                  [disabled]="enableEditorSaveButton"
                  (click)="saveNote()" style="position: sticky;float:right;z-index: 10"><i
            class="fa fa-save"></i></button>
          <!--https://ckeditor.com/latest/samples/toolbarconfigurator/index.html#basic-->
          <!--toolbar: [ 'heading','Font','FontSize','Format','|', 'bold', 'italic','Underline','|' ]-->
          <ckeditor [editor]="Editor" [config]="EditorConfig" [(ngModel)]="litige.note"
                    (change)="onChange($event)">
          </ckeditor>
        </div>
      </panel>
    </div><!-- Notes -->
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" noButton="true" bodyClass="no-padding"
             class="text-center">
        <div header class="text-center">
          <strong> Responsabilités </strong>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="overflow-scroll" style="height: 165px;">
              <app-table-responsability [loading]="loadingOther" [data]="responsabilites"></app-table-responsability>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-4 col-4">
            <button class="btnResponsabilite btn btn-agediss btn-block m-b-15"
                    *ngIf="view.hasDroit('RESPONSABILITE_CREATE') && view.hasDroit('RESPONSABILITE_EDIT')
                        && litige.type_litige.code !== TEMPORAIRE"
                    (click)="openModalModifResponsabilite()">
              Définir les responsabilités
            </button>
          </div>
        </div>
      </panel>
    </div> <!--infos responsabité-->


    <!--ligne imputation et retrocession-->

    <div class="col-md-12 col-lg-6">
      <panel variants="danger" noButton="true" class="text-center"
             bodyClass="no-padding">
        <div header>
          <div class="row text-center">
            <div class="offset-2 col-8">
              <strong> Imputation </strong>
            </div>
            <div class="col-2 text-right">
              <button *ngIf="view.hasDroit('ODA_SHOW')"
                class="btn btn-xs btn-primary"
                ngbTooltip="Voir les oda"
                (click)="openModalOda()">
                ODA
              </button>
            </div>
          </div>
        </div>
        <div class="overflow-scroll" style="height: 200px;">
          <app-table-imputation-litige [data]="imputations" [hiddenColumns]="hiddenColumnImputation"
                                       [loading]="loaderImputation"></app-table-imputation-litige>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6 ">
      <panel variants="danger" title="Retrocessions" noButton="true" class="text-center" bodyClass="no-padding">
        <div class="overflow-scroll" style="height: 200px;">
          <app-table-retrocession [data]="retrocessions" [hiddenColumns]=""
                                  [loading]="loaderRetrocession"></app-table-retrocession>
        </div>
      </panel>
    </div>

    <!--ligne conversation et contestation-->

    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Conversations" noButton="true" class="text-center" bodyClass="no-padding">
        <div class="overflow-scroll" style="height: 200px;">
          <app-table-conversation [data]="conversations"
                                  [showAddConversationBtn]="view.hasDroit('CONVERSATION_SHOW') && litige.type_litige.code !== TEMPORAIRE"
                                  [showDeleteConversationBtn]="view.hasDroit('CONVERSATION_DELETE')"
                                  [loading]="loadingTableConversation"
                                  [displayedColumns]="['id', 'contexte', 'dateCreation', 'dateModification', 'action']"
                                  (onAdd)="openModalAddConversation()"
                                  (onSelectConverstation)="openModalConversation($event)">
          </app-table-conversation>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Contestations" noButton="true" class="text-center" bodyClass="no-padding">
        <div class="overflow-scroll" style="height: 200px;">
          <app-table-contestation [data]="contestations"
                                  [loading]="loadingTableContestation"
                                  [showAddButton]="canShowAddButton && litige.type_litige.code !== TEMPORAIRE"
                                  [showDeleteButton]="view.hasDroit('CONTESTATION_DELETE')"
                                  [showConversationButton]="view.hasDroit('CONVERSATION_SHOW')"
                                  (onAddContestation)="addContestation()"
                                  [sortingAPI]="false"
                                  [litigeStatut]="litige.statut"
                                  [displayedColumns]="[
                'date',
                'contestataire',
                'statut',
                'traitePar',
                'voirDetail'
          ]">
          </app-table-contestation>
        </div>
      </panel>
    </div>

  </div>

  <!--  ligne historique-->
  <div class="row">
    <div class="col-sm-12">
      <panel variants="danger" noButton="true" class="text-center" bodyClass="no-padding">
        <div header>
          <div class="row text-center">
            <div class="offset-2 col-8">
              <strong> Historique </strong>
            </div>
            <div class="col-2 text-right">
              <button
                class="btn btn-xs btn-primary"
                ngbTooltip="Rafraichir"
                (click)="refreshHistorique()"
              >
                <i class="fa fa-sync cursor-pointer" [ngClass]="{'fa-spin':loaderHistorique}"></i>
              </button>
            </div>
          </div>

        </div>
        <div class="overflow-scroll" style="height: 260px;">
          <app-table-historique [loading]="loaderHistorique" [data]="evenements"></app-table-historique>
        </div>
      </panel>
    </div>
  </div>

  <!--modal mise a jour statut litige-->
  <ng-template #modal_statut let-modal>
    <div class="modal-header">
      <h4 class="modal-title text-center w-100">Modification du statut</h4>
    </div>
    <div class="modal-body">
      <div *ngIf="loadingStatutLitigeForm" style="height: 310px">
        <div class="spinner"></div>
      </div>
      <div *ngIf="!loadingStatutLitigeForm">
        <form [formGroup]="statutLitigeForm">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Statut </label>
                <ng-select class="ng-select filter form-control"
                           [items]="statutsLitige"
                           [selectOnTab]="true"
                           bindValue="id"
                           bindLabel="libelle"
                           formControlName="statut">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Commentaire </label>
                <textarea class="form-control" cols="30" rows="10" formControlName="commentaire"></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer text-center">
      <button class="btn bg-black-lighter text-white" (click)="modal.close()">Annuler</button>
      <button class="btn btn-agediss" *ngIf="litige.statut.code !== 'LIT_6'"
              (click)="submitFormStatutLitige()" [ladda]="loadingBtnUpdateStatut">Enregistrer
      </button>
      <button *ngIf="litige.statut.code === 'LIT_6' && view.hasDroit('LITIGE_APPROVE__DISAPPROVE')"
              class="btn btn-info text-white" (click)="submitFormStatutApprove()"
              [ladda]="loadingBtnUpdateStatut">Approuver
      </button>
      <button *ngIf="litige.statut.code === 'LIT_6' && view.hasDroit('LITIGE_APPROVE__DISAPPROVE')"
              class="btn btn-warning text-white"
              (click)="submitFormStatutRefuse()" [ladda]="loadingBtnUpdateStatut">Refuser
      </button>
    </div>
  </ng-template>
  <!--modal Ajout Article-->
  <ng-template #modal_add_articles let-modal>
    <app-edit-article [litigeId]="litige.id" (submited)="onSubmit($event)"
                     (canceled)="modal.close($event)"></app-edit-article>
  </ng-template>
  <!-- fin de modal Ajout Article-->
  <!--modal Visualistation Article-->
  <ng-template #modal_visualisation_articles let-modal>
    <app-edit-article [articleId]="selectArticle" [viewOnly]="true" (canceled)="modal.close($event)"></app-edit-article>
  </ng-template>
  <!-- fin de modal Visualistation Article-->

  <!--modal Edition Article-->
  <ng-template #modal_edit_articles let-modal>
    <app-edit-article [articleId]="selectArticle" (submited)="modal.close($event)"
                      (canceled)="modal.dismiss()"></app-edit-article>
  </ng-template>
  <!-- fin de modal Edition Article-->

  <ng-template #modal_show_annuaire let-modal>
    <app-annuaire-editor [itemClientId]="litige.client?.id" [viewOnly]="true" [context]="viewContext"></app-annuaire-editor>
  </ng-template>


</div>


