<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       matSortActive="id" matSortDirection="desc"
       class="table table-hover table-striped table-bordered mat-elevation-z8 text-center">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Num</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.id}}">
            <a [routerLink]="['/','contestations',element.id]" class="label label-info text-white">
                {{element?.id}}
            </a>

        </td>
    </ng-container>
    <ng-container matColumnDef="numeroAvis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> BL</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.litige?.numero_avis}}">
            {{element?.litige?.numero_avis}} </td>
    </ng-container>
    <ng-container matColumnDef="numeroCommande">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Commande</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.litige?.numero_commande}}">
            {{element?.litige?.numero_commande}} </td>
    </ng-container>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.created_at | date:'dd-MM-yyyy'}}">
            {{element?.created_at | date:'mediumDate'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="destinataireNom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinataire</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.litige?.destinataire_nom}}">
            {{element?.litige?.destinataire_nom}} </td>
    </ng-container>
    <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.litige?.client?.libelle}}">
            {{element?.litige?.client?.libelle}} </td>
    </ng-container>
    <ng-container matColumnDef="contestataire">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contestataire</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.contestataire}} | {{element.contestataire_id}} | {{element.contestataire_libelle}}">
            {{element?.contestataire}}  | {{element.contestataire_id}} |  {{element.contestataire_libelle}} </td>
    </ng-container>
    <ng-container matColumnDef="statut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{element?.statut?.libelle}}">
            {{element?.statut?.libelle}}
        </td>
    </ng-container>
    <ng-container matColumnDef="traitePar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Traitée par</th>
        <td mat-cell *matCellDef="let element"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ element?.traite_par?.fullname }}">
            {{element?.traite_par?.fullname}} </td>
    </ng-container>
    <ng-container matColumnDef="voirConversation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Conversation</th>
        <td mat-cell *matCellDef="let element">
          <button
            placement="top"
            container="body"
            ngbTooltip="Ouvrir la discution"
            class="btn btn-agediss m-r-2" *ngIf="showConversationButton"
            (click)=" onSelectConverstation.emit(element.id)"><i class="fa fa-comments"></i>
          </button>
        </td>
    </ng-container>
    <ng-container matColumnDef="voirDetail">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          <button *ngIf="showAddButton && disabledButton === false" class="btn btn-agediss btn-sm"
                  placement="top"
                  container="body"
                  ngbTooltip="Nouvelle contestatation"
                  (click)="onAddContestation.emit()">
            <i class="fa fa-plus"></i>
          </button>
            <button *ngIf="showAddButton && disabledButton" class="btn btn-agediss btn-sm"
                    disabled
                  placement="top"
                  container="body"
                  ngbTooltip="Nouvelle contestatation'">
            <i class="fa fa-plus"></i>
          </button>
        </th>
        <td class="text-center" mat-cell *matCellDef="let element">
            <a [routerLink]="['/','contestations',element.id]" class="btn btn-rounded btn-sm btn-agediss">
                <i class="fa fa-eye"></i>
            </a>
        </td>
    </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>
