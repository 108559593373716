<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center">Refuser la contestation</h4>
  </div>
  <div class="pt-4 pb-2 px-4 col-12">
    <form [formGroup]="contestationForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Réponse</label>
            <textarea class="form-control"
                      cols="30" rows="10" formControlName="commentaire_statut"
            [ngClass]="{'is-invalid': contestationForm.get('commentaire_statut').invalid  && (contestationForm.get('commentaire_statut').dirty || formSubmit)}">
            </textarea>
            <div class=" text-center invalid-tooltip" *ngIf="contestationForm.get('commentaire_statut').invalid  && (contestationForm.get('commentaire_statut').dirty || formSubmit)">
            Vous devez renseigner le refus de la contestation.
          </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button type="button" class="btn bg-black-lighter text-white m-r-5" (click)="canceled.emit()">Annuler</button>
          <button type="submit" class="btn btn-agediss" [ladda]="loadingBtn" data-style="zoom-in"
                  (click)="submitFormRefuseContestation()">Refuser
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
