<div *ngIf="loading" style="height: 310px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="contextClient == true && !loading" class="row mb-2">
  <div class="col-6 text-right p-t-5">
    <label>uploader en tant que : </label>
  </div>
  <div class="col-6">
    <ng-select [items]="compteClients"
               [selectOnTab]="true"
               bindValue="id"
               bindLabel="libelle"
               [clearable]="false"
               labelForId="motifDetails"
               placeholder="Choisir"
               (change)="changeSelect($event)">
    </ng-select>
  </div>
</div>
<hr>
<div *ngIf="idClient != null || idLitige != null || idContestation != null">
  <div class="row m-b-30 m-t-10">
    <div class="col-6">
      <input type="file" ng2FileSelect [uploader]="uploader" multiple/>
    </div>
    <div class="col-6">

      <!--<p>Queue length: {{ uploader?.queue?.length }}</p>-->
      <div ng2FileDrop
           [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
           (fileOver)="fileOverBase($event)"
           [uploader]="uploader"
           class="well my-drop-zone">
        ou glissez un fichier ici
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
        <tr>
          <th width="20%">Nom</th>
          <th>Extention</th>
          <th width="25%">Type</th>
          <th>Caché</th>
          <th>Taille</th>
          <th>Progression</th>
          <th>Statut</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of uploader.queue;" class="align-middle">
          <td class="with-input-group"><input class="form-control" type="text" [(ngModel)]="item.formData.name"/></td>
          <td class="align-middle">{{item.formData.extention}}</td>
          <td *ngIf="typePiecesJointes.length > 1" class="with-input-group">
            <ng-select class="ng-select"
                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                       [items]="typePiecesJointes"
                       [selectOnTab]="true"
                       bindValue="id"
                       bindLabel="libelle"
                       [clearable]="false"
                       required
                       placeholder="Choisir"
                       [(ngModel)]="item.formData.typePieceJointe">
            </ng-select>
          </td>
          <td *ngIf="typePiecesJointes.length === 1" class="with-input-group">
            <ng-select class="ng-select"
                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                       [items]="typePiecesJointes"
                       [selectOnTab]="true"
                       bindValue="id"
                       bindLabel="libelle"
                       [clearable]="false"
                       required
                       placeholder="Choisir"
                       [(ngModel)]="item.formData.typePieceJointe">
            </ng-select>
          </td>
          <td>
              <input type="checkbox" class="form-control" [(ngModel)]="item.formData.hidden">
          </td>
          <td class="align-middle">{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</td>
          <td class="align-middle">
            <div class="progress" style="margin-bottom: 0;">
              <div class="progress-bar" role="progressbar"
                   [ngStyle]="{ 'width': item.progress + '%' }"></div>
            </div>
          </td>
          <td class="align-middle text-center">
            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
          </td>
          <td nowrap>
            <button type="button" class="btn btn-green m-r-3"
                    (click)="item.upload()"
                    [disabled]="item.isReady || item.isUploading || item.isSuccess">
              <i class="fa fa-paper-plane " ngbTooltip="Envoyé" container="body"></i>
            </button>
            <button type="button" class="btn btn-danger"
                    (click)="item.remove()">
              <i class="fa fa-trash" ngbTooltip="retirer" container="body"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 m-t-15">
      <div>
        Progression:
        <div class="progress" style="">
          <div class="progress-bar" role="progressbar"
               [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row text-right m-t-10">
    <div class="col-12">
      <button type="button" class="btn btn-green btn-s m-r-3"
              (click)="sentAllFile()" [ladda]="laddaSentButton">
        <span class="glyphicon glyphicon-upload"></span>Tout envoyer
      </button>
      <button type="button" class="btn btn-danger btn-s m-r-3"
              (click)="uploader.clearQueue()" [disabled]="disabledButton">
        <span class="glyphicon glyphicon-trash"></span>Tout retirer
      </button>
      <button *ngIf="showReturnButton" type="button" class="btn bg-black-lighter text-white" (click)="return.emit()">
        Fermer
      </button>
    </div>
  </div>


</div>
<div *ngIf="contextClient == true && !idClient" class="row text-right m-t-10">
  <div class="col-12">
    <button *ngIf="showReturnButton" type="button" class="btn bg-black-lighter text-white" (click)="return.emit()">
      Fermer
    </button>
  </div>
</div>
