import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ArticleHelper {

  public PLANTRAVAIL = ['Crédence', 'Plan de travail', 'Socle', 'Autre'];
  public CUISINE = [];
  public AMENAGEMENTEXT = ['Abri de jardin', 'Coffre de rangement', 'Maisonnette', 'Mobilier de jardin',
    'Pergola', 'Piscine', 'Portique', 'Autre'];
  public JARDINOUTILS = ['Récupérateur d\'eau', 'Tondeuse', 'Autre'] ;
  public SPORTLOISIRS = ['Baby foot', 'Billard', 'Draisienne', 'Table de ping pong', 'Trottinette', 'Vélo', 'Autre'];
  public MATSPORTINT = ['Appareil de musculation', 'Rameur', 'Tapis de marche', 'Vélo d\'appartement', 'Autre'];
  public DECORATION = ['Art de la table', 'Linge de maison', 'Luminaire', 'Miroir', 'Tapis', 'Autre'];
  public FOURNITUREBUREAU = ['Classeur', 'Ramette de papier', 'Autre'];
  public AUTRES = ['Cabine téléphonique', 'Matériel médical', 'Quincaillerie'];
  public MEUBLEKIT = ['Bibliothèque', 'Buffet', 'Bureau', 'Caisson', 'Table à manger', 'Table basse', 'Tête de lit',
    'Vitrine', 'Autre'];
  public MEUBLES = ['Bibliothèque', 'Buffet', 'Bureau', 'Caisson', 'Table à manger', 'Table basse', 'Tête de lit',
    'Vitrine', 'Autre'];
  public CANAPES = ['Banc', 'Banquette clic-clac / BZ', 'Chaise', 'Chauffeuse', 'Pouf', 'Tabouret', 'Autre'];
  public ELECTROMENAGER = ['Barre de son', 'Cave à vin', 'Congélateur', 'Four', 'Hotte', 'Lave linge', 'Lave vaisselle',
    'Montre connectée', 'Piano de cuisson', 'Plaque de cuisson', 'Réfrigérateur', 'Réfrigérateur américain',
    'Sèche linge', 'TV', 'Autre'];
  public LITERIE = ['Cadre à lattes', 'Couette', 'Lit coffre', 'Matelas', 'Oreiller', 'Sommier', 'Autre'];
  public MENUISERIES = ['Porte d\'entrée', 'Porte de placard', 'Rail', 'Verrière', 'Autre'];
  public PLOMBERIE = ['Radiateur', 'Receveur', 'Vasque', 'Autre'];


  getSousTypeArticleOptions(typeArticleCollection, typeArticleId: number) {
    for (const typeArticle of typeArticleCollection) {
      if (typeArticle.id === typeArticleId) {
        return this[typeArticle.code];
      }
    }
  }
}
