import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Article} from '../../../../shared/models/api/article.model';
import {Subscription} from 'rxjs';
import {ArticleService} from '../../../../shared/services/api/article.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {Colis} from '../../../../shared/models/api/colis.model';
import {isNumber} from '../../../../shared/utils/util';
import {TypeArticleService} from '../../../../shared/services/api/typeArticle.service';
import {ArticleHelper} from '../../../../shared/helpers/article.helper';
import {LitigeService} from '../../../../shared/services/api/litige.service';

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss'],
})
export class EditArticleComponent implements OnInit, OnDestroy {

  @Input() articleId: number;
  @Input() litigeId: number;
  @Input() viewOnly = false;

  @Output() submited: EventEmitter<Article> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public nb_colis = 0;
  public nbAddColis = 1;
  public article: Article;
  public articleForm: FormGroup;
  public colis: Colis[] = [];
  public typeArticles = [];
  public articleOptions = [];
  public loadingTypeArticle = false;
  public subscriptions: Subscription[] = [];
  public loading = false;
  public loadingBtn = false;
  public formSubmit = false;

  private typeArticleAutresId: number;

  constructor(private articleSvc: ArticleService,
              public toastr: ToastrService,
              private litigeSvc: LitigeService,
              private typeArticleSvc: TypeArticleService,
              private articleHelper: ArticleHelper,
              public formBuilder: FormBuilder)
  {
    // this.articleOptions = this.articleHelper.PLOMBERIE;
  }

  ngOnInit() {
    this.loading = true;

    if (!isNumber(this.articleId) && !isNumber(this.litigeId)) {
      this.canceled.emit();
      throw new Error('Le composant \'edit article\' à besoin d\'un id d\'article ou de litige en paramètre.');
    }

    if (this.articleId) {
      this.subscriptions.push(this.articleSvc.getArticle(this.articleId).subscribe(
        (article) => {
          this.article = article;
          this.initForm();
        }));
    }
    else {
      this.initFormNewArticle();
    }
  }

  initForm() {
    const colis = [];

    this.article.colis.forEach(coli => {
      colis.push(this.generateRowColis(coli.id, coli.cab));
    });
    this.subscriptions.push(this.typeArticleSvc.getTypeArticle().subscribe(value => {
      this.typeArticles = value;
      this.typeArticles.forEach(typeArticle => {
        if (typeArticle.code === 'AUTRES') {
          this.typeArticleAutresId = typeArticle.id;
        }
      });
      this.loadingTypeArticle = false;
      this.articleOptions = this.articleHelper.getSousTypeArticleOptions(this.typeArticles, this.article.type_article?.id);
    }));


    this.articleForm = new FormGroup({
      id: new FormControl(this.article.id),
      reference: new FormControl( this.article.reference),
      libelle: new FormControl(this.article.libelle, Validators.required),
      volume: new FormControl(this.article.volume),
      poids: new FormControl(this.article.poids, Validators.required),
      typeArticle: new FormControl(this.article.type_article ? this.article.type_article.id : null),
      sousTypeArticle: new FormControl(this.article.sous_type_article),
      umType: new FormControl(this.article.um_type),
      colis: this.formBuilder.array(colis)
    });
    this.loading = false;

    this.articleForm.controls.typeArticle.valueChanges.subscribe(value => {
      this.articleOptions = this.articleHelper.getSousTypeArticleOptions(this.typeArticles, value);
      this.articleForm.controls.sousTypeArticle.setValue(null);
    });
  }

  initFormNewArticle() {
    this.loadingTypeArticle = true;
    this.subscriptions.push(this.typeArticleSvc.getTypeArticle().subscribe(value => {
      this.typeArticles = value;
      this.loadingTypeArticle = false;
    }));
    this.articleForm = this.formBuilder.group( {
      reference: [null],
      libelle: ['', Validators.required],
      volume: [null],
      poids: ['', Validators.required],
      typeArticle: [null],
      sousTypeArticle: [null],
      umType: [null],
      colis: this.formBuilder.array([])
    });
    this.loading = false;

    this.articleForm.controls.typeArticle.valueChanges.subscribe(value => {
      this.articleOptions = this.articleHelper.getSousTypeArticleOptions(this.typeArticles, value);
      this.articleForm.controls.sousTypeArticle.setValue(null);
    });
  }

  generateRowColis(id: number = null, cab: string = null) {
    return this.formBuilder.group({
      id: this.formBuilder.control(id),
      cab: this.formBuilder.control(cab)
    });
  }

  addColis() {
    const colis = this.articleForm.get('colis') as FormArray;
    for (let i = 0; i < this.nbAddColis; i++) {
      colis.insert(0, this.generateRowColis());
      if (this.articleId) {
        this.article.nb_colis++;
      }
      else {
        this.nb_colis++;
      }
    }
  }

  removeColis(index: number) {
    const colis = this.articleForm.get('colis') as FormArray;
    colis.removeAt(index);
    if (this.articleId) {
      this.article.nb_colis--;
    }
    else {
      this.nb_colis--;
    }
  }

  submitArticle() {
    this.formSubmit = true;
    if (this.articleForm.invalid) {
      return;
    }
    this.loadingBtn = true;

    const values = this.articleForm.value;
    if (this.litigeId) {
      this.subscriptions.push(this.litigeSvc.addArticle(this.litigeId, values).subscribe(
        article => {
          this.loadingBtn = false;
          this.toastr.info('Modifications enregistrées', 'Info', {progressBar: true});
          this.submited.emit(article);
        },
        error => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false});
          this.loadingBtn = false;
        }
      ));
    }
    else {
      this.subscriptions.push(this.articleSvc.updateArticle(values).subscribe(
        next => {
          this.loadingBtn = false;
          this.toastr.info('Modifications enregistrées', 'Info', {progressBar: true});
          this.submited.emit(next);
        },
        error => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false});
          this.loadingBtn = false;
        }
      ));
    }
  }

  cancel() {

    let formIsDirty = false;
    // vérification que le formulaire n'a pas des données non sauvegardées.
    // Si oui on averti l'utilisateur avec un sweetalert. Sinon on émet l'évènement d'annulation
    Object.keys(this.articleForm.controls).forEach(key => {
      if (this.articleForm.controls[key].dirty) {
        formIsDirty = true;
        Swal.fire({
          title: 'Attention',
          text: 'Des modifications on été effectuées, Etes-vous sur de vouloir perdre vos modifications ?',
          icon: 'warning',
          cancelButtonText: 'Non',
          confirmButtonText: 'Oui !',
          confirmButtonColor: '#aa1c1c',
          allowOutsideClick: false,
          showCancelButton: true,
          allowEscapeKey: false,
        }).then(
          (choice) => {
            if (choice.value) {
              this.canceled.emit();
            }
          },
        );
        return;
      }
    });
    if (!formIsDirty) {
      this.canceled.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  get isTypeArticleAutres() {
    return this.typeArticleAutresId && this.articleForm.controls.typeArticle.value === this.typeArticleAutresId;
  }

}
