import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Facture} from '../../../../shared/models/api/facture.model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TypeFacture} from '../../../../shared/models/api/type-facture.model';
import {TypeFactureService} from '../../../../shared/services/api/typeFacture.service';
import {PieceJointe} from '../../../../shared/models/api/piece-jointe.model';
import {FactureService} from '../../../../shared/services/api/facture.service';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {PieceJointeGed} from "../../../../shared/models/api/piece-jointe-ged.model";
import {PieceJointeService} from "../../../../shared/services/api/piece-jointe.service";

@Component({
  selector: 'app-modal-attente-facture',
  templateUrl: './modal-attente-facture.component.html',
  styleUrls: ['./modal-attente-facture.component.scss']
})
export class ModalAttenteFactureComponent implements OnInit {

  @Input() pieceJointe: PieceJointe;
  @Input() piecesJointesGed: PieceJointeGed[];
  @Input() typeFacture: TypeFacture;
  @Input() disabled: boolean = false;
  @Input() factureId: number;
  @Output() submited: EventEmitter<boolean> = new EventEmitter();

  public facture: Facture = new Facture();
  public subscriptions: Subscription[] = [];
  public factureForm: FormGroup;
  public typesFacture: TypeFacture[];
  public loading: Boolean = false;
  public loadingFile: Boolean = false;
  public loadingBtn: Boolean = false;
  public formSubmit = false;
  public loadTypeFacture: Boolean = false;
  public loadingOtherFacture: Boolean = false;
  public otherFacture: Facture[] = [];
  public showImage: boolean;
  public urlPreview: string;
  public currentType : number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private PieceJointeSvc: PieceJointeService,
    public factureSvc: FactureService,
    public typeFactureSvc: TypeFactureService,
    private toastSvc: ToastrService
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.loadTypeFacture = true;

    // on recupère la facture
    if (this.factureId) {
      this.factureSvc.getFacture(this.factureId).subscribe((facture) => {
        this.facture = facture;
        this.pieceJointe = this.facture.piece_jointe;
        this.showPieceJointe(this.pieceJointe.id)
        this.initForm();
        this.loadOtherFacture(this.facture.type_facture.code);
        let d = [];
        if (this.facture.type_facture.code == 'AVOIR') {
          this.facture.factures_asso.forEach((f) => {
            d.push(f.id)
          });
          this.factureForm.get('otherFactures').setValue(d)
        } else {
          this.facture.avoirs_asso.forEach((f) => {
            d.push(f.id)
          });
          this.factureForm.get('otherFactures').setValue(d)
        }
        this.loadTypeFacture = false;
      });
    }else{
      this.showPieceJointe(this.pieceJointe.id)
      this.initForm();
    }

    this.typeFactureSvc.getTypeFacture().subscribe(value => {
      this.typesFacture = value.filter((val) => {
        return val.code === 'FACTURE' || val.code === 'AVOIR';
      });
      this.loadTypeFacture = false;
    });

  }

  loadOtherFacture(code) {

    if (code == "AVOIR") {
      this.loadingOtherFacture = true;
      // récupérarion des numéros de facture du client
      this.factureSvc.getFactureByCompteClient(this.pieceJointe.compte_client.id).subscribe((factures) => {
        this.otherFacture = factures;
        this.loadingOtherFacture = false;
      });
    }
    if (code == "FACTURE") {
      // récupérarion des numéros de facture du client
      this.factureSvc.getAvoirByCompteClient(this.pieceJointe.compte_client.id).subscribe((factures) => {
        this.otherFacture = factures;
        this.loadingOtherFacture = false;
      });
    }
  }

  close() {
    this.activeModal.close(false);
  }

  submit() {
    this.formSubmit = true;
    if (this.factureForm.invalid) {
      return;
    }

    this.loadingBtn = true;
    this.facture.montant = this.factureForm.controls['montant'].value;
    this.facture.numero_interne = this.factureForm.controls['numeroInterne'].value;
    this.facture.numero_client = this.factureForm.controls['numeroClient'].value;
    this.facture.type_facture = this.factureForm.controls['typeFacture'].value;
    this.facture.piece_jointe = this.factureForm.controls['pieceJointe'].value;
    this.facture.other_facture = this.factureForm.controls['otherFactures'].value;
    // Si on n'a pas Id de facture, on crée une facture
    if (!this.factureId) {

      this.subscriptions.push(this.factureSvc.postFacture(this.facture).subscribe(
        () => {
          this.toastSvc.success('facture crée', 'Info', {progressBar: true});
          this.loadingBtn = false;
          this.activeModal.close(true);
        },
        message => {
          this.toastSvc.warning(message.error, 'Info', {progressBar: true});
          this.loadingBtn = false;
        }));
    }

    // si on a un id de la facture, on peut editer la facture
    if (this.factureId) {
      this.subscriptions.push(this.factureSvc.updateFacture(this.factureId, this.facture).subscribe(
        next => {
          console.log(next);
          this.loadingBtn = false;
          this.toastSvc.info('Modifications enregistrées', 'Info', {progressBar: true});
          this.submited.emit(next);
          this.close();
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false});
          this.loadingBtn = false;
        }
      ));
    }
  }


  initForm() {
    this.factureForm = new FormGroup({
      montant: new FormControl({value: this.facture.montant ,disabled: this.disabled}, Validators.required),
      numeroInterne: new FormControl({value: this.facture.numero_interne,disabled: this.disabled}, Validators.required),
      numeroClient: new FormControl({value: this.facture.numero_client,disabled: this.disabled}, Validators.required),
      typeFacture: new FormControl({value: this.facture.type_facture ? this.facture.type_facture.id : null,disabled: this.disabled}, Validators.required),
      pieceJointe: new FormControl(this.pieceJointe),
      otherFactures: new FormControl(),
    });
    this.loading = false;
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if(this.pieceJointe.path.startsWith('../var/')) {
      const sub1 = this.PieceJointeSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.PieceJointeSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed
          this.loadingFile = false;
          sub.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }
}
