<div *ngIf="!conversation" class="modal-body" style="height: 350px">
</div>
<div *ngIf="conversation">
  <div class="modal-header {{modalHeaderClass}}">
    <h4 class="modal-title w-100 {{modalTitleClass}}"
        id="modal-basic-title">
      <span *ngIf="!conversation.contestation">{{conversation.contexte | contextConversation }} | {{conversation.context_id}} {{conversation.context_libelle}}</span>
      <span *ngIf="conversation.contestation">Conversation de la contestation : {{conversation.contestation.id}} | {{conversation.contestation.contestataire_libelle}}</span>
    </h4>
  </div>
  <div class="modal-body no-padding">
    <app-dialog-conversation (onChange)="conversationDirty = true" [conversation]="conversation"></app-dialog-conversation>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn bg-black-lighter text-white" (click)="close()">Fermer</button>
  </div>
</div>
