import {Injectable} from '@angular/core';
import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {environment} from '../../../environments/environment';
import {camelCase} from "@swimlane/ngx-datatable/release/utils";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  inflightAuthRequest = null;
  blacklist = [
    environment.urlsso + '/sso/token/refresh',
    environment.url_api_litige + '/log/add-error-client',
  ];

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {




    // On laisse passer les url qui n'ont pas besoin de token d'authentification
    if (this.blacklistCheckup(req.url)) {
      return next.handle(req);
    }

    // si la variable *inflightAuthRequest* est lancer (!==null) on va pouvoir tenter la recupération du token
    // SINON CELA VEUT DIRE QUE NOUS SOMME ENTRAIN DE DEMANDER LE RAFRAICHISSEMENT DU TOKEN
    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.authenticationService.getToken();
    }

    // on ajout le token dans le header de la request
    return this.inflightAuthRequest.pipe(
      switchMap((newToken:  { token: string, tokenView: string }) => {
        // unset request inflight
        this.inflightAuthRequest = null;
        // use the newly returned token
        const authReq = this.addTokenToRequest(req, newToken);
        return next.handle(authReq);
      }),
      catchError(error => {
        // checks if a url is to an admin api or not
        if (error.status === 401) {
          if (error.error.message === 'Bad credentials') {
            this.authenticationService.logout();
            return throwError(error);
          }
          console.log(this.inflightAuthRequest);
          if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = this.authenticationService.refreshToken();
            console.log(this.inflightAuthRequest);
            if (!this.inflightAuthRequest) {
              this.authenticationService.logout();
              return throwError(error);
            }
          }

          return this.inflightAuthRequest.pipe(
            switchMap((newToken: { token: string, tokenView: string }) => {
              // unset inflight request
              this.inflightAuthRequest = null;

              // clone the original request
              const authReqRepeat = this.addTokenToRequest(req, newToken);

              // resend the request
              return next.handle(authReqRepeat);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  blacklistCheckup($url: string): boolean {
    let returnValue = false;
    for (const i of this.blacklist) {
      if ($url == i) {
        returnValue = true;
        break;
      }
    }

    return returnValue;
  }

  private addTokenToRequest(request: HttpRequest<any>, token: { token: string, tokenView: string }): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        TokenView: token.tokenView
      },
      withCredentials: true
    });
  }

}
