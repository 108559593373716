import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {Contestation} from '../../../../shared/models/api/contestation.model';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {Litige} from '../../../../shared/models/api/litige.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {View} from '../../../../shared/models/view.model';

@Component({
  selector: 'app-add-contestation',
  templateUrl: './add-contestation.component.html',
  styleUrls: ['./add-conversation.component.scss']
})
export class AddContestationComponent implements OnInit {

  @Output() submitted: EventEmitter<Contestation> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Input() idLitige: number = null;
  @Input() contestationFor ?: string = null;
  @Input() contestataireId ?: string | number = null;

  public contestatairesIds: { libelle: string, code: string, type: string }[] = [];
  public contestationForm: FormGroup = new FormGroup({
    contestataire: new FormControl(null, Validators.required),
    contestataireId: new FormControl(null, Validators.required),
    objet: new FormControl(null, Validators.required)
  });
  public formSubmit: boolean;
  public loadingBtn: boolean;
  public loading: boolean = false;
  public litige: Litige;
  public maxContestationsReached: boolean = false;
  public view: View;

  constructor(
    private authSvc: AuthenticationService,
    private litigeSvc: LitigeService,
    private toastSvc: ToastrService
  ) {
  }

  ngOnInit() {

    this.loading = true;

    this.authSvc.currentView.subscribe(x => {
      this.view = x;

      const sub = this.litigeSvc.getLitige(this.idLitige).subscribe((litige) => {
          this.litige = litige;
          if (this.view.context != 1 && this.litige.nb_contestations >= 2) {
            this.maxContestationsReached = true;
            this.loading = false;
            return;
          }
          if (this.contestationFor) {
            if (this.contestationFor == 'PF') {
              this.contestationForm.get('contestataire').setValue('PF');
              if (!this.contestataireId) {
                this.toastSvc.error('Code plateforme absent', 'Erreur');
                this.canceled.emit();
              }
              this.contestationForm.get('contestataireId').setValue(this.contestataireId);
            } else if (this.contestationFor == 'DO') {
              this.contestationForm.get('contestataireId').setValue(this.litige.client.code);
              this.loading = false;

            }
            this.loading = false;

          } else {
            // on ajoute le choix du DO seulement si le statut du litige le permet
            if (this.litige.statut.code == 'LIT_5' || this.litige.statut.code == 'LIT_1') {
              this.contestatairesIds.push(
                {libelle: this.litige.client.libelle, code: this.litige.client.code, type: 'DO'}
              );
            }
            this.litigeSvc.getResponsabilites(this.idLitige).subscribe(responsabilites => {
              if (responsabilites.length == 0) {
                if (this.contestatairesIds.length == 0) {
                  this.toastSvc.error('Impossible de créer une contestation à ce stade', 'Erreur');
                  this.canceled.emit();
                } else {
                  this.toastSvc.warning('Aucune responsabilités trouvées pour ce litige', 'Attention');
                }
              } else {
                responsabilites.forEach(res => {
                  this.contestatairesIds.push({
                    libelle: `${res.responsable.libelle} | ${res.responsable.code}`,
                    code: res.responsable.code,
                    type: 'PF'
                  });
                });
              }
              this.loading = false;
            });
          }

        }
      );
    });
  }


  submitFormAddContestation() {
    this.formSubmit = true;

    if (this.contestationForm.invalid) {
      return;
    }

    this.loadingBtn = true;
// si on est en présence d'un id de litige
    const sub = this.litigeSvc.addContestation(this.idLitige, this.contestationForm.value).subscribe((contestation) => {
      this.submitted.emit(contestation);
      sub.unsubscribe();
      this.loadingBtn = false;
      this.formSubmit = false;
      this.toastSvc.success('Contestation Créée', 'Info', {progressBar: true});
    }, () => {
      this.loadingBtn = false;
      this.formSubmit = false;
      Swal.fire('Désolé', 'Une erreur est survenue lors de l\'enregistrement.', 'error');
    });
  }

  onSelectContestataire(val) {
    if (val) {
      this.contestationForm.get('contestataire').setValue(val.type);
    }
  }

  get f() {
    return this.contestationForm.controls;
  }
}
