<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="date" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">


  <ng-container matColumnDef="idPieceJointe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">N° PJ</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        ngbTooltip="{{element?.id}}" class="px-10">
      {{element?.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">DO</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        ngbTooltip="{{element?.compte_client.libelle}}" class="px-10">
      {{element?.compte_client.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="filename">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fichier</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        ngbTooltip="{{element?.filename}}" class="px-10">
      {{element?.filename}}
    </td>
  </ng-container>
  <ng-container matColumnDef="typePieceJointe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        ngbTooltip="{{element?.type_piece_jointe.libelle}}" class="px-10">
      {{element?.type_piece_jointe.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        class="text-nowrap"
        ngbTooltip="{{element?.created_at | date :'dd-MM-yyyy'}}">
      {{element?.created_at | date :'dd-MM-yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="valider">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td class="text-center" mat-cell *matCellDef="let element; let i = dataIndex;"
        style="vertical-align: top">
      <button *ngIf="showEditFactureBtn"
              ngbTooltip="Valider la facture"
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-info mr-1"
              (click)="editFacture(element)">
        <i class="fa fa-pencil-alt"></i>
      </button>
      <button  *ngIf="showDeleteFactureBtn"
        [ladda]="element.pending" data-style="zoom-in"
              ngbTooltip="Suprimer la facture"
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss"
              (click)="deleteFacture(element.id, i)">
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>
