import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {ContestationService} from '../../../../shared/services/api/contestation.service';
import {Oda} from '../../../../shared/models/api/oda.model';

@Component({
  selector: 'app-modal-oda',
  templateUrl: './modal-oda.component.html',
})
export class ModalOdaComponent implements OnInit, OnDestroy {


  @Input() litigeId: number;
  @Input() btnModalClass: string = 'btn btn-agediss btn-block';
  @Input() modalTitleText: string = 'ODA';
  @Input() modalTitleClass: string;
  @Input() modalHeaderClass: string = 'text-center';
  @Input() configModal: { backdrop: string, keyboard: string };

  public odas: Oda[];
  public loadingTable: boolean;
  private odaSub: Subscription;


  constructor(private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private config: NgbModalConfig,
              private odasvc: PieceJointeService,
              private litigeService: LitigeService) {
    if (this.configModal) {
      Object.assign(this.config, this.configModal);
    }
  }

  ngOnInit() {
    if (!this.litigeId ) {
      throw new Error('Vous devez passer un ID de LITIGE  en paramètre du composant \'app-modal-oda\'.');
    }
    this.getData();
  }

  getData() {
    this.loadingTable = true;
    this.odaSub = this.litigeService.getOda(this.litigeId).subscribe(
      (odas) => {
        this.odas = odas;
        this.loadingTable = false;
      }, () => {
        this.loadingTable = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.odaSub.unsubscribe();
  }

}
