import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Litige} from '../../../../shared/models/api/litige.model';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from "../../../../animations";
import {ModalImputationComponent} from "../../../imputation/modal/modal-imputation/modal-imputation.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalRetrocessionComponent} from "../../../retrocession/modal/modal-retrocession/modal-retrocession.component";
import { MatPaginator } from "@angular/material/paginator";
import {View} from "../../../../shared/models/view.model";

@Component({
  selector: 'app-table-litige-eligible-ou-valide',
  templateUrl: './table-litige-eligible-ou-valide.component.html',
  styleUrls: ['./table-litige-eligible-ou-valide.component.scss'],
  animations: [tableAnimation],
})
export class TableLitigeEligibleOuValideComponent implements OnInit, OnChanges {

  expandedElement: Litige | null;

  @Input() data: Litige[];
  @Input() noRecordText = 'Aucun litige';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = null;
  @Input() hiddenColumns: string[] = [];
  @Input() sortingAPI: Boolean = true;
  @Input() length: number;
  @Input() context: number;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() showPagination: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showValidateBtn: boolean = false;
  @Input() showContestBtn: boolean = false;
  @Input() displayedColumns: string[] = [
    'id',
    'numeroAvis',
    'type',
    'client',
    'referenceComplementaire',
    'numeroCommande',
    'destinataireNom',
    'plateforme',
    'flux',
    'date',
    'traitePar',
    'statut',
    'responsables',
    'actions',
    'options',
  ];

  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() submited: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() onSelectContestation: EventEmitter<{id, index}> = new EventEmitter<{id, index}>();
  @Output() onSelectValidation: EventEmitter<{id,numero_avis}> = new EventEmitter<{id,numero_avis}>();

  public innerWidth: number;
  public view: View;
  public dataSource: any;
  // public dataSorted: MatTableDataSource<Litige>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.onResizeTablecontextPf();
    this.dataSource = new MatTableDataSource<Litige>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    if (this.sortingAPI == true){
      this.onSorted.emit(sort);
    }else {
      const data = this.data.slice();
      if (!sort.active || sort.direction === '') {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        return;
      }
      this.dataSource = new MatTableDataSource(data.sort(
        (a, b) => {
          const isAsc = sort.direction === 'asc';
          if (sort.active in a) {
            return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
          }
          return 0;
        }));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }


  openModalModifResponsabilite(litige: Litige) {

    const modal = this.modalService.open(ModalImputationComponent, {
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });

    modal.componentInstance.litigeId = litige.id;
    modal.result.then(value => {
      if (value == true) {
        this.submited.emit(value)
      }
    });
  }

  openModalRetocessionResponsabilite(litige: Litige) {

    const modal = this.modalService.open(ModalRetrocessionComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modal.componentInstance.litigeId = litige.id;
    modal.result.then(value => {
      if (value == true) {
        this.submited.emit(value)
      }
    });
  }

  formatListResponsable(responsables){
    let libelle = '';
    if(!responsables || !Array.isArray(responsables)){
      return libelle;
    }
    libelle = '<ul class="list-group">';
    responsables.forEach(resp => {
      libelle += "<li class='list-group-item'>"+resp+"</li>"
    })
    libelle += "</ul>";
    return libelle;
  }

  selectContestation(id: number, index: number) {
    this.onSelectContestation.emit({id: id, index: index})
  }

  selectValidation(id: number,numero_avis:string) {
    this.onSelectValidation.emit({id,numero_avis})
  }

  @HostListener('window:resize', ['$event'])
  onResizeTablecontextPf() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 500) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'options',
      ];
    }
    if (this.innerWidth > 500 && this.innerWidth <= 560) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'actions',
        'options',
      ];
    } else if (this.innerWidth > 560 && this.innerWidth <= 950) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'actions',
        'options',
      ];
    } else if (this.innerWidth > 950 && this.innerWidth <= 1050) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'client',
        'actions',
        'options',
      ];
    } else if (this.innerWidth > 1050 && this.innerWidth <= 1230) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'client',
        'referenceComplementaire',
        'actions',
        'options',
      ];
    }else if (this.innerWidth > 1230 && this.innerWidth <= 1360) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'client',
        'referenceComplementaire',
        'numeroCommande',
        'actions',
        'options',
      ];
    } else if (this.innerWidth > 1360 && this.innerWidth <= 1516) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'client',
        'referenceComplementaire',
        'numeroCommande',
        'destinataireNom',
        'actions',
        'options',
      ];
    } else if (this.innerWidth > 1516 && this.innerWidth <= 1600) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'client',
        'referenceComplementaire',
        'numeroCommande',
        'destinataireNom',
        'flux',
        'actions',
        'options',
      ];
    } else if (this.innerWidth > 1600 && this.innerWidth <= 1710) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'client',
        'referenceComplementaire',
        'numeroCommande',
        'destinataireNom',
        'flux',
        'date',
        'actions',
        'options',
      ];
    } else if (this.innerWidth > 1710) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'type',
        'client',
        'referenceComplementaire',
        'numeroCommande',
        'destinataireNom',
        'flux',
        'date',
        'traitePar',
        'actions',
      ];
    }
  }

}
