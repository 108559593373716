import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';

import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../services/authentication.service';
import Swal from 'sweetalert2';
import {urlWithProtocol} from "../utils/util";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // vérification si il y a un token et si il est toujour valide
    const currentUser = this.authenticationService.currentUserValue;
    const currentView = this.authenticationService.currentViewValue;
    if (currentUser && currentView) {
      return true;
    }
    // pas connecter redirige sur la page de login avec l'url de retour
    window.location.href = urlWithProtocol(environment.urlsso);
    return false;
  }
}
