<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center">
      <span *ngIf="articleId && !viewOnly">Edition de l'article n° {{articleId}}</span>
      <span *ngIf="!articleId">Création d'un article sur le litige n° {{litigeId}}</span>
      <span *ngIf="viewOnly">Visualisation de l'article n° {{articleId}}</span>
    </h4>
  </div>
  <div class="pt-4 pb-2 px-4 col-12">
    <form [formGroup]="articleForm">
      <div class="row">
        <div class="col-6">
          <div class="row form-group">
            <label class="col-lg-3 col-md-4 col-form-label">Référence</label>
            <div class="col-lg-9 col-md-8">
              <div class="input-group mb-3">
                <input id="reference" type="text" class="form-control" autocomplete="off" formControlName="reference"
                       [readonly]="viewOnly"
                       [ngClass]="{
                       'is-invalid' : articleForm.get('reference').invalid && (articleForm.get('reference').dirty || formSubmit)
                       }"
                />
                <div *ngIf="articleForm.get('reference').invalid && (articleForm.get('reference').dirty || formSubmit)"
                  class="invalid-tooltip">Veuillez saisir une référence
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-lg-3 col-md-4 col-form-label">Libellé</label>
            <div class="col-lg-9 col-md-8">
              <div class="input-group mb-3">
                <input id="libelle" type="text" class="form-control" autocomplete="off" formControlName="libelle"
                       [readonly]="viewOnly"
                       [ngClass]="{'is-invalid' : articleForm.get('libelle').invalid && (articleForm.get('libelle').dirty || formSubmit)}">
                <div
                  *ngIf="articleForm.get('libelle').invalid && (articleForm.get('libelle').dirty || formSubmit)"
                  class="invalid-tooltip">Veuillez saisir un libelle
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-lg-3 col-md-4 col-form-label">Type Article</label>
            <div class="col-lg-9 col-md-8">
              <div class="input-group mb-3">
                <ng-select class="ng-select filter"
                           [items]="typeArticles"
                           [selectOnTab]="true"
                           [clearable]="false"
                           [readonly]="viewOnly"
                           bindValue="id"
                           bindLabel="libelle"
                           [loading]="loadingTypeArticle"
                           formControlName="typeArticle">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-lg-3 col-md-4 col-form-label">Unité de manutention</label>
            <div class="col-lg-9 col-md-8">
              <div class="input-group mb-3">
                <ng-select class="ng-select filter"
                           [items]="['Colis', 'Palette']"
                           [selectOnTab]="true"
                           [clearable]="false"
                           [readonly]="viewOnly"
                           [loading]="loadingTypeArticle"
                           formControlName="umType">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row form-group">
            <label class="col-lg-3 col-md-4 col-form-label">Volume</label>
            <div class="col-lg-9 col-md-8">
              <div class="input-group mb-3">
                <input id="volume" step="0.01" lang="en-150" type="number" class="form-control" formControlName="volume"
                       [readonly]="viewOnly"
                       [ngClass]="{'is-invalid' : articleForm.get('volume').invalid && (articleForm.get('volume').dirty || formSubmit)}">
                <span class="input-group-addon bg-grey-transparent-5">m³</span>
                <div
                  *ngIf="articleForm.get('volume').invalid && (articleForm.get('volume').dirty || formSubmit)"
                  class="invalid-tooltip">Veuillez saisir un volume (numérique)
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-lg-3 col-md-4 col-form-label">Poids</label>
            <div class="col-lg-9 col-md-8">
              <div class="input-group mb-3">
                <input id="poids" step="0.01" lang="en-150" type="number" class="form-control" formControlName="poids"
                       [readonly]="viewOnly"
                       [ngClass]="{'is-invalid' : articleForm.get('poids').invalid && (articleForm.get('poids').dirty || formSubmit)}">
                <span class="input-group-addon bg-grey-transparent-5">kg</span>
                <div
                  *ngIf="articleForm.get('poids').invalid && (articleForm.get('poids').dirty || formSubmit)"
                  class="invalid-tooltip">Veuillez saisir un poids (numérique)
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-lg-3 col-md-4 col-form-label">Article</label>
            <div class="col-lg-9 col-md-8">
              <div class="input-group mb-3">
                <ng-select class="ng-select filter"
                           [items]="articleOptions"
                           [selectOnTab]="true"
                           [clearable]="false"
                           [addTag]="isTypeArticleAutres"
                           [addTagText]="'Autre : '"
                           [readonly]="viewOnly"
                           [loading]="loadingTypeArticle"
                           formControlName="sousTypeArticle">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!viewOnly">
        <div class="row mt-2 mb-4">
          <div class="offset-2 col-7">
            <div>
              <label>liste des colis
                <span ngbTooltip="Nombre total de colis"
                      class="col-2 py-1 bg-grey-transparent-5 rounded text-center">{{!!article ? article.nb_colis : nb_colis}}
              </span>
              </label>
            </div>
          </div>
          <div class="col-2">
            <input step="1" lang="en-150" type="number" [(ngModel)]="nbAddColis" [ngModelOptions]="{standalone: true}" class="mr-2 addcolis">
            <button (click)="addColis()" ngbTooltip="Ajouter" class="btn btn-icon btn-info">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div formArrayName="colis">
          <div *ngFor="let coli of articleForm.get('colis').controls; let indexColis = index;">
            <div [formGroupName]="indexColis">
              <div class="row m-t-1">
                <div class="offset-2 col-7">
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="cab" placeholder="Code barre  --- laisser vide pour ajouter un colis ---">
                  </div>
                </div>
                <div class="col-2">
                  <button (click)="removeColis(indexColis)" ngbTooltip="Supprimer le colis" class="btn btn-icon btn-info">
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="viewOnly">
        <div class="row my-2">
          <div class="offset-1 col-7">
            <div>
              <label>liste des colis
                <span ngbTooltip="Nombre total de colis" class="col-2 py-1 bg-grey-transparent-5 rounded text-center">
                  {{article.nb_colis}}
              </span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-1 col-10 text-center">
            <app-table-colis [displayedColumns]="['codeBarre']" [loading]="loading"
                             [data]="article.colis"></app-table-colis>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right my-2">
            <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">Fermer</button>
          </div>
        </div>
f      </div>
    </form>

    <div *ngIf="!viewOnly" class="row ">
      <div class="col-12 text-right">
        <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">Annuler</button>
        <button [ladda]="loadingBtn" data-style="zoom-in"  class="btn btn-agediss" (click)="submitArticle()">Enregistrer</button>
      </div>
    </div>
  </div>
</div>
