import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Oda} from '../../models/api/oda.model';
import {HttpUtil} from '../../utils/http.util';

@Injectable({
  providedIn: 'root'
})
export class OdaService {

  urlRessource = environment.url_api_litige + '/odas';

  constructor(private http: HttpClient) {

  }

  getOda(filter: any = {}): Observable<Oda[]> {
    return this.http
      .get<Oda[]>(`${this.urlRessource}`,{params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((odas) => odas.map((oda) => {
        return new Oda().deserialize(oda);
      })));
  }

  downloadOda(odaDownloadble: number[]): Observable<{ data: Blob, filename: string }> {
    return this.http
      .put(`${this.urlRessource}/download`, odaDownloadble, {
        responseType: 'blob',
        observe: 'response',
      }).pipe(map(res => {
        if (!res.headers.get('content-disposition')) {
          throw 'L\'api ne renvoi pas le header \'content-disposition\'';
        }
        return {
          filename: HttpUtil.extractFilenameInContentDisposition(res.headers.get('content-disposition')),
          data: res.body,
        };
      }));
  }

}
