import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Responsable} from '../../../../shared/models/api/responsable.model';
import {forkJoin, Subscription} from 'rxjs';
import {Responsabilite} from '../../../../shared/models/api/responsabilite.model';
import {ToastrService} from 'ngx-toastr';
import {ActeurService} from '../../../../shared/services/api/acteur.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';
import {Acteur} from '../../../../shared/models/api/acteur.model';

@Component({
  selector: 'app-modal-edit-responsabilite',
  templateUrl: './modal-edit-responsabilite.component.html',
  styleUrls: ['./modal-edit-responsabilite.component.scss']
})
export class ModalEditResponsabiliteComponent implements OnInit, OnDestroy {

  @Input() acteursLitige: Responsable[];
  @Input() litigeId: number;
  @Input() montantReclamation: number;
  @Input() montantValide: number;
  @Input() libelleSubmit = 'Enregistrer';

  // public litige: Litige;
  public acteursInterne: Responsable[] = [];
  public acteursTiers: Responsable[] = [];
  public acteursAgedissPartenaires: Responsable[] = [];
  public responsabilites: Responsabilite[] = [];
  public responsabiliteForm: FormGroup;
  public loadingResponsableForm = false;
  public subscriptions: Subscription[] = [];
  public loadingSelecteur = false;
  public disableSubmit = false;
  public loadingSubmitBtn = false;
  public loading = false;
  public formSubmit = false;
  public litigePrescrit = false;

  public agedissPartenaires = null;

  groupingHelper(item) {
    return item.discr;
  }

  constructor(
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    private acteurSvc: ActeurService,
    public activModal: NgbActiveModal,
    private litigesSvc: LitigeService,
  ) {
  }

  ngOnInit() {
    if (!this.litigeId) {
      throw new Error('Vous devez passer un id litige au composant ModalEditResponsabilite');
    }
    // récupération du litige
    this.subscriptions.push(this.litigesSvc.getLitige(this.litigeId).subscribe((litige) => {
      if (litige.date_litige) {
        const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        if (oneYearFromNow.getTime() > new Date(litige.date_litige).getTime()) {
          this.litigePrescrit = true;
        }
      }
    }));
    if (!this.montantValide) {
      this.montantValide = 0;
    }
    this.initActeurs();
  }

  initFormResponsabilites() {
    this.subscriptions.push(this.litigesSvc.getResponsabilites(this.litigeId).subscribe(responsabilites => {
          this.responsabilites = responsabilites;
          const responsabiliteInterne = [];
          const responsabiliteTiers = [];
          // création des formgroup pour les responsabilités
          if (this.responsabilites) {
            this.responsabilites.forEach((res: Responsabilite) => {
              if (res.responsable.type_responsable && res.responsable.type_responsable.code === 'INT') {
                responsabiliteInterne.push(this.generateResponsableLine(res));
              } else {
                responsabiliteTiers.push(this.generateResponsableLine(res));
              }
            });
          }
          this.responsabiliteForm = this.formBuilder.group({
            responsabilitesInterne: this.formBuilder.array(responsabiliteInterne),
            responsabilitesTiers: this.formBuilder.array(responsabiliteTiers),
          });
          this.loadingResponsableForm = false;
          this.disableSubmit = false;
        },
        () => {
          Swal.fire('Désolé', 'Une erreur est survenue', 'error');
        })
    );
  }

  initActeurs() {
    // lancement du loading sur la modal le temps d'initialiser le formulaire
    this.loadingResponsableForm = true;
    // récupération des acteurs
    if (this.acteursTiers.length === 0 || this.acteursInterne.length === 0) {
      this.loadingSelecteur = true;
      this.disableSubmit = true;
      // récupération des acteurs interne et tiers
      this.litigesSvc.getLitige(this.litigeId).subscribe(value => {
        if (!value.plateforme) {
          Swal.fire('Erreur', 'Vous devez renseigner la plateforme du litige avant de définir les responsabilités !').then(() => {
            this.activModal.close();
          });
        }
        this.subscriptions.push(forkJoin([
          this.acteurSvc.getActeursInterne({discr: 'plateforme'}),
          this.acteurSvc.getActeursTiers({sousTraitantResponsableInterne: value.plateforme.id}),
          this.acteurSvc.getActeursInterne({code: Acteur.CODE_AGEDISS_PARTENAIRES})
        ]).subscribe(responses => {
            this.acteursInterne = responses[0];
            this.acteursTiers = responses[1];
            this.acteursAgedissPartenaires = responses[2];
            // on récupère la plateforme interne AGEDISS PARTENAIRES
            for (const acteur of this.acteursAgedissPartenaires) {
              if (acteur.code === Acteur.CODE_AGEDISS_PARTENAIRES) {
                this.agedissPartenaires = acteur;
                break;
              }
            }
            if (!this.agedissPartenaires) {
              Swal.fire('Erreur', 'La plateforme AGEDISS PARTENAIRES n\'existe pas. Contactez le service informatique.').then(() => {
                this.activModal.close();
              });
            } else {
              this.loadingSelecteur = false;
              this.initFormResponsabilites();
            }
          },
          () => {
            this.activModal.close();
          }));
      });
    } else {
      this.initFormResponsabilites();
    }
  }

  // FONCTIONS POUR LE FORMULAIRE RESPONSABILITE
  searchActeur(term: string, item: Responsable) {
    term = term.toLocaleLowerCase();
    return item.libelle.toLocaleLowerCase().indexOf(term) > -1 || item.code.toLocaleLowerCase().indexOf(term) > -1;
  }

  addResponsableInterneRow() {
    const items = this.responsabiliteForm.get('responsabilitesInterne') as FormArray;
    items.push(this.generateEmptyResponsableLine());
  }

  deleteResponsableInterne(index) {
    const items = this.responsabiliteForm.get('responsabilitesInterne') as FormArray;
    items.removeAt(index);
  }

  addResponsableTiersRow() {
    const items = this.responsabiliteForm.get('responsabilitesTiers') as FormArray;
    items.push(this.generateEmptyResponsableLine());
  }

  deleteResponsableTiers(index) {
    const items = this.responsabiliteForm.get('responsabilitesTiers') as FormArray;
    items.removeAt(index);
    this.onChangeResponsableTiers();
  }

  private generateEmptyResponsableLine() {
    return this.formBuilder.group({
      id: this.formBuilder.control(null),
      responsable: this.formBuilder.control(null/*, Validators.required*/),
      pourcent: this.formBuilder.control(0),
      montant: this.formBuilder.control(0),
      isDeletable: this.formBuilder.control(true),
    });
  }

  private generateResponsableLine(res: Responsabilite) {
    return this.formBuilder.group({
      id: this.formBuilder.control(res.id),
      responsable: this.formBuilder.control(res.responsable.id, Validators.required),
      pourcent: this.formBuilder.control(res.pourcent ? res.pourcent : 0),
      montant: this.formBuilder.control(res.montant ? res.montant : 0),
      isDeletable: this.formBuilder.control(res.is_deletable)
    });
  }

  submitFormResponsabilite() {
    this.formSubmit = true;
    if (this.responsabiliteForm.invalid) {
      return;
    }
    // this.loadingSubmitBtn = true;
    this.checkMontant().then(
      (success) => {
        const responsabilites = {
          responsabilitesInterne: this.responsabiliteForm.get('responsabilitesInterne').value,
          responsabilitesTiers: this.responsabiliteForm.get('responsabilitesTiers').value,
        };
        this.litigesSvc.updateResponsabilites(this.litigeId, responsabilites).toPromise().then(resp => {
          this.toastSvc.success('Modifications enregistrées', 'Info', {progressBar: true});
          this.activModal.close(resp);
          this.loadingSubmitBtn = false;
        }, (error: HttpErrorResponse) => {
          let msg = 'Une erreur s\'est produite lors de l\'enregistrement';
          if (error.error && error.error.code == 2700) {
            msg = error.error.message;
            console.log(error.error);
            if (error.error.payload) {
              let payload = JSON.parse(error.error.payload);
              if (typeof payload === 'object') {
                payload = Object.values(payload);
              }
              msg += '<br>' + payload.join('<br>');
              console.log(JSON.parse(error.error.payload));
            }
          }
          Swal.fire({title: 'Attention', html: msg}).then(() => {
          });
          this.loadingSubmitBtn = false;
        });
      },
      (error) => {
        this.loadingSubmitBtn = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  cancel() {
    let formIsDirty = false;
    // vérification que le formulaire n'a pas des données non sauvegardées.
    // Si oui on averti l'utilisateur avec un sweetalert. Sinon on émet l'évènement d'annulation
    Object.keys(this.responsabiliteForm.controls).forEach(key => {
      const tt = this.responsabiliteForm.controls[key] as FormArray;
      tt.controls.forEach(value => {
        Object.keys(value.value).forEach(t => {
          if (value.get(t).dirty) {
            formIsDirty = true;
            Swal.fire({
              title: 'Attention',
              text: 'Des modification on été effectuées, Etes vous sur de vouloir perdre vos modifications.',
              icon: 'warning',
              cancelButtonText: 'Non',
              confirmButtonText: 'Oui !',
              confirmButtonColor: '#aa1c1c',
              allowOutsideClick: false,
              showCancelButton: true,
              allowEscapeKey: false,
            }).then(
              (choice) => {
                if (choice.value) {
                  this.activModal.close();
                }
              },
            );
            return;
          }
        });
      });
    });
    if (!formIsDirty) {
      this.activModal.close();
    }
  }

  isActeurExternalPlateforme(id) {
    for (const acteur of this.acteursTiers) {
      if (acteur.id === id) {
        return acteur.discr === 'plateforme';
      }
    }
    return false;
  }

  // on surveille les modifications sur les tiers pour mettre à jour la responsabilité interne automatique AGEDISS PARTENAIRES
  onChangeResponsableTiers() {
    const responsablesTiers = this.responsabiliteForm.get('responsabilitesTiers').value;
    let partenairesPercent = 0;
    for (const item of responsablesTiers) {
      if (this.isActeurExternalPlateforme(item.responsable)) {
        partenairesPercent += Number(item.pourcent);
      }
    }

    let index = -1;
    const forms = this.responsabiliteForm.get('responsabilitesInterne') as FormArray;
    for (let i = 0; i < forms.controls.length; i++) {
      if (forms.controls[i].value.responsable === this.agedissPartenaires.id) {
        index = i;
        break;
      }
    }

    if (index > -1) {
      if (partenairesPercent > 0) {
        forms.at(index).get('pourcent').setValue(partenairesPercent);
        forms.at(index).get('montant').setValue((this.montantValide * partenairesPercent / 100).toFixed(2));
      } else {
        if (!forms.at(index).get('isDeletable').value) {
          forms.at(index).get('pourcent').setValue(0);
          forms.at(index).get('montant').setValue((0).toFixed(2));
        } else {
          forms.removeAt(index);
        }
      }
    } else if (partenairesPercent > 0) {
      const form = this.formBuilder.group({
        id: this.formBuilder.control(null),
        responsable: this.formBuilder.control(this.agedissPartenaires.id, Validators.required),
        pourcent: this.formBuilder.control(partenairesPercent),
        montant: this.formBuilder.control((this.montantValide * partenairesPercent / 100).toFixed(2)),
        isDeletable: this.formBuilder.control(true)
      });
      forms.push(form);
    }
  }

  // check le montant et le pourcentage
  checkMontant(): Promise<void> {
    return new Promise((resolve, reject) => {
      let montantTotalInterne = 0;
      let pourcentTotalInterne = 0;
      let montantTotalExterne = 0;
      let pourcentTotalExterne = 0;
      this.responsabiliteForm.get('responsabilitesInterne').value.forEach((value) => {
        montantTotalInterne += value.montant;
        pourcentTotalInterne += value.pourcent;
      });
      this.responsabiliteForm.get('responsabilitesTiers').value.forEach((value) => {
        montantTotalExterne += value.montant;
        pourcentTotalExterne += value.pourcent;
      });
      console.log('interne ', montantTotalInterne);
      console.log('externe ', montantTotalExterne);
      if (montantTotalInterne > this.montantValide || montantTotalExterne > this.montantValide) {
        this.toastSvc.error('Le montant affecté ne doit pas être supérieur au montant validé ' + this.montantValide, 'Attention');
        reject();
      } else if (pourcentTotalInterne > 100 || pourcentTotalExterne > 100) {
        this.toastSvc.error('La somme des responsabilités ne doit pas être supérieure à 100%. ', 'Attention');
        reject();
      } else {
        resolve();
      }
    });
  }

  ratioPourcentToMontant(context, index) {
    const target = context === 'interne' ? 'responsabilitesInterne' : 'responsabilitesTiers';
    const items = this.responsabiliteForm.get(target) as FormArray;

    let pourcent = items.at(index).get('pourcent').value;
    if (typeof pourcent !== 'number') {
      pourcent = 0.0;
      items.at(index).get('pourcent').setValue((pourcent).toFixed(2));
    }
    const convert = ((this.montantValide * pourcent) / 100).toFixed(2);
    items.at(index).get('montant').setValue(convert);

    if (context === 'externe') {
      this.onChangeResponsableTiers();
    }
  }

  ratioMontantToPourcent(context, index) {
    const target = context === 'interne' ? 'responsabilitesInterne' : 'responsabilitesTiers';
    const items = this.responsabiliteForm.get(target) as FormArray;

    let montant = items.at(index).get('montant').value;
    if (typeof montant !== 'number') {
      montant = 0.0;
      items.at(index).get('montant').setValue((montant).toFixed(2));
    }
    const convert = this.montantValide ? ((100 * montant) / this.montantValide).toFixed(2) : (0).toFixed(2);
    items.at(index).get('pourcent').setValue(convert);

    if (context === 'externe') {
      this.onChangeResponsableTiers();
    }
  }

}
