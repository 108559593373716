<div *ngIf="loading" style="height: 310px" class="row col-12">
  <span class="spinner"></span>
</div>
<form *ngIf="!loading" class="" [formGroup]="litigeForm">
  <div class="row m-t-10">
    <div class="col-md-12 col-lg-6">
      <!-- Date litige-->
      <div class="row form-group">
        <label class="col-md-3 col-form-label">Date </label>
        <div class="col-md-9">
          <div class="input-group">
            <input class="form-control"
                   [ngClass]="{'is-invalid' :litigeForm.get('dateLitige').invalid && (litigeForm.get('dateLitige').dirty || formSubmit) }"
                   name="dp"
                   (click)="d.toggle();"
                   [autoClose]=true
                   ngbDatepicker #d="ngbDatepicker"
                   [maxDate]="currentDate"
                   placeholder="dd-MM-yyyy"
                   formControlName="dateLitige">
            <div *ngIf="litigeForm.get('dateLitige').invalid && (litigeForm.get('dateLitige').dirty || formSubmit)"
                 class="invalid-tooltip">le champs date doit être renseigné et validé !
            </div>
          </div>
        </div>
      </div>
      <!-- flux-->
      <div class="row form-group">
        <label class="col-md-3 col-form-label" id="flux">Flux </label>
        <div class="col-md-9">
          <ng-select [items]="flux"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="false"
                     labelForId="flux"
                     placeholder="Choisir"
                     formControlName="flux">
          </ng-select>
        </div>
      </div>
      <!-- statut frais de transport-->
      <div class="row form-group">
        <label class="col-md-3 col-form-label" id="statutFraisTransport">Frais de transport </label>
        <div class="col-md-9">
          <ng-select [items]="statutsFraisTransport"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="false"
                     labelForId="statutFraisTransport"
                     placeholder="Choisir"
                     formControlName="statutFraisTransport">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6">
      <!-- assurance-->
      <div class="row form-group">
        <label class="col-xs-6 col-md-3 col-lg-3 col-form-label">Assurance </label>
        <div class="col-xs-6 col-md-9  col-lg-2  p-t-5">
          <div class="switcher">
            <input type="checkbox" name="hasAssurance"
                   (change)="disableEnableInput('montantAssurance',litigeForm.get('hasAssurance').value)"
                   id="hasAssurance" formControlName="hasAssurance">
            <label for="hasAssurance"></label>
          </div>
        </div>
        <div class="col-xs-6 col-md-4 col-lg-3">
          <label class="col-form-label">Montant </label>
        </div>
        <div class="col-xs-6 col-md-8 col-lg-4">
          <input type="number" step="0.01" lang="en-150" class="form-control" formControlName="montantAssurance"/>
        </div>
      </div>
      <!--reparation-->
      <div class="row form-group">
        <label class="col-xs-6 col-md-3 col-lg-3 col-form-label">Réparable </label>
        <div class="col-xs-6 col-md-9  col-lg-2  p-t-5">
          <div class="switcher">
            <input type="checkbox" name="isReparable"
                   (change)="disableEnableInput('montantReparation',litigeForm.get('isReparable').value)"
                   id="isReparable" formControlName="isReparable">
            <label for="isReparable"></label>
          </div>
        </div>
        <div class="col-xs-6 col-md-4 col-lg-3">
          <label class="col-form-label">Montant </label>
        </div>
        <div class="col-xs-6 col-md-8 col-lg-4">
          <input type="number" step="0.01" lang="en-150" class="form-control" formControlName="montantReparation"/>
        </div>
      </div>
      <!--dedomagement-->
      <div class="row form-group">
        <label class="col-xs-6 col-md-3 col-lg-3 col-form-label">Dédommagement </label>
        <div class="col-xs-6 col-md-9  col-lg-2  p-t-5">
          <div class="switcher">
            <input type="checkbox" name="isDedomagement"
                   (change)="disableEnableInput('montantDedomagement',litigeForm.get('isDedomagement').value)"
                   id="isDedomagement" formControlName="isDedomagement">
            <label for="isDedomagement"></label>
          </div>
        </div>
        <div class="col-xs-6 col-md-4 col-lg-3">
          <label class="col-form-label">Dédommagement </label>
        </div>
        <div class="col-xs-6 col-md-8 col-lg-4">
          <input type="number" step="0.01" lang="en-150" class="form-control" formControlName="montantDedomagement"/>
        </div>
      </div>
    </div>
  </div>


  <div class="row mb-4 mt-4">
    <div class="col-3">
      <div class="row form-group">
        <label class="col-4 col-form-label" id="perimetreResponsabiliteLabel">Périmètre </label>
        <div class="col-8">
          <ng-select [items]="perimetreResp"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="false"
                     labelForId="perimetreResponsabiliteLabel"
                     placeholder="Choisir"
                     formControlName="perimetreResponsabilite">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-3" [hidden]="national">
      <div class="row form-group">
        <label class="col-3 col-form-label" id="sensResponsabiliteLabel">Sens </label>
        <div class="col-9">
          <ng-select [items]="sensResp"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="false"
                     labelForId="sensResponsabiliteLabel"
                     placeholder="Choisir"
                     formControlName="sensResponsabilite">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-3" [hidden]="national">
      <div class="row form-group">
        <div class="col-5">
          <label class="col-form-label" id="dateDtsLabel">Date du DTS </label>
        </div>
        <div class="col-7">
          <div class="input-group">
            <input class="form-control"
                   [ngClass]="{'is-invalid' :litigeForm.get('dateDts').invalid && (litigeForm.get('dateDts').dirty || formSubmit) }"
                   name="dt"
                   (click)="dt.toggle();"
                   [autoClose]=true ngbDatepicker #dt="ngbDatepicker"
                   placeholder="dd-MM-yyyy"
                   formControlName="dateDts">
            <div *ngIf="litigeForm.get('dateDts').invalid && (litigeForm.get('dateDts').dirty || formSubmit)"
                 class="invalid-tooltip">le champs date doit être renseigné et validé !
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3" [hidden]="national">
      <div class="row form-group">
        <div class="col-5" popoverTitle="Information"
             triggers="mouseenter:mouseleave"
             placement="top"
             container="body"
             [ngbPopover]="'Ce bouton renvoi vers le site officiel du Fond Monétaire International.'">
          <a class="btn btn-agediss" href="{{urlDts}}" target="_blank">Taux DTS</a>
        </div>
        <div class="col-7">
          <input type="number" step="0.01" lang="en-150" class="form-control" formControlName="tauxDts"/>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-4">
      <!-- montant réclamation-->
      <div class="row form-group">
        <label class="col-md-6 col-form-label">Montant réclamation </label>
        <div class="col-md-6">
          <input type="text" step="0.01" lang="en-150" class="form-control" formControlName="montantReclamation"/>
        </div>
      </div>
    </div>
    <!-- montant validé-->
    <div class="col-md-12 col-lg-4">
      <div class="row form-group">
        <label class="col-md-6 col-form-label">Montant validé </label>
        <div class="col-md-6">
          <input type="number" step="0.01" lang="en-150" class="form-control" formControlName="montantValide"/>
        </div>
      </div>
    </div>
    <!--limit de responsabilité-->
    <div class="col-md-12 col-lg-4">
      <div class="row form-group">
        <label class="col-md-6 col-form-label">Limite responsabilité </label>
        <div class="col-md-6">
          <div class="input-group">
            <input type="number" step="0.01" lang="en-150" class="form-control"
                   formControlName="montantLimitResponsabilite"/>
            <div class="input-group-append cursor-pointer">
              <span class="input-group-text text-center"
                    (click)="calculLimiteResponsabilite()"
                    popoverTitle="Information"
                    triggers="mouseenter:mouseleave"
                    placement="top"
                    container="body"
                    [ngbPopover]="!loadingCalculResponsabilite ? 'Ce bouton lance le calcul automatique de la limite de responsabilité en fonction d\'une règle définie .': 'Calcul en cours ....'"
              ><i class="fa fa-sync cursor-pointer" [ngClass]="{'fa-spin':loadingCalculResponsabilite}"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <!-- motif-->
      <div class="row form-group">
        <label class="col-md-3 col-form-label" id="motif">Motif </label>
        <div class="col-md-9">
          <ng-select [items]="motifs"
                     [selectOnTab]="true"
                     (ngModelChange)="refreshMotifDetail($event)"
                     (change)="disableEnableInput('motifDetail',litigeForm.get('motif').value)"
                     (click)="onChangeMotif()"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="true"
                     labelForId="motif"
                     placeholder="Choisir"
                     formControlName="motif">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                <span placement="left"
                      container="body"
                      ngbTooltip="{{item?.libelle}}">{{item.libelle}}
                </span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <!-- motif detaillé-->
      <div class="row form-group">
        <label class="col-md-4 col-form-label" id="motifDetails">Motif détaillé </label>
        <div class="col-md-8">
          <ng-select [items]="motifsDetails"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [loading]="loadMotifsDetails"
                     [clearable]="true"
                     labelForId="motifDetails"
                     [placeholder]="placeholder"
                     appendTo="body"
                     formControlName="motifDetail">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                <span placement="left"
                      container="body"
                      ngbTooltip="{{item?.libelle}}">{{item.libelle}}
                </span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <!--libelle anomalie-->
      <div class="row form-group">
        <label class="col-md-4 col-form-label">Libellé de l'anomalie </label>
        <div class="col-md-8">
          <input type="text" class="form-control" formControlName="libelleAnomalie"/>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="row form-group">
        <label class="col-md-4 col-form-label" id="instructionRetour">Instruction de retour </label>
        <div class="col-md-8">
          <ng-select class="ng-select filter"
                     [items]="instructionsRetour"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     (change)="onChangeInstruction($event)"
                     [clearable]="true"
                     labelForId="instructionRetour"
                     placeholder="Aucune"
                     formControlName="instructionRetour">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <!-- traité par-->
      <div class="row form-group">
        <label class="col-md-3 col-form-label" id="societe">Société </label>
        <div class="col-md-9">
          <ng-select [items]="societes"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [ngClass]="{ 'is-invalid': litigeForm.get('societe').invalid} "
                     [clearable]="false"
                     labelForId="societe"
                     placeholder="Choisir"
                     formControlName="societe">
          </ng-select>
          <div
            *ngIf="litigeForm.get('societe').invalid && (litigeForm.get('societe').dirty || formSubmit)"
            class="invalid-tooltip">La société ne peut pas être null
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <!-- traité par-->
      <div  *ngIf="view.hasDroit('NO_RULE_ACCESS')"  class="row form-group">
        <label class="col-md-5 col-form-label">Sans règle de gestion </label>
        <div class="col-md-7 p-t-5">
          <div class="switcher">
            <input type="checkbox" name="norule"
                   (change)="disableEnableInput('noRule',litigeForm.get('noRule').value)"
                   id="norule" formControlName="noRule">
            <label for="norule"></label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <!-- traité par-->
      <div class="row form-group">
        <label class="col-md-3 col-form-label" id="traitePar">Traité par </label>
        <div class="col-md-9">
          <ng-select [items]="litigeUsers"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="fullname"
                     [clearable]="true"
                     labelForId="traitePar"
                     placeholder="Choisir"
                     formControlName="traitePar">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">Annuler</button>
      <button [ladda]="loadingBtn" class="btn btn-agediss" (click)="updateLitige()">Enregistrer</button>
    </div>
  </div>
</form>
