import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'factureAvoir'})
export class FactureAvoirPipe implements PipeTransform {
  transform(value: boolean, exponent?: number): string {
    if (value) {
      return 'Avoir';
    }
    return 'Facture';
  }
}
