import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Conversation} from '../../../../shared/models/api/conversation.model';
import {ConversationService} from '../../../../shared/services/api/conversation.service';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {View} from '../../../../shared/models/view.model';
import {LitigeService} from '../../../../shared/services/api/litige.service';

@Component({
  selector: 'app-modal-add-contestation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './modal-add-contestation.component.html'
})
export class ModalAddContestationComponent implements OnInit {

  @Input() litigeId: number;
  @Input() modalTitleClass: string = 'text-center';
  @Input() modalHeaderClass: string;
  @Input() configModal: NgbModalConfig;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSubmit: EventEmitter<Conversation> = new EventEmitter<Conversation>();
  public view: View;

  constructor(private config: NgbModalConfig,
              public activeModal: NgbActiveModal,
              private authSvc: AuthenticationService) {
    if (this.configModal) {
      Object.assign(this.config, this.configModal);
    }
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    if (!this.litigeId) {
      throw new Error('Vous devez passer un id de litige en parametre du composant \'app-modal-add-contestation\'.');
    }

  }

  submit(conversation) {
    this.onSubmit.emit(conversation);
    this.activeModal.close();
  }

  close() {
    this.onClose.emit();
    this.activeModal.close();
  }

}
