<div class="modal-header {{modalHeaderClass}}">
  <h4 class="modal-title w-100{{modalTitleClass}}">{{modalTitleText}}</h4>
</div>
<div class="modal-body">
  <div class="row text-right">
    <div class="col-12">
      <app-table-oda [data]="odas" [loading]="loadingTable"></app-table-oda>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn  bg-black-lighter text-white" (click)="activeModal.close()">Fermer</button>
</div>



