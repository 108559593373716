import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Article} from "../../models/api/article.model";
import {Colis} from "../../models/api/colis.model";


@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  urlRessourceArticle = environment.url_api_litige + '/articles';

  constructor(private http: HttpClient) {
  }

  getArticles(): Observable<Article[]> {
    return this.http
      .get<Article[]>(`${this.urlRessourceArticle}`)
      .pipe(map((articles) => articles.map((article) => {
        return new Article().deserialize(article);
      })));
  }

  getArticle(id: number): Observable<Article> {
    return this.http
      .get<Article>(`${this.urlRessourceArticle}/${id}`)
      .pipe(map((article) => {
        return new Article().deserialize(article);
      }));
  }

  getColis(id: number): Observable<Colis[]> {
    return this.http
      .get<Colis[]>(`${this.urlRessourceArticle}/${id}/colis`)
      .pipe(map((allColis) => allColis.map((colis) => {
        return new Colis().deserialize(colis);
      })));
  }

  updateArticle(article: Article): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessourceArticle}/${article.id}`, article)
      .pipe(map((response) => {
        return response.next;
      }));
  }

  delete(articleId): Observable<Article> {
    return this.http.delete<Article>(`${this.urlRessourceArticle}/${articleId}/delete`).pipe(map((article) => {
      return new Article().deserialize(article);
    }));
  }

}
