<!-- begin Form -->
<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <!-- begin Form first row -->
  <div class="row">
    <div *ngIf="!hiddenInput.includes('plateforme')" class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Plateforme</span>
        <ng-select class="ng-select filter"
                   [items]="plateformes"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [virtualScroll]="true"
                   [loading]="loadPlateformes"
                   [clearable]="true"
                   labelForId="Plateforme"
                   placeholder="Choisir"
                   formControlName="plateforme">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('client')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Client</span>
        <ng-select class="ng-select filter"
                   [items]="clients"
                   [selectOnTab]="true"
                   [multiple]="true"
                   [virtualScroll]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadClient"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="Choisir"
                   formControlName="client">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('comptesClients')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Compte client</span>
        <ng-select class="ng-select filter"
                   [items]="compteClients"
                   [selectOnTab]="true"
                   [multiple]="true"
                   [virtualScroll]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadGroup"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="Choisir"
                   formControlName="comptesClients">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('origine')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Origine</span>
        <ng-select class="ng-select filter"
                   [items]="origines"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadOrigines"
                   [clearable]="true"
                   labelForId="origine"
                   placeholder="Choisir"
                   formControlName="origine">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('statut')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Statut</span>
        <ng-select class="ng-select filter"
                   [items]="statuts"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadStatut"
                   [clearable]="true"
                   labelForId="statut"
                   placeholder="Choisir"
                   formControlName="statut">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('motif')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Motif</span>
        <ng-select class="ng-select filter"
                   [items]="motifs"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadMotif"
                   [clearable]="true"
                   labelForId="motif"
                   placeholder="Choisir"
                   formControlName="motif">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('date')" class="col-md-4  m-b-15">
      <app-range-date-picker [filterForm]="filterForm" [datemin]="minDate" [datemax]="maxDate" #inputDate></app-range-date-picker>
    </div>
    <div *ngIf="!hiddenInput.includes('traitePar') " class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Assigné à</span>
        <ng-select class="ng-select filter"
                   [items]="users"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="id"
                   bindLabel="fullname"
                   [loading]="loadUsers"
                   [clearable]="true"
                   labelForId="user"
                   placeholder="Choisir"
                   formControlName="traitePar">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('responsable')" class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Responsable</span>
        <ng-select class="ng-select filter"
                   [items]="responsable"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadResponsable"
                   [clearable]="true"
                   labelForId="responsable"
                   placeholder="Choisir"
                   formControlName="responsable">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('typeLitige')" class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Type Litige</span>
        <ng-select class="ng-select filter"
                   [items]="typeLitige"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadTypeLitige"
                   [clearable]="true"
                   labelForId="typeLitige"
                   placeholder="Choisir"
                   formControlName="typeLitige">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('pole')" class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Pôle</span>
        <ng-select class="ng-select filter"
                   [items]="pole"
                   [multiple]="true"
                   [selectOnTab]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadPole"
                   [clearable]="true"
                   labelForId="pole"
                   placeholder="Choisir"
                   formControlName="pole">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('searchFacture')" class="col-md-4 m-b-15">
      <div class="input-group input-group">
        <div class="input-group-prepend"><span class="input-group-text agediss">Rechercher facture</span></div>
        <input class="form-control" placeholder="Recherche un numéro de facture"
               formControlName="searchFacture"/>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('search')" class="col-12">
      <div class="input-group input-group">
        <div class="input-group-prepend"><span class="input-group-text agediss">Rechercher</span></div>
        <input class="form-control" placeholder="Recherche (Numéro de BL, commande, destinataire)"
               formControlName="search"/>
      </div>
    </div>


  </div>
  <!-- end Form trhird row -->

  <!-- begin Form fourth row -->
  <div class="form-group row m-t-10">
    <div class="offset-5 col-2 p-r-0">
      <input class="btn btn-agediss btn-lg btn-block" type="submit" value="Filtrer">
    </div>
    <div class="px-0">
      <button type="button" class="btn btn-agediss btn-lg m-l-4"
              ngbTooltip="Effacer les infos selectionnées"
              placement="right"
              container="body"
              (click)="reset()">
        <i class='fa fa-times'></i>
      </button>
    </div>
  </div>
  <!-- end Form fourth row -->


</form>


