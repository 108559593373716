// Core Module
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {CookieService} from 'ngx-cookie-service';
// Layout Components
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {SidebarComponent} from './components/layout/sidebar/sidebar.component';
import {SidebarRightComponent} from './components/layout/sidebar-right/sidebar-right.component';
import {TopMenuComponent} from './components/layout/top-menu/top-menu.component';
import {FooterComponent} from './components/layout/footer/footer.component';
// Template Components
import {PanelComponent} from './components/template/panel/panel.component';
// Component Module
import {SlimLoadingBarModule} from 'ng2-slim-loading-bar';
import {TrendModule} from 'ngx-trend';
import {HighlightJsModule} from 'ngx-highlight-js';
// import {TagsInputModule} from 'ngx-tags-input/dist';
import {TableauDeBord} from './pages/tableau-de-bord/tableau-de-bord';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {LaddaModule} from 'angular2-ladda';
import {LitigesPage} from './pages/litiges/litiges';
import {MultiCriterePage} from './pages/multiCritere/multiCritere';
import {NgbDatepickerFrenchFormatter} from './shared/utils/ngDatepicker/ngbDatepickerFrenchFormatter';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {I18nDatepicker} from './shared/utils/ngDatepicker/i18nDatepicker';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {FilterLitigeComponent} from './components/litige/filter-litige/filter-litige.component';
import {LitigePage} from './pages/litiges/show/litige';
import {ModalDocumentsComponent} from './components/commande/modal/modal-documents/modal-documents.component';
import {ModalConversationComponent} from './components/conversation/modal/modal-conversation/modal-conversation.component';
import {TableConversationComponent} from './components/conversation/table/table-conversation/table-conversation.component';
import {TableArticleComponent} from './components/commande/table/table-article/table-article.component';
import {TableContestationComponent} from './components/contestation/table/table-contestation/table-contestation.component';
import {TableResponsabilityComponent} from './components/litige/table/table-responsability/table-responsability.component';
import {TableHistoriqueComponent} from './components/shared/table/table-historique/table-historique.component';
import {TableLitigeComponent} from './components/litige/table/table-litige/table-litige.component';
import {HttpErrorInterceptor} from './shared/interceptors/http-error.interceptor';
import {ContestationsPage} from './pages/contestations/contestations';
import {ContestationPage} from './pages/contestations/show/contestation';
import {ChatConversationComponent} from './components/conversation/chat/chat-conversation.component';
import {NgxAutoScrollModule} from 'ngx-auto-scroll';
import {TableFileComponent} from './components/shared/table/table-file/table-file.component';
import {FilterContestationComponent} from './components/contestation/filter-contestation/filter-contestation.component';
import {checkApi} from './shared/interceptors/check-api.interceptor';
import {LogService} from './shared/services/log.service';
import {Page404Page} from './pages/http/page404/page404';
import {Page401Page} from './pages/http/page401/page401';
import {Page503Page} from './pages/http/page503/page503';
import {Page500Page} from './pages/http/page500/page500';
import {FileUploaderComponent} from './components/shared/file-uploader/fileUploader.component';
import {FileUploadModule} from 'ng2-file-upload';
import {OuiNonPipe} from './shared/pipes/oui-non.pipe';
import {EditLitigeComponent} from './components/litige/form/edit-litige/edit-litige.component';
import {ToastrModule} from 'ngx-toastr';
import {NgbStringAdapter} from './shared/utils/ngDatepicker/NgbStringAdapter';
import {HttpDateInterceptor} from './shared/interceptors/http-date.interceptor';
import {NgSelectModule} from '@ng-select/ng-select';
import {TableJustificatifComponent} from './components/justificatif/table/table-justificatif/table-justificatif.component';
import {JustificatifsPage} from './pages/justificatifs/justificatifs.component';
import {ValidationJustificatfComponent} from './components/justificatif/form/validation-justificatf/validation-justificatf.component';
import {SafePipe} from './shared/pipes/safe.pipe';
import {EditArticleComponent} from './components/article/form/edit-article/edit-article.component';
import {TableColisComponent} from './components/article/table/table-colis/table-colis.component';
import {AddConversationComponent} from './components/conversation/form/add-conversation/add-conversation.component';
import {ContextConversationPipe} from './shared/pipes/context-conversation.pipe';
import {ModalAddConversationComponent} from './components/conversation/modal/modal-add-conversation/modal-add-conversation.component';
import {ModalEditResponsabiliteComponent} from './components/litige/modal/modal-edit-responsabilite/modal-edit-responsabilite.component';
import {ModalEditLitigeComponent} from './components/litige/modal/modal-edit-litige/modal-edit-litige.component';
import {ModalAddContestationComponent} from './components/contestation/modal/modal-add-contestation/modal-add-contestation.component';
import {AddContestationComponent} from './components/contestation/form/add-convertestation/add-contestation.component';
import {FacturesAttentePage} from './pages/factures/attente/facturesAttente';
import {RangeDatePickerComponent} from './components/shared/range-date-picker/range-date-picker.component';
import {AdminDashbord} from './pages/admin/admin-dashbord';
import {ContestatairePipe} from './shared/pipes/contestataire.pipe';
import {RefuseContestationComponent} from './components/contestation/form/refuse-contestation/refuse-contestation.component';
import {FacturesAffecterPage} from './pages/factures/affecter/facturesAffecter';
import {TableFactureAffecterComponent} from './components/facture/table/table-facture-affecter/table-facture-affecter.component';
import {ModalAffecterFactureComponent} from './components/facture/modal/modal-affecter-facture/modal-affecter-facture.component';
import {ModalAttenteFactureComponent} from './components/facture/modal/modal-attente-facture/modal-attente-facture.component';
import {FilterImputationComponent} from './components/imputation/filter-imputation/filter-imputation.component';
import {TableImputationComponent} from './components/imputation/table/table-imputation/table-imputation.component';
import {ModalImputationComponent} from './components/imputation/modal/modal-imputation/modal-imputation.component';
import {ImputationsPage} from './pages/imputations/imputations';
import {ModalCreateLitige} from './components/litige/modal/modal-create-litige/modal-create-litige';
import {CreateLitigeB2c} from './components/litige/modal/modal-create-litige/b2c/create-litige-b2c';
import {CreateLitigeB2bStock} from './components/litige/modal/modal-create-litige/b2b-stock/create-litige-b2b-stock';
import {TableImputationLitigeComponent} from './components/imputation/table/table-imputation-litige/table-imputation-litige.component';
import {TableRetrocessionComponent} from './components/retrocession/table/table-retrocession/table-retrocession.component';
import {FactureAvoirPipe} from './shared/pipes/facture-avoir.pipe';
import {RetrocessionsPage} from './pages/retrocession/retrocessions';
import {ModalRetrocessionComponent} from './components/retrocession/modal/modal-retrocession/modal-retrocession.component';
import {ModalEditCommandeComponent} from './components/litige/modal/modal-edit-commande/modal-edit-commande.component';
import {SiegeViewComponent} from './components/dashboard/siege-view/siege-view.component';
import {PlateformeViewComponent} from './components/dashboard/platfeforme-view/plateforme-view.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import {MatPaginatorIntlFr} from './shared/translation/PaginationTranslate';
import {TableLitigeEligibleOuValideComponent} from './components/litige/table/table-litige-eligible-ou-valide/table-litige-eligible-ou-valide.component';
import {DateImputationPipe} from './shared/pipes/date-imputation';
import {ImputationTableauDeBord} from './pages/imputations/tableau-de-bord/imputation-tableau-de-bord';
import {ModalOdaComponent} from './components/commande/modal/modal-oda/modal-oda.component';
import {TableOdaComponent} from './components/commande/table/table-oda/table-oda.component';
import {OdaTableauDeBord} from './pages/oda/tableau-de-bord/oda-tableau-de-bord';
import {MatTabsModule} from '@angular/material/tabs';
import {TableStatPfComponent} from './components/dashboard/table/table-stat-pf/table-stat-pf.component';
import {EditionPieceJointeComponent} from './components/pieceJointe/form/edition-PieceJointe/edition-pieceJointe.component';
import {NotificationComponent} from './components/notification/notification/notification.component';
import {ContextPipe} from './shared/pipes/context.pipe';
import {AffectationsPage} from './pages/affectations/affectations';
import {ModalAffecterEditableFactureComponent} from './components/facture/modal/modal-affecter-editable-facture/modal-affecter-editable-facture.component';
import {ModalReaffecterFacture} from './components/facture/modal/modal-reaffecter-facture/modal-reaffecter-facture.component';
import { FilePreviewComponent } from './components/shared/file-preview/file-preview/file-preview.component';
import { FilePreviewModalComponent } from './components/shared/modal/file-preview-modal/file-preview-modal.component';
import { ModalValidEnMasseComponent } from './components/facture/modal/modal-valid-en-masse/modal-valid-en-masse.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {Test} from './pages/test/test';
import {MatExpansionModule} from '@angular/material/expansion';
import { FacturesPageComponent } from './pages/factures/factures/factures';
import {TableFactureAttenteComponent} from './components/facture/table/table-facture-attente/table-facture-attente.component';
import {TableFactureComponent} from './components/facture/table/table-facture/table-facture.component';
import {FacturePage} from './pages/factures/facture/facture';
import {FilterFactureComponent} from './components/facture/filter-facture/filter-facture.component';
import {AuthenticationService} from './shared/services/authentication.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {HttpCacheInterceptor} from './shared/interceptors/http-cache.interceptor';
import {TableImputationFactureComponent} from './components/imputation/table/table-imputation-facture/table-imputation-facture.component';
import {environment} from '../environments/environment';
import {SentryErrorHandler} from './shared/services/sentry-log.service';
import {ModalFactureTemporaireComponent} from './components/facture/modal/modal-facture-temporaire/modal-facture-temporaire.component';
import {AnnuairePageComponent} from './pages/annuaire/annuaire';
import {AnnuaireEditorComponent} from './components/annuaire/form/annuaire-editor';
import {PortefeuillesPageComponent} from './pages/portefeuilles/portefeuilles';
import {PortefeuilleEditorComponent} from './components/portefeuille/portefeuille-editor';
import {SchemeInterceptor} from './shared/interceptors/scheme.interceptor';

registerLocaleData(localeFr, 'fr');


@NgModule({
  declarations: [
    AppComponent,
    OuiNonPipe,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    TableauDeBord,
    LitigePage,
    PanelComponent,
    ModalDocumentsComponent,
    ModalConversationComponent,
    TableConversationComponent,
    TableArticleComponent,
    TableContestationComponent,
    TableResponsabilityComponent,
    TableHistoriqueComponent,
    LitigesPage,
    TableLitigeComponent,
    TableLitigeEligibleOuValideComponent,
    MultiCriterePage,
    FilterLitigeComponent,
    FilterContestationComponent,
    ContestationsPage,
    ContestationPage,
    ImputationsPage,
    ChatConversationComponent,
    TableFileComponent,
    Page404Page,
    Page401Page,
    Page503Page,
    Page500Page,
    FileUploaderComponent,
    EditLitigeComponent,
    TableJustificatifComponent,
    JustificatifsPage,
    ValidationJustificatfComponent,
    SafePipe,
    EditArticleComponent,
    TableColisComponent,
    AddConversationComponent,
    ContextConversationPipe,
    ModalAddConversationComponent,
    ModalEditResponsabiliteComponent,
    ModalEditLitigeComponent,
    ModalAddContestationComponent,
    TableFactureComponent,
    ModalAttenteFactureComponent,
    ModalFactureTemporaireComponent,
    AddContestationComponent,
    RangeDatePickerComponent,
    FacturesAttentePage,
    AdminDashbord,
    ContestatairePipe,
    RefuseContestationComponent,
    FacturesAffecterPage,
    TableFactureAffecterComponent,
    ModalAffecterFactureComponent,
    ModalAffecterEditableFactureComponent,
    ModalImputationComponent,
    TableImputationComponent,
    FilterImputationComponent,
    ImputationsPage,
    ModalCreateLitige,
    CreateLitigeB2c,
    CreateLitigeB2bStock,
    TableImputationLitigeComponent,
    TableRetrocessionComponent,
    FactureAvoirPipe,
    RetrocessionsPage,
    ModalRetrocessionComponent,
    ModalEditCommandeComponent,
    ImputationTableauDeBord,
    SiegeViewComponent,
    PlateformeViewComponent,
    DateImputationPipe,
    ModalOdaComponent,
    TableOdaComponent,
    OdaTableauDeBord,
    TableStatPfComponent,
    EditionPieceJointeComponent,
    NotificationComponent,
    ContextPipe,
    AffectationsPage,
    ModalReaffecterFacture,
    ModalValidEnMasseComponent,
    FilePreviewComponent,
    FilePreviewModalComponent,
    Test,
    FacturesPageComponent,
    FacturePage,
    TableFactureAttenteComponent,
    FilterFactureComponent,
    TableImputationFactureComponent,
    AnnuairePageComponent,
    AnnuaireEditorComponent,
    PortefeuillesPageComponent,
    PortefeuilleEditorComponent
  ],
    imports: [
        AppRoutingModule,
        NgSelectModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HighlightJsModule,
        NgbModule,
        ReactiveFormsModule,
        SlimLoadingBarModule.forRoot(),
        TrendModule,
        HttpClientModule,
        MatSortModule,
        MatTableModule,
        LaddaModule,
        CKEditorModule,
        NgxAutoScrollModule,
        FileUploadModule,
        SweetAlert2Module.forRoot(),
        ToastrModule.forRoot(),
        MatPaginatorModule,
        MatTabsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatListModule,
        MatGridListModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatChipsModule,

    // ToastrModule added
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: checkApi,
      deps: [HttpClient, CookieService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}, // intercepteur pour les code erreur http 401
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpDateInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SchemeInterceptor, multi: true},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: NgbDateParserFormatter, useClass: NgbDatepickerFrenchFormatter}, // permet d'avoir le format de date en européen
    {provide: LOCALE_ID, useValue: 'fr-FR'}, // définit la locale
    {provide: NgbDatepickerI18n, useClass: I18nDatepicker}, // prise en charge de la local pour les 'NgbDatepicker'
    {provide: NgbDateAdapter, useClass: NgbStringAdapter},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr},
    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalDocumentsComponent,
    ModalConversationComponent,
    ModalAddConversationComponent,
    ModalEditResponsabiliteComponent,
    ModalEditLitigeComponent,
    ModalImputationComponent,
    ModalAddContestationComponent,
    ModalAttenteFactureComponent,
    ModalFactureTemporaireComponent,
    FileUploaderComponent,
    ModalAffecterFactureComponent,
    ModalCreateLitige,
    ModalRetrocessionComponent,
    ModalOdaComponent,
    ModalEditCommandeComponent,
    ModalAffecterEditableFactureComponent,
    ModalReaffecterFacture,
    ModalValidEnMasseComponent,
  ]
})

export class AppModule {
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const title = this.route.snapshot.firstChild.data.title;
        this.titleService.setTitle(title);
      }
    });
  }
}
