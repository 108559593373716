import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Notif} from '../../../shared/models/api/notif.model';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {DatatableHelper} from '../../../shared/helpers/datatable.helper';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnChanges {

  search = new FormControl();

  @Input() data: Notif[];
  @Input() notif: Notif;
  @Input() displayedColumns: string[] = [
    'select', 'content', 'options'
  ];
  @Input() noRecordText = 'Aucune notification';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() showCheckIcon = false;
  @Input() showDeleteIcon = false;
  @Input() loadingBtn = false;
  @Input() showBtn = false;
  @Input() notifLength: number;
  @Input() hiddenColumns: string[] = [];
  public dataSource: any;



  selection = new SelectionModel<Notif>(true, []);

  @Output() selectCheckNotif: EventEmitter<{id: number, index: number}> = new EventEmitter<{id: number, index: number}>();
  @Output() selectdeleteNotif: EventEmitter<{id: number, index: number}> = new EventEmitter<{id: number, index: number}>();
  @Output() selectCheckAllNotif: EventEmitter<Notif> = new EventEmitter<Notif>();
  @Output() selectDeleteAllNotif: EventEmitter<Notif> = new EventEmitter<Notif>();

  constructor() {}

  ngOnInit() {
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        const index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  selectCheckNotifId(id: number, index: number) {
    this.selectCheckNotif.emit({id, index});
  }

  selectDeleteNotifId(id: number, index: number) {
    this.selectdeleteNotif.emit({id, index});
  }

  onCheckAllNotif(notif) {
    this.selectCheckAllNotif.emit(notif);
  }

  onDeleteAllNotif(notif) {
    this.selectDeleteAllNotif.emit(notif);
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
