<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       matSortActive="id" matSortDirection="desc"
       class="table table-hover table-striped table-bordered mat-elevation-z8">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.id}}">
      {{element?.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="contexte">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contexte</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element.contexte | contextConversation : true}} ">
      {{element.contexte | contextConversation : true}} : {{element.context_id}} | {{element.context_libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="dateCreation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Créée le</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.created_at}}">
      {{element.created_at |  date:'mediumDate'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="dateModification">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Modifiée le</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.updated_at}}">
      {{element.updated_at |  date:'mediumDate'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{ element?.messages }}">
      {{element.messages?.length}}
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="text-center">
      <button *ngIf="showAddConversationBtn"
        placement="top"
        container="body"
        ngbTooltip="Ouvrir une nouvelle discution"
        class="btn btn-agediss btn-sm" (click)="onAdd.emit()"><i class="fa fa-plus"></i></button>
    </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <button
        placement="top"
        container="body"
        ngbTooltip="Ouvrir la discution"
        class="btn btn-agediss  btn-sm  m-r-2" (click)=" onSelectConverstation.emit(element.id)"><i class="fa fa-comments"></i>
      </button>
      <button
        placement="top"
        container="body"
        ngbTooltip="Supprimer la conversation"
        class="btn btn-agediss btn-sm " *ngIf="!element.contestation && showDeleteConversationBtn" data-style="zoom-in" [ladda]="element.pending"
              (click)=" deleteConversation(element.id,i)"><i class="fa fa-trash"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>

  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns, sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>



