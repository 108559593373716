import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'contextPipe'})
export class ContextPipe implements PipeTransform {
  transform(value: number, exponent?: number): string {
    switch(value) {

      case 1:
        return 'label-danger';
      case 2:
        return 'label-primary';
      case 3:
        return 'label-green';
    }

  }
}
