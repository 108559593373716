import {Deserializable} from '../interfaces/deserializable.interface';
import {Litige} from './litige.model';
import {Facture} from "./facture.model";

export class FactureLitige implements Deserializable {

  public id: number;
  public montant: number;
  public litige: Litige;
  public facture: Facture;
  public non_traite?: boolean



  deserialize(input: any): this {
    Object.assign(this, input);
    this.litige = (input.type_facture) ? new Litige().deserialize(input.litige) : null;
    this.facture = (input.client) ? new Facture().deserialize(input.facture) : null;
    return this;
  }

}
