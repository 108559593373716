import {Litige} from './litige.model';
import {Deserializable} from '../interfaces/deserializable.interface';
import {Statut} from './statut.model';
import {User} from "../user.model";
import {Client} from "./client.model";
import {Flux} from "./flux.model";

export class ValideEnMasse implements Deserializable {

  public id?: number;
  public numero_avis: string;
  public client?: Client;
  public flux?: Flux;
  public statut?: Statut;
  public litige?: Litige;
  public destinataire_nom?: string;
  public montant_valide?: number;
  public date_litige: string | Date;
  public montant_facture: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
