import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, map, share} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {User} from '../models/user.model';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {View} from '../models/view.model';
import Swal from 'sweetalert2';
import {urlWithProtocol} from "../utils/util";


@Injectable({providedIn: 'root'})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private currentViewSubject: BehaviorSubject<View>;
  public currentView: Observable<View>;
  public jwtHelper: JwtHelperService;

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) {

    this.jwtHelper = new JwtHelperService();

    try {
      // récupération des donnée dans le storage
      // récupération du token d'identité
      const userStorage = this.extractCurrentUser(this.cookieService.get('_taged'));
      this.currentUserSubject = new BehaviorSubject<User>(userStorage);
      this.currentUser = this.currentUserSubject.asObservable();
    } catch (e) {
      this.currentUserSubject = new BehaviorSubject<User>(null);
      this.currentUser = this.currentUserSubject.asObservable();
      this.logout();
    }

    try {
      // récupération du token de vue
      const viewstorage = this.extractCurrentView(this.cookieService.get('_taged_view'),this.cookieService.get('_taged_rights_access'));
      this.currentViewSubject = new BehaviorSubject<View>(viewstorage);
      this.currentView = this.currentViewSubject.asObservable();
    } catch (e) {
      this.currentViewSubject = new BehaviorSubject(null);
      this.currentView = this.currentViewSubject.asObservable();
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentViewValue(): View {
    return this.currentViewSubject.value;
  }


  clearAuthStorage() {
    this.cookieService.delete('_taged');
    this.cookieService.delete('_taged_refresh');
    this.cookieService.delete('_taged_rights_access');
  }

  /**
   * La methode ci-dessous n'est plus utilisé depuis la version 1.0.0 du SSO
   * Elle est conservé pour le moment
   *
   * @param username
   * @param password
   */
  login(username: string, password: string) {
    return this.http.post<any>(`${environment.urlsso}/sso/login_check`, {username, password})
      .pipe(map(response => {
        //On vérifie que la requête http nous renvoie bien un token et un refresh token
        if (response.token && response.refresh_token != null) {
          const token = response.token;
          const refreshToken = response.refresh_token;
          const currentUser = this.extractCurrentUser(token);

          //On passe par CheckTokenFormat pour vérifier le format du token
          if (AuthenticationService.CheckTokenFormat(currentUser) == true) {

            // si on a un token et un refresh_token on les mets dans les cookies;
            if (token && refreshToken) {
              // Stock les details utilisateur et le token jwt en local storgae pour garder l'user connecter en cas de rafraichissement de page
              this.cookieService.set('_taged', token, null, '/',environment.domain);
              this.cookieService.set('_taged_refresh', refreshToken, null, '/',environment.domain);
              this.currentUserSubject.next(currentUser);
            }
          } else {
            throw Error('Token Data wrong format');
          }
        }
      }));

  }

  public logout() {
    window.location.href = urlWithProtocol(`${environment.urlsso}/logout`);
    // retire le user de local storage pour le déconnecter
    this.clearAuthStorage();
  }

  public redirecToSso() {
    window.location.href = urlWithProtocol(environment.urlsso);
  }

  refreshToken(): Observable<{ token: string, tokenView: string }> {

    const token = {
      token: null,
      tokenView: this.cookieService.get('_taged_view')
    };
    // append refresh token if you have one
    let refreshToken = this.cookieService.get('_taged_refresh');
    return this.http
      .post<any>(`${environment.urlsso}/sso/token/refresh`, {refresh_token: refreshToken})
      .pipe(
        share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY**
        map(response => {
          token.token = response.token;
          refreshToken = response.refresh_token;
          const currentUser = this.extractCurrentUser(token.token);
          // si on a un token et un refresh_token on le mets dans les cookies;
          if (token && refreshToken) {
            // Stock les details utilisateur et le token jwt en local storgae pour garder l'user connecter en cas de rafraichissement de page
            // récupération du domain
            this.cookieService.set('_taged', token.token, null, '/',environment.domain,false,"Lax");
            this.cookieService.set('_taged_refresh', refreshToken, null, '/',environment.domain,false,"Lax");
            this.currentUserSubject.next(currentUser);
          }
          return token;
        })
      );
  }

  public getToken(): Observable<{ token: string, tokenView: string }> {

    const token = {
      token: this.cookieService.get('_taged'),
      tokenView: this.cookieService.get('_taged_view'),
    };

    const isTokenExpired = this.jwtHelper.isTokenExpired(token.token);
    if (!isTokenExpired) {
      return of(token);
    }

    return this.refreshToken();
  }

  public getRefreshToken() {
    return (this.cookieService.get('_taged_refresh'));
  }

  public extractCurrentUser(token: string): User {
    if (!token) {
      throw new Error('Token _taged absent');
    }
    return new User().deserialize(this.jwtHelper.decodeToken(token));
  }

  public extractCurrentView(tokenView: string,tokenRigthsAccess: string): View {
    if (!tokenView || tokenView == "") {
      this.logout();
      throw new Error('Token _token_view absent');
    }
    if (!tokenRigthsAccess || tokenRigthsAccess == "") {
      this.logout();
      throw new Error('Token _token_rights_access absent');
    }

    let view = new View().deserialize(this.jwtHelper.decodeToken(tokenView));
    if (view.applications.length === 0) {
      throw new Error('Le token view ne contient pas de role');
    }

    let rightsAccess = new View().deserialize(this.jwtHelper.decodeToken(tokenRigthsAccess));
    if (rightsAccess.applications.length === 0) {
      throw new Error('Le token _token_rights_access ne contient pas de role');
    }

    let app = view.applications.filter((value) => {
      if (environment.application === value.name) {
        return value;
      }
    });
    if (app.length === 0) {
      throw new Error('l\'application ' + environment.application + ' n\'existe pas dans le token view');
    }
    if (app.length > 1) {
      throw new Error(app.length + ' rôles ont été trouvés pour l\'application ' + environment.application + ' dans le token view');
    }

    let droits = rightsAccess.applications.filter((value) => {
      if (environment.application === value.name) {
        return value;
      }
    });

    view.libelle = app[0].libelle;
    view.droits = droits[0].droits;
    view.subContextIds = app[0].subContextIds;
    return view;
  }


//Méthode qui vérifie que le format de l'object envoyé par le token est bien un User
  static CheckTokenFormat(object: Object) {

    //check si l'object à la propriété donnée
    if (object.hasOwnProperty('username') &&
      object.hasOwnProperty('roles') &&
      object.hasOwnProperty('email') &&
      object.hasOwnProperty('firstname') &&
      object.hasOwnProperty('lastname')
    ) {
      return true;
    } else {
      return false;
    }

  }

}
