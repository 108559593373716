import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import {FormControl, FormGroup} from '@angular/forms';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {Subscription} from 'rxjs';
import {Statut} from '../../../../shared/models/api/statut.model';
import {isNumber} from '../../../../shared/utils/util';
import {TypePieceJointe} from '../../../../shared/models/api/type-piece-jointe.model';
import {ToastrService} from 'ngx-toastr';
import {FileService} from '../../../../shared/services/file.service';
import {PieceJointe} from "../../../../shared/models/api/piece-jointe.model";
import {CompteClient} from "../../../../shared/models/api/compte-client.model";
import {ImageGed} from "../../../../shared/models/api/image-ged.model";
import {PieceJointeGed} from "../../../../shared/models/api/piece-jointe-ged.model";


@Component({
  selector: 'app-edition-pieceJointe',
  templateUrl: './edition-pieceJointe.component.html',
  styleUrls: ['./edition-pieceJointe.component.scss']
})
export class EditionPieceJointeComponent implements OnInit, OnDestroy {

  @Input() imagesGed: ImageGed[] = null;
  @Input() pieceJointe: PieceJointe;

  @Output() submited: EventEmitter<boolean> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public pieceJointeForm: FormGroup;
  public piecesJointes: PieceJointe[];
  public piecesJointesGed: PieceJointeGed[];
  public pieceJointeGed: PieceJointeGed
  public subscriptions: Subscription[] = [];
  public loading = false;
  public loadingFile = false;
  public loadingBtn = false;
  public loadingDownloadBtn = false;
  public formSubmit = false;

  //selecteurs
  public typePieceJointes: TypePieceJointe[];
  public comptesClients: CompteClient[];
  public statuts: Statut[];
  public montantTotal: number;
  public showImage: boolean;
  public urlPreview: string;
  public currentType : number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  constructor(private pieceJointeSvc: PieceJointeService,
              public toastr: ToastrService) {
  }

  ngOnInit() {

    this.loading = true;


    // recupération du justif
    if (!isNumber(this.pieceJointe.id)) {
      this.canceled.emit();
      throw new Error('Le composant \'validation-pieceJointe\' à besoin d\'un id du pieceJointe en paramètre.');
    }


    // PieceJointe du litige
    this.subscriptions.push(this.pieceJointeSvc.getPieceJointe(this.pieceJointe.id).subscribe(
      (file) => {
        this.pieceJointe = file;
        this.showPieceJointe(this.pieceJointe)
        this.initForm();
      }));

    // statut et typePieceJointe
    this.subscriptions.push(
      this.pieceJointeSvc.getTypePieceJointe().subscribe((response) => {
        this.typePieceJointes = response;
      },
      () => {
        Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', icon:'error', showCancelButton: false});
        this.loading = false;
        this.canceled.emit();
      }
    ));
  }

  initForm() {
    this.pieceJointeForm = new FormGroup({
      id: new FormControl(this.pieceJointe.id),
      name: new FormControl(this.pieceJointe.libelle),
      typePieceJointe: new FormControl(this.pieceJointe.type_piece_jointe.id),
      hidden: new FormControl(this.pieceJointe.hidden)
    });
    this.loading = false;
  }

  showPieceJointe(pieceJointe: PieceJointe) {
    this.loadingFile = true;
    if(pieceJointe.path.startsWith('../var/')) {
      const sub1 = this.pieceJointeSvc.getFilePreview(this.pieceJointe.id).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.pieceJointeSvc.getFilePreviewGed(this.pieceJointe.id).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed
          this.loadingFile = false;
          sub.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }

  updatePieceJointe() {
    this.formSubmit = true;
    if (this.pieceJointeForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const values = this.pieceJointeForm.value;
    console.log(values);
    this.subscriptions.push(this.pieceJointeSvc.updatePieceJointe(values).subscribe(
      next => {
        this.loadingBtn = false;
        this.toastr.info('Modifications enregistrées', 'Info', {progressBar: true});
        this.submited.emit(next);
      },
      () => {
        Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', icon:'warning', showCancelButton: false});
        this.loadingBtn = false;
      }
    ));
  }

  download(fileId) {
    this.loadingDownloadBtn = true;
    this.pieceJointeSvc.downloadFile(fileId).subscribe((res) => {
        this.loadingDownloadBtn = false;

        FileService.downloadDataToFile(res);
      },
      (error) => {
        FileService.handleDownloadError(this.pieceJointe.filename);
        this.loadingDownloadBtn = false
      }
    );
  }


  cancel() {

    let formIsDirty = false;
    // nous vérifions que le formulaire n'a pas des données non sauvegardées.
    // Si oui on averti l'utilisateur avec un sweetalert. Sinon on émet l'évènement d'annulation.
    Object.keys(this.pieceJointeForm.controls).forEach(key => {
      if (this.pieceJointeForm.controls[key].dirty) {
        formIsDirty = true;
        Swal.fire({
          title: 'Attention',
          text: 'Des modifications ont été effectuées, Etes-vous sur de vouloir perdre vos modifications?',
          icon: 'warning',
          cancelButtonText: 'Non',
          confirmButtonText: 'Oui !',
          confirmButtonColor: '#aa1c1c',
          allowOutsideClick: false,
          showCancelButton: true,
          allowEscapeKey: false,
        }).then(
          (choice) => {
            if (choice.value) {
              this.canceled.emit();
            }
          },
        );
        return;
      }
    });
    if (!formIsDirty) {
      this.canceled.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }


}
