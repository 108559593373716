<div class="modal-header">
  <h4 class="modal-title w-100 {{modalTitleClass}}">Ouvrir une Contestation</h4>
</div>
<div class="modal-body" style="min-height: 350px">
  <app-add-contestation (canceled)="close()"
                        [contestationFor]="view.context == 2 ? 'PF' : view.context == 3 ? 'DO' : null"
                        [contestataireId]="view.context == 2 ? view.contextId : null"
                        (submitted)="submit($event)"
                        [idLitige]="litigeId">
  </app-add-contestation>
</div>
