<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       class="table table-hover table-striped table-bordered mat-elevation-z8">

  <ng-container matColumnDef="format">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Format</th>
    <td mat-cell *matCellDef="let element" class="with-img text-center"
        [ngbTooltip]="element.format"
        placement="top"
        container="body">
      <img *ngIf="element.format === 'pdf'" class="img-rounded height-30" src="../../../../../assets/img/icon-pdf.png">
      <img *ngIf="element.format === 'image'" class="img-rounded height-30" src="../../../../../assets/img/icon-jpeg.png">
      <img *ngIf="element.format !== 'image' && element.format !== 'pdf'" class="img-rounded height-30"
           src="../../../../../assets/img/icon-jpeg.png">
    </td>
  </ng-container>
  <ng-container matColumnDef="libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
    <td mat-cell *matCellDef="let element"
        container="body"
        placement="top"
        ngbTooltip="{{element?.libelle}}">
      {{element.libelle}} </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element"
        container="body"
        placement="top"
        ngbTooltip="{{element?.created_at | date : 'dd-MM-yyy' }}">
      {{element?.created_at | date : 'dd-MM-yyy' }} </td>
  </ng-container>
  <ng-container matColumnDef="typePieceJointe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element"
        container="body"
        placement="top"
        ngbTooltip="{{element?.type_piece_jointe?.libelle}}">
      {{element?.type_piece_jointe?.libelle }} </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
    <td mat-cell *matCellDef="let element"
        [ngbTooltip]="element?.detail_piece_jointe?.statut?.libelle"
        placement="top"
        container="body">
      {{element?.detail_piece_jointe?.statut?.libelle }} </td>
  </ng-container>
  <ng-container matColumnDef="contestation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contestation</th>
    <td mat-cell class="text-center" *matCellDef="let element"
        container="body"
        placement="top"
        ngbTooltip="voir la contestation">
      <div *ngIf="element.contestation">
        <a [routerLink]="['/','contestations',element.contestation.id ]">voir</a>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="text-center">
      <button *ngIf="showAddDocumentBtn"
              placement="top"
              container="body"
              ngbTooltip="Ajouter un document"
              class="btn btn-agediss btn-sm btn-rounded mr-1"
              (click)="onAdd.emit()">
        <i class="fa fa-plus"></i>
      </button>
    </th>
    <td mat-cell *matCellDef="let element let i = index" class="with-img align-middle text-nowrap">
      <button *ngIf="showDownloadDocumentBtn && element.downloadable"
              ngbTooltip="Télécharger le fichier."
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1"
              (click)="download(element.id)">
        <i class="fa fa-lg fa-download"></i>
      </button>
      <button *ngIf="showDeleteDocumentBtn && element.deletable" [ladda]="element.pending" data-style="zoom-in"
              ngbTooltip="Supprimer le fichier."
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1" (click)="delete(element.id,i)"><i
        class="fa fa-lg fa-trash"></i></button>
      <button *ngIf="showEditDocumentBtn && element.watchable && element?.type_piece_jointe?.code !='DOC_COMPTABLE'"
              ngbTooltip="Editer la pièce jointe."
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1"
              (click)="editFile(element,i)"><i class="fa fa-lg fa-pen-alt"></i></button>
      <button *ngIf="showViewDocumentBtn && element.watchable"
              ngbTooltip="Visualiser la pièce jointe."
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1"
              (click)="selectFile(element,i)"><i class="fa fa-lg fa-eye"></i></button>
      <button *ngIf="showValideJustifBtn && (element?.detail_piece_jointe?.statut?.code == 'JUS_0'  && element?.type_piece_jointe?.code !='DOC_COMPTABLE')"
              ngbTooltip="Valider un justificatif."
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss"
              (click)="selectJustificatif(element,i)"><i class="fa fa-lg fa-file-alt"></i></button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td  class="text-center" mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>

  <ng-container matColumnDef="loading">
    <td class="text-center" mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>
  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns, sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>

