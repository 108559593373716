import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-create-litige-b2c',
  templateUrl: './create-litige-b2c.html',
})
export class CreateLitigeB2c implements OnInit, OnDestroy {

  public b2cForm: FormGroup;
  public displayedColumns: string[] = ['checkbox', 'reference', 'libelle', 'nbcolis', 'volume', 'poids', 'options'];
  public listBl: Array<any> = [
    {
      bl: 'BL123456',
      date: new Date(),
      destinataire: 'Jean',
      adresse: '3 rue par la bas',
      codePostal: '85300',
      ville: 'ville1'
    },
    {
      bl: 'BL845650',
      date: new Date(),
      destinataire: 'michel',
      adresse: '3 rue par la bas',
      codePostal: '85100',
      ville: 'ville2'
    },
    {
      bl: 'BL963258',
      date: new Date(),
      destinataire: 'roger',
      adresse: '3 rue par la bas',
      codePostal: '85500',
      ville: 'ville3'
    },
    {
      bl: 'BL147258',
      date: new Date(),
      destinataire: 'bertrand',
      adresse: '3 rue par la bas',
      codePostal: '85800',
      ville: 'ville4'
    }
  ];


  public tradissArticle: Array<any> = [
    {
      reference: 'lit2P',
      libelle: 'lit 2 place',
      nb_colis: 2,
      volume: 3,
      poids: 5,
      bl: 'BL123456'
    },
    {
      reference: 'frigoMG',
      libelle: 'Refrigerateur moyen gamme',
      nb_colis: 1,
      volume: 3,
      poids: 5,
      bl: 'BL123456'
    },
    {
      reference: 'ikeaTableBas',
      libelle: 'table basse ikea',
      nb_colis: 2,
      volume: 3,
      poids: 5,
      bl: 'BL123456'
    }
  ];

  private subscriptions: Subscription[] = [];
  public loadingForm: Boolean = false;
  public articles: any[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    public activModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {


  }

  initForm(bl) {
    // this.loadingForm = true;
    // this.subscriptions.push(bl.promise(value => {

    this.b2cForm = this.formBuilder.group({
      bl: this.formBuilder.control(bl.bl),
      date: this.formBuilder.control(bl.date),
      destinataire: this.formBuilder.control(bl.destinataire),
      ville: this.formBuilder.control(bl.adresse),
      adresse: this.formBuilder.control(bl.codePostal),
      codePostal: this.formBuilder.control(bl.ville),
    });

    this.loadingForm = true;
    //   }
    // ));
    this.articles = this.tradissArticle.filter(value => value.bl === bl.bl);


  }

  submit() {

  }

  ngOnDestroy(): void {
  }


}
