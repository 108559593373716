import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Notification} from '../../models/api/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  urlRessource = environment.url_api_litige + '/notifications';

  constructor(private http: HttpClient) {

  }

  getNotifications(): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.urlRessource}`);
  }

  /**
   *
   * @param id
   */
  checkNotif(id: number): Observable<any> {
    return this.http.put(`${this.urlRessource}/${id}/check`, {});
  }

  /**
   *
   * @param notificationsId
   */
  checkAllNotif(notificationsId: number[]): Observable<any> {
    return this.http.put(`${this.urlRessource}/check-all`, {notificationsId});
  }

  /**
   *
   * @param id
   */
  removeNotif(id: number): Observable<any> {
    return this.http.delete(`${this.urlRessource}/${id}/user-delete`,{});
  }

  /**
   *
   * @param notificationsId
   */
  removeAllNotif(notificationsId): Observable<any> {
    return this.http.request('delete', `${this.urlRessource}/user-delete-all`, { body: { notificationsId } })
  }

}
