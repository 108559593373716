import {Deserializable} from '../interfaces/deserializable.interface';
import {TypeFacture} from './type-facture.model';
import {Client} from "./client.model";
import {PieceJointe} from "./piece-jointe.model";
import {Litige} from "./litige.model";
import {ActeurService} from "../../services/api/acteur.service";
import {TypeResponsable} from "./type-responsable.model";

export class Acteur implements Deserializable {

  public static readonly CODE_AGEDISS_PARTENAIRES = 'AGEDISS_PARTENAIRES_410';

  public id: number;
  public type_responsable: TypeResponsable;
  public siret: string;
  public discr: string;
  public libelle: string;



  deserialize(input: any): this {
    Object.assign(this, input);
    this.type_responsable = (input.type_responsable) ? new TypeResponsable().deserialize(input.type_responsable) : null;
    return this;
  }

}
