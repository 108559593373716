import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../shared/models/api/client.model';
import {ToastrService} from 'ngx-toastr';
import {AppEvent, EventQueueService} from '../../shared/services/event-queue.service';
import Swal from 'sweetalert2';
import {CompteClient} from '../../shared/models/api/compte-client.model';
import {UserService} from '../../shared/services/api/user.service';
import {User} from '../../shared/models/user.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';


@Component({
  selector: 'app-portefeuille-editor',
  templateUrl: './portefeuille-editor.html',
  styleUrls: ['./portefeuille-editor.scss']
})
export class PortefeuilleEditorComponent implements OnDestroy, OnInit, OnChanges {

  public savingItem = false;
  public deletingItem = false;

  public clients: Client[] = [];
  public comptesClients: CompteClient[] = [];

  public loadingUser = false;
  public user: User;
  public userChanged = false;
  public dataSourceCompteClient: Observable<CompteClient[]>;
  public dataSourceClient: Observable<Client[]>;

  private modal: NgbModalRef;

  @Input() userId: any;
  @Input() clientsSelect: Client[];
  @Input() compteClientsSelect: CompteClient[];

  constructor(
    private toastSvc: ToastrService,
    private eventQueue: EventQueueService,
    private userSvc: UserService,
    private modalSvc: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (!!changes.userId) {
        this.loadUser();
    }
  }

  ngOnDestroy(): void {
  }

  add(modalName) {
    this.modal = this.modalSvc.open(modalName, {size: 'sm'});
  }

  newCompteClient(event) {
    if (!!this.user) {
      if (this.user.comptes_clients.some(item => item.id === event.id)) {
        return;
      }
      this.user.comptes_clients.push(event);
      this.user.comptes_clients.sort((a, b) => a.libelle > b.libelle ? 1 : 0);
      this.dataSourceCompteClient = of(this.user.comptes_clients);
      this.userChanged = true;
      this.modal.close();
    }
  }

  removeCompteClient(id) {
    Swal.fire({
      title: 'Attention !',
      text: 'Supprimer ce compte client ?',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer',
      icon: 'warning',
    }).then(
      result => {
        if (result.value) {
          this.doRemoveCompteClient(id);
        }
      });
  }

  doRemoveCompteClient(id) {
    if (!!this.user) {
      this.user.comptes_clients = this.user.comptes_clients.filter(item => item.id !== id);
      this.dataSourceCompteClient = of(this.user.comptes_clients);
      this.userChanged = true;
    }
  }

  newClient(event) {
    if (!!this.user) {
      if (this.user.clients.some(item => item.id === event.id)) {
        return;
      }
      this.user.clients.push(event);
      this.user.clients.sort((a, b) => a.libelle > b.libelle ? 1 : 0);
      this.dataSourceClient = of(this.user.clients);
      this.userChanged = true;
      this.modal.close();
    }
  }

  removeClient(id) {
    Swal.fire({
      title: 'Attention !',
      text: 'Supprimer ce client ?',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer',
      icon: 'warning',
    }).then(
      result => {
        if (result.value) {
          this.doRemoveClient(id);
        }
      });
  }

  doRemoveClient(id) {
    if (!!this.user) {
      this.user.clients = this.user.clients.filter(item => item.id !== id);
      this.dataSourceClient = of(this.user.clients);
      this.userChanged = true;
    }
  }

  submit() {
    this.savingItem = true;
    const clients = [];
    this.user.clients.forEach(item => clients.push(item.id));
    const comptesClients = [];
    this.user.comptes_clients.forEach(item => comptesClients.push(item.id));
    this.updatePortefeuille(clients, comptesClients);
  }

  loadUser() {
    if (this.userId) {
      this.loadingUser = true;
      this.userSvc.getUserPortefeuille(this.userId).subscribe(u => {
        this.user = u;
        this.user.comptes_clients.sort((a, b) => a.libelle > b.libelle ? 1 : 0);
        this.dataSourceCompteClient = of(this.user.comptes_clients);
        this.user.clients.sort((a, b) => a.libelle > b.libelle ? 1 : 0);
        this.dataSourceClient = of(this.user.clients);
        this.loadingUser = false;
      });
    }
  }

  deleteItem() {
    if (!!this.user) {
      Swal.fire({
        title: 'Attention !',
        text: 'Souhaitez-vous supprimer ce portefeuille ? Cette action ne peut pas être annulée.',
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Supprimer',
        icon: 'warning',
      }).then(
        result => {
          if (result.value) {
            this.doDelete();
          }
        });
    }
  }

  doDelete() {
    this.deletingItem = true;
    this.updatePortefeuille([], [], true);
  }

  updatePortefeuille(clients, comptesClients, deletion = false) {
    this.userSvc.updateUserPortefeuille(this.user.id, {clients, comptesClients}).subscribe(obj => {
      this.savingItem = this.deletingItem = false;
      this.userChanged = false;
      if (deletion) {
        this.user = null;
        this.dataSourceCompteClient = of([]);
        this.dataSourceClient = of([]);
      }
      this.eventQueue.dispatch(new AppEvent(AppEvent.EventType.RELOAD_PORTEFEUILLES, {}));
    }, error => {
      this.toastSvc.error('Une erreur est survenue', 'Desolé');
      this.savingItem = this.deletingItem = false;
    });
  }

}
