<!-- bopenModalAddConversationegin page-header -->
<div class="row mb-2">
  <div class="col-md-4"></div>
  <div class="col-md-4 text-center"><h1 class="page-header">Encours des Litiges</h1></div>
  <div class="col-md-2"></div>
  <div class="col-md-2 text-right">
    <button *ngIf="view.hasDroit('LITIGE_CREATE')" class="btn btn-green btn-sm " (click)="openModalCreateLitige()">
      Nouveau litige
    </button>
  </div>
</div>
<!-- end page-header -->

<div class="alert alert-light fade show">
  <app-filter-litige (submitFilter)="onSubmit($event)" (onLoad)="onSubmit($event)" [displayInput]="displayInputFilter" [pageName]="'litiges' "></app-filter-litige>
</div>

<!-- begin table "A traiter" -->
<div class="row" *ngIf="view.context == 1">
  <div class="col-12">
    <panel noButton="true" bodyClass="no-padding" class="text-center">
      <div class="row" header>
        <div class="offset-2 col-8"><h5 class="p-t-5 text-white"><i>Litige à traiter : <span
          *ngIf="litigesATraiter">{{litigesATraiter.length}}</span> </i></h5></div>
      </div>
      <div style="height: 300px" class="overflow-scroll">
        <app-table-litige [loading]="loadingTableATraite" [showInfosBtn]="true"  [hiddenColumns]="['retroceder']" [data]="litigesATraiter"
                          (onSorted)="onSortATraiter($event)"></app-table-litige>
      </div>
    </panel>
  </div>
  <!-- end processing table "A traiter" -->
</div>

<!-- begin table "Analyse en cours" -->
<div class="row" *ngIf="view.context == 1">
  <div class="col-sm-12">
    <panel noButton="true" bodyClass="no-padding" class="text-center">
      <div class="row" header>
        <div class="offset-2 col-8"><h5 class="p-t-5 text-white"><i>Litige en cours d'analyse : <span
          *ngIf="litigesEncours">{{litigesEncours.length}}</span> </i></h5></div>
      </div>
      <div style="height: 300px;" class="overflow-scroll">
        <app-table-litige [loading]="loadingTableEncours" [showInfosBtn]="true"  [hiddenColumns]="['retroceder']" [data]="litigesEncours"
                          (onSorted)="onSortEncours($event)"></app-table-litige>
      </div>
    </panel>
  </div>
</div>
<!-- end processing table "Analyse en cours" -->

<!-- begin table "Eligible et Validé -->
<div class="row" *ngIf="view.context == 2">
  <div class="col-sm-12">
    <panel noButton="true" bodyClass="no-padding" class="text-center">
      <div class="row" header>
        <div class="offset-2 col-8"><h5 class="p-t-5 text-white"><i>Litige eligible ou validé : <span
          *ngIf="litigesEligibleValide">{{litigesEligibleValide.length}}</span> </i></h5></div>
      </div>
      <div style="height: 600px;" class="overflow-scroll">
        <app-table-litige-eligible-ou-valide
                          [loading]="loadingTableEligibleValide"
                          [showContestBtn]="true"
                          [showValidateBtn]="true"
                          [showInfosBtn]="true"
                          [data]="litigesEligibleValide"
                          (onSelectContestation)="openModalContestation($event)"
                          (onSelectValidation)="validateResponsablite($event)"
                          (onSorted)="onSortEncours($event)"></app-table-litige-eligible-ou-valide>
      </div>
    </panel>
  </div>
</div>
<!-- end processing table "Analyse en cours" -->

