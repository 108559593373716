import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {OdaService} from '../../../shared/services/api/oda.service';
import {Oda} from '../../../shared/models/api/oda.model';
import Swal from 'sweetalert2';
import {FileService} from '../../../shared/services/file.service';
import {View} from "../../../shared/models/view.model";
import {AuthenticationService} from "../../../shared/services/authentication.service";


@Component({
  selector: 'odaTabBord',
  templateUrl: './oda-tableau-de-bord.html',
  styleUrls: ['./oda-tableau-de-bord.scss']
})


export class OdaTableauDeBord implements OnDestroy, OnInit {

  public loading: boolean;
  public loadingDownload: boolean;
  public odas: any[];
  public nbLineSelected: number = 0;
  public limit = 10;
  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  private subscriptions: Subscription[] = [];
  public textBtnToggleDonneLine: string = 'Voir les lignes déjà traitées.';
  public showDoneLine: boolean = false;
  public view: View;


  constructor(private odaSvc: OdaService, private authSvc: AuthenticationService) {}

  ngOnInit(): void {
    this.authSvc.currentView.subscribe(x => {this.view = x;});
    this.filter.toggleDoneLine = false;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.nbLineSelected = 0;
    this.subscriptions.push(this.odaSvc.getOda(this.filter).subscribe(
      (oda) => {
        this.odas = oda;
        this.loading = false;
      }, () => {
        this.loading = false;
      }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  addLine(oda: Oda) {
    oda.added = true;
    this.nbLineSelected++;
    // this.odaDownloadable.push(oda)
  }

  removeLine(oda: Oda) {
    oda.added = false;
    this.nbLineSelected--;
    // this.odaDownloadable.splice(this.odaDownloadable.indexOf(oda),1);
  }

  addRemoveAll() {
    this.nbLineSelected = 0;
    this.odas.map((oda) => {
      oda.added = !oda.added;
      if (oda.added) {
        this.nbLineSelected++;
      }
    });
  }

  toggleDoneLine() {
    this.showDoneLine = !this.showDoneLine;
    if (this.showDoneLine) {
      this.filter.toggleDoneLine = true;
      this.textBtnToggleDonneLine = 'Voir les lignes à traiter';
    } else {
      this.textBtnToggleDonneLine = 'Voir les lignes déjà traitées.';
      this.filter.toggleDoneLine = false;
    }

    this.loadData();
  }

  downloadLines() {

    Swal.fire({
      title: 'Attention',
      html: '<p class="font-weight-bold">Est vous sur de vouloir exporter les lignes selectionnées?</p> <p>une fois l\'export effectué les lignes sont validées et n\'apparaitrons plus dans cet écran.</p>',
      cancelButtonText: 'Non',
      showCancelButton: true,
      icon: 'question',
      backdrop: false,
      confirmButtonText: 'Exporter'
    }).then(
      (result) => {
        if (result.value) {
          this.loadingDownload = true;
          let odaDownloadable: number[] = [];
          this.odas.map((oda) => {
            if(oda.added){
              odaDownloadable.push(oda.id);
            }
          });
          this.odaSvc.downloadOda(odaDownloadable).subscribe(res => {
              FileService.downloadDataToFile(res);
              this.loadingDownload = false;
              this.loadData();
            },
            FileService.handleDownloadError
          );
        }
      }
    );
  }

}
