import {Deserializable} from '../interfaces/deserializable.interface';
import {Litige} from './litige.model';

export class Evenement implements Deserializable {

  public id: number;
  public litige: Litige;
  public created_at: Date;
  public updated_at: Date;
  public libelle: string;
  public emetteur

  deserialize(input: any): this {
    Object.assign(this, input);
    this.litige = (input.litige) ? new Litige().deserialize(input.litige) : null;
    return this;
  }

}
