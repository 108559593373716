<div class="modal-header">
    <h4 class="modal-title">Rétrocessions aux responsables</h4>
</div>
<div class="modal-body">
    <div *ngIf="loadingResponsableForm" style="height: 310px">
        <div class="spinner"></div>
    </div>
    <div *ngIf="!loadingResponsableForm">
        <div class="row row-info">
          <div class="col-md-4 center">
            <div>BL : {{numeroAvis}}</div>
          </div>
          <div class="col-md-8 center">
            <div>Donneur d'ordre : {{nomDO}}</div>
          </div>
        </div>
        <form class="" [formGroup]="retrocessionForm">
            <!--TIERS-->
            <div class="row mb-1">
              <div class="col-md-4 text-center"><label>Responsable tiers</label></div>
              <div class="col-md-2 text-center"><label>Montant</label></div>
              <div class="col-md-2 text-center"><label>N° rétrocession</label></div>
              <div class="col-md-2 text-center"><label>Date facture</label></div>
              <div class="col-md-2 text-center"><label>Type</label></div>
            </div>

            <div formArrayName="retrocessions">
                <div *ngFor="let element of retrocessionForm.get('retrocessions').controls; let indexResp = index;">
                    <div [formGroupName]="indexResp">
                        <div class="row  mb-2">
                            <div class="col-md-4">
                                <label class="text-center pt-2">
                                    {{element.get('discr').value}} - {{element.get('typeResponsable').value}} -
                                    {{element.get('responsable').value}}
                                </label>
                            </div>
                            <div class="col-md-2">
                                <div class="input-icon"><input class="form-control" type="number"
                                                               readonly
                                                               formControlName="montant"/>
                                </div>

                            </div>
                            <div class="col-md-2" style="text-align: center">
                                <div class="input-icon"><input class="form-control"
                                                               type="number"
                                                               formControlName="numeroRetrocession"/>
                                </div>
                            </div>
                            <div class="col-md-2" style="text-align: center">
                                <div class="input-group-append">
                                    <input class="form-control" name="dp"
                                           (click)="d.toggle();$event.stopPropagation();"
                                           [autoClose]=true ngbDatepicker #d="ngbDatepicker"
                                           formControlName="date"/>
                                    <button class="btn btn-outline-danger calendar fa fa-calendar"
                                            (click)="d.toggle();$event.stopPropagation();"
                                            type="button"></button>
                                </div>

                            </div>
                            <div class="col-md-2" style="text-align: center">
                              <label class="text-center pt-2">
                                {{ element.get('avoir').value | factureAvoir}}
                              </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn bg-black-lighter text-white" (click)="activeModal.close()">
                        Annuler
                    </button>
                    <input type="submit" class="btn btn-agediss" [ladda]="" data-style="zoom-in"
                           (click)="submitForm()" value="Enregistrer"/>
                </div>
            </div>
        </form>
    </div>
</div>
