import {Deserializable} from '../interfaces/deserializable.interface';
import {Litige} from "./litige.model";
import {Responsable} from './responsable.model';
import {Retrocession} from "./retrocession.model";


export class Responsabilite implements Deserializable {

  public id: number;
  public litige: Litige;
  public montant: number;
  public pourcent: number;
  public responsable?: Responsable;
  public is_deletable ?: boolean;
  public created_at?: Date;
  public updated_at?: Date;
  public retrocessions: [Retrocession];


  deserialize(input: any): this {
    Object.assign(this, input);
    this.responsable = (input.responsable) ? new Responsable().deserialize(input.responsable) : null;
    return this;
  }

}
