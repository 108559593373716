import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LitigePage} from "../../../../pages/litiges/show/litige";

@Component({
  selector: 'app-table-imputation-facture',
  templateUrl: './table-imputation-facture.component.html',
  styleUrls: ['./table-imputation-facture.component.scss']
})
export class TableImputationFactureComponent implements OnInit {


  @Input() data: any[];

  @Input() noRecordText = 'Aucune imputation';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = null;
  @Output() submited: EventEmitter<Boolean> = new EventEmitter<Boolean>();


  public litigeSelectId: number;
  public modalPreviewLitige: NgbModalRef;
  @ViewChild('modal_litige', {static: true}) modalLtige: LitigePage;

  constructor(
    private modalSvc: NgbModal
  ) {
  }

  ngOnInit() {

  }

  getLenght(obj) {
    return Object.keys(obj).length;
  }

  getType(index){
    for (const [key, value] of Object.entries(this.data[index])) {
      return value[0].code
    }
  }

  openModalLitige(id) {
    this.litigeSelectId = id;
    this.modalLtige.litigeId = id;
    this.modalPreviewLitige = this.modalSvc.open(this.modalLtige, {
      size: "xl",
    });
  }

}
