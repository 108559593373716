import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../../environments/environment';
import {TypeArticle} from "../../models/api/type-article.model";


@Injectable({
  providedIn: 'root'
})
export class TypeArticleService {


  urlRessource = environment.url_api_litige + "/type-article";

  constructor(private http: HttpClient) {

  }

  getTypeArticle(): Observable<TypeArticle[]> {
    return this.http
      .get<TypeArticle[]>(`${this.urlRessource}`).pipe(map((observe) => observe.map(function (observe) {
        return new TypeArticle().deserialize(observe);
      })))
  }

}
