<!-- begin Form -->
<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <!-- begin Form first row -->
  <div class="row">
    <div *ngIf="!hiddenInput.includes('plateforme')" class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Plateforme</span>
        <ng-select class="ng-select filter"
                   [items]="plateformes"
                   [multiple]="true"
                   [selectOnTab]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadPlateformes"
                   [clearable]="true"
                   labelForId="Plateforme"
                   placeholder="Choisir"
                   formControlName="plateforme">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('client')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Client</span>
        <ng-select class="ng-select filter"
                   [items]="clients"
                   [multiple]="true"
                   [selectOnTab]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadClient"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="Choisir"
                   formControlName="client">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('comptesClients')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Compte client</span>
        <ng-select class="ng-select filter"
                   [items]="compteClients"
                   [multiple]="true"
                   [selectOnTab]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadGroup"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="Choisir"
                   formControlName="comptesClients">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('origine')" class="col-md-4  m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Origine</span>
        <ng-select class="ng-select filter"
                   [items]="origines"
                   [multiple]="true"
                   [selectOnTab]="true"
                   bindValue="id"
                   bindLabel="libelle"
                   [loading]="loadOrigines"
                   [clearable]="true"
                   labelForId="origine"
                   placeholder="Choisir"
                   formControlName="origine">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('statut')" class="col-md-3 m-b-15">
      <div class="input-group">
        <div class="input-group-prepend"><span class="input-group-text agediss">Statut</span>
          <ng-select class="ng-select filter"
                     [items]="statuts"
                     [multiple]="true"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="true"
                     labelForId="statut"
                     placeholder="Choisir"
                     formControlName="statut">
          </ng-select>
        </div>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('date')" class="col-md-4  m-b-15">
      <app-range-date-picker [filterForm]="filterForm"  [datemin]="minDate" [datemax]="maxDate" #inputDate></app-range-date-picker>
    </div>
    <div *ngIf="!hiddenInput.includes('traitePar') " class="col-md-4 m-b-15">
      <div class="input-group-prepend"><span class="input-group-text agediss">Assigné à</span>
        <ng-select class="ng-select filter"
                   [items]="users"
                   [multiple]="true"
                   [selectOnTab]="true"
                   bindValue="id"
                   bindLabel="fullname"
                   [loading]="loadUsers"
                   [clearable]="true"
                   labelForId="user"
                   placeholder="Choisir"
                   formControlName="traitePar">
        </ng-select>
      </div>
    </div>
    <div  class="col-md-4 m-b-15">
      <div *ngIf="!hiddenInput.includes('pole')" class="input-group-prepend"><span class="input-group-text agediss">Pôle</span>
        <ng-select class="ng-select filter"
                   [items]="pole"
                   [multiple]="true"
                   [selectOnTab]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadPole"
                   [clearable]="true"
                   labelForId="pole"
                   placeholder="Choisir"
                   formControlName="pole">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput.includes('search') " class="col-12 ">
      <div class="input-group-prepend"><span class="input-group-text agediss">Rechercher</span>
        <input class="form-control" placeholder="Recherche (Numéro de BL, commande, destinataire)"
               formControlName="search"/>
      </div>
    </div>
  </div>
  <!-- end Form trhird row -->

  <!-- begin Form fourth row -->
  <div class="form-group row m-t-10">
    <div class="offset-5 col-2 p-r-0">
      <input class="btn btn-agediss btn-lg btn-block" type="submit" value="Filtrer">
    </div>
    <div class="px-0">
      <button type="button" class="btn btn-agediss btn-lg m-l-4"
              ngbTooltip="Effacer les infos selectionnées"
              placement="right"
              container="body"
              (click)="reset()">
        <i class='fa fa-times'></i>
      </button>
    </div>
  </div>
  <!-- end Form fourth row -->
</form>
