import {Component, OnDestroy, OnInit} from '@angular/core';
import {FactureService} from "../../../shared/services/api/facture.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {PieceJointe} from "../../../shared/models/api/piece-jointe.model";
import {PieceJointeService} from "../../../shared/services/api/piece-jointe.service";
import {ModalAttenteFactureComponent} from "../../../components/facture/modal/modal-attente-facture/modal-attente-facture.component";
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {View} from '../../../shared/models/view.model';


@Component({
  selector: 'facturesAttente',
  templateUrl: './facturesAttente.html'

})
export class FacturesAttentePage implements OnDestroy, OnInit {

  public loadingTable: boolean = false;
  public displayedColumns = [
    'client',
    'date',
    'valider',
  ];
  public context = "attente";
  public pieceJointes: PieceJointe[];
  private activModal: NgbModalRef;
  private modalUpload: NgbModalRef;
  public view: View;

  constructor( private PieceJointeSvc: PieceJointeService,
               private authSvc: AuthenticationService,
               private modalSvc: NgbModal,) {
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.loadData();
  }

  ngOnDestroy(): void {
  }

  openModalAffectationFacture(event) {
    this.activModal = this.modalSvc.open(ModalAttenteFactureComponent, {
      size: "xl", backdrop: 'static', keyboard: false
    });

    this.activModal.componentInstance.pieceJointe = event;

    this.activModal.result.then(
      value => {
        if (value == true) {
          this.loadData()
        }
      },
      () => {
      }
    )
  }


  openModalUploader(modal) {
    this.modalUpload = this.modalSvc.open(modal, {
      size: "xl", backdrop: 'static', keyboard: false
    });
  }

  onUploaded() {
    this.loadData();
    this.modalUpload.close();
  }

  loadData() {
    this.loadingTable = true;
    const sub = this.PieceJointeSvc.getPieceJointeComptable().subscribe(value => {
      this.pieceJointes = value;
      this.loadingTable = false;
      sub.unsubscribe();
    })
  }


}

