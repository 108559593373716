import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import pageSettings from '../../../config/page-settings';
import {User} from '../../../shared/models/user.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {View} from '../../../shared/models/view.model';
import {environment} from '../../../../environments/environment';
import {NotificationService} from '../../../shared/services/api/notification.service';
import {ToastrService} from 'ngx-toastr';
import {Notif} from '../../../shared/models/api/notif.model';
import {Subscription} from 'rxjs';
import {urlWithProtocol} from '../../../shared/utils/util';



@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy, OnInit {
  @Input() public pageSidebarTwo = false;
  @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<boolean>();
  @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();

  pageSettings = pageSettings;
  private authSvc: AuthenticationService;
  public currentUser: User;
  public view: View;
  public urlsso: string = urlWithProtocol(environment.urlsso);
  public urlSharePoint: string = environment.url_sharepoint;
  public notifInterval;
  public lastNbNotif = 0;
  public selectNotif: number = null;
  public notifs: Notif[];
  public notifsSub: Subscription;
  public notifLength;
  public showBtn;

  // loading
  public loadingTableNotification = false;
  public loadingNbNotifs = false;
  public loading = false;
  public loadingBtn = false;
  private subscriptions: Subscription[] = [];

  constructor(authSvc: AuthenticationService,
              private notificationSvc: NotificationService,
              private toastSvc: ToastrService
  ) {
    this.authSvc = authSvc;
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit(): void {
    this.getNotif();

    this.notifInterval = setInterval(() => {
      this.getNotif();
    }, 180000);
    this.pageSidebarTwo = pageSettings.pageSidebarTwo;
  }

  getNotif() {
    this.loadingNbNotifs = true;
    this.loading = true;
    this.loadingTableNotification = true;
    this.subscriptions.push(this.notificationSvc.getNotifications().subscribe(notifs => {
      this.notifs = notifs;
      this.notifLength = this.notifs.length;

      if (this.lastNbNotif > this.notifs.length) {
        this.lastNbNotif = 0;
      }
      if (this.lastNbNotif < this.notifs.filter(notif => notif.viewed == false || notif.viewed == undefined).length) {
        this.toastSvc.info('Vous avez ' + (this.notifs.length - this.lastNbNotif) + ' nouvelles notifications', 'Notification',
          {
            progressBar: true,
            timeOut: 1000,
            positionClass: 'toast-top-center'
          });
      }
      this.lastNbNotif = this.notifs.filter(notif => notif.viewed == false).length;
      this.loadingTableNotification = false;
      this.loadingNbNotifs = false;
      this.loading = false;
    }, err => {
      console.log(err);
    }));

  }

  checkNotif(value: { id: number, index: number }) {
    this.selectNotif = value.id;
    this.notifs[value.index].viewed = !this.notifs[value.index].viewed;
    this.notifs = this.notifs.slice();
    this.notificationSvc.checkNotif(this.selectNotif).subscribe(() => {
    });
  }

  checkAllNotif() {
    const notificationsId = [];
    this.showBtn = !this.showBtn;
    this.notifs.forEach(val => {
      val.viewed = !val.viewed;
      notificationsId.push(val.id);
      this.notifs = this.notifs.slice();
    });
    this.notificationSvc.checkAllNotif(notificationsId).subscribe(() => {
    });
  }

  removeNotif(value: { id: number, index: number }) {
    this.notifs[value.index].pending = true;
    this.notifsSub = this.notificationSvc.removeNotif(value.id).subscribe(() => {
        this.notifs.splice(value.index, 1);
        this.notifs[value.index].pending = false;
        this.getNotif();
      },
      () => {
        this.notifs[value.index].pending = false;
        this.toastSvc.error('la notification n\'a pas pu être supprimées');
      }
    );
  }

  removeAllNotif() {
    const notificationsId = [];
    this.notifs.forEach(val => {
      val.viewed = true;
      notificationsId.push(val.id);
      this.notifs = this.notifs.slice();
    });
    this.loadingBtn = true;
    this.notifsSub = this.notificationSvc.removeAllNotif(notificationsId).subscribe(() => {
        this.notifs.splice(0);
        this.getNotif();
        this.loadingBtn = false;
      }
    );
  }

  mobileSidebarToggle() {
    this.toggleMobileSidebar.emit(true);
  }

  mobileRightSidebarToggle() {
    this.toggleMobileRightSidebar.emit(true);
  }

  toggleSidebarRight() {
    this.toggleSidebarRightCollapsed.emit(true);
  }

  mobileTopMenuToggle() {
    this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
  }

  mobileMegaMenuToggle() {
    this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
  }

  ngOnDestroy() {
    this.pageSettings.pageMobileTopMenuToggled = false;
    this.pageSettings.pageMobileMegaMenuToggled = false;
    clearInterval(this.notifInterval);
    this.subscriptions.map(s => {
      s.unsubscribe();
    });
  }

  logout() {
    this.authSvc.logout();
  }


}
