<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       class="table table-hover table-striped table-bordered mat-elevation-z8">

  <ng-container matColumnDef="libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Libellé</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.libelle}}">
      {{element.libelle}} </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.created_at | date : 'dd-MM-yyy - H:mm' }}">
      {{element?.created_at | date : 'dd-MM-yyy - H:mm' }} </td>
  </ng-container>
  <ng-container matColumnDef="emetteur">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Qui</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element.emetteur  ? element.emetteur.fullname : 'Automate'}}">
      {{element.emetteur  ? element.emetteur.fullname : 'Automate'}} </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>

  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>
  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns, sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>
