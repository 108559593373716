import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import pageMenus from '../../../config/page-menus';
import pageSettings from '../../../config/page-settings';
import {User} from '../../../shared/models/user.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {View} from '../../../shared/models/view.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  animations: [
    trigger('expandCollapse', [
      state('expand', style({height: '*', overflow: 'hidden', display: 'block'})),
      state('collapse', style({height: '0px', overflow: 'hidden', display: 'block'})),
      state('active', style({height: '*', overflow: 'hidden', display: 'block'})),
      transition('expand <=> collapse', animate(100)),
      transition('active => collapse', animate(100))
    ])
  ]
})

export class SidebarComponent implements AfterViewChecked , OnInit {
  navProfileState = 'collapse';
  @Output() toggleSidebarMinified = new EventEmitter<boolean>();
  @Output() hideMobileSidebar = new EventEmitter<boolean>();
  @Input() pageSidebarTransparent;
  @Input() pageSidebarMinified;
  menus = pageMenus;
  pageSettings = pageSettings;
  mobileMode;
  desktopMode;
  scrollTop;
  @ViewChild('sidebarScrollbar', {static: true}) private sidebarScrollbar: ElementRef;
  currentUser: User;
  public view: View;

  constructor(private eRef: ElementRef, private authSvc: AuthenticationService) {
    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }

  }

  ngOnInit() {
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  toggleNavProfile() {
    if (this.navProfileState === 'collapse') {
      this.navProfileState = 'expand';
    } else {
      this.navProfileState = 'collapse';
    }
  }

  toggleMinified() {
    this.toggleSidebarMinified.emit(!this.pageSettings.pageSidebarMinified);
    this.scrollTop = 40;
  }

  expandCollapseSubmenu(currentMenu, allMenu, active) {
    for (const menu of allMenu) {
      if (menu !== currentMenu) {
        menu.state = 'collapse';
      }
    }
    if (currentMenu.state === 'expand' || (active.isActive && !currentMenu.state)) {
      currentMenu.state = 'collapse';
    } else {
      currentMenu.state = 'expand';
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hideMobileSidebar.emit(true);
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.scrollTop = (this.pageSettings.pageSidebarMinified) ? event.target.scrollTop + 40 : 0;
    if (typeof (Storage) !== 'undefined') {
      localStorage.setItem('sidebarScroll', event.target.scrollTop);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }
  }

  ngAfterViewChecked() {
    if (typeof (Storage) !== 'undefined' && localStorage.sidebarScroll) {
      if (this.sidebarScrollbar && this.sidebarScrollbar.nativeElement) {
        this.sidebarScrollbar.nativeElement.scrollTop = localStorage.sidebarScroll;
      }
    }
  }

  printVersion() {
    console.log(`version : ${environment.VERSION}`);
  }

}
