import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {Facture} from '../../../../shared/models/api/facture.model';
import {merge, of, Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Litige} from '../../../../shared/models/api/litige.model';
import {FactureService} from '../../../../shared/services/api/facture.service';
import {ToastrService} from 'ngx-toastr';
import {formatDate} from '@angular/common';
import {forkJoinWithProgress} from 'rxjs-toolbox';
import {catchError, debounceTime, finalize, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {ModalValidEnMasseComponent} from "../modal-valid-en-masse/modal-valid-en-masse.component";
import {PieceJointeGed} from "../../../../shared/models/api/piece-jointe-ged.model";
import Swal from "sweetalert2";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {PieceJointeService} from "../../../../shared/services/api/piece-jointe.service";


@Component({
  selector: 'app-modal-affecter-facture',
  templateUrl: './modal-affecter-facture.component.html',
  styleUrls: ['./modal-affecter-facture.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalAffecterFactureComponent implements OnInit {


  @HostListener('window:beforeunload', ["$event"])
  checkstatus($event) {
    if (this.loadingBtn) {
      this.toastSvc.warning('Vous devez attendre que la tache soit terminée.', 'Attention', {progressBar: true});
      $event.returnValue = 'Vous devez attendre que la tache soit terminée.';
    }
  }

  @Input() facture: Facture = null;
  @Input() piecesJointesGed: PieceJointeGed[];

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public subscriptions: Subscription[] = [];
  public factureForm: FormGroup;
  public listLitiges: Litige[] = [];
  public listLitigesBackup: Litige[] = [];
  public totalMontantValider: number;
  public totalMontantFacture: number;
  public totalEcart: number = 0;
  public loadingBtn: Boolean = false;
  public loading: Boolean = false;
  public loadingFile = false;
  public BalanceValue: Boolean = false;
  public starting: Boolean = true;
  public showTable: Boolean = false;
  public height: string = '';
  public width: string = '200px';
  public width2 = '0%';
  public data = [];
  public dataValideEnMasse;
  public test: number;
  public retryLitiges: any[] = [];
  public success: boolean = true;
  public showManuel: boolean = false;
  public loadListLitige: boolean = false;
  public showImage: boolean;
  public urlPreview: string;
  public currentType : number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  searchLitige = new FormControl();
  public listLitigesAffecter: Litige[] = [];
  isLoading = false;
  errorMsg: string;
  displayFn: any;
  isAllSelected = false;
  selectedItems: any[] = new Array<any>();
  filteredItems: any[] = [];
  public selected?: boolean

  @ViewChild(NgbTooltip, {static: false}) tooltip: NgbTooltip;

  constructor(
    public activeModal: NgbActiveModal,
    private PieceJointeSvc: PieceJointeService,
    private modalService: NgbModal,
    public litigesSvc: LitigeService,
    public factureSvc: FactureService,
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    private cd: ChangeDetectorRef,
  ) {
    if (this.selected === undefined) this.selected = false;

  }

  ngOnInit() {
    this.starting = true;

    this.loading = true;
    this.initSearchLitigeForm();
    this.initForm();
    this.sommeMontantValider();
    this.sommeMontantFacture();

    if (!this.facture || !(this.facture instanceof Facture)) {
      throw new Error('Vous devez passer une facture au composant ');
    }
    this.showPieceJointe(this.facture.piece_jointe.id)
  }


  close() {
    if (this.loadingBtn) {
      this.toastSvc.warning('Vous devez attendre que la tache soit terminée.', 'Attention', {progressBar: true});
      return;
    }
    this.activeModal.close(false);
  }

  submit() {

    this.success = true;
    this.width2 = '0';
    this.loadingBtn = true;
    const values = this.factureForm.value;
    let litiges = [];
    if (this.retryLitiges.length > 0) {
      values.litiges = this.retryLitiges;
    }
    this.retryLitiges = []
    // boucle pour grouper l'envoi des litiges par 5
    /*let tmplitige = [];
    for (let v = 0; v < values.litiges.length; v++) {
      tmplitige.push(values.litiges[v]);
      if (tmplitige.length == 5 || v == (values.litiges.length - 1)) {
        litiges.push(tmplitige)
        tmplitige = [];
      }
    }*/
    litiges.push(values.litiges);

    let factureId = values.id;

    let result$ = forkJoinWithProgress(litiges.map(lit => {
      let fac = new Facture();
      fac.id = factureId;
      fac.litiges = lit;
      return this.factureSvc.updateFactureAffecter(fac).pipe(catchError((err) => {
        this.retryLitiges = this.retryLitiges.concat(fac.litiges)
        console.error('il y a eu un crash dans les litiges ' + JSON.stringify(fac))
        this.success = false;
        return of(err);
      }));
    }));

    result$.pipe(
      mergeMap(([finalResult, progress]) => merge(
        progress.pipe(
          tap((value) => {
            this.width2 = value + '%';
          }),
          ignoreElements()
        ),
        finalResult
      ))
    ).subscribe(values => {

      this.loadingBtn = false;
      if (this.success) {
        this.factureSvc.updateStatutFacture(this.facture.id, 'FAC_4').subscribe(() => {
          this.activeModal.close(true);
          this.toastSvc.success('Affectation à la facture réussie', 'Info', {progressBar: true});
        }, error => {
          console.log(error);
        })

      } else {
        let errors = [];
        if(!Array.isArray(values)){
          return
        }
        for (const v in values) {
          if (values[v].error) {
            errors.push(values[v].error);
          }
        }
        let retryLitigeString = "";
        for (const l in this.retryLitiges) {
          console.log(l);
          console.log(this.retryLitiges[l]);
          retryLitigeString += `<li>${this.retryLitiges[l].numeroAvis}</li>`
        }
        Swal.fire({
          title: 'Désolé', html: `
<p>Une erreur est survenue lors de l\'affectation des factures.</p>
<p>Vous pouvez éssayer de re-soumettre le formulaire (le formulaire ne renverra que les BL ci-dessous) ou bien merci de contacter le service informatique (pensez à faire une capture de votre écran) </p>
<p>Voici la liste du lot BL qui ne sont pas passés : </p>
<ul style="text-align: left">${retryLitigeString}</ul>
<p>Message d'érreur pour le service informatique</p>
<pre style="color: darkred; text-align: left">${JSON.stringify(errors, null, '\t')}</pre>`
          , showCancelButton: false
        });
      }
    }, e => {
      console.error(e);
      this.success = false;
      this.toastSvc.warning('Affectation à la facture échouée', 'Info', {progressBar: true});
      this.loadingBtn = false;
    });

  }

  addBl() {
    this.tooltip.close()
    this.starting = false;
    if (this.selectedItems instanceof Array) {
      for (let litige of this.selectedItems) {
        this.listLitigesAffecter.push(litige)
        this.addBlBody(litige.id);
      }
      this.selectedItems = [];
    }

  }

  addBlBody(id) {
    if (!id) {
      return;
    }
    const value = this.selectedItems.find(value1 => value1.id === id);

    //On vérifie les montants ne sont pas nuls, si c'est le cas on les set à 0

    if (value.montant_valide == null) {
      value.montant_valide = 0;
    }
    value.montant_facture = value.montant_valide;


    this.totalMontantFacture = this.totalMontantFacture + value.montant_facture;
    this.totalMontantValider = this.totalMontantValider + value.montant_valide;
    this.totalEcart = this.facture.montant - this.totalMontantFacture;
    //on push le litige sélectionnée avec le ng-select dans factureForm
    let items = this.factureForm.get('litiges') as FormArray;
    items.push(new FormGroup({
      numeroAvis: this.formBuilder.control(value.numero_avis),
      client: this.formBuilder.control(value.client.libelle),
      destinataireNom: this.formBuilder.control(value.destinataire_nom),
      montantValider: this.formBuilder.control(value.montant_valide),
      montantAffecter: this.formBuilder.control(value.montant_facture),
      ecart: this.formBuilder.control(value.montant_facture - value.montant_valide),
      litigeId: this.formBuilder.control(value.id),
    }));

    this.removeBlList(value.id);
    //recalcule l'écart
    this.sommeMontantFacture();
    this.showTable = true;

  }

  removeBL(index) {
    this.starting = false;

    let items = this.factureForm.get('litiges') as FormArray;
    let lit = <Litige>this.listLitigesBackup.find((value) => {
      return value.id === items.at(index).get('litigeId').value;
    })
    this.listLitiges = [...this.listLitiges, lit];
    items.removeAt(index);
    //recalcule l'écart
    this.sommeMontantFacture();
  }

  initForm() {

    let litiges = [];
    //On vérifie les montants ne sont pas nuls, si c'est le cas on les set à 0

    if (this.facture.litiges != undefined) {

      this.facture.litiges.forEach(value => {

        if (value.montant_valide == null) {
          value.montant_valide = 0;
        }
        value.montant_facture = value.montant_valide;
        //on push les litiges liée à cette facture dans le formcontroller
        litiges.push(new FormGroup({
          numeroAvis: this.formBuilder.control(value.numero_avis),
          client: this.formBuilder.control(value.client.libelle),
          destinataireNom: this.formBuilder.control(value.destinataire_nom),
          montantValider: this.formBuilder.control(value.montant_valide),
          montantAffecter: this.formBuilder.control(value.montant_facture),
          ecart: this.formBuilder.control(value.montant_facture - value.montant_valide),
          litigeId: this.formBuilder.control(value.id),
        }));
      });
    }


    this.factureForm = this.formBuilder.group({
      id: new FormControl(this.facture.id),
      litiges: this.formBuilder.array(litiges),
      selectedLitige: new FormControl(),
    });
    this.loading = false;
  }

  removeBlList(litigeId) {
    let deletedIndex = this.listLitiges.findIndex(value1 => value1.id === litigeId);
    this.listLitiges.splice(deletedIndex, 1);
    this.listLitiges = this.listLitiges.slice();
    this.factureForm.get('selectedLitige').setValue(null);
  }

  sommeMontantValider() {
    this.totalMontantValider = 0;
    let items = this.factureForm.get('litiges') as FormArray;
    items.controls.forEach(value => {
      this.totalMontantValider = this.totalMontantValider + value.get('montantValider').value;
    });

    this.totalMontantValider = +this.totalMontantValider.toFixed(2);
    return this.totalMontantValider;
  }

  sommeMontantFacture(index = null) {

    this.totalMontantFacture = 0;
    this.totalEcart = 0;
    let items = this.factureForm.get('litiges') as FormArray;
    items.controls.forEach(value => {
      if (index != null) {
        if (items.at(index).get('montantAffecter').value < 0) {
          items.at(index).get('montantAffecter').setValue(0);
        }
        let ecart = items.at(index).get('montantAffecter').value - items.at(index).get('montantValider').value;
        items.at(index).get('ecart').setValue(ecart);
      }
      this.totalMontantFacture = this.totalMontantFacture + value.get('montantAffecter').value;
      this.totalMontantFacture = +this.totalMontantFacture.toFixed(2);
      this.totalEcart = this.facture.montant - this.totalMontantFacture;

    });

    this.BalanceValue = this.totalEcart == 0;
    return this.totalMontantFacture;

  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if(this.facture.piece_jointe.path.startsWith('../var/')) {
      const sub1 = this.PieceJointeSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.PieceJointeSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed
          this.loadingFile = false;
          sub.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }

  openModalValidEnMasse() {
    const modalRef = this.modalService.open(ModalValidEnMasseComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.factureId = this.facture.id;
    modalRef.componentInstance.loading = this.loading;
    modalRef.componentInstance.onSelectValideEnMasse.subscribe((valideEnMasse) => {
      this.dataValideEnMasse = valideEnMasse
      Object.values(this.dataValideEnMasse).forEach(value => {
        this.addBlBodyForValideEnMasse(value)
      })
    })
  }

  addBlBodyForValideEnMasse(value) {
    this.starting = false;
    if (!value) {
      return;
    }
    //On vérifie les montants ne sont pas nuls, si c'est le cas on les set à 0

    if (value.montant_valide == null) {
      value.montant_valide = 0;
    }
    value.montant_facture = value.montant_valide;


    this.totalMontantFacture = this.totalMontantFacture + value.montant_facture;
    this.totalMontantValider = this.totalMontantValider + value.montant_valide;
    this.totalEcart = this.facture.montant - this.totalMontantFacture;
    //on push le litige sélectionnée avec le ng-select dans factureForm
    let items = this.factureForm.get('litiges') as FormArray;
    items.push(new FormGroup({
      numeroAvis: this.formBuilder.control(value.numero_avis),
      client: this.formBuilder.control(value.client.libelle),
      destinataireNom: this.formBuilder.control(value.destinataire_nom),
      montantValider: this.formBuilder.control(value.montant_valide),
      montantAffecter: this.formBuilder.control(value.montant_facture),
      ecart: this.formBuilder.control(value.montant_facture - value.montant_valide),
      litigeId: this.formBuilder.control(value.id),
    }));

    this.removeBlList(value.id);
    //recalcule l'écart
    this.sommeMontantFacture();
    this.showTable = true;

  }

  optionClicked(event: Event, item: Litige) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      const i = this.selectedItems.findIndex(value => value.numero_avis === item.numero_avis);
      this.selectedItems.splice(i, 1);
    }
    if (this.selectedItems.length > 0) {
      this.tooltip.open();
    } else {
      this.tooltip.close()
    }

  }

  toggleSelectAll() {
    this.isAllSelected = !this.isAllSelected;
    let len = this.filteredItems.length;
    if (this.isAllSelected) {
      for (let i = 0; i++; i < len)
        for (let i = 0; i++; i < len)
          this.filteredItems[i].selected = true;
      this.filteredItems = this.filteredItems.slice()
      this.selectedItems = this.filteredItems;
      this.cd.markForCheck();
    } else {
      this.selectedItems = [];
      for (let i = 0; i++; i < len)
        this.filteredItems[i].selected = false;

    }
  }

  initSearchLitigeForm(){
    this.searchLitige.valueChanges
      .pipe(
        debounceTime(500),
        tap((t) => {
          this.errorMsg = "";
          this.filteredItems = [];
        }),
        switchMap((value) => {
            if (!value || value.length < 4) {
              return of([])
            }
            this.isLoading = true;
            return this.litigesSvc.getLitigesAAffecter(<FilterMultiCritereModel>{search: value})
              .pipe(
                map((litiges) => litiges.map((litige) => {
                  // on check les litige déja affectés.
                  const litigeHold = this.listLitigesAffecter.find(i => {
                    return i.numero_avis == litige.numero_avis
                  });

                  litige.affected = !!litigeHold;
                  // on regarde si le bl à déja été selectionné
                  litige.selected = false;
                  const lit = this.selectedItems.find(i => {
                    return i.numero_avis == litige.numero_avis
                  })
                  if (lit) {
                    litige.selected = true;
                  }
                  return litige;
                })),
                finalize(() => {
                  this.isLoading = false
                }),
              )
          }
        )
      )
      .subscribe(data => {
        this.filteredItems = data
      },error => {
        this.filteredItems = []
      });
  }
}
