<div class="modal-header text-center">
  <h4 class="modal-title w-100">Affectation en attente <span *ngIf="factureId">{{factureId}}</span></h4>
</div>

<div class="modal-body">
  <form  class="form-group" [formGroup]="factureForm">
    <div class="row">
      <div class="col-md-8">
        <app-file-preview [pieceJointe]="pieceJointe"
                          [piecesJointesGed]="piecesJointesGed"
                          [currentType]="currentType"
                          [showImage]="showImage"
                          [urlPreview]="urlPreview"
                          [loadingPage]="loadingFile">

        </app-file-preview>
      </div>

      <div class="col-md-4">

        <div class="row col pb-16">
          <label>Motif </label>
          <textarea class="form-control" formControlName="motif" rows="4">
          </textarea>
        </div>

        <div class="row col pb-16">
          <label >Date de fin d'attente</label>
          <input class="form-control"
                 [ngClass]="{'is-invalid' : formEndDate.invalid && (formEndDate.dirty || formSubmit) }"
                 name="de"
                 (click)="de.toggle();"
                 [autoClose]=true ngbDatepicker #de="ngbDatepicker"
                 placeholder="JJ-MM-AAAA"
                 formControlName="endDate">
          <div *ngIf="formEndDate.invalid && (formEndDate.dirty || formSubmit)"
               class="invalid-tooltip relative">Ce champs doit être renseigné !
          </div>
        </div>

        <div class="row col pb-16">
          <label >Date de litige </label>
          <input class="form-control"
                 [ngClass]="{'is-invalid' : formImputationDate.invalid && (formImputationDate.dirty || formSubmit) }"
                 name="di"
                 (click)="di.toggle();"
                 [autoClose]=true ngbDatepicker #di="ngbDatepicker"
                 placeholder="JJ-MM-AAAA"
                 formControlName="imputationDate">
          <div *ngIf="formImputationDate.invalid && (formImputationDate.dirty || formSubmit)"
               class="invalid-tooltip relative">Ce champs doit être renseigné !
          </div>
        </div>

        <div class="row col">
          <label>Responsable </label>
          <ng-select class="ng-select filter"
                     [ngClass]="{'is-invalid' : formImputationResponsable.invalid && (formImputationResponsable.dirty || formSubmit) }"
                     [items]="acteursInterne"
                     [selectOnTab]="true"
                     bindValue="id"
                     [loading]="loadingActeurs"
                     bindLabel="libelle"
                     [clearable]="false"
                     [searchFn]="searchActeur"
                     placeholder="choisir"
                     formControlName="imputationResponsable">

            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div><span>Title: </span><span>{{item.libelle}}</span></div>
              <small><b>Id:</b> {{item.code}} | <b>type :</b> {{item.discr}} |
                <span class="badge badge-green" *ngIf="item.enable">actif</span>
                <span class="badge badge-danger" *ngIf="!item.enable">non-actif</span>
              </small>
            </ng-template>
          </ng-select>
          <div *ngIf="formImputationResponsable.invalid && (formImputationResponsable.dirty || formSubmit)"
               class="invalid-tooltip relative">Ce champs doit être renseigné !
          </div>
        </div>

        <div class="row pb-16">
        </div>


      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" class="btn bg-black-lighter text-white" type="button">Fermer</button>
  <button (click)="submit()" [ladda]="loadingBtn" class="btn btn-agediss text-white" data-style="zoom-in"
          type="button">Enregistrer
  </button>
</div>
