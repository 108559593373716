import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Contestation} from '../../../../shared/models/api/contestation.model';
import {FactureService} from '../../../../shared/services/api/facture.service';
import {ThemePalette} from '@angular/material/core';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-modal-valid-en-masse',
  templateUrl: './modal-valid-en-masse.component.html',
  styleUrls: ['./modal-valid-en-masse.component.scss'],
})
export class ModalValidEnMasseComponent implements OnInit {

  @Output() submitted: EventEmitter<Contestation> = new EventEmitter();
  @Output() onSelectValideEnMasse: EventEmitter<[]> = new EventEmitter<[]>();

  @Input() factureId: number = null;

  public valideEnMasse;
  public noDataText = 'Aucun Bl'
  public valideEnMasseForm: FormGroup;

  //number
  public nbLitigeDoublons: number;

  //loading
  public starting: boolean = true;
  public loadingBtn: boolean = false;
  public loadingBtnSubmit: boolean = false;
  public showAnomalie: boolean = false;
  public loading: boolean = false;
  public disable: boolean = false;
  public formSubmit: boolean;
  public clickButton: boolean = false

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private toastSvc: ToastrService,
              private factureSvc: FactureService) {
  }

  ngOnInit() {
    this.loading = true;
    this.initForm();
  }

  initForm() {
    this.valideEnMasseForm = new FormGroup({
      valideEnMasse: new FormControl(null, Validators.required)
    });
    this.loading = false;
  }

  checkValideEnMasse() {
    this.formSubmit = true;

    if (this.valideEnMasseForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const numeroAvis = this.valideEnMasseForm.get('valideEnMasse').value;
    const re = /,|;|\n/;
    const sub = this.factureSvc.checkValideEnMasse(this.factureId, numeroAvis.split(re)).subscribe((valideEnMasse) => {
      this.submitted.emit(valideEnMasse);
      this.showAnomalie = true;
      this.disable = true;
      this.valideEnMasse = valideEnMasse;
      this.nbLitigeDoublons = Object.keys(valideEnMasse['doublon']).length
      sub.unsubscribe();
      this.loadingBtn = false;
      this.formSubmit = false;
      this.toastSvc.success('Valide en masse contrôlé', 'Info', {progressBar: true});
    }, () => {
      this.loadingBtn = false;
      this.formSubmit = false;
      Swal.fire('Désolé', 'Une erreur est survenue lors du control.', 'error');
    });
  }

  submit() {
    this.loadingBtnSubmit = true;
    const sub = this.factureSvc.postValideEnMasse(this.factureId, this.valideEnMasse['valid']).subscribe((valideEnMasse) => {
      this.submitted.emit(valideEnMasse);
      this.onSelectValideEnMasse.emit(valideEnMasse)
      this.activeModal.close()
      sub.unsubscribe();
      this.loadingBtnSubmit = false;
      this.toastSvc.success('Valide en masse effectué', 'Info', {progressBar: true});
    }, () => {
      this.loadingBtnSubmit = false;
      this.formSubmit = false;
      Swal.fire('Désolé', 'Une erreur est survenue lors du valide en masse.', 'error');
    });
  }

  get f() {
    return this.valideEnMasseForm.controls;
  }

  getTitleLitige(itemValue) {
    return itemValue.id + ' - [' + itemValue.type + '] - BL ' + itemValue.numeroAvis + ' - ' + itemValue.client + ' - ' + itemValue.statut + ' - ' + itemValue.flux + ' | ' + itemValue.montant + ' €'
  }

  retour() {
    this.showAnomalie = !this.showAnomalie;
  }

  onCheckboxChange(e, value) {
    if (e.checked) {
      this.valideEnMasse['valid'].push(value);
    } else {
      this.valideEnMasse['valid'].splice(this.valideEnMasse['valid'].findIndex(val => val == value), 1);
    }
  }

  onCopyData(object, flag = false, value = null) {
    this.clickButton = true;
    let tbData = [];
    if(flag == true) {
      Object.keys(object).forEach((key) => {
        tbData.push(key);
      })
    } else {
      Object.keys(object).forEach((key) => {
        let item = object[key][value]
        tbData.push(item);
      })
    }
    let str = tbData.toString()
    let regex = new RegExp(',', 'g');
    str = str.replace(regex, '\n');
    this.copyMessage(str)
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
