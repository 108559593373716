import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Responsable} from '../../../../shared/models/api/responsable.model';
import {forkJoin, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ActeurService} from '../../../../shared/services/api/acteur.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {Litige} from '../../../../shared/models/api/litige.model';
import {ImputationService} from '../../../../shared/services/api/imputation.service';
import {Imputation} from '../../../../shared/models/api/imputation.model';

@Component({
  selector: 'app-modal-imputation',
  templateUrl: './modal-imputation.component.html',
  styleUrls: ['./modal-imputation.component.scss']
})
export class ModalImputationComponent implements OnInit, OnDestroy {

  @Input() litigeId: number;

  public imputations: Imputation[] = [];
  public acteursInterne: Responsable[] = [];
  public imputationsForm: FormGroup;
  public loadingResponsableForm: boolean = false;
  private subscriptions: Subscription[] = [];
  public loadingSubmitBtn: boolean = false;
  public loadingSelecteur: boolean = false;
  public litige: Litige;

  constructor(
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    private acteurSvc: ActeurService,
    public activeModal: NgbActiveModal,
    private litigesSvc: LitigeService,
    private imputationSvc: ImputationService,
  ) {
  }

  ngOnInit() {
    if (!this.litigeId) {
      throw new Error('Vous devez passer un id litige au composant ModalImputationComponent');
    }
    this.loadingResponsableForm = true;
    this.initActeurs();

    this.subscriptions.push(forkJoin([
      this.litigesSvc.getImputations(this.litigeId,{lastVersion: true}),
      this.litigesSvc.getLitige(this.litigeId)
    ]).subscribe(responses => {
      this.imputations = responses[0];
      this.litige = responses[1];
      this.initForm();
    }));
  }


  initForm() {
    const imputations = [];
    this.filterImputation(false).forEach((imp: Imputation) => {
      imputations.push(this.generateImputationLineForm(imp));
    });
    this.imputationsForm = new FormGroup({
      imputations: new FormArray(imputations)
    });
    this.loadingResponsableForm = false;
  }

  generateImputationLineForm(imputation: Imputation) {
    return this.formBuilder.group({
      id: this.formBuilder.control(imputation.id),
      numero_facture: this.formBuilder.control({
        value: imputation.facture.numero_interne,
        disabled: true
      }),
      acteur: this.formBuilder.control(null),
      type: this.formBuilder.control({
        value: imputation.facture.type_facture.libelle,
        disabled: true
      }),
      montant: this.formBuilder.control({
        value: imputation.montant,
        disabled: true
      }),
      date_imputation: this.formBuilder.control({
        value: imputation.date_imputation,
        disabled: true
      }),
    });
  }

  filterImputation(withActeur = true) {
    if (withActeur) {
      return this.imputations.filter(x => x.acteur !== null);
    } else {
      return this.imputations.filter(x => x.acteur === null);
    }
  }

  submitFormImputation() {
    this.loadingSubmitBtn = true;
    const formSubmitted = this.imputationsForm.value;
    this.subscriptions.push(this.imputationSvc.updateImputations(formSubmitted).subscribe(
      imputation => {
        this.toastSvc.success('imputations terminées', 'Info', {progressBar: true});
        this.loadingSubmitBtn = false;
        this.activeModal.close(true);
      },
      error => {
        let msg = 'Une erreur est survenue.';
        if (error.error && error.error.message) {
          msg = error.error.message;
        }
        this.toastSvc.warning(msg, 'warning', {progressBar: true});
        this.loadingSubmitBtn = false;
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  initActeurs() {
    // récupération des acteurs
    if (this.acteursInterne.length === 0) {
      this.loadingSelecteur = true;

      // récupération des acteurs interne et tiers
      this.subscriptions.push(forkJoin([
        this.acteurSvc.getActeursInterne(),
      ]).subscribe(responses => {
          this.acteursInterne = responses[0];
          // on ajoute les acteurs du litige en haut des listes
          this.loadingSelecteur = false;
        },
        () => {
          this.activeModal.close(false);
        }));
    }
  }

  searchActeur(term: string, item: Responsable) {
    term = term.toLocaleLowerCase();
    return item.libelle.toLocaleLowerCase().indexOf(term) > -1 || item.code.toLocaleLowerCase().indexOf(term) > -1;
  }


}
