import {Component, Input, OnInit} from '@angular/core';
import {Litige} from '../../../../shared/models/api/litige.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AnnuaireItem} from '../../../../shared/models/api/annuaire.model';
import {AnnuaireService} from '../../../../shared/services/api/annuaire.service';
import {isNumber} from '../../../../shared/utils/util';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';

@Component({
  selector: 'app-modal-edit-litige',
  templateUrl: './modal-edit-litige.component.html',
  styleUrls: ['./modal-edit-litige.component.scss']
})
export class ModalEditLitigeComponent implements OnInit {

  @Input() litige: Litige;

  public annuaireItem: AnnuaireItem;
  public view: View;

  constructor(
    public activModal: NgbActiveModal,
    private modalSvc: NgbModal,
    private annuaireSvc: AnnuaireService,
    private authSvc: AuthenticationService,
  )
  { }

  ngOnInit() {
    if (!this.litige ||  !(this.litige instanceof Litige)){
      throw new Error('Vous devez passer un  litige au composant ModalEditLitige');
    }

    this.authSvc.currentView.subscribe(x => {
      this.view = x;
    });
    this.loadAnnuaireItem(this.litige.client?.id);
  }

  loadAnnuaireItem(clientId) {
    if (isNumber(clientId)) {
      this.annuaireSvc.getAnnuaireItemByClient(clientId).subscribe(
        (annuaireItem) => {
          this.annuaireItem = annuaireItem;
        });
    }
  }

  openAnnuaire(modalName) {
    this.modalSvc.open(modalName, {size: 'xl'});
  }

  get viewContext(): number {
    return Number(this.view.context);
  }
}
