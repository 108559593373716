<div class="row">
    <div *ngIf="loading" class="spinner"></div>

    <div *ngIf="maxContestationsReached && !loading" class="col-12">
      <div class="row">
        <div class="col-12" style="text-align: center; font-weight: bold; font-size: 1.2em;">
          <!-- remplissage vertical -->
          <div style="height: 50px"></div>
          <p>Vous avez dépassé le nombre maximal de contestation.</p>
          <p>Nous vous invitons à vous rapprocher de votre Direction Régionale.</p>
          <!-- remplissage vertical -->
          <div style="height: 150px"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button type="button" class="btn btn-agediss" (click)="canceled.emit()">
            Fermer
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!loading && !maxContestationsReached"  class="col-12">
        <form [formGroup]="contestationForm">
            <div *ngIf="contestationFor == null" class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Contestataire </label>
                        <ng-select class="ng-select filter"
                                   [ngClass]="{'is-invalid':f.contestataire.invalid && (f.contestataire.dirty || formSubmit) }"
                                   [items]="contestatairesIds"
                                   [selectOnTab]="true"
                                   [clearable]="false"
                                   (change)="onSelectContestataire($event)"
                                   [placeholder]="'CHOISIR'"
                                   bindValue="code"
                                   bindLabel="libelle"
                                   formControlName="contestataireId">
                        </ng-select>
                        <div *ngIf="f.contestataire.invalid && (f.contestataire.dirty || formSubmit)"
                             class="invalid-tooltip">
                            Vous devez choisir un contestataire
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Objet </label>
                        <textarea class="form-control"
                                  [ngClass]="{'is-invalid':f.objet.invalid && (f.objet.dirty || formSubmit) }"
                                  cols="30" rows="10" formControlName="objet">
            </textarea>
                        <div *ngIf="f.objet.invalid && (f.objet.dirty || formSubmit)"
                             class=" text-center invalid-tooltip">
                            Vous devez renseigner l'objet de la contestation.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-right">
                    <button type="button" class="btn bg-black-lighter text-white m-r-5" (click)="canceled.emit()">
                        Annuler
                    </button>
                    <button type="submit" class="btn btn-agediss" [ladda]="loadingBtn" data-style="zoom-in"
                            (click)="submitFormAddContestation()">Ouvrir
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>



