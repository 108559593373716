<div class="alert alert-light fade show m-b-10">


      <div class="row">
        <div class="col-2 ">Date litige</div>
        <div class="col-10">
          <div class="row">
            <div class="col-5 ">Plateforme imputable</div>
            <div class="col-5">Montant</div>
            <div class="col-2">Litiges</div>
          </div>
        </div>
      </div>

</div>
<div *ngIf="loading" style="height: 50em">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="alert alert-light fade show m-b-5"  [ngStyle]="{ 'background-color': (getType(item.key) === 'AVOIR') ? '#99e4e6':'white'}" style="box-shadow: 0 2px 5px black;"
       *ngFor="let item of data | keyvalue; let i = index;">



        <div class="row" *ngFor="let row of item.value | keyvalue; last as isLast1;">
          <div class="col-2 p-t-10" [ngClass]="{'bottom-line ': getLenght(item.value) > 1 && !isLast1}"
               style="border-right: solid 1px black">{{row.key }}</div>
          <div class="col-10">
            <div class="row p-5" *ngFor="let line of row.value; last as isLast2;"
                 [ngClass]="{'bottom-line ': (row.value.length > 1 &&  !isLast2) ||(getLenght(item.value) > 1 && !isLast1)}">
              <div class="col-5 p-t-5">{{line.acteur}}</div>
              <div class="col-5 p-t-5">{{line.total_imputer | currency : 'EUR': '€'}}</div>
<!--              <div class="col-2">-->
<!--                <a [routerLink]="['/','litiges',line.litige_id]" class="label label-info text-white">-->
<!--                  {{line.litige_id}}-->
<!--                </a>-->
<!--              </div>-->

<!--              <div *ngFor="let list of line.value" class="col-2">-->
<!--                <ng-template #popupContent>Hello, <b>{{list.litige_id}}</b>!</ng-template>-->
<!--                <i class="fa fa-file-alt" [ngbPopover]="popupContent" popoverTitle="Fancy content"></i>-->
<!--              </div>-->
              <ng-template #popupContent>
                <div *ngFor="let list of line.litiges">
                                  <button  (click)="openModalLitige(list.litige_id)" class="label label-info text-white">
                                    {{list.litige_id}}
                                  </button>
                </div>
              </ng-template>
<!--              <div *ngFor="let list of line.litiges">-->
<!--                {{list.litige_id}}-->
<!--              </div>-->
              <div class="col-2"
                   popoverTitle="litiges : "
                   [ngbPopover]="popupContent"
                   container="body">

                <i class="fa fa-file-alt"></i>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>


<!--modal validation des justificatifs-->
<ng-template #modal_litige let-modal>

  <div class="modal-header text-center">
    <h4 class="modal-title  w-100">Aperçu du litige : {{litigeSelectId}}</h4>
  </div>
  <div class="modal-body text-center">
    <litige [litigeId]="litigeSelectId"></litige>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn  bg-black-lighter text-white" (click)="modalPreviewLitige.close()">Fermer</button>
  </div>

</ng-template>
