import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUtil} from '../../utils/http.util';
import {FilterMultiCritereModel} from '../../models/filters/filterMultiCritere.model';

@Injectable({
  providedIn: 'root'
})
export class StatService {


  urlRessource = environment.url_api_litige + '/stats';


  constructor(
    private http: HttpClient) {
  }

  /**
   *
   * @param filter
   */
  getNbLitige(filter: FilterMultiCritereModel = null): Observable<number> {
    return this.http
      .get<number>( `${this.urlRessource}/nb-litige`, {params: HttpUtil.convertObjectToHttpParams(filter)})
  }

  /**
   *
   * @param filter
   */
  getNbContestation(filter: FilterMultiCritereModel = null): Observable<number> {
    return this.http
      .get<number>( `${this.urlRessource}/nb-contestation`, {params: HttpUtil.convertObjectToHttpParams(filter)})
  }


  getNbJustificatifATraite(): Observable<number> {
    const params = HttpUtil.convertObjectToHttpParams({statutCode: 'JUS_0', Affecter:true, refuser:false});
    return this.http
      .get<number>( `${this.urlRessource}/nb-justificatif`,{params})
  }

  getStatNbLitigePf(filter:{} = {}): Observable<any[]> {
    const params = HttpUtil.convertObjectToHttpParams(filter);
    return this.http
      .get<any[]>( `${this.urlRessource}/encours_pf`,{params})
  }



}
