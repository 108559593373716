import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from "../../../../animations";
import {PieceJointe} from "../../../../shared/models/api/piece-jointe.model";
import Swal from "sweetalert2";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ArticleService} from "../../../../shared/services/api/article.service";
import {ToastrService} from "ngx-toastr";
import {FactureService} from "../../../../shared/services/api/facture.service";
import {PieceJointeService} from "../../../../shared/services/api/piece-jointe.service";

@Component({
  selector: 'app-table-facture-attente',
  templateUrl: './table-facture-attente.component.html',
  styleUrls: ['./table-facture-attente.component.scss'],
  animations: [tableAnimation],
})
export class TableFactureAttenteComponent implements OnInit, OnChanges {

  public dataSorted: MatTableDataSource<PieceJointe>;
  @Input() data: PieceJointe[];
  @Input() displayedColumns: string[] = [
    'idPieceJointe',
    'client',
    'filename',
    'typePieceJointe',
    'date',
    'valider',
  ];
  @Input() noRecordText = 'Aucun facture en attente';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = 0;
  @Input() hiddenColumns: string[] = [];

  @Input() showEditFactureBtn: boolean = false;
  @Input() showDeleteFactureBtn: boolean = false;

  @Output() selectShowFacture: EventEmitter<any> = new  EventEmitter();
  @Output() selectEditFacture: EventEmitter<any> = new  EventEmitter();
  @Output() selectDeleteFacture: EventEmitter<any> = new EventEmitter();

  public innerWidth: number;

  private subscription: Subscription;

  constructor(private pieceJointeSvc: PieceJointeService,
              private toastr: ToastrService) {

  }
  ngOnInit() {

    this.dataSorted = new MatTableDataSource(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }


  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  editFacture(pj) {
    this.selectShowFacture.emit(pj);
  };

  deleteFacture(fileId,index) {

    console.log(fileId);
    console.log(index);
    Swal.fire({
      title: 'Attention !',
      text: 'Etes-vous sur de vouloir supprimer la facture ? ',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui !'
    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSorted.data[index].pending = true;

        //service pour supprimer le fichier
        this.subscription = this.pieceJointeSvc.delete(fileId).subscribe(
          () => {
            this.toastr.info('la facture à été supprimé');
            this.dataSorted.data[index].pending = false;
            this.dataSorted.data.splice(index, 1);
            this.dataSorted = new MatTableDataSource(this.dataSorted.data);
          },
          () => {
            this.dataSorted.data[index].pending = false;
            this.toastr.error('la facture n\'à pas pu être supprimé');
          },
        );
      }
    });
  };


  //todo:Update le OnResizeTable

  @HostListener('window:resize', ['$event'])
  onResizeTable(event) {
    this.innerWidth = event;
    if (this.innerWidth <= 500) {
      this.displayedColumns = [
        'client',
        'filename',
        'date',
        'valider',
      ];
    } else if (this.innerWidth <= 768) {
      this.displayedColumns = [
        'client',
        'filename',
        'typePieceJointe',
        'date',
        'valider',
      ];
    } else if (this.innerWidth <= 1024) {
      this.displayedColumns = [
        'client',
        'filename',
        'typePieceJointe',
        'date',
        'valider',
      ];
    } else if (this.innerWidth <= 1300) {
      this.displayedColumns = [
        'client',
        'filename',
        'typePieceJointe',
        'date',
        'valider',
      ];
    }
  }
}
