<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="date" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">


  <ng-container matColumnDef="idFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">Id Facture</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        ngbTooltip="{{element?.id}}" class="px-10">
      <a [routerLink]="['/','factures',element.id]" class="label label-info text-white">
        {{element?.id}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">DO</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        ngbTooltip="{{element?.piece_jointe.compte_client.libelle}}" class="px-10">
      {{element?.piece_jointe.compte_client.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="filename">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fichier</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        style="vertical-align: top"
        ngbTooltip="{{element?.piece_jointe.filename}}" class="px-10">
      {{element?.piece_jointe.filename}}
    </td>
  </ng-container>
  <ng-container matColumnDef="typeFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.type_facture?.libelle}}">
      {{element?.type_facture?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numeroInterne">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Numéro Interne</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.numero_interne}}">
      {{element?.numero_interne}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numeroClient">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Numéro Externe (client)</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.numero_client}}">
      {{element?.numero_client}}
    </td>
  </ng-container>
  <ng-container matColumnDef="statutBap">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>statut</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.statut_bap}}">
      <div class="label" [ngClass]="element?.statut_bap == 'BAP' ? 'label-green' : 'label-danger'">{{element?.statut_bap}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll">
  </tr>
  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>
