import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {PieceJointe} from "../../../../shared/models/api/piece-jointe.model";

@Component({
  selector: 'app-table-justificatif',
  templateUrl: './table-justificatif.component.html',
  styleUrls: ['./table-justificatif.component.scss']
})
export class TableJustificatifComponent implements OnInit, OnChanges {

  @Input() data: PieceJointe[];
  @Input() displayedColumns: string[] = [
    'litige.id',
    'id',
    'litige.numero_avis',
    'litige.numero_commande',
    'litige.created_at',
    'litige.destinataire_nom',
    'litige.client.libelle',
    'litige.plateforme.libelle',
    'detail_piece_jointe.statut.libelle',
    'litige.traite_par.fullname',
    'detail_piece_jointe.montant',
    'type_piece_jointe.libelle',
    'voir',
  ];

  @Input() noRecordText = 'Aucun Justificatif';
  @Input() loadingText = 'Chargements en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() disableColumns: string[] = [];

  @Input() showValideJustifBtn: boolean = false;


  @Output() selectJustif: EventEmitter<{id : number, index: number}> = new EventEmitter<{id : number, index: number}>();
  @Output() openLitige: EventEmitter<{id : number, index: number}> = new EventEmitter<{id : number, index: number}>();
  @ViewChild(MatSort,{static: true}) sort: MatSort;

  public dataSorted: MatTableDataSource<PieceJointe>;

  constructor() { }

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
    this.dataSorted.sort = this.sort;
    this.dataSorted.sortingDataAccessor = (data, sortHeaderId: string) => {
      return this.getPropertyByPath(data, sortHeaderId);
    };
    if(this.disableColumns.length > 0) {
      this.disableColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if(index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
    if(!this.showValideJustifBtn){
      let index = this.displayedColumns.indexOf('voir');
      if(index){
        this.displayedColumns.splice(index,1);
      }
    }

  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {

    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      this.dataSorted.sortingDataAccessor = (data, sortHeaderId: string) => {
        return this.getPropertyByPath(data, sortHeaderId);
      };
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
        this.dataSorted.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    this.dataSorted.sort = this.sort;
  }

  selectJustificatif(id: number, index: number) {
    this.selectJustif.emit({id: id, index: index});
  }

  showLitige(id: number, index: number) {
    this.openLitige.emit({id: id, index: index});
  }

  getPropertyByPath(obj: Object, pathString: string){
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }




}
