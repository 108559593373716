<div class="modal-header">
  <h4 class="modal-title w-100 text-center">Imputation du litige {{litigeId}}</h4>
</div>
<div class="modal-body">
  <div *ngIf="loadingResponsableForm" style="height: 310px">
    <div class="spinner"></div>
  </div>
  <div *ngIf="!loadingResponsableForm">
    <div class="row">
      <div class="offset-1 col-3 h5 ">Info litige =></div>
      <div class="col-3 h5">Montant total facturé : {{litige.montant_facture}}</div>
      <div class="col-3 h5">Montant total validé : {{litige.montant_valide}}</div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 text-center"><h4> Responsables interne / imputation </h4></div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table w-100 table-bordered">
          <thead>
          <th>Facture N°</th>
          <th>Type</th>
          <th>Responsable</th>
          <th>Montant</th>
          <th>Mois imputation</th>
          </thead>
          <tbody>
          <tr *ngFor="let imputation of filterImputation() ">
            <td>{{imputation.facture.numero_interne}}</td>
            <td>{{imputation.facture.type_facture.libelle}}</td>
            <td>{{imputation.acteur.libelle}}</td>
            <td>{{imputation.montant}}</td>
            <td>{{imputation.date_imputation | date :'M-yyyy'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 text-center"><h4> Imputation écart / non définit</h4></div>
    </div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="imputationsForm">
          <table class="table w-100 table-bordered"  >
            <thead>
            <th>Facture N°</th>
            <th>Type</th>
            <th>Responsable</th>
            <th>Montant</th>
            <th>Mois imputation</th>
            </thead>
            <tbody *ngFor="let imp of imputationsForm.get('imputations').controls; let i = index; " formArrayName="imputations">
            <tr [formGroupName]="i">
              <td><input class="form-control" type="text" readonly formControlName="numero_facture"></td>
              <td><input class="form-control" type="text" readonly formControlName="type"></td>
              <td style="width: 30%" >
                <ng-select class="ng-select filter"
                           [items]="acteursInterne"
                           [selectOnTab]="true"
                           bindValue="id"
                           bindLabel="libelle"
                           [clearable]="false"
                           [loading]="loadingSelecteur"
                           [searchFn]="searchActeur"
                           placeholder="choisir"
                           formControlName="acteur">
                  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                    <div><span>Nom: </span><span>{{item.libelle}}</span></div>
                    <small><b>Id:</b> {{item.code}} | <b>type :</b> {{item.discr}}</small>
                  </ng-template>
                </ng-select>
              </td>
              <td><input class="form-control" type="text" readonly formControlName="montant"></td>
              <td><input class="form-control" type="text" readonly [value]="imputationsForm.get('imputations').at(i).get('date_imputation').value | date : 'M-yyyy'"></td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-center">
  <button type="button" class="btn bg-black-lighter text-white" (click)="activeModal.close()">Annuler
  </button>
  <input type="submit" class="btn btn-agediss" [ladda]="loadingSubmitBtn" data-style="zoom-in"
         (click)="submitFormImputation()" value="Enregistrer"/>
</div>
