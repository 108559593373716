<div class="row" *ngIf="view.hasDroit('ANNUAIRE_EDIT')">
  <div class="col-3">
    <div class="col-12">
      <div class="text-black pointer-cursor action" (click)="newItem()"><i class="fa fa-plus"></i></div>
      <panel title="Annuaire" noButton="true" bodyClass="no-padding" class="text-center">

          <input class="search shadow" placeholder="Rechercher ..." [(ngModel)]="searchTerm"/>

          <div class="overflow-scroll" style="height: 82vh">
            <table mat-table [dataSource]="annuaire" multiTemplateDataRows
                   class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8
                 text-center pointer-cursor">
              <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef class="px-10">Client</th>
                <td mat-cell *matCellDef="let element" (click)="selected(element.id)"
                    class="pointer"
                    [hidden]="searchTerm.length && (
                  (element?.client?.libelle && !element.client.libelle.toLowerCase().startsWith(searchTerm.toLowerCase()))
                  || (element?.compteClient?.libelle && !element.compteClient.libelle.toLowerCase().startsWith(searchTerm.toLowerCase()))
                  )"
                >
                  {{element?.client?.libelle || element?.compteClient?.libelle}}
                </td>
              </ng-container>

              <ng-container matColumnDef="noData">
                <td mat-footer-cell *matFooterCellDef>
                  Aucune fiche
                </td>
              </ng-container>
              <ng-container matColumnDef="loading">
                <td mat-footer-cell *matFooterCellDef>
                  Chargement en cours <i class="fas fa-spin fa-circle-notch"></i>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: ['client']"></tr>
              <tr mat-footer-row *matFooterRowDef="['noData']"
                  [hidden]="annuaire && annuaire.length > 0 || loadingAnnuaire"></tr>
              <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loadingAnnuaire"></tr>
            </table>
          </div>
      </panel>
    </div>
  </div>
  <div class="col-9">
    <app-annuaire-editor [itemId]="currentItemId"
                         [clientsSelect]="clients"
                         [compteClientsSelect]="compteClients"
                         [plateformesSelect]="plateformes"
                         [fraisDeTransportSelect]="statutsFraisTransport">
    </app-annuaire-editor>
  </div>
</div>
