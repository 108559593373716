<!-- end page-header -->
<div class="row">
  <div class="col-4">
    <div class="row">
      <div class="col-12">  <panel noButton="true" class="text-center">
        <div header class="text-center">
          <strong> Litiges à traiter </strong>
        </div>
        <div class="panel-body text-center" style="overflow-x: auto; height: 190px;">
          <h1>
            <span [ngClass]="(!nbLitigeATraiter && nbLitigeATraiter != 0) ? 'fa fa-circle-notch fa-spin':null">{{nbLitigeATraiter}}</span> litige<span *ngIf="nbLitigeATraiter">s</span> </h1>
          <h1>à traiter </h1>
        </div>
      </panel></div>
      <div class="col-12">
        <panel noButton="true" class="text-center">
          <div header class="text-center">
            <strong>Litiges en cours</strong>
          </div>
          <div class="panel-body text-center" style="overflow-x: auto;height: 190px;">
            <h1><span [ngClass]="(!nbLitigeEncours && nbLitigeEncours != 0 )?   'fa fa-circle-notch fa-spin':null">{{nbLitigeEncours}}</span> litige<span *ngIf="nbLitigeEncours">s</span> </h1>
            <h1>en cours </h1>
          </div>
        </panel>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-12">  <panel noButton="true" class="text-center">
        <div header class="text-center">
          <strong> Contestations DO </strong>
        </div>
        <div class="panel-body text-center" style="overflow-x: auto; height: 190px;">
          <h1><span [ngClass]="(!nbContestationsDo && nbContestationsDo != 0 )? 'fa fa-circle-notch fa-spin':null">{{nbContestationsDo}}</span> contestation<span *ngIf="nbContestationsDo">s</span> </h1>
          <h1>DO à traiter</h1>
        </div>
      </panel></div>
      <div class="col-12">
        <panel noButton="true" class="text-center">
          <div header class="text-center">
            <strong>Contestations plateforme</strong>
          </div>
          <div class="panel-body text-center" style="overflow-x: auto;height: 190px;">
            <h1><span [ngClass]="!nbContestationsPlateforme ? 'fa fa-circle-notch fa-spin':null">{{nbContestationsPlateforme}}</span> contestation<span *ngIf="nbContestationsPlateforme">s</span> </h1>
            <h1>plateformes à traiter </h1>
          </div>
        </panel>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-12">  <panel noButton="true" class="text-center">
        <div header class="text-center">
          <strong> Justificatifs à traiter </strong>
        </div>
        <div class="panel-body text-center" style="overflow-x: auto; height: 190px;">
          <h1><span [ngClass]="(!nbJustificatifATraiter && nbJustificatifATraiter != 0) ? 'fa fa-circle-notch fa-spin':null">{{nbJustificatifATraiter}}</span> justificatif<span *ngIf="nbJustificatifATraiter">s</span> </h1>
          <h1>à traiter </h1>
        </div>
      </panel></div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <panel noButton="true" class="text-center" >
      <div class="panel-body no-padding text-center">
        <ngb-tabset [justify]="'justified'">
          <ngb-tab title="STATS RESPONSABLES">
            <ng-template ngbTabContent>
            <app-table-stat-pf [data]="statsPf" [displayedColumns]="[ 'responsable',  'a_valider', 'contestation_en_cours']" [loading]="statsPfLoading"></app-table-stat-pf>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="10 DERNIER LITIGES">
            <ng-template ngbTabContent>
              <app-table-litige [data]="litiges" [showInfosBtn]="true"  [hiddenColumns]="['retroceder','responsables','traitePar','referenceComplementaire']" [loading]="loading"></app-table-litige>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </panel>
  </div>
</div>
