import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PieceJointeService} from '../../shared/services/api/piece-jointe.service';
import {PieceJointe} from '../../shared/models/api/piece-jointe.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Litige} from '../../shared/models/api/litige.model';
import {LitigeService} from '../../shared/services/api/litige.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {View} from '../../shared/models/view.model';
import {LitigePage} from "../litiges/show/litige";
import {FilterMultiCritereModel} from "../../shared/models/filters/filterMultiCritere.model";
import {FieldParametersModel} from "../../shared/models/filters/field-parameters.model";


@Component({
  selector: 'app-justificatifs',
  templateUrl: './justificatifs.component.html',
})
export class JustificatifsPage implements OnInit, OnDestroy {


  @Input() modal: NgbModal;

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @ViewChild('modal_litige', {static: true}) modalLtige: LitigePage;


  public litige: Litige;
  public litigeSelectId: number;
  public nbJustificatif: number;
  public loadingTableJustificatifs: boolean = false;
  public showRefused: boolean = false;
  public loadingNbJustificatif: boolean;
  public pieceJointes: PieceJointe[];
  public selectedJustifId: number = null;
  public selectedJustifIndex: number = null;
  private activModal: NgbModalRef;
  private subscriptions: Subscription[] = [];
  public disableColumns = ['detail_piece_jointe.montant', 'type_piece_jointe.libelle'];
  public view: View;
  public modalPreviewLitige: NgbModalRef;
  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public displayInputFilter = [
    new FieldParametersModel().deserialize({
      input: 'motif',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'plateforme',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'responsable',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'statut',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'origine',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'client',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'date',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'typeLitige',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'searchFacture',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'search',
      show: false
    })
  ];

  public nbFilteredJustificatif: number;


  constructor(private pieceJointeSvc: PieceJointeService,
              private litigeSvc: LitigeService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal) {
  }


  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }


  openModalAffectationPieceJointe(modalName, size = null, value: { id: number, index: number }) {
    this.selectedJustifId = value.id;
    this.selectedJustifIndex = value.index;
    this.activModal = this.modalSvc.open(modalName, {
      size: size, backdrop: 'static', keyboard: false
    });
    this.activModal.result.then(
      (result) => {
        if (result === true) {
          this.pieceJointes.splice(this.selectedJustifIndex, 1);
          this.pieceJointes = this.pieceJointes.slice()
        }
        if (result === false) {
          this.loadData();
        }
      });
  }


  loadData() {
    this.loadingTableJustificatifs = true;
    this.getNbJustificatif();

    if (this.showRefused == false) {
      this.subscriptions.push(this.pieceJointeSvc.getJustificatifsAffecter(this.filter).subscribe(
        pieceJointe => {
          this.pieceJointes = pieceJointe;
          this.nbFilteredJustificatif = this.pieceJointes.length;
          this.loadingTableJustificatifs = false;
        }, () => this.loadingTableJustificatifs = false));
    } else {
      this.subscriptions.push(this.pieceJointeSvc.getJustificatifsRefuser(this.filter).subscribe(
        pieceJointe => {
          this.pieceJointes = pieceJointe;
          this.nbFilteredJustificatif = this.pieceJointes.length;
          this.loadingTableJustificatifs = false;
        }, () => this.loadingTableJustificatifs = false));
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }


  getNbJustificatif(){
    this.subscriptions.push(this.pieceJointeSvc.getNbJustificatif().subscribe(
      (nbJusti) => {
        this.nbJustificatif = nbJusti;
      }));
  }


  switcher() {
    if (this.showRefused) {
      this.showRefused = false;
    } else {
      this.showRefused = true;
    }

    this.loadData()
  }


  openModalLitige(event: { id, index }) {
    this.litigeSelectId = event.id;
    this.modalLtige.litigeId = event.id;
    this.modalPreviewLitige = this.modalSvc.open(this.modalLtige, {
      size: "xl",
    });
  }


  onSubmit(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.loadData();

  }
}
