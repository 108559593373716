import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'ouiNon'})
export class OuiNonPipe implements PipeTransform {
  transform(value: boolean, exponent?: number): string {
    if (value) {
      return 'OUI';
    }
    return 'NON';
  }
}
