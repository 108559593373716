import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from "rxjs";
import {TypeLitige} from "../../../../../shared/models/api/type-litige.model";
import {LitigeService} from "../../../../../shared/services/api/litige.service";
import {ClientService} from "../../../../../shared/services/api/client.service";

@Component({
  selector: 'app-create-litige-b2b-stock',
  templateUrl: './create-litige-b2b-stock.html',
  styleUrls: ['./create-litige-b2b-stock.scss']
})
export class CreateLitigeB2bStock implements OnInit, OnDestroy {


  @Input() typeLite: TypeLitige;
  public form: FormGroup;
  public listClients: Array<any> = [
    {
      libelle: 'IKEA', id: 2,
    },
    {
      libelle: 'Show Room Prive', id: 1,
    }
  ];
  private subscriptions: Subscription[] = [];
  public loadingForm: Boolean = false;
  public loadingSubmitBtn: Boolean = false;
  @Output() EventSubmited: EventEmitter<number> = new EventEmitter();
  public submited: Boolean = false;
  public loadClient: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    public activModal: NgbActiveModal,
    private litigeSvc: LitigeService,
    private clientSvc: ClientService
  ) {
  }

  ngOnInit() {
    this.loadClient = true;
    this.subscriptions.push(this.clientSvc.getClients().subscribe(value => {
        this.listClients = value;
      this.loadClient = false;
      }
    ));
    this.initForm();
  }

  initForm(client = null) {
    // this.loadingForm = true;
    let cli = null;
    if (client != null) {
      cli = client.libelle
    }

    this.form = this.formBuilder.group({
      client: this.formBuilder.control(cli, Validators.required),
      typeLitige: this.formBuilder.control(this.typeLite),
    });

    this.loadingForm = true;
  }


  submit() {
    this.loadingSubmitBtn = true;
    const formSubmitted = this.form.value;
    this.subscriptions.push(this.litigeSvc.postLitige(formSubmitted).subscribe(value => {
        this.toastSvc.success("Le litige à été crée", 'Info', {progressBar: true});
        this.loadingSubmitBtn = false;
        this.EventSubmited.emit(value);
        this.submited = true;
      },
      error => {
        this.toastSvc.warning("Erreur lors de la création du litige", 'Info', {progressBar: true});
        this.loadingSubmitBtn = false;
        this.submited = true;
      }
    ))


  }

  ngOnDestroy()
    :
    void {
  }


}
