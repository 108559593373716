<!-- bopenModalAddConversationegin page-header -->
<h1 class="page-header">Modification des affectations</h1>
<!-- end page-header -->

<div class="alert alert-light fade show">
  <app-filter-litige (submitFilter)="onSubmit($event)" [displayInput]="displayInputFilter" (onLoad)="onSubmit($event)" [pageName]="'affectation'" ></app-filter-litige>
</div>


<!-- begin table "pieceJointes" -->
<div class="row">
    <div class="col-12">
        <panel title="Modification des affectations" noButton="true" bodyClass="no-padding" class="text-center">
            <div style="height: 75vh" class="overflow-scroll">
                <app-table-facture-affecter [loading]="loadingTable" [data]="factures"
                                            [displayedColumns]="displayedColumns"
                                            (Affecter)="openModalAffectationFacture($event)"
                                            (selectEditFacture)="openModalEditFacture($event)"
                                            [caseAffectations]="true"
                                            [temporaireBtn]="false"
                ></app-table-facture-affecter>
            </div>
        </panel>
    </div>
    <!-- end processing table "pieceJointes" -->
</div>






