import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Facture} from '../../models/api/facture.model';
import {PieceJointe} from '../../models/api/piece-jointe.model';
import {HttpUtil} from '../../utils/http.util';
import {FilterMultiCritereModel} from '../../models/filters/filterMultiCritere.model';
import {FactureLitige} from '../../models/api/factureLitige.model';
import {ValideEnMasse} from '../../models/api/valideEnMasse.model';
import {Imputation} from "../../models/api/imputation.model";

@Injectable({
  providedIn: 'root'
})
export class FactureService {

  urlRessource = environment.url_api_litige + '/factures';
  urlRessourceValideEnMasse = environment.url_api_litige + '/valide-en-masse';

  constructor(private http: HttpClient) {

  }

  /**
   *
   * @param filter
   */
  getFacturesAffecter(filter: any = null): Observable<Facture[]> {
    return this.http
      .get<Facture[]>(`${this.urlRessource}-affecter`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((factures) => factures.map((facture) => {
        return new Facture().deserialize(facture);
      })));
  }

  getFacturesAffecterEditable(filter: any = {}): Observable<Facture[]> {
    return this.http
      .get<Facture[]>(`${this.urlRessource}-affecter-editable`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((observe) => observe.map((observe) => {
        return new Facture().deserialize(observe);
      })));
  }

  postFacture(facture: Facture): Observable<Facture> {
    return this.http.post<Facture>(`${this.urlRessource}-attente/ajouter`, facture)
      .pipe(map((observe) => {
        return new Facture().deserialize(observe);
      }));
  }

  updateFactureAffecter(facture: Facture, reaffecte = false): Observable<Facture> {
    let params = {};
    if(reaffecte){
      params = {
        'reaffecte':true
      }
    }
    return this.http.post<Facture>(`${this.urlRessource}-affecter/${facture.id}/update`, facture,{params})
      .pipe(map((observe) => {
        return new Facture().deserialize(observe);
      }));
  }

  updateFactureReaffecter(facture: Facture, oldLitigeIndex, newLitige ): Observable<Facture> {
    const object = Object.assign({oldLitigeIndex: oldLitigeIndex, newLitige: newLitige});
    const params=HttpUtil.convertObjectToHttpParams(object);
    return this.http.post<Facture>(`${this.urlRessource}-affecter/${facture.id}/reafecter`, facture,{params: params})
      .pipe(map((observe) => {
        return new Facture().deserialize(observe);
      }));
  }

  updateFactureTemporaire(facture: Facture, motif: string, endDate: Date, imputationDate: Date,
                          imputationResponsableId: number): Observable<void> {
    const body = Object.assign({
      motif,
      endDate,
      imputationDate,
      imputationResponsableId
    });
    return this.http.put<void>(`${this.urlRessource}-temporaire/${facture.id}`, body);
  }

  updateFactureDelete(facture: Facture, oldLitigeIndex, newLitige ): Observable<Facture> {
    const object = Object.assign({oldLitigeIndex: oldLitigeIndex, newLitige: newLitige});
    const params=HttpUtil.convertObjectToHttpParams(object);
    return this.http.post<Facture>(`${this.urlRessource}-affecter/${facture.id}/deleteAffecter`, facture,{params: params})
      .pipe(map((observe) => {
        return new Facture().deserialize(observe);
      }));
  }


  delete(factureId: number): Observable<PieceJointe> {
    return this.http.delete<PieceJointe>(`${this.urlRessource}/${factureId}/delete`).pipe(map((facture) => {
      return new Facture().deserialize(facture);
    }));
  }

  getFacture(id: number): Observable<Facture> {
    return this.http
      .get<Facture>(`${this.urlRessource}/${id}`)
      .pipe(map((observe) => {
        return new Facture().deserialize(observe);
      }));
  }

  getFacturDocuments(id: number): Observable<PieceJointe> {
    // return this.http
    //     //   .get<PieceJointe>(`${this.urlRessource}/${id}`)
    //     //   .pipe(map((observe) => {
    //     //     return new PieceJointe().deserialize(observe);
    //     //   }));
    return null;
  }

  getFactures(filter: any = {}): Observable<Facture[]> {
    return this.http
      .get<Facture[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((observe) => observe.map((observe) => {
        return new Facture().deserialize(observe);
      })));
  }

  getFactureByCompteClient(compteClientId: number, filter: any = {}): Observable<Facture[]> {
    return this.http
      .get<Facture[]>(`${this.urlRessource}-compte-client/${compteClientId}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((observe) => observe.map((observe) => {
        return new Facture().deserialize(observe);
      })));
  }

  getAvoirByCompteClient(compteClientId: number, filter: any = {}): Observable<Facture[]> {
    return this.http
      .get<Facture[]>(`${this.urlRessource}-avoir-compte-client/${compteClientId}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((observe) => observe.map((observe) => {
        return new Facture().deserialize(observe);
      })));
  }

  updateFacture(factureId: number, facture: Facture): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessource}/${factureId}`, facture)
      .pipe(map((response) => {
          return response.next;
        }
      ));
  }

  updateStatutFacture(factureId: number, statutCode: string): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessource}/${factureId}/update-statut`, {statutCode})
      .pipe(map((response) => {
          return response.next;
        }
      ));
  }

  getFacturesImputation(filter: FilterMultiCritereModel = null): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.urlRessource}-imputation`, {params: HttpUtil.convertObjectToHttpParams(filter)});
  }

  getFactureImputation(factureId: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.urlRessource}/${factureId}/imputations`);
  }

  getFactureAvoirs(factureId: number, type: string): Observable<Imputation[]> {
    return this.http
      .get<Imputation[]>(`${this.urlRessource}/${factureId}/avoirs?type=${type}`)
      .pipe(map((imputations) => imputations.map((imputations) => {
        return new Imputation().deserialize(imputations);
      })));
  }

  getFactureLitigeByFacture(id: number): Observable<FactureLitige> {
    return this.http
      .get<FactureLitige>(`${this.urlRessource}/${id}/litiges`)
      .pipe(map((observe) => {
        return new FactureLitige().deserialize(observe);
      }));
  }

  checkValideEnMasse(factureId: number, valideEnMasse: ValideEnMasse): Observable<any> {
    return this.http.post<any>(`${this.urlRessourceValideEnMasse}/${factureId}`, valideEnMasse)
      .pipe(map((valideEnMasse) => {
        return new ValideEnMasse().deserialize(valideEnMasse);
      }));
  }

  postValideEnMasse(factureId: number, valideEnMasse: []): Observable<any> {
    return this.http.post<any>(`${this.urlRessourceValideEnMasse}/${factureId}/send`, valideEnMasse)
      .pipe(map((valideEnMasse) => {
        return new ValideEnMasse().deserialize(valideEnMasse);
      }));
  }

  updateDateBap(factureId: number, dateBap: Date | string): Observable<void> {
    const object = Object.assign({dateBap});
    const params = HttpUtil.convertObjectToHttpParams(object);
    return this.http.put<void>(`${this.urlRessource}/${factureId}/date-bap`, null, {params});
  }

  updateNotes(factureId: number, notes: string): Observable<void> {
    const object = Object.assign({notes});
    const params = HttpUtil.convertObjectToHttpParams(object);
    return this.http.put<void>(`${this.urlRessource}/${factureId}/notes`, null, {params});
  }
}
