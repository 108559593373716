<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       matSortActive="noData" matSortDirection="desc"
       class="table table-hover table-striped table-bordered mat-elevation-z8">

  <ng-container matColumnDef="codeBarre">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Code barre</th>
    <td mat-cell *matCellDef="let element">{{element.cab}}</td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns; sticky: false"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>
