import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LitigeService} from '../../shared/services/api/litige.service';
import {Litige} from '../../shared/models/api/litige.model';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {FieldParametersModel} from '../../shared/models/filters/field-parameters.model';
import {ModalCreateLitige} from '../../components/litige/modal/modal-create-litige/modal-create-litige';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {View} from '../../shared/models/view.model';
import {ModalAddContestationComponent} from '../../components/contestation/modal/modal-add-contestation/modal-add-contestation.component';
import Swal from 'sweetalert2';
import {Contestation} from '../../shared/models/api/contestation.model';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';


@Component({
  selector: 'litiges',
  templateUrl: './litiges.html'

})
export class LitigesPage implements OnDestroy, OnInit {

  @Input() litigeId: number;

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterEncours: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterATraiter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterEligibleValide: FilterMultiCritereModel = new FilterMultiCritereModel();
  public activModal: NgbModalRef;
  public view: View;
  public loadingTableEncours = false;
  public loadingTableATraite = false;
  public loadingTableEligibleValide = false;
  public litigesATraiter: Litige[];
  public litigesEncours: Litige[];
  public litigesEligibleValide: Litige[];
  public litige: Litige;
  public contestations: Contestation[];
  public subscriptions: Subscription[];
  public displayInputFilter = [
    new FieldParametersModel().deserialize({
      input: 'motif',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'statut',
      show: false
    })
  ];

  constructor(private litigesSvc: LitigeService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal,
              private toastSvc: ToastrService,
              private router: Router
  ) {
  }

  ngOnInit() {

    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnDestroy(): void {
  }

  onSubmit(filter: FilterMultiCritereModel) {
    // récupère les infos du filtre
    this.filter = filter;
    const filterTabAtraiter = {...filter};
    const filterTabEncours = {...filter};
    const filterTabEligibleValide = {...filter};
    filterTabAtraiter.statut = 1;
    filterTabEncours.statut = 3;


    filterTabEligibleValide.statutCode = ['LIT_3', 'LIT_5'];

    if (this.view.context != '2') {
      // applique les changements
      this.loadDataATraiter(new FilterMultiCritereModel().deserialize(filterTabAtraiter));
      this.loadDataEnCours(new FilterMultiCritereModel().deserialize(filterTabEncours));
    } else {
      this.loadDataEligibleValide(new FilterMultiCritereModel().deserialize(filterTabEligibleValide));
    }
  }

  loadDataATraiter(filter: FilterMultiCritereModel) {
    this.loadingTableATraite = true;
    this.litigesSvc.getLitiges(filter).subscribe(litiges => {
      this.litigesATraiter = litiges;
      this.loadingTableATraite = false;
    });
  }

  loadDataEnCours(filter: FilterMultiCritereModel) {
    this.loadingTableEncours = true;
    this.litigesSvc.getLitiges(filter).subscribe(litiges => {
      this.litigesEncours = litiges;
      this.loadingTableEncours = false;
    });
  }

  loadDataEligibleValide(filter: FilterMultiCritereModel) {
    this.loadingTableEligibleValide = true;
    filter.responsable_alternative = true;
    filter.showOnwResponsabilityOnly = true;
    this.litigesSvc.getLitigesContestationATraiter(filter).subscribe(litiges => {
      this.litigesEligibleValide = litiges;
      this.loadingTableEligibleValide = false;
    });
  }

  onSortEncours(sort): void {
    this.loadingTableEncours = true;
    this.filterEncours = this.filter;

    this.filterEncours.orderChamp = sort.active;
    this.filterEncours.orderDirection = sort.direction;
    this.filterEncours.statut = 3;

    // applique les changements
    this.litigesSvc.getLitiges(this.filterEncours).subscribe((litiges) => {
      this.litigesEncours = litiges;
      this.loadingTableEncours = false;
    });
  }

  onSortATraiter(sort): void {
    this.loadingTableATraite = true;
    this.filterATraiter = this.filter;

    this.filterATraiter.orderChamp = sort.active;
    this.filterATraiter.orderDirection = sort.direction;
    this.filterATraiter.statut = 1;

    // applique les changements
    this.litigesSvc.getLitiges(this.filterATraiter).subscribe((litiges) => {
      this.litigesATraiter = litiges;
      this.loadingTableATraite = false;
    });
  }

  onSortEligibleValide(sort): void {
    this.loadingTableEligibleValide = true;
    this.filterEligibleValide = this.filter;

    this.filterEligibleValide.orderChamp = sort.active;
    this.filterEligibleValide.orderDirection = sort.direction;
    this.filterEligibleValide.statut = 1;

    // applique les changements
    this.litigesSvc.getLitiges(this.filterEligibleValide).subscribe((litiges) => {
      this.litigesEligibleValide = litiges;
      this.loadingTableEligibleValide = false;
    });
  }

  openModalCreateLitige() {
    this.activModal = this.modalSvc.open(ModalCreateLitige, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.result.then(
      value => {
        if (value) {
          this.router.navigate(['/litiges', value]);
        }
      },
      value => {
      }
    );
  }


  validateResponsablite(event) {

    Swal.fire({
      title: 'Validation',
      html: '<p>Est vous sûr de vouloir valider le litige n° ' + event.id + ' ?</p><p> N° BL : ' + event.numero_avis + ' </p> ',
      icon: 'question',
      cancelButtonText: 'NON',
      confirmButtonColor: '#32a932',
      confirmButtonText: 'OUI',
      cancelButtonColor: '#cd0131',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.litigesSvc.validatePlatforme(event).subscribe(next => {
          this.toastSvc.info('Litige n° ' + event.id + ' validé !', 'Info', {progressBar: true});
          const index = this.litigesEligibleValide.findIndex(litige => litige.id == event.id);
          this.litigesEligibleValide.splice(index, 1);
          this.litigesEligibleValide = this.litigesEligibleValide.slice();
        }, error => {
          this.toastSvc.error('Une erreur est survenue', 'Desolé');
        });
      }
    });
  }


  openModalContestation(event) {
    this.activModal = this.modalSvc.open(ModalAddContestationComponent, {
      size: 'lg', keyboard: false, backdrop: 'static'
    });
    this.activModal.componentInstance.litigeId = event.id;

    this.activModal.componentInstance.onSubmit.subscribe((contestation) => {
      Swal.fire({
        title: 'Question !',
        text: 'Souhaitez-vous être redirigé vers la nouvelle contestation ou rester sur la page en cours?',
        showCancelButton: true,
        cancelButtonText: 'Rester',
        confirmButtonText: 'Redirige-moi',
        icon: 'question',
      }).then(
        result => {
          if (result.value) {
            this.router.navigate(['/contestations', contestation.id]);
          }
        });
      const index = this.litigesEligibleValide.findIndex(litige => litige.id == event.id);
      this.litigesEligibleValide.splice(index, 1);
      this.litigesEligibleValide = this.litigesEligibleValide.slice();
    });
    this.activModal.result.then(
      (result) => {
        console.log(result);
      }, reason => {
      });
  }
}
