<table
  mat-table
  [dataSource]="dataSorted"
  (matSortChange)="sortData($event)"
  matSort
  matSortActive="id" matSortDirection="desc"
  class="table table-hover table-striped table-bordered mat-elevation-z8 text-center"
>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> id</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.id}}">
      {{element?.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="responsable">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Entité</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.responsabilite?.responsable?.libelle}}">
      {{element?.responsabilite?.responsable?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="montant">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.montant | currency : 'EUR':'€'}}">
      {{element?.montant | currency : 'EUR':'€'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.avoir | factureAvoir }}">
      {{element?.avoir | factureAvoir }}
    </td>
  </ng-container>
  <ng-container matColumnDef="numeroFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> N°Fac rétrocession</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.numero_facture }}">
      {{element?.numero_facture }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dateFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date facture</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.date_facture | date: 'dd-MM-yyyy'}}">
      {{element?.date_facture | date: 'dd-MM-yyyy'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading"></tr>
  <tr
    mat-footer-row
    *matFooterRowDef="['noData']"
    [hidden]="dataSorted && dataSorted.data.length > 0 || loading"
  >
  </tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>

</table>
