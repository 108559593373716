<div class="modal-header {{modalHeaderClass}}">
  <h4 class="modal-title w-100{{modalTitleClass}}">{{modalTitleText}}</h4>
</div>
<div class="modal-body">
  <div class="row text-right">
    <div class="col-12">
      <button class="btn btn-agediss" (click)="openModalUpload(modal_file_uploader)">Ajouter des documents</button>
    </div>
  </div>
  <div class="row m-t-15 ">
    <div class="col-12">
      <app-table-file [data]="piecesJointes"
                      [showViewDocumentBtn]="view.hasDroit('PIECE-JOINTE_SHOW')"
                      [showDeleteDocumentBtn]="view.hasDroit('PIECE-JOINTE_DELETE')"
                      [showDownloadDocumentBtn]="view.hasDroit('PIECE-JOINTE_DOWNLOAD')"
                      [showEditDocumentBtn]="view.hasDroit('PIECE-JOINTE_EDIT')"
                      [showValideJustifBtn]="view.hasDroit('JUSTIFICATIF_VALIDATE')"
                      [loading]="loadingTable"
                      (onSelectFile)="openModalVisualisation(modal_visualisation,$event)"
                      (onEditFile)="openModalEdition(modal_edition,$event)"
                      (onSelectJustif)="openModalAffectationPieceJointe(modal_validation_justificatifs,$event)"></app-table-file>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn bg-black-lighter text-white" (click)="activeModal.close()">Fermer</button>
</div>

<ng-template #modal_file_uploader let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title  w-100">Ajouter des documents </h4>
  </div>
  <div class="modal-body">
    <FileUploader [idLitige]="litigeId" [idContestation]="contestationId" (return)="modal.close()"
                  (uploaded)="onUploaded($event)"></FileUploader>
  </div>
</ng-template>

<ng-template #modal_visualisation let-modal>
  <app-file-preview-modal [pieceJointe]="pieceJointe" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile" (canceled)="modal.close($event)"></app-file-preview-modal>
</ng-template>


<!--modal validation des justificatifs-->
<ng-template #modal_validation_justificatifs let-modal>
  <app-validation-justificatf [justificatifId]="pieceJointe.id" (submited)="modal.close($event)"
                              (canceled)="modal.close($event)"></app-validation-justificatf>
</ng-template>
<!--fin modal validation des justificatifs -->

<!--modal editon des PJ-->
<ng-template #modal_edition let-modal>
  <app-edition-pieceJointe [pieceJointe]="pieceJointe" (submited)="modal.close($event)"
                              (canceled)="modal.close($event)"></app-edition-pieceJointe>
</ng-template>
<!--fin modal editon des PJ -->
