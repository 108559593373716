<panel noButton="true" bodyClass="no-padding" class="text-center">
  <div class="row" header>
    <div class="col-12">
      <h5 class="p-t-5 text-white">
        Portefeuille de : <i><b>{{user?.fullname}}</b></i>
      </h5>
    </div>
  </div>

  <div *ngIf="loadingUser" style="height: 70px" class="row col-12">
    <span class="spinner"></span>
  </div>
  <div *ngIf="!loadingUser" style="max-height: 80vh" class="overflow-scroll">

    <div class="row form-group m-4">
      <div class="col-6 m4">
        <!-- comptes clients -->
        <span *ngIf="user" class="pointer-cursor mr-2"><i class="fa fa-plus-circle" (click)="add(modal_choose_compte_client)"></i></span>
        <label>Comptes clients : </label><br>
        <div class="overflow-scroll">
          <table mat-table [dataSource]="dataSourceCompteClient" multiTemplateDataRows
                 class="table mat-responsive table-hover table-striped-n3 mat-elevation-z8
                 text-center">
            <ng-container matColumnDef="client">
              <td mat-cell *matCellDef="let element">
                {{element.libelle}}
              </td>
            </ng-container>

            <ng-container matColumnDef="code">
              <td mat-cell *matCellDef="let element">
                {{element.code}}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <td mat-cell *matCellDef="let element"
                  class="pointer">
                <span class="pointer-cursor"><i class="fa fa-minus-circle" (click)="removeCompteClient(element.id)"></i></span>
              </td>
            </ng-container>

            <tr class="border" mat-row *matRowDef="let row; columns: ['client', 'action']"></tr>
          </table>
        </div>
      </div>

      <div class="col-6 m4">
        <!-- clients -->
        <span *ngIf="user" class="pointer-cursor mr-2"><i class="fa fa-plus-circle" (click)="add(modal_choose_client)"></i></span>
        <label>Clients : </label><br>
        <div class="overflow-scroll">
          <table mat-table [dataSource]="dataSourceClient" multiTemplateDataRows
                 class="table mat-responsive table-hover table-striped-n3 mat-elevation-z8
                 text-center">
            <ng-container matColumnDef="client">
              <td mat-cell *matCellDef="let element">
                {{element.libelle}}
              </td>
            </ng-container>

            <ng-container matColumnDef="code">
              <td mat-cell *matCellDef="let element">
                {{element.code}}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <td mat-cell *matCellDef="let element">
                <span class="pointer-cursor"><i class="fa fa-minus-circle" (click)="removeClient(element.id)"></i></span>
              </td>
            </ng-container>

            <tr class="border" mat-row *matRowDef="let row; columns: ['client', 'code', 'action']"></tr>
          </table>
        </div>
      </div>

    </div>

  </div>
</panel>

<div class="row m-10">
  <div class="col-6 text-left">
    <button [ladda]="deletingItem" (click)="deleteItem()" [hidden]="loadingUser || !user"
            class="btn btn-grey">Supprimer
    </button>
  </div>
  <div class="col-6 text-right">
    <button [ladda]="savingItem" (click)="submit()" [disabled]="loadingUser || !userChanged"
            class="btn btn-agediss">Enregistrer
    </button>
  </div>
</div>



<!-- modal add compte client -->
<ng-template #modal_choose_compte_client let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Ajouter un compte client</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <ng-select [items]="compteClientsSelect"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="false"
                     (change)="newCompteClient($event)"
                     placeholder="Choisir">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- modal add client -->
<ng-template #modal_choose_client let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Ajouter un client</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <ng-select [items]="clientsSelect"
                     [selectOnTab]="true"
                     bindValue="id"
                     bindLabel="libelle"
                     [clearable]="false"
                     (change)="newClient($event)"
                     placeholder="Choisir">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</ng-template>
