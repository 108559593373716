import {Component, OnDestroy, OnInit} from '@angular/core';
import {Litige} from '../../shared/models/api/litige.model';
import {LitigeService} from '../../shared/services/api/litige.service';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {AdminService} from '../../shared/services/api/admin.service';
import {FileService} from '../../shared/services/file.service';


@Component({
  selector: 'admin-tableau-de-bord',
  templateUrl: './admin-dashbord.html'
})

export class AdminDashbord implements OnInit, OnDestroy {


  constructor(private adminSvc: AdminService) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  downloadErrorLogImport(){
    this.adminSvc.downloadErrorLogImportExcel().subscribe((res) => {
        FileService.downloadDataToFile(res);
      },
      () => {
        FileService.handleDownloadError;
      }
    );
  }

}
