import {Deserializable} from '../interfaces/deserializable.interface';
import {ImageGed} from "./image-ged.model";

export class PieceJointeGed implements Deserializable {

  public id: number;
  public images?: ImageGed[];
  public categorie: string;
  public id_piece_jointe: string;
  public id_categorie: string;
  public numero_client: string;
  public type_document: string;
  public nom: string;
  public code_image: string;
  public societe: string;
  public type_image: string;
  public downloadable: boolean;
  public watchable: boolean;

  constructor(){
    this.downloadable = true;
    this.watchable = true;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
