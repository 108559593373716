import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {Conversation} from '../../../../shared/models/api/conversation.model';
import {ConversationService} from '../../../../shared/services/api/conversation.service';
import {NotificationService} from '../../../../shared/services/api/notification.service';
import {Notification} from '../../../../shared/models/api/notification.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {View} from '../../../../shared/models/view.model';
import {Application} from "../../../../shared/models/api/application.model";

@Component({
  selector: 'app-modal-conversation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './modal-conversation.component.html'
})
export class ModalConversationComponent implements OnInit {

  @Input() conversationId: number;
  @Input() modalTitleClass: string = 'text-center';
  @Input() modalHeaderClass: string;
  @Input() configModal: NgbModalConfig;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  public conversation: Conversation;
  public application: Application;
  public conversationDirty: boolean = false;
  public view: View;

  constructor(private config: NgbModalConfig,
              public activeModal: NgbActiveModal,
              private notificationSvc: NotificationService,
              private authSvc: AuthenticationService,
              private conversationSvc: ConversationService) {
    if (this.configModal) {
      Object.assign(this.config, this.configModal);
    }
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);

    if (!this.conversationId) {
      throw new Error('Vous devez passer un id de conversation en parametre du composant \'app-modal-conversation\'.');
    }

    this.conversationSvc.getConversation(this.conversationId).subscribe(conversation => {
      this.conversation = conversation;
    }, () => {
      this.activeModal.close();
    });
  }

  close() {
    this.onClose.emit();
    this.activeModal.close();
  }

}
