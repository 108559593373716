import {Deserializable} from '../interfaces/deserializable.interface';

export class Motif implements Deserializable {

  public id: number;
  public created_at?: Date;
  public updated_at?: Date;
  public libelle: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
