import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Origine} from "../../models/api/origine.model";

@Injectable({
  providedIn: 'root'
})
export class OrigineService {

  urlRessource = environment.url_api_litige+"/origines";

  constructor(private http: HttpClient) {

  }

  getOrigines(): Observable<Origine[]> {
    return this.http
      .get<Origine[]>(`${this.urlRessource}`).pipe(map((observe) => observe.map(function (observe) {
        return new Origine().deserialize(observe);
      })))
  }


}
