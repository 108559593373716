import {Component, OnDestroy, OnInit} from '@angular/core';
import pageSettings from '../../../config/page-settings';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.html',
  styleUrls: ['./page404.scss']
})
export class Page404Page implements OnDestroy {

  pageSettings = pageSettings;

  constructor() {
    this.pageSettings.pageEmpty = true;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

}
