<!--  page-header -->
<h1 class="page-header">Rétrocessions</h1>
<!-- end page-header -->

<div class="alert alert-light fade show">
  <app-filter-litige (submitFilter)="onSubmit($event)" (onLoad)="onSubmit($event)" [pageName]="'retrocession'"></app-filter-litige>
</div>

<!-- begin table "rétrocession" -->
<div class="row">
  <div class="col-12">
    <panel noButton="true" bodyClass="no-padding" class="text-center">
      <div class="row" header>
        <div class="col-12">
          <h5 class="p-t-5 text-white">
            <i>Retrocession :
              <span *ngIf="!loadingNbLitige">{{nbLitige}}</span>
              <i *ngIf="loadingNbLitige" class="fa fa-xs fa-spinner"></i>
            </i>
            <button class="btn btn-primary ml-4" *ngIf="linesToDo > 0" (click)="retrocessionButtonClicked()">Rétrocéder ...</button>
          </h5>
        </div>
      </div>
      <div class="overflow-scroll">
        <app-table-litige [loading]="loadingTable" [sortingAPI]="false" [displayedColumns]="displayedColumn"
                          (submited)="loadData()" [data]="litiges" (linesToDo)="nbRetrocessionsToProcessChanges($event)"></app-table-litige>
      </div>
    </panel>
  </div>
  <!-- end processing table "rétrocession" -->
</div>

