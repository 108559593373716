<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       class="table table-hover table-striped table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="litige.id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="litige.id" class="px-10">N°</th>
    <td mat-cell *matCellDef="let element  let i = index"
        placement="top"
        container="body"
        ngbTooltip="{{element?.litige.id}}" class="px-10">
      <button (click)="showLitige(element?.litige?.id, i)" class="btn btn-info btn-info">
          {{element.litige.id}}
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Num</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.id}}">
      {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="litige.numero_avis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> BL</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.numero_avis}}">
      {{element?.litige.numero_avis}} </td>
  </ng-container>
  <ng-container matColumnDef="litige.numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Commande</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.numero_commande}}">
      {{element?.litige.numero_commande}} </td>
  </ng-container>
  <ng-container matColumnDef="litige.created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date du litige</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.created_at | date:'dd-MM-yyyy'}}">
      {{element?.litige.created_at | date:'mediumDate'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="litige.destinataire_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinataire</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.destinataire_nom}}">
      {{element?.litige?.destinataire_nom}} </td>
  </ng-container>
  <ng-container matColumnDef="litige.client.libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.client?.libelle}}">
      {{element?.litige?.client?.libelle}} </td>
  </ng-container>
  <ng-container matColumnDef="litige.plateforme.libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Plateforme</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.plateforme?.libelle}}">
      {{element?.litige?.plateforme?.libelle}} </td>
  </ng-container>
  <ng-container matColumnDef="statut.libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut litige</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.statut?.libelle}}">
      {{element?.litige?.statut?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="detail_piece_jointe.statut.libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut Justif.</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.detail_piece_jointe?.statut?.libelle}}">
      {{element?.detail_piece_jointe?.statut?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="litige.traite_par.fullname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Traité par</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.litige?.traite_par?.fullname}}">
      {{element?.litige?.traite_par?.fullname}}</td>
  </ng-container>
  <ng-container matColumnDef="detail_piece_jointe.montant">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.detail_piece_jointe?.montant}}">
      {{element?.detail_piece_jointe?.montant}} €</td>
  </ng-container>
  <ng-container matColumnDef="type_piece_jointe.libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> type</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.type_piece_jointe?.libelle}}">
      {{element?.type_piece_jointe?.libelle}}</td>
  </ng-container>
  <ng-container  class="col-2" matColumnDef="voir">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Voir</th>
    <td class="text-center" mat-cell *matCellDef="let element; let i = index">
      <button (click)="selectJustificatif(element.id, i)" class="btn btn-icon btn-info">
        <i class="fa fa-eye"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>
