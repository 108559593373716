import {Component, HostListener, Input, OnDestroy, OnInit, DoCheck} from '@angular/core';
import {forkJoin, Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as Editor from '@ckeditor/ckeditor5-build-classic';
import {LitigeService} from '../../../shared/services/api/litige.service';
import {ConversationService} from '../../../shared/services/api/conversation.service';
import {ContestationService} from '../../../shared/services/api/contestation.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import Swal, {SweetAlertResult} from 'sweetalert2';
import {Litige} from '../../../shared/models/api/litige.model';
import {Conversation} from '../../../shared/models/api/conversation.model';
import {Article} from '../../../shared/models/api/article.model';
import {Contestation} from '../../../shared/models/api/contestation.model';
import {Evenement} from '../../../shared/models/api/evenement.model';
import {OrigineService} from '../../../shared/services/api/origine.service';
import {MotifService} from '../../../shared/services/api/motif.service';
import {Responsabilite} from '../../../shared/models/api/responsabilite.model';
import {ToastrService} from 'ngx-toastr';
import {ModalDocumentsComponent} from '../../../components/commande/modal/modal-documents/modal-documents.component';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {Statut} from '../../../shared/models/api/statut.model';
import {StatutService} from '../../../shared/services/api/statut.service';
import {arrayPushAndUpdate, isNumber} from '../../../shared/utils/util';
import {ModalConversationComponent} from '../../../components/conversation/modal/modal-conversation/modal-conversation.component';
import {ModalAddConversationComponent} from '../../../components/conversation/modal/modal-add-conversation/modal-add-conversation.component';
import {ModalEditResponsabiliteComponent} from '../../../components/litige/modal/modal-edit-responsabilite/modal-edit-responsabilite.component';
import {ModalEditLitigeComponent} from '../../../components/litige/modal/modal-edit-litige/modal-edit-litige.component';
import {ModalAddContestationComponent} from '../../../components/contestation/modal/modal-add-contestation/modal-add-contestation.component';
import {Imputation} from '../../../shared/models/api/imputation.model';
import {Retrocession} from '../../../shared/models/api/retrocession.model';
import {ModalEditCommandeComponent} from '../../../components/litige/modal/modal-edit-commande/modal-edit-commande.component';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {View} from '../../../shared/models/view.model';
import {Oda} from '../../../shared/models/api/oda.model';
import {ModalOdaComponent} from '../../../components/commande/modal/modal-oda/modal-oda.component';
import {ActeurService} from '../../../shared/services/api/acteur.service';
import {AnnuaireService} from '../../../shared/services/api/annuaire.service';
import {AnnuaireItem} from '../../../shared/models/api/annuaire.model';
import {User} from '../../../shared/models/user.model';


@Component({
  selector: 'litige',
  templateUrl: './litige.html',
  styleUrls: ['./litige.scss']
})
export class LitigePage implements OnDestroy, OnInit, DoCheck {

  // On limite à 2 le nombre de contestations PF
  static readonly MAX_CONTESTATION_PF = 2;

  public TEMPORAIRE = 'TEMPORAIRE';

  public litige: Litige;
  public nbNewJustif = 0;
  public Editor = Editor;
  public EditorData = '';
  public HiddenColumnsRetrocession = ['id'];
  public EditorConfig = {
    language: 'fr', toolbar: [
      'heading',
      '|', 'bold', 'italic', 'blockQuote',
      '|', 'NumberedList', 'BulletedList',
      '|', 'Undo', 'Redo']
  };
  public enableEditorSaveButton = true;
  private subscriptions: Subscription[] = [];
  private activModal: NgbModalRef;


  // LOADER
  public loadingPage = false;
  public loadingOther = false;
  public loadingTableConversation = false;
  public loadingTableContestation = false;
  public loadingAnnuaireItem = true;

  // TABLEAUX
  public articles: Article[];
  public conversations: Conversation[];
  public contestations: Contestation[];
  public responsabilites: Responsabilite[];
  public evenements: Evenement[];
  public statutLitigeForm: FormGroup;
  public hiddenColumnImputation = [];

  public annuaireItem: AnnuaireItem;

  // SELECTEURS
  public statutsLitige: Statut[] = [];

  // Laddda
  public loadingBtn = false;
  public loadingBtnAffecteMe = false;
  public loadingStatutLitigeForm: boolean;
  public selectArticle: number = null;
  public modalAddArticle: NgbModalRef;
  public imputations: Imputation[];
  public retrocessions: Retrocession[];
  public loaderRetrocession: boolean;
  public loaderImputation: boolean;
  public loaderHistorique: boolean;
  public loadingBtnUpdateStatut: boolean;
  public view: View;
  public loaderOda: boolean;
  public odas: Oda[];
  public swal: Promise<SweetAlertResult>;
  public options: any;
  public canShowAddButton: boolean;

  @Input() litigeId: number = null;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    Swal.close();
  }

  constructor(
    private authSvc: AuthenticationService,
    private litigesSvc: LitigeService,
    private ActeurSvc: ActeurService,
    private conversationSvc: ConversationService,
    private contestationSvc: ContestationService,
    private modalService: NgbModal,
    private routeActive: ActivatedRoute,
    private origineSvc: OrigineService,
    private motifSvc: MotifService,
    private toastSvc: ToastrService,
    private statutSvc: StatutService,
    private annuaireSvc: AnnuaireService,
    private modalSvc: NgbModal,
    private router: Router) {
  }


  ngOnInit() {

    this.authSvc.currentView.subscribe(x => {
      this.view = x;
      if (Number(this.view.context) === User.CONTEXT_PLATEFORME) {
        this.hiddenColumnImputation = [
          'montantFacture',
          'acteur',
          'statutCalculer',
        ];
      }
    });
    if (!this.litigeId) {
      this.routeActive.params.subscribe((params) => {
        this.litigeId = params.id;
        this.loadLitige();
      });
    } else {
      this.loadLitige();
    }

  }

  ngDoCheck() {
    if (this.contestations) {
      const pf = this.contestations.filter(c => c.contestataire === 'PF');
      this.canShowAddButton = (this.view.context == 1)
        || (pf.length < LitigePage.MAX_CONTESTATION_PF && this.view.hasDroit('CONTESTATION_CREATE'));
    }
  }

  checkNbJustificatif(litigeId) {
    this.nbNewJustif = 0;
    this.litigesSvc.getJustificatifs(litigeId, null).subscribe(value => {
      value.forEach(value1 => {
        if (value1.is_validated == false) {
          this.nbNewJustif = ++this.nbNewJustif;
        }
      });
    });
  }

  loadLitige() {
    this.loadingPage = true;
    this.subscriptions.push(this.litigesSvc.getLitige(this.litigeId).subscribe(
      (litige) => {
        if (!litige.societe) {
          this.subscriptions.push(this.ActeurSvc.getActeursInterne({discr: 'plateforme'}).subscribe(
            (societes) => {
              this.options = societes.reduce((a, b) => (a[b.id] = b.libelle, a), {});
              Swal.fire({
                title: 'Aucune Société liée',
                input: 'select',
                allowOutsideClick: false,
                inputOptions: this.options,
                text: 'Ce litige n\'as pas de société affectée merci de lui en affecter une :',
                confirmButtonText: 'Confirmer !'
              }).then((result) => {
                if (result.value) {
                  const t = {id: this.litige.id, societe: result.value} as Litige;
                  this.subscriptions.push(this.litigesSvc.updateLitige(t).subscribe());
                }
              });
            }
          ));
        }


        this.checkNbJustificatif(litige.id);
        this.loadAnnuaireItem(litige.client?.id);

        this.litige = litige;
        this.loadingOther = true;
        this.loadingTableConversation = true;
        this.loadingTableContestation = true;
        this.loadingPage = false;
        this.subscriptions.push(
          forkJoin([
            this.litigesSvc.getConversations(this.litigeId),
            this.litigesSvc.getContestations(this.litigeId),
            this.litigesSvc.getEvenements(this.litigeId),
            this.litigesSvc.getResponsabilites(this.litigeId),
            this.litigesSvc.getArticles(this.litigeId),
          ])
            .subscribe(
              responses => {
                this.conversations = responses[0];
                this.contestations = responses[1];
                this.evenements = responses[2];
                this.responsabilites = responses[3];
                this.articles = responses[4];
                this.loadingOther = false;
                this.loadingTableConversation = false;
                this.loadingTableContestation = false;
              })
        );
        this.loadRetrocession();
        this.loadImputation();
      },
      (err) => {
        let msg = 'Vous n\'avez pas accès à cette resource.';
        if (err.error.message != null) {
          msg = err.error.message;
        }
        Swal
          .fire({titleText: msg})
          .then(
            () => {
              this.router.navigate(['/litiges']);
            }
          ).catch(() => {
          Swal.fire('Désolé.', 'L\'application a rencontrée une erreur.');
        });
      }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  loadAnnuaireItem(clientId) {
    if (isNumber(clientId)) {
      this.annuaireSvc.getAnnuaireItemByClient(clientId).subscribe(
        (annuaireItem) => {
          this.annuaireItem = annuaireItem;
          this.loadingAnnuaireItem = false;
        },
        () => {
          this.loadingAnnuaireItem = false;
        });
    }
  }

  loadRetrocession() {
    this.loaderRetrocession = true;
    this.subscriptions.push(this.litigesSvc.getRetrocessions(this.litige.id).subscribe(retrocessions => {
      this.retrocessions = retrocessions;
      this.loaderRetrocession = false;
    }));
  }

  loadResponsabilites() {
    this.subscriptions.push(this.litigesSvc.getResponsabilites(this.litige.id).subscribe(responsabilites => {
      this.responsabilites = responsabilites;
    }));
  }

  loadImputation() {
    this.loaderImputation = true;
    this.subscriptions.push(this.litigesSvc.getImputations(this.litige.id, {lastVersion: true}).subscribe(imputations => {
      this.imputations = imputations;
      this.loaderImputation = false;
    }));
  }

  loadOda() {
    this.loaderOda = true;
    this.subscriptions.push(this.litigesSvc.getOda(this.litige.id).subscribe(odas => {
      this.odas = odas;
      this.loaderOda = false;
    }));
  }

  refreshHistorique() {
    this.loaderHistorique = true;
    this.subscriptions.push(this.litigesSvc.getEvenements(this.litige.id).subscribe(evenements => {
      this.evenements = evenements;
      this.loaderHistorique = false;
    }));
  }

  // FORMS
  openModalDocument() {
    const modalRef = this.modalService.open(ModalDocumentsComponent, {size: 'xl'});
    modalRef.componentInstance.litigeId = this.litige.id;

    modalRef.componentInstance.reCheckNbJustificatif.subscribe(($e) => {
      this.checkNbJustificatif($e)
    })

  }

  // FORMS
  openModalOda() {
    const modalRef = this.modalService.open(ModalOdaComponent, {size: 'xl'});
    modalRef.componentInstance.litigeId = this.litige.id;
  }

  openModalCommande() {
    const modal = this.modalService.open(ModalEditCommandeComponent, {size: 'xl'});
    modal.componentInstance.litige = this.litige;
    modal.result.then(
      (result) => {
        if (result instanceof Litige) {
          this.litige = result;
        }
      });

  }

  initFormStatutLitige() {
    // lancement du loading sur la modal le temps d'initialiser le formulaire
    this.loadingStatutLitigeForm = true;

    // récupération des statut

    this.subscriptions.push(this.litigesSvc.getWorkflowStatuts(this.litige.id).subscribe(
      statuts => {
        this.statutsLitige = statuts;
        // on ajoute le statut du litige en cours
        this.statutsLitige.push(this.litige.statut);
        this.loadingStatutLitigeForm = false;
      }
    ));
    this.statutLitigeForm = new FormGroup({
      statut: new FormControl(this.litige.statut.id, Validators.required),
      commentaire: new FormControl(this.litige.commentaire_statut)
    });

  }

  // MODALS
  openModalModifLitige() {
    const modal = this.modalService.open(ModalEditLitigeComponent, {size: 'xl', keyboard: false, backdrop: 'static'});
    modal.componentInstance.litige = this.litige;
    modal.result.then(
      (result) => {
        if (result instanceof Litige) {
          this.litige = result;
        }
      });
  }

  openModalModifResponsabilite() {

    const modal = this.modalService.open(ModalEditResponsabiliteComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modal.componentInstance.litigeId = this.litige.id;
    modal.componentInstance.montantReclamation = this.litige.montant_reclamation;
    modal.componentInstance.montantValide = this.litige.montant_valide;
    modal.componentInstance.acteursLitige = this.litige.acteurs;
    modal.result.then(
      (result) => {
        if (result) {
          this.responsabilites = result;
          this.loadImputation();
          this.loadRetrocession();
        }
      });
  }

  openModalStatutLitige(modalName) {
    this.initFormStatutLitige();

    this.activModal = this.modalService.open(modalName, {size: 'lg', keyboard: false, backdrop: 'static'});
    this.activModal.result.then(
      (result) => {
        if (result instanceof Statut) {
          this.litige.statut = result;
        }
      });
  }

  openModalAddConversation() {
    const modal = this.modalService.open(ModalAddConversationComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modal.componentInstance.litigeId = this.litige.id;
    modal.componentInstance.onSubmit.subscribe(conversation => {
      this.conversations = arrayPushAndUpdate(this.conversations, conversation);
      this.openModalConversation(conversation.id);
    });
  }

  openModalConversation(id) {
    const modalRef = this.modalService.open(ModalConversationComponent, {size: 'xl'});
    modalRef.componentInstance.conversationId = id;
  }

  openAnnuaire(modalName) {
    this.activModal = this.modalSvc.open(modalName, {size: 'xl'});
  }

  // FONCTIONS POUR LA MODIFICATION DU STATUT
  submitFormStatutLitige() {
    this.loadingBtnUpdateStatut = true;
    const statut = this.statutLitigeForm.get('statut').value;
    const commentaire = this.statutLitigeForm.get('commentaire').value;

    if (statut) {
      this.litigesSvc.changeStatut(this.litige.id, statut, commentaire).subscribe((litige) => {
        this.litige = litige;
        this.loadingBtnUpdateStatut = false;
        this.loadRetrocession();
        this.loadImputation();
        this.loadResponsabilites();
        this.activModal.close();
        this.toastSvc.success('Modification enregistrée');
      }, (error) => {
        let msg = 'Une erreur s\'est produite, veuillez contacter le service IT';
        if (error.error && error.error.code) {
          msg = error.error.message;
        }
        Swal.fire('Désolé', msg, 'error');
        this.activModal.close();
      });
    }

  }

  submitFormStatutApprove() {
    this.loadingBtnUpdateStatut = true;
    const commentaire = this.statutLitigeForm.get('commentaire').value;
    this.litigesSvc.changeStatut(this.litige.id, 6, commentaire).subscribe((litige) => {
      this.litige = litige;
      // update responsabilities
      this.litigesSvc.getResponsabilites(this.litige.id).subscribe((responsabilites) => {
        this.responsabilites = responsabilites;
        this.loadingBtnUpdateStatut = false;
        this.activModal.close();
        this.toastSvc.success('Modification enregistrée');
      }, (e) => {
        this.loadingBtnUpdateStatut = false;
      });
    }, (e) => {
      this.loadingBtnUpdateStatut = false;
    });
  }

  submitFormStatutRefuse() {
    this.loadingBtnUpdateStatut = true;
    const commentaire = this.statutLitigeForm.get('commentaire').value;
    this.litigesSvc.changeStatut(this.litige.id, 2, commentaire).subscribe((litige) => {
      this.litige = litige;
      this.loadingBtnUpdateStatut = false;
      this.activModal.close();
      this.toastSvc.success('Modification enregistrée');
    }, (e) => {
      this.loadingBtnUpdateStatut = false;
    });


  }

  // FONCTIONS POUR LE CKEDITOR

  // FONCTIONS POUR LE CKEDITOR
  public onChange({editor}: ChangeEvent) {
    this.EditorData = editor.getData();
    this.enableEditorSaveButton = false;
  }

  saveNote() {
    this.enableEditorSaveButton = true;
    this.loadingBtn = true;
    this.subscriptions.push(this.litigesSvc.updateLitige({
      id: this.litige.id,
      note: this.litige.note
    } as Litige).subscribe((litge) => {

        this.toastSvc.info('Modifications enregistrées', 'Info', {progressBar: true});
        this.litige = litge;
        this.loadingBtn = false;
      },
      () => {
        this.toastSvc.error('Modifications non enregistrées', 'Info', {progressBar: true});
        this.loadingBtn = false;
      }));
  }

  // Component article

  openModalAddArticle(modalName, size = null) {

    this.modalAddArticle = this.modalService.open(modalName, {
      size: size, backdrop: 'static', keyboard: false
    });
  }

  openModalShowArticle(modalName, size = null, value: { id: number }) {
    this.selectArticle = value.id;
    this.activModal = this.modalService.open(modalName, {
      size: size, backdrop: 'static', keyboard: false
    });
    this.activModal.result.then(
      (result) => {
        this.loadData();
      }, reason => {
      });
  }

  openModalEditArticle(modalName, size = null, value: { id: number }) {
    this.selectArticle = value.id;
    this.activModal = this.modalService.open(modalName, {
      size: size, backdrop: 'static', keyboard: false
    });
    this.activModal.result.then(
      (result) => {
        this.loadData();
      }, reason => {
      });
  }

  loadData() {
    this.loadingOther = true;
    this.subscriptions.push(this.litigesSvc.getArticles(this.litige.id).subscribe(articles => {
      this.articles = articles;
      this.loadingOther = false;
    }));
  }

  onSubmit(article: Article) {
    this.articles = arrayPushAndUpdate(this.articles, article);
    this.modalAddArticle.close();
  }

  addContestation() {
    if (this.litige.statut.code != 'LIT_2') {
      const modal = this.modalService.open(ModalAddContestationComponent, {
        size: 'lg',
        keyboard: false,
        backdrop: 'static'
      });
      modal.componentInstance.litigeId = this.litige.id;
      modal.componentInstance.onSubmit.subscribe(contestation => {
        this.contestations = arrayPushAndUpdate(this.contestations, contestation);
        Swal.fire({
          title: 'Question !',
          text: 'Souhaitez-vous être redirigé vers la nouvelle contestation ou rester sur la page en cours?',
          showCancelButton: true,
          cancelButtonText: 'Rester',
          confirmButtonText: 'Redirige-moi',
          icon: 'question',
        }).then(
          result => {
            if (result.value) {
              this.router.navigate(['/contestations', contestation.id]);
            }
          });
      });
    } else {
      this.toastSvc.info(
        'Ne peux émettre une contestation si le statut du litige est \'en cours d\'analyse\'', 'Info',
        {progressBar: true}
      );
    }
  }

  affecteMe() {
    this.loadingBtnAffecteMe = true;
    this.litigesSvc.affectUserToLitige(this.litige.id).subscribe((response) => {
      this.toastSvc.success('le litige vous à été affecté !', 'Enregistrement Effectué.');
      this.loadingBtnAffecteMe = false;
      this.litige.traite_par = response.user;
      this.litige.statut = response.statut;
    }, () => {
      this.toastSvc.error('Impossible de vous affecter le litige!', 'Désolé.');
      this.loadingBtnAffecteMe = false;
    });
  }

  get viewContext(): number {
    return Number(this.view.context);
  }

}



