import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Litige} from '../../../../shared/models/api/litige.model';
import Swal from 'sweetalert2';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {ToastrService} from 'ngx-toastr';
import {forkJoin, Subscription} from 'rxjs';
import {OrigineService} from '../../../../shared/services/api/origine.service';
import {MotifService} from '../../../../shared/services/api/motif.service';
import {Origine} from '../../../../shared/models/api/origine.model';
import {Motif} from '../../../../shared/models/api/motif.model';
import {Flux} from '../../../../shared/models/api/flux.model';
import {FluxService} from '../../../../shared/services/api/flux.service';
import {MotifDetail} from '../../../../shared/models/api/motif-detail.model';
import {DateService} from '../../../../shared/services/date.service';
import {UserService} from '../../../../shared/services/api/user.service';
import {User} from '../../../../shared/models/user.model';
import {Statut} from '../../../../shared/models/api/statut.model';
import {StatutService} from '../../../../shared/services/api/statut.service';
import {InstructionRetourService} from '../../../../shared/services/api/instructionRetour.service';
import {InstructionRetour} from '../../../../shared/models/api/instruction-retour.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {View} from '../../../../shared/models/view.model';
import {ActeurService} from '../../../../shared/services/api/acteur.service';
import {Acteur} from '../../../../shared/models/api/acteur.model';
import {environment} from '../../../../../environments/environment';
import {NgbDateStructAdapter} from "@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter";

@Component({
  selector: 'app-edit-litige',
  templateUrl: './edit-litige.component.html',
  styleUrls: ['./edit-litige.component.scss']
})
export class EditLitigeComponent implements OnInit, OnDestroy {

  @Input() litige: Litige;
  @Output() submited: EventEmitter<void> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public litigeForm: FormGroup;
  public subscriptions: Subscription[] = [];
  // Selecteurs
  public origines: Origine[];
  public motifs: Motif[];
  public motifsDetails: MotifDetail[];
  public flux: Flux[];
  public statutsFraisTransport: Statut[];
  public loading = false;
  public loadingBtn = false;
  public litigeUsers: User[];
  public formSubmit = false;
  public loadingCalculResponsabilite = false;
  public instructionsRetour: InstructionRetour[];
  public loadMotifsDetails = false;
  public placeholder = 'Choisir';
  public view: View;
  public instructionRetourChanged = null;
  public societes: Acteur[];
  public perimetreResp = ['National', 'International'];
  public sensResp = ['Import', 'Export'];
  public national = false;
  public urlDts: string;
  public currentDate = {};

  constructor(
    private toastr: ToastrService, private litigeSvc: LitigeService,
    private origineSvc: OrigineService,
    private motifSvc: MotifService,
    private fluxSvc: FluxService,
    private dateSvc: DateService,
    private toastSvc: ToastrService,
    private userSvc: UserService,
    private ActeurSvc: ActeurService,
    private authSvc: AuthenticationService,
    private statutSvc: StatutService,
    private instructionRetourSvc: InstructionRetourService,
  ) {
    // @ts-ignore
    this.urlDts = environment.url_dts;
    const d = new Date();
    this.currentDate = {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);

    this.loading = true;
    // initialisation du formulaire
    this.initForm();
    // récupération des données pour les sélecteurs.
    this.subscriptions.push(forkJoin([
      this.motifSvc.getMotifs(),
      this.origineSvc.getOrigines(),
      this.fluxSvc.getFlux(),
      this.userSvc.getUsers(),
      this.statutSvc.getStatuts(StatutService.FRAIS_TRANSPORT),
      this.instructionRetourSvc.getInstructionsRetourByLitige(this.litige.id),
      this.ActeurSvc.getActeursInterne(),
    ]).subscribe((responses: any) => {
      this.motifs = responses[0];
      this.origines = responses[1];
      this.flux = responses[2];
      this.litigeUsers = responses[3];
      this.statutsFraisTransport = responses[4];
      this.instructionsRetour = responses[5];
      this.societes = responses[6];
      this.loading = false;
    }, (error) => {

      let msg = 'Merci de contacter le support informatique.';
      if (error.error && error.error.code) {
        msg = error.error.message + '<br> ' + msg;
      }
      Swal.fire({title: 'Désolé', icon: 'warning', html: msg}).then(s => this.canceled.emit());
    }));


    // on récupère la liste des motifs détails correspondant au motif
    if (this.litige.motif_detail) {
      this.motifSvc.getMotifsDetails(this.litige.motif_detail.motif.id).subscribe((motifsDetail) => {
        this.motifsDetails = motifsDetail;
      });
    }


  }

  initForm() {

    if (!this.litige.perimetre_responsabilite) {
      this.litige.perimetre_responsabilite = 'National';
    }

    this.litigeForm = new FormGroup({
      id: new FormControl(this.litige.id),
      dateLitige: new FormControl(
        DateService.convertStringToDateStruct(this.litige.date_litige as string),
        [Validators.required]
      ),
      origine: new FormControl(this.litige.origine ? this.litige.origine.id : null),
      flux: new FormControl(this.litige.flux ? this.litige.flux.id : null),
      statutFraisTransport: new FormControl((this.litige.statut_frais_transport) ? this.litige.statut_frais_transport.id : null),
      motifDetail: new FormControl(this.litige.motif_detail ? this.litige.motif_detail.id : null),
      motif: new FormControl(this.litige.motif_detail ? this.litige.motif_detail.motif.id : null),

      isReparable: new FormControl(this.litige.is_reparable),
      montantReparation: new FormControl({
        value: this.litige.montant_reparation,
        disabled: !this.litige.is_reparable
      }),
      isDedomagement: new FormControl(this.litige.is_dedomagement),
      montantDedomagement: new FormControl({
        value: this.litige.montant_dedomagement,
        disabled: !this.litige.is_dedomagement
      }),

      hasAssurance: new FormControl(this.litige.has_assurance),
      montantAssurance: new FormControl({
        value: this.litige.montant_assurance,
        disabled: !this.litige.has_assurance
      }),

      perimetreResponsabilite: new FormControl(this.litige.perimetre_responsabilite),
      sensResponsabilite: new FormControl(this.litige.sens_responsabilite),
      dateDts: new FormControl(
        DateService.convertStringToDateStruct(this.litige.date_dts as string),
        [Validators.required]
      ),
      tauxDts: new FormControl(this.litige.taux_dts),

      montantReclamation: new FormControl({
        value: this.litige.montant_reclamation,
        disabled: false

      }),
      libelleAnomalie: new FormControl({
        value: this.litige.libelle_anomalie,
        disabled: true
      }),
      noRule: new FormControl(this.litige.no_rule),
      montantValide: new FormControl({
        value: this.litige.montant_valide,
        disabled: true
      }),
      instructionRetour: new FormControl({
        value: this.litige.instruction_retour ? this.litige.instruction_retour.id : null,
        disabled: (this.litige.instruction_retour !== null)
      }),
      societe: new FormControl( this.litige.societe ? this.litige.societe.id : null, [Validators.required]),
      montantLimitResponsabilite: new FormControl(this.litige.montant_limit_responsabilite),
      traitePar: new FormControl(this.litige.traite_par ? this.litige.traite_par.id : null)
    });


    const perimetre = this.litigeForm.get('perimetreResponsabilite');
    perimetre.valueChanges.subscribe(newPerimetre => {
      if (newPerimetre === this.perimetreResp[0]) {
        this.litigeForm.get('sensResponsabilite').disable();
        this.litigeForm.get('dateDts').disable();
        this.litigeForm.get('tauxDts').disable();
        this.national = true;
      } else {
        this.litigeForm.get('sensResponsabilite').enable();
        this.litigeForm.get('dateDts').enable();
        this.litigeForm.get('tauxDts').enable();
        this.national = false;
      }
    });
    perimetre.updateValueAndValidity();

  }

  disableEnableInput(input: string, b: boolean) {
    const formControl = this.litigeForm.get(input);
    if (b) {
      formControl.reset({value: formControl.value, disabled: false});
    } else {
      formControl.reset({value: null, disabled: true});
    }
  }

  updateLitige() {
    this.formSubmit = true;
    if (this.litigeForm.invalid) {
      this.toastSvc.warning('Veuillez corriger les erreurs dans le formulaire.');
      return;
    }

    if (this.instructionRetourChanged) {
      Swal.fire({
        icon: 'question',
        titleText: 'Attention !',
        html: `<p>Vous avez définie l'instruction de retour :</p>
        <p><strong>${this.instructionRetourChanged}</strong></p>
        <p>Etes-vous sûr de vouloir envoyer cette instruction de retour ? </p>
        <p>(vous ne pourrez plus la modifier par la suite.) </p>`,
        cancelButtonText: 'Non',
        confirmButtonText: 'Oui',
        showCancelButton: true
      }).then((choice) => {
        if (choice.value) {
          this.submit();
        }
      }, );
    } else {
      this.submit();
    }


  }

  private submit() {
    this.loadingBtn = true;
    // copie des données du formulaire pour modifié des élément avant l'envoi des données à l'api
    const values = this.litigeForm.value;
    // on retirer la propriété 'motif' car l'api ne la prends pas en charge.
    delete values.motif;

    this.subscriptions.push(this.litigeSvc.updateLitige(values).subscribe(
      litige => {
        // tous c'est passé correctement on revoi le litige modifié dans l'événement.
        this.loadingBtn = false;
        this.toastr.success('Modifications enregistrées', 'Info', {progressBar: true});
        this.submited.emit(litige);
      },
      error => {
        this.loadingBtn = false;
      }
    ));
  }

  onChangeMotif() {
    if (this.litigeForm.get('motif').value) {
      this.litigeForm.get('motifDetail').setValue(null);
    }
  }

  /**
   * Cette methode actualise le selecteur des motifs détaillés en fonction du motif passé en parametre.
   * @param motifId
   */
  refreshMotifDetail(motifId) {
    if (this.litigeForm.get('motif').value === null) {
      this.disableEnableInput('motifDetail', this.litigeForm.get('motif').value);
      this.placeholder = 'Sélectionnez un motif';
    } else {
      this.loadMotifsDetails = true;
      this.subscriptions.push(this.motifSvc.getMotifsDetails(motifId).subscribe(value => {
        this.motifsDetails = value;
        this.placeholder = 'Choisir';
        this.loadMotifsDetails = false;
      }));
    }
  }

  cancel() {

    let formIsDirty = false;
    // vérification que le formulaire n'a pas des données non sauvegardées.
    // Si oui on averti l'utilisateur avec un sweetalert. Sinon on emet l'evenement d'annulation
    Object.keys(this.litigeForm.controls).forEach(key => {
      if (this.litigeForm.controls[key].dirty) {
        formIsDirty = true;
        Swal.fire({
          title: 'Attention',
          text: 'Des modifications ont été effectuées, Etes-vous sur de vouloir perdre vos modifications?',
          icon: 'warning',
          cancelButtonText: 'Non',
          confirmButtonText: 'Oui !',
          confirmButtonColor: '#aa1c1c',
          allowOutsideClick: false,
          showCancelButton: true,
          allowEscapeKey: false,
        }).then(
          (choice) => {
            if (choice.value) {
              this.canceled.emit();
            }
          },
        );
        return;
      }
    });
    if (!formIsDirty) {
      this.canceled.emit();
    }
  }



  calculLimiteResponsabilite() {
    const params = {
      perimetre: this.litigeForm.get('perimetreResponsabilite').value,
      dts: this.litigeForm.get('tauxDts').value
    };
    this.loadingCalculResponsabilite = true;
    this.subscriptions.push(this.litigeSvc.caculateLimitResponsability(this.litige.id, params).subscribe(
      limit => {
        this.litigeForm.get('montantLimitResponsabilite').setValue(limit);
        this.loadingCalculResponsabilite = false;
      },
      error => {
        this.toastr.warning(error.error.message);
        this.loadingCalculResponsabilite = false;
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }


  onChangeInstruction(event) {
    console.log(event);
    this.instructionRetourChanged = null;
    if (event) {
      this.instructionRetourChanged = event.libelle;
    }
  }

  protected readonly Date = Date;
}
