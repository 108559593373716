import {Injectable} from '@angular/core';
import {Client} from '../../models/api/client.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FilterMultiCritereModel} from '../../models/filters/filterMultiCritere.model';
import {HttpUtil} from '../../utils/http.util';
import {map} from 'rxjs/operators';
import {CompteClient} from '../../models/api/compte-client.model';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  urlRessource = environment.url_api_litige + '/clients';
  urlRessourceCompteClient = environment.url_api_litige + '/comptes-clients';

  constructor(private  http: HttpClient) {
  }


  getClients(filter: FilterMultiCritereModel = null): Observable<Client[]> {
    return this.http
      .get<Client[]>(this.urlRessource, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((clients) => clients.map((client) => {
        return new Client().deserialize(client);
      })));
  }

  /* faster implementation */
  getClientsFaster(): Observable<Client[]> {
    return this.http.get<Client[]>(`${this.urlRessource}/faster`)
      .pipe(map((clients) => clients.map((client) => {
        return new Client().deserialize(client);
      })));
  }

  getCompteClients(filter: FilterMultiCritereModel = null): Observable<CompteClient[]> {
    return this.http
      .get<CompteClient[]>(this.urlRessourceCompteClient, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((compteClients) => compteClients.map((compteClient) => {
        return new CompteClient().deserialize(compteClient);
      })));
  }


}

