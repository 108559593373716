import {Component, OnDestroy, OnInit} from '@angular/core';
import {View} from '../../shared/models/view.model';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {forkJoin, Subscription} from 'rxjs';
import {User} from '../../shared/models/user.model';
import {UserService} from '../../shared/services/api/user.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Client} from '../../shared/models/api/client.model';
import {CompteClient} from '../../shared/models/api/compte-client.model';
import {ClientService} from '../../shared/services/api/client.service';
import {AppEvent, EventQueueService} from '../../shared/services/event-queue.service';

@Component({
  selector: 'app-portefeuilles-page',
  templateUrl: './portefeuilles.html',
  styleUrls: ['./portefeuilles.scss']
})
export class PortefeuillesPageComponent implements OnDestroy, OnInit {

  public view: View;
  public searchTerm = '';
  public portefeuilles: User[];
  public loadingPortefeuilles = false;
  public clients: Client[];
  public compteClients: CompteClient[];
  public users: User[];
  public currentUserId: number;
  private userModal: NgbModalRef;

  private subscriptions: Subscription[] = [];
  private portefeuillesSubscription: Subscription;

  constructor(
    private authSvc: AuthenticationService,
    private userSvc: UserService,
    private modalSvc: NgbModal,
    private clientService: ClientService,
    private eventQueue: EventQueueService,
  )
  {
  }

  ngOnInit() {
    this.subscriptions.push(this.authSvc.currentView.subscribe(v => {
      this.view = v;
      if (this.view.hasDroit('PORTEFEUILLE_EDIT')) {
        this.loadOnInit();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.portefeuillesSubscription) {
      this.portefeuillesSubscription.unsubscribe();
    }
  }

  loadOnInit() {
    this.subscriptions.push(this.eventQueue.on(AppEvent.EventType.RELOAD_PORTEFEUILLES).subscribe(event => {
      this.loadPortefeuilles();
    }));

    this.loadingPortefeuilles = true;
    this.subscriptions.push(forkJoin([
      this.userSvc.getUsers(),
      this.clientService.getClientsFaster(),
      this.clientService.getCompteClients(),
    ]).subscribe(results => {
      this.users = results[0];
      this.clients = results[1];
      this.compteClients = results[2];
      this.loadPortefeuilles();
    }));
  }

  selected(id: number) {
    this.currentUserId = id;
    this.searchTerm = '';
  }

  newUser(user) {
    this.userModal.close();
    this.currentUserId = user.id;
  }

  loadPortefeuilles() {
    this.loadingPortefeuilles = true;
    if (this.portefeuillesSubscription) {
      this.portefeuillesSubscription.unsubscribe();
    }
    this.portefeuillesSubscription = this.userSvc.getUsers({portefeuille: true}).subscribe(users => {
      this.portefeuilles = users;
      this.loadingPortefeuilles = false;
    });
  }

  addUser(modalName) {
    this.userModal = this.modalSvc.open(modalName, {size: 'sm'});
  }
}
