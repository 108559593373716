import {Deserializable} from '../interfaces/deserializable.interface';
import {Article} from "./article.model";

export class Colis implements Deserializable {

  public id?: number;
  public article: Article;
  public cab: string;
  public created_at: Date;
  public updated_at: Date;
  public libelle: string;


  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
