import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import {Justificatif} from '../../../../shared/models/api/justificatif.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {forkJoin, Subscription} from 'rxjs';
import {StatutService} from '../../../../shared/services/api/statut.service';
import {Statut} from '../../../../shared/models/api/statut.model';
import {isNumber} from '../../../../shared/utils/util';
import {TypePieceJointe} from '../../../../shared/models/api/type-piece-jointe.model';
import {ToastrService} from 'ngx-toastr';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {AdminService} from '../../../../shared/services/api/admin.service';
import {FileService} from '../../../../shared/services/file.service';
import {NotificationService} from '../../../../shared/services/api/notification.service';
import {Litige} from '../../../../shared/models/api/litige.model';
import {PieceJointeGed} from '../../../../shared/models/api/piece-jointe-ged.model';
import {AnnuaireItem} from '../../../../shared/models/api/annuaire.model';
import {AnnuaireService} from '../../../../shared/services/api/annuaire.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-validation-justificatf',
  templateUrl: './validation-justificatf.component.html',
  styleUrls: ['./validation-justificatf.component.scss']
})
export class ValidationJustificatfComponent implements OnInit, OnDestroy {

  @Input() justificatifId: number;

  @Output() submited: EventEmitter<boolean> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public litige: Litige;
  public justificatifForm: FormGroup;
  public justificatif: Justificatif;
  public justificatifs: Justificatif[] = [];
  public subscriptions: Subscription[] = [];
  public loading = false;
  public loadingFile = false;
  public loadingBtn = false;
  public loadingDownloadBtn = false;
  public loadingRulesBtn = false;
  public formSubmit = false;
  private pathname: string;

  public annuaireItem: AnnuaireItem;

  // selecteurs
  public piecesJointesGed: PieceJointeGed[];
  public typePieceJointes: TypePieceJointe[];
  public statuts: Statut[];
  public showImage = false;
  public montantTotal: number;
  public urlPreview: string;
  public currentType: number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];
  displayedColumns: string[] = ['litige.id', 'type_piece_jointe.libelle', 'detail_piece_jointe.statut.libelle', 'detail_piece_jointe.montant'];

  constructor(private pieceJointeSvc: PieceJointeService,
              private adminSvc: AdminService,
              private notificationService: NotificationService,
              private litigeSvc: LitigeService,
              private statutSvc: StatutService,
              public toastr: ToastrService,
              private modalSvc: NgbModal,
              private annuaireSvc: AnnuaireService)
  {
  }

  ngOnInit() {
    this.loading = true;
    this.pathname = document.location.pathname;
    // recupération du justif
    if (!isNumber(this.justificatifId)) {
      this.canceled.emit();
      throw new Error('Le composant \'validation-justificatif\' à besoin d\'un id du Justificatif en paramètre.');
    }

    // jutificatifs du litige
    this.subscriptions.push(this.pieceJointeSvc.getJustificatif(this.justificatifId).subscribe(
      (justificatif) => {
        this.justificatif = justificatif;
        this.loadAnnuaireItem(this.justificatif.litige.client?.id);
        this.showPieceJointe(this.justificatif, this.justificatifId);
        this.litigeSvc.getJustificatifs(this.justificatif.litige.id, {
          except: this.justificatifId
        }).subscribe((justificatifs) => {
          this.justificatifs = justificatifs;
          this.initForm();
        });
      }));
    // statut et typePieceJointe
    this.subscriptions.push(forkJoin([
      this.pieceJointeSvc.getTypePieceJointe(true),
      this.statutSvc.getStatuts(StatutService.JUSTIFICATIF),
    ]).subscribe((response) => {
        this.typePieceJointes = response[0];
        this.statuts = response[1];
      },
      () => {
        Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', icon: 'error', showCancelButton: false});
        this.loading = false;
        this.canceled.emit();
      }
    ));
  }

  loadAnnuaireItem(clientId) {
    if (isNumber(clientId)) {
      this.annuaireSvc.getAnnuaireItemByClient(clientId).subscribe(
        (annuaireItem) => {
          this.annuaireItem = annuaireItem;
        });
    }
  }

  checkMontant(value) {
    this.montantTotal = +((!!this.justificatif.litige.montant_valide ? this.justificatif.litige.montant_valide : 0)
      + parseFloat(value)).toFixed(2);
  }


  initForm() {
    this.justificatifForm = new FormGroup({
      id: new FormControl(this.justificatif.id),
      typePieceJointe: new FormControl(this.justificatif.type_piece_jointe.id),
      statut: new FormControl(this.justificatif.detail_piece_jointe.statut.id),
      montant: new FormControl((this.justificatif.detail_piece_jointe.montant) ? this.justificatif.detail_piece_jointe.montant : 0,
        [Validators.required, Validators.maxLength(20), Validators.pattern(/^\d*(?:[.,]\d{1,2})?$/)]),
      commentaire: new FormControl(this.justificatif.detail_piece_jointe.commentaire)
    });
    this.checkMontant(this.justificatifForm.get('montant').value);
    this.loading = false;
  }

  showPieceJointe(justificatif: Justificatif, pieceJointeId) {
    this.loadingFile = true;
    if (justificatif.path.startsWith('../var/')) {
      const sub1 = this.pieceJointeSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe();
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.pieceJointeSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed;
          this.loadingFile = false;
          sub.unsubscribe();
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }

  updateJustificatif() {
    this.formSubmit = true;
    if (this.justificatifForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const values = this.justificatifForm.value;
    // si le statut est validé et le montant à 0 on bloque l'envoi du formulaire et notifie
    if (values.statut === 17 && values.montant <= 0) {
      this.toastr.warning('Vous ne pouvez pas valider un justificatif à 0 €', 'Attention');
      this.formSubmit = false;
      this.loadingBtn = false;
      return;
    }
    this.subscriptions.push(this.pieceJointeSvc.validateJustificatif(values).subscribe(
      next => {
        this.loadingBtn = false;
        this.toastr.info('Modifications enregistrées', 'Info', {progressBar: true});
        this.submited.emit(next);
      },
      () => {
        Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', icon: 'warning', showCancelButton: false});
        this.loadingBtn = false;
      }
    ));
  }

  download(fileId) {
    this.loadingDownloadBtn = true;
    this.pieceJointeSvc.downloadFile(fileId).subscribe((res) => {
        this.loadingDownloadBtn = false;

        FileService.downloadDataToFile(res);
      },
      (error) => {
        FileService.handleDownloadError(this.justificatif.filename);
        this.loadingDownloadBtn = false;
      }
    );
  }

  downloadExcelFile() {
    this.loadingRulesBtn = true;
    this.adminSvc.downloadFichierDesregles().subscribe((res) => {
        this.loadingRulesBtn = false;
        FileService.downloadDataToFile(res);
      },
      () => {
        FileService.handleDownloadError('des règles');
        this.loadingRulesBtn = false;
      }
    );
  }

  cancel() {

    let formIsDirty = false;
    // nous vérifions que le formulaire n'a pas des données non sauvegardées.
    // Si oui on averti l'utilisateur avec un sweetalert. Sinon on émet l'évènement d'annulation.
    Object.keys(this.justificatifForm.controls).forEach(key => {
      if (this.justificatifForm.controls[key].dirty) {
        formIsDirty = true;
        Swal.fire({
          title: 'Attention',
          text: 'Des modifications ont été effectuées, Etes-vous sur de vouloir perdre vos modifications?',
          icon: 'warning',
          cancelButtonText: 'Non',
          confirmButtonText: 'Oui !',
          confirmButtonColor: '#aa1c1c',
          allowOutsideClick: false,
          showCancelButton: true,
          allowEscapeKey: false,
        }).then(
          (choice) => {
            if (choice.value) {
              this.canceled.emit();
            }
          },
        );
        return;
      }
    });
    if (!formIsDirty) {
      this.canceled.emit();
    }
  }

  openAnnuaire(modalName) {
    this.modalSvc.open(modalName, {size: 'xl'});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
