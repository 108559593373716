import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {Subscription} from 'rxjs';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {PoleService} from '../../../shared/services/api/pole.service';
import {View} from '../../../shared/models/view.model';
import {FilterService} from "../../shared/filter.service";
import {Pole} from "../../../shared/models/api/pole.model";

@Component({
  selector: 'app-filter-facture',
  templateUrl: './filter-facture.component.html',
  styleUrls: ['./filter-facture.component.scss']
})
export class FilterFactureComponent implements OnInit, OnDestroy {

  @Input() displayInput: FieldParametersModel[] = [];
  @Input() pageName: String;

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public hiddenInput = [];
  public subscriptions: Subscription[] = [];
  public pole: Pole[];
  public filterForm = new FormGroup({
    pole: new FormControl(),
  });
  public loadPole: boolean = false;
  public view: View;

  /**
   * @param authSvc
   * @param poleSvc
   * @param filterSvc
   */
  constructor(private authSvc: AuthenticationService,
              private poleSvc: PoleService, private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.loadPole = true;
    this.authSvc.currentView.subscribe(x => this.view = x);
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput, this.hiddenInput);
    this.subscriptions.push(this.poleSvc.getPoles().subscribe(value => {
      this.pole = value;
      this.loadPole = false;
    }));
    this.getValueLocalstorage();
    this.onLoad.emit(this.filterForm.getRawValue());

  }

  onSubmit() {
    //récupère les infos du filtre
    this.filterSvc.onSaveInLocalstorage(this.filterForm,this.pageName+'poleFilter', 'pole');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }


  getValueLocalstorage() {
    this.filterSvc.onGetMultipleValue(this.filterForm,this.pageName+'poleFilter', 'pole', false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  reset(): void {
    this.filterForm.reset();
  }


}
