import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {Facture} from '../../../../shared/models/api/facture.model';
import {of, Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Litige} from '../../../../shared/models/api/litige.model';
import {FactureService} from '../../../../shared/services/api/facture.service';
import {ToastrService} from 'ngx-toastr';
import {formatDate} from '@angular/common';
import {PieceJointeGed} from '../../../../shared/models/api/piece-jointe-ged.model';
import Swal from 'sweetalert2';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {ModalReaffecterFacture} from '../modal-reaffecter-facture/modal-reaffecter-facture.component';
import {debounceTime, finalize, map, switchMap, tap} from 'rxjs/operators';
import {FilterMultiCritereModel} from '../../../../shared/models/filters/filterMultiCritere.model';
import {PieceJointe} from '../../../../shared/models/api/piece-jointe.model';


@Component({
  selector: 'app-modal-affecter-facture',
  templateUrl: './modal-affecter-editable-facture.component.html',
  styleUrls: ['./modal-affecter-editable-facture.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalAffecterEditableFactureComponent implements OnInit, OnDestroy {

  constructor(
    public activeModal: NgbActiveModal,
    private pieceJointeSvc: PieceJointeService,
    public litigesSvc: LitigeService,
    public factureSvc: FactureService,
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    private modalSvc: NgbModal,
    private cd: ChangeDetectorRef,
  ) {
    if (this.selected === undefined) { this.selected = false; }
  }

  @Input() pieceJointe: PieceJointe;
  @Input() piecesJointesGed: PieceJointeGed[];
  @Input() factureId: number = null;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public facture: Facture = null;
  public subscriptions: Subscription[] = [];
  public factureForm: FormGroup;
  public listLitigesAffecter: Litige[] = [];
  public totalMontantValider: number;
  public totalMontantFacture: number;
  public totalEcart = 0;
  public loadingBtn = false;
  public loading = false;
  public loadingFile = false;
  public BalanceValue = false;
  public starting = true;
  public showTable = false;
  public height = '';
  public width = '200px';
  public width2 = '0%';
  data = [];
  searchLitige = new FormControl();

  @ViewChild(NgbTooltip, {static: false}) tooltip: NgbTooltip;
  public showImage: boolean;
  public urlPreview: string;
  public currentType: number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];


  isLoading = false;
  errorMsg: string;
  displayFn: any;
  isAllSelected = false;
  selectedItems: any[] = new Array<any>();
  filteredItems: any[] = [];
  public selected?: boolean;
  public disableSearch = false;

  private activModal: NgbModalRef;


  @HostListener('window:beforeunload', ['$event'])
  checkstatus($event) {
    if (this.loadingBtn) {
      this.toastSvc.warning('Vous devez attendre que la tache soit terminée.', 'Attention', {progressBar: true});
      $event.returnValue = 'Vous devez attendre que la tache soit terminée.';
    }
  }

  ngOnInit() {
    this.starting = true;
    this.initSearchLitigeForm();
    this.loadData();

  }

  initSearchLitigeForm() {
    this.searchLitige.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = '';
          this.filteredItems = [];
        }),
        switchMap((value) => {
            if (!value || value.length < 4) {
              return of([]);
            }
            this.isLoading = true;
            return this.litigesSvc.getLitigesAAffecter({search: value} as FilterMultiCritereModel)
              .pipe(
                map((litiges) => litiges.map((litige) => {
                  // on check les litige déja affectés.
                  const litigeHold = this.listLitigesAffecter.find(i => {
                    return i.numero_avis == litige.numero_avis;
                  });

                  litige.affected = !!litigeHold;
                  // on regarde si le bl à déja été selectionné
                  litige.selected = false;
                  const lit = this.selectedItems.find(i => {
                    return i.numero_avis == litige.numero_avis;
                  });
                  if (lit) {
                    litige.selected = true;
                  }
                  return litige;
                })),
                finalize(() => {
                  this.isLoading = false;
                }),
              );
          }
        )
      )
      .subscribe(data => {
        this.filteredItems = data;
      }, () => {
        this.filteredItems = [];
      });
  }

  loadData() {
    this.loading = true;
    this.subscriptions.push(this.factureSvc.getFacture(this.factureId).subscribe(value => {

        this.facture = value;
        if (!this.facture || !(this.facture instanceof Facture)) {
          throw new Error('Vous devez passer une facture au composant ');
        }

        this.facture.facture_litiges.forEach(fl => {
          if (fl.non_traite) {
            this.disableSearch = true;
          }
          this.listLitigesAffecter.push(fl.litige);
        });
        this.showPieceJointe(this.facture.piece_jointe.id);
        this.initForm();
        this.sommeMontantValider();
        this.sommeMontantFacture();
        this.showTable = true;
        this.starting = true;


      }, error => {
        this.toastSvc.error('impossible de charger la facture', 'erreur');
        this.activeModal.close();
      }
    ));
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    this.pieceJointe = this.facture.piece_jointe;
    if (this.facture.piece_jointe.path.startsWith('../var/')) {
      const sub1 = this.pieceJointeSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe();
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.pieceJointeSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed;
          this.loadingFile = false;
          sub.unsubscribe();
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loading = false;
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }

  groupByFn = (item) => {
    return item.date_litige != undefined ? formatDate(item.date_litige, 'MMMM y', 'fr') : 'SANS DATE LITIGE';
  }
  optionClicked(event: Event, item: Litige) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      const i = this.selectedItems.findIndex(value => value.numero_avis === item.numero_avis);
      this.selectedItems.splice(i, 1);
    }
    if (this.selectedItems.length > 0) {
      this.tooltip.open();
    } else {
      this.tooltip.close();
    }

  }

  toggleSelectAll() {
    this.isAllSelected = !this.isAllSelected;
    const len = this.filteredItems.length;
    if (this.isAllSelected) {
      for (let i = 0; i++; i < len) {
        this.filteredItems[i].selected = true;
      }
      this.filteredItems = this.filteredItems.slice();
      this.selectedItems = this.filteredItems;
      this.cd.markForCheck();
    } else {
      this.selectedItems = [];
      for (let i = 0; i++; i < len) {
        this.filteredItems[i].selected = false;
      }
    }
  }

  close() {
    if (this.loadingBtn) {
      this.toastSvc.warning('Vous devez attendre que la tache soit terminée.', 'Attention', {progressBar: true});
      return;
    }
    this.activeModal.close(false);
  }

  submit() {

    this.loadingBtn = true;
    const values = this.factureForm.value;
    const litigesArr = [];
    const litiges = values.litiges;
    litiges.map(lit => {
      if (lit.modify === true) {
        litigesArr.push(lit);
      }
    });
    const factureId = values.id;
    const fac = new Facture();
    fac.id = factureId;
    fac.litiges = litigesArr;

    this.factureSvc.updateFactureAffecter(fac, true).subscribe(values => {
      this.toastSvc.success('Affectation à la facture réussie', 'Info', {progressBar: true});
      this.loadingBtn = false;
      this.activeModal.close(true);
    }, () => {
      this.toastSvc.warning('Affectation à la facture échouée', 'Info', {progressBar: true});
      this.loadingBtn = false;
    });
  }

  addBl() {
    this.starting = false;
    this.tooltip.close();
    if (this.selectedItems instanceof Array) {
      for (const litige of this.selectedItems) {
        this.listLitigesAffecter.push(litige);
        this.addBlBody(litige.id);
      }
      this.selectedItems = [];
    }
  }

  addBlBody(id) {
    if (!id) {
      return;
    }
    const value = this.selectedItems.find(value1 => value1.id === id);

    // On vérifie les montants ne sont pas nuls, si c'est le cas on les set à 0

    if (value.montant_valide == null) {
      value.montant_valide = 0;
    }
    value.montant_facture = value.montant_valide;


    this.totalMontantFacture = this.totalMontantFacture + value.montant_facture;
    this.totalMontantValider = this.totalMontantValider + value.montant_valide;
    this.totalEcart = this.facture.montant - this.totalMontantFacture;
    // on push le litige sélectionnée avec le ng-select dans factureForm
    const items = this.factureForm.get('litiges') as FormArray;
    items.push(new FormGroup({
      numeroAvis: this.formBuilder.control(value.numero_avis),
      client: this.formBuilder.control(value.client.libelle),
      destinataireNom: this.formBuilder.control(value.destinataire_nom),
      montantValider: this.formBuilder.control(value.montant_valide),
      montantAffecter: this.formBuilder.control(value.montant_facture.toFixed(2)),
      ecart: this.formBuilder.control(parseFloat(value.montant_facture.toFixed(2)) - parseFloat(value.montant_valide.toFixed(2))),
      litigeId: this.formBuilder.control(value.id),
      modify: this.formBuilder.control(true),
      nonTraite: this.formBuilder.control(false),
      newLine: this.formBuilder.control(true)
    }));


    // recalcule l'écart
    this.sommeMontantFacture();
    this.showTable = true;

  }

  removeBL(index) {

    this.starting = false;
    const items = this.factureForm.get('litiges') as FormArray;

    // suppréssion du blocage dans l'autocomplete
    const index2 = this.listLitigesAffecter.findIndex(i => {
      return i.numero_avis == items.value.numero_avis;
    });

    items.removeAt(index);

    // recalcule l'écart
    this.sommeMontantFacture();
  }


  openModalReAffectationFacture(index) {

    this.activModal = this.modalSvc.open(ModalReaffecterFacture, {
      size: 'lg', backdrop: 'static', keyboard: false
    });
    this.activModal.componentInstance.litige = index;
    this.activModal.componentInstance.facture = this.facture;
    this.activModal.componentInstance.Indexlitige = index;
    this.activModal.componentInstance.listLitigesAffecter = this.listLitigesAffecter;
    this.activModal.result.then(
      (result) => {
        if (result) {
          this.loadData();
        }
      }, () => {
      });
  }


  initForm() {

    const litiges = [];
    // On vérifie que les montants ne sont pas nuls, si c'est le cas on les set à 0
    if (this.facture.facture_litiges != undefined) {
      this.facture.facture_litiges.forEach(value => {

        if (value.litige.montant_valide == null) {
          value.litige.montant_valide = 0;
        }
        value.litige.montant_facture = value.litige.montant_valide;
        // on push les litiges liés à cette facture dans le formcontroller
        litiges.push(new FormGroup({
          numeroAvis: this.formBuilder.control(value.litige.numero_avis),
          client: this.formBuilder.control(value.litige.client_name),
          destinataireNom: this.formBuilder.control(value.litige.destinataire_nom),
          montantValider: this.formBuilder.control(value.litige.montant_valide),
          montantAffecter: this.formBuilder.control(value.montant.toFixed(2)),
          ecart: this.formBuilder.control(parseFloat((parseFloat(value.montant.toFixed(2))
            - parseFloat(value.litige.montant_valide.toFixed(2))).toFixed(2))),
          litigeId: this.formBuilder.control(value.litige.id),
          modify: this.formBuilder.control(false),
          nonTraite: (value.non_traite) !== null ? this.formBuilder.control(value.non_traite) : this.formBuilder.control(false),
          newLine: this.formBuilder.control(false)
        }));
      });
    }

    this.factureForm = this.formBuilder.group({
      id: new FormControl(this.facture.id),
      litiges: this.formBuilder.array(litiges),
      selectedLitige: new FormControl(),
    });

    this.loading = false;
  }

  sommeMontantValider() {
    this.totalMontantValider = 0;
    const items = this.factureForm.get('litiges') as FormArray;
    items.controls.forEach(value => {
      this.totalMontantValider = this.totalMontantValider + value.get('montantValider').value;
    });

    this.totalMontantValider = +this.totalMontantValider.toFixed(2);
    return this.totalMontantValider;
  }

  sommeMontantFacture(index = null) {

    this.totalMontantFacture = 0;
    this.totalEcart = 0;
    const items = this.factureForm.get('litiges') as FormArray;
    items.controls.forEach(value => {
      if (index != null) {
        const ecart = items.at(index).get('montantAffecter').value - items.at(index).get('montantValider').value;
        items.at(index).get('ecart').setValue(ecart);
        items.at(index).get('modify').setValue(true);
      }
      this.totalMontantFacture = this.totalMontantFacture + parseFloat(value.get('montantAffecter').value);
      this.totalMontantFacture = +this.totalMontantFacture.toFixed(2);
      this.totalEcart = this.facture.montant - this.totalMontantFacture;

    });
    this.BalanceValue = this.totalEcart == 0;
    return this.totalMontantFacture;

  }

  ngOnDestroy(): void {

    this.subscriptions.forEach(value => {
      value.unsubscribe();
    });
  }

}
