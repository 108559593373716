import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'dateImputation'})
export class DateImputationPipe implements PipeTransform {
  transform(value: string): string {
    if(value.length < 6){
      return 'NC'
    }
    let year =  value.substr(0,4);
    let mounth = value.substr(4,2);
    return mounth + '-'+ year;
  }
}
