<!-- bopenModalAddConversationegin page-header -->
<h1 class="page-header">Facture à affecter</h1>
<!-- end page-header -->

<div class="alert alert-light fade show">
  <app-filter-facture (submitFilter)="onSubmit($event)" (onLoad)="onSubmit($event)" [pageName]="'factureNonAffectée'"></app-filter-facture>
</div>

<!-- begin table "pieceJointes" -->
<div class="row">
    <div class="col-12">
        <panel title="Facture à affecter" noButton="true" bodyClass="no-padding" class="text-center">

          <div style="height: 50px; display:flex; align-items:center; justify-content:center;">
            <div class="p-r-4" style="display: inline-block">
              Voir les affectations en attente :
            </div>

            <div class="switcher">
              <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" [(ngModel)]="showTemporaire">
              <label for="switcher_checkbox_1"></label>
            </div>
          </div>

          <div style="height: 59vh" class="overflow-scroll">
            <app-table-facture-affecter [loading]="loadingTable" [data]="showTemporaire ? facturesTemporaires : facturesNonTemporaires"
                                        [displayedColumns]="showTemporaire ?  temporairesColumns : displayedColumns"
                                        [temporaireBtn]= "!showTemporaire"
                                        (Affecter)="showTemporaire ? openModalAffectationEditableFacture($event) : openModalAffectationFacture($event)"
                                        (setFactureTemporaire)="openModalFactureTemporaire($event)"
                                        (selectEditFacture)="openModalEditFacture($event)"
            ></app-table-facture-affecter>
          </div>
        </panel>
    </div>
    <!-- end processing table "pieceJointes" -->
</div>






