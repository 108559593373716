<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="id" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">


  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">N°</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.id}}" class="px-10">
      <a [routerLink]="['/','litiges',element.id]" class="label label-info text-white">
        {{element?.id}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="numeroAvis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>BL</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.numero_avis}}">
      {{element?.numero_avis}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.type_litige?.libelle}}">
      {{element?.type_litige?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        class="text-nowrap"
        ngbTooltip="{{element?.date_litige | date :'dd-MM-yyyy'}}">
      {{element?.date_litige | date :'dd-MM-yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.client?.libelle}}">
      {{element?.client?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="referenceComplementaire">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Référence</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.reference_complementaire}}">
      {{element?.reference_complementaire}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numeroCommande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Commande</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.numero_commande}}">
      {{element?.numero_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="traitePar">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
      <span ngbTooltip="Traité par"
            placement="top"
            container="body">
        <i class="fa fa-user"></i>
      </span>
    </th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.traite_par?.fullname}}">
      {{element?.traite_par?.fullname}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataireNom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Destinataire</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.destinataire_nom}}">
      {{element?.destinataire_nom}}
    </td>
  </ng-container>
  <ng-container matColumnDef="plateforme">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Plateforme</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.plateforme?.libelle}}">
      {{element?.plateforme?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="montantFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant affecté</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.montant_facture | currency : 'EUR':'€' }}">
      {{element?.montant_facture | currency : 'EUR':'€' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="typeApproche">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Approche</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.type_approche?.libelle}}">
      {{element?.type_approche?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        triggers="mouseenter:mouseleave"
        popoverTitle="Commentaire"
        ngbPopover="{{ (element.commentaire_statut) ? element.commentaire_statut : element?.statut?.libelle }}">
      <span *ngIf="element.date_prescrit" class="label font-weight-bold label-dark m-r-2" ngbTooltip="{{element.date_prescrit | date : 'dd-MM-yyyy' }}">Prescrit</span>
      <span class="label font-weight-bold" [ngClass]="{
    'label-primary':element?.statut.code ==='LIT_2',
    'label-green':element?.statut.code ==='CON_1' || element?.statut.code ==='LIT_3' || element?.statut.code ==='LIT_5',
    'label-danger':element?.statut.code ==='LIT_1',
    'label-warning':element?.statut.code ==='FAC_0' || element?.statut.code ==='LIT_6',
    'label-secondary':element?.statut.code ==='ANA_13'|| element?.statut.code ==='LIT_0'
    }">{{element?.statut?.libelle}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="flux">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Flux</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.flux?.libelle}}">
      {{element?.flux?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="statutBapFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut pièce par litige</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.BAPFacture}}">
      <span class="label font-weight-bold" [ngClass]="{
    'label-green':element?.BAPFacture === 'BAP',
    'label-danger':element?.BAPFacture === 'CONTESTE',
    'label-dark':element?.BAPFacture !== 'CONTESTE' && element?.BAPFacture !== 'BAP'
    }">{{element?.BAPFacture}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="montantAvoir">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant pièce associée</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.montantAvoir | currency : 'EUR':'€'  }}">
      {{element?.montantAvoir | currency : 'EUR':'€' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="montantAvoirAttendu">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant avoir attendu</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.montantAvoirAttendue | currency : 'EUR':'€' }}">
      {{element?.montantAvoirAttendue | currency : 'EUR':'€' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="entite">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Entité</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.entite}}">
      {{element?.entite}}
    </td>
  </ng-container>
  <ng-container matColumnDef="evenement">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Evénement</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.dernier_evenement?.libelle}}" class="text-truncate" style="max-width: 140px;">
      {{element?.dernier_evenement?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="instruction">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Instruction</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.instruction}}">
      {{element?.instruction}}
    </td>
  </ng-container>
  <ng-container matColumnDef="voir">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td class="text-center" mat-cell *matCellDef="let element">
      <a [routerLink]="['/','litiges',element.id]" class="btn btn-icon btn-info">
        <i class="fa fa-eye"></i>
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="montantValide">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant facturer DO</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.montant_valide}}">
      {{element?.montant_valide}}
    </td>
  </ng-container>
  <ng-container matColumnDef="avoir">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Avoir/facture</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.avoir}}">
      {{element?.avoir ? 'avoir': 'facture'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="montantValideBis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant Validé</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.montant_valide | currency : 'EUR':'€' }}">
      {{element?.montant_valide | currency : 'EUR':'€' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="imputer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Imputer</th>
    <td class="text-center" mat-cell *matCellDef="let element">
      <button class="btn btn-icon btn-info" (click)="openModalModifResponsabilite(element)">
        <i class="fa fa-eye"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="retroceder">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Retrocéder</th>
    <td class="text-center" mat-cell *matCellDef="let element">
      <button class="btn btn-icon btn-info" (click)="openModalRetocessionResponsabilite(element)">
        <i class="fa fa-eye"></i>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="doline">
    <th mat-header-cell *matHeaderCellDef>
      <button (click)="addRemoveAll()" class="btn btn-info"><i
        class="fa fa-check-double"></i></button>
    </th>
    <td class="text-center" mat-cell *matCellDef="let element">
      <button (click)="addLine(element)" *ngIf="!element.added" class="btn btn-icon btn-info">
        <i class="fa fa-2x fa-square"></i>
      </button>
      <button (click)="removeLine(element)" *ngIf="element.added" class="btn btn-icon btn-green">
        <i class="fa fa-2x fa-check-square "></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="responsables">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        placement="top"
        container="body"
        ngbTooltip="Responsables">Resp.
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-template #responsablePopover>
        <div [innerHTML]="formatListResponsable(element.listResponsable)"></div>
      </ng-template>
      <span *ngIf="element.listResponsable !='' "
            placement="left"
            container="body"
            [ngbPopover]="responsablePopover" popoverTitle="Responsable" triggers="mouseenter:mouseleave">
        <i class="fa fa-users cursor-pointer"></i>
      </span>
      <!--<div *ngFor="let responsable of element.getResponsables() ">-->
      <!--{{responsable.value()}}-->
      <!--</div>-->
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle px-10" mat-cell *matCellDef="let element; let i = dataIndex;">
      <button *ngIf="showInfosBtn"
              ngbTooltip="Informations complémentaires"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-sm-size btn-agediss"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 560"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Type : </strong><span>{{element?.type_litige?.libelle}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 950"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Client : </strong><span>{{element?.client?.libelle}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1050"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Référence complémentatre : </strong><span>{{element?.reference_complementaire}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1230"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Commande : </strong><span>{{element?.numero_commande}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1360"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Destinataire : </strong><span>{{element?.destinataire_nom}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1516"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Plateforme :</strong><span>{{element?.plateforme?.libelle}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Flux : </strong><span>{{element?.flux?.libelle}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Statut pièce par litige : </strong><span>{{element?.BAPFacture}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1700"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Date :</strong><span> {{element?.date_litige | date :'dd-MM-yyyy'}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1810"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Traité par : </strong><span>{{element?.traite_par?.fullname()}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 500"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Statut : </strong>
          <span>
                    <span *ngIf="element?.date_prescrit" class="label font-weight-bold label-dark m-r-2" ngbTooltip="{{element?.date_prescrit | date : 'dd-MM-yyyy' }}">Prescrit</span>
           <span class="label font-weight-bold" [ngClass]="{
    'label-primary':element?.statut.libelle ==='En cours d\'analyse',
    'label-green':element?.statut.libelle ==='Accepté' || element?.statut.libelle ==='Eligible' || element?.statut.libelle ==='Validé',
    'label-danger':element?.statut.libelle ==='Refusé',
    'label-warning':element?.statut.libelle ==='En attente' || element?.statut.libelle ==='en attente d\'approbation',
    'label-secondary':element?.statut.libelle ==='Prescrit'|| element?.statut.libelle ==='A traiter'
    }">{{element?.statut?.libelle}}</span>
          </span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1900 && this.context !== 2"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>Responsable : </strong><span>{{element.listResponsable}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator *ngIf="showPagination" [length]="dataSource.data.length" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
