export class FieldParametersModel {
  public input: number | string;
  public show?: number | string;
  public conf?: { value, disabled };

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }


}
