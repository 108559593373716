import {Component, Input, OnInit} from '@angular/core';
import {Litige} from '../../../../shared/models/api/litige.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {LitigeService} from "../../../../shared/services/api/litige.service";
import {Plateforme} from "../../../../shared/models/api/plateforme.model";
import {PlateformeService} from "../../../../shared/services/api/plateforme.service";
import {Subscription} from "rxjs";
import {ClientService} from "../../../../shared/services/api/client.service";
import {Client} from "../../../../shared/models/api/client.model";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-edit-commande',
  templateUrl: './modal-edit-commande.component.html',
  styleUrls: ['./modal-edit-commande.component.scss']
})
export class ModalEditCommandeComponent implements OnInit {

  @Input() litige: Litige;
  public loading: Boolean = true;
  public loadingSubmitBtn: Boolean = false;
  public formGroup: FormGroup;
  public plateformes: Plateforme[];
  public clients: Client[];

  private subscriptions: Subscription[] = [];


  constructor(
    public activModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    private litigesSvc: LitigeService,
    private plateformeSvc: PlateformeService,
    private clientSvc: ClientService,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    if (!this.litige || !(this.litige instanceof Litige)) {
      throw new Error('Vous devez passer un  litige au composant ModalEditLitige');
    }
    this.subscriptions.push(this.plateformeSvc.getPlateformes().subscribe(value => {
      this.initForm();
      this.plateformes = value;
      this.loading = false;
    }));
  }


  initForm() {
    //Initialise le formulaire
    this.formGroup = new FormGroup({
      id: new FormControl(this.litige.id,),
      referenceComplementaire: new FormControl(this.litige.reference_complementaire),
      plateforme: new FormControl((this.litige.plateforme) ? this.litige.plateforme.id : null),
      destinataireNom: new FormControl((this.litige.destinataire_nom) ? this.litige.destinataire_nom : null),
      destinataireVille: new FormControl((this.litige.destinataire_ville) ? this.litige.destinataire_ville : null),
      destinataireAdresse: new FormControl((this.litige.destinataire_adresse) ? this.litige.destinataire_adresse : null),
      destinataireCodePostal: new FormControl((this.litige.destinataire_code_postal) ? this.litige.destinataire_code_postal : null),
      numeroCommande: new FormControl((this.litige.numero_commande) ? this.litige.numero_commande : null),
      numeroAvis: new FormControl((this.litige.numero_avis) ? this.litige.numero_avis : null),
    });
  }

  submitForm() {
    this.loadingSubmitBtn = true;
    this.subscriptions.push(this.litigesSvc.updateLitige(this.formGroup.value).subscribe(value => {
      this.litige = value;
      this.loading = false;
      this.activModal.close(this.litige);
    },error => {
      let msg = 'Une erreur s\'est produite, veuillez contacter le service IT';
      if(error.error && error.error.code){
        msg = error.error.message
      }
      Swal.fire("Désolé",msg,'error');
      this.activModal.close(this.litige);
    }));

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
