import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {PieceJointe} from "../../../../shared/models/api/piece-jointe.model";
import {PieceJointeGed} from "../../../../shared/models/api/piece-jointe-ged.model";

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss']
})
export class FilePreviewModalComponent implements OnInit {

  @Input() piecesJointes: PieceJointe[] = null;
  @Input() pieceJointe: PieceJointe = null;
  @Input() piecesJointesGed: PieceJointeGed[] = null;
  @Input() pieceJointeGed: PieceJointeGed = null;
  @Input() width: string = "500px";
  @Input() height: string = "625px";
  @Input() loadingPage : Boolean = false;
  @Input() urlPreview: string | void;
  @Input() showImage = false;
  @Input() currentType : number =  null;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public loadingDownloadBtn = false;
  public subscriptions: Subscription[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  cancel() {
    this.canceled.emit();
  }

}
