import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {Responsable} from '../../models/api/responsable.model';
import {HttpUtil} from '../../utils/http.util';
import {Litige} from "../../models/api/litige.model";
import {Acteur} from "../../models/api/acteur.model";

@Injectable({
  providedIn: 'root'
})
export class ActeurService {


  urlRessource = environment.url_api_litige + '/acteurs';

  constructor(private http: HttpClient) {

  }

  getActeursInterne(filter: any = null): Observable<Responsable[]> {
    let params = {typeResponsable: 'INT'};
    if (filter) {
      params = Object.assign(params, filter);
    }
    return this.http
      .get<Responsable[]>(`${this.urlRessource}`, {
        params: HttpUtil.convertObjectToHttpParams(params)
      }).pipe(map((observe) => observe.map(function(observe) {
        return new Responsable().deserialize(observe);
      })));
  }

  getActeursTiers(filter:any = null): Observable<Responsable[]> {
    let params = {typeResponsable: 'EXT'};
    if (filter) {
      params = Object.assign(params, filter);
    }
    return this.http
      .get<Responsable[]>(`${this.urlRessource}`,{
        params: HttpUtil.convertObjectToHttpParams(params)
      }).pipe(map((observe) => observe.map(function(observe) {
        return new Responsable().deserialize(observe);
      })));
  }


  getActeursAll(filter:any = null): Observable<Responsable[]> {
    let params = {responsable: true};
    if (filter) {
      params = Object.assign(params, filter);
    }
    return this.http
      .get<Responsable[]>(`${this.urlRessource}`,{
        params: HttpUtil.convertObjectToHttpParams(params)
      }).pipe(map((observe) => observe.map(function(observe) {
        return new Responsable().deserialize(observe);
      })));
  }

  getActeurs(filter:any = null): Observable<Responsable[]> {
    let params = {};
    if (filter) {
      params = Object.assign(params, filter);
    }
    return this.http
      .get<Responsable[]>(`${this.urlRessource}`,{
        params: HttpUtil.convertObjectToHttpParams(params)
      }).pipe(map((observe) => observe.map(function(observe) {
        return new Responsable().deserialize(observe);
      })));
  }

  getActeur(id: number): Observable<Acteur> {
    return this.http
      .get<Acteur>(`${this.urlRessource}`)
      .pipe(map((observe) => {
        return new Acteur().deserialize(observe);
      }));
  }


}
