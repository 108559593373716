import {Deserializable} from '../interfaces/deserializable.interface';

export class FilterMultiCritereModel implements Deserializable {
  public plateforme?: number | string;
  public client?: number | string;
  public pole?: number | string;
  public origine?: number | string;
  public Motif?: number | string;
  public createdAt: string |  Date;
  public Responsabilite?: number | string;
  public traitePar?: number | string;
  public statut?: number | string;
  public statutCode?: string | string[];
  public search?: number | string;
  public searchFacture?: number | string;
  public limit?: number;
  public orderDirection?: string;
  public orderChamp?: string;
  public dateLitige?: string | Date;
  public minDate?: string | Date;
  public maxDate?: string | Date;
  public contestataire?: string ;
  public responsable_alternative?: boolean;
  public toggleDoneLine?: boolean;
  public showOnwResponsabilityOnly?: boolean;
  public compteClient?:number|string

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }



}
