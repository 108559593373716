<div class="modal-header">
  <div class="col-6">
    <h4 class="modal-title align-middle">Responsabilité du litige {{litigeId}}</h4>
  </div>
  <div class="col-3 text-center">
    <label class="py-0 m-0">Montant validé </label>
    <div class="input-group">
      <input type="text" readonly [value]="montantValide || 0" class="form-control text-center">
      <span class="input-group-append">
           <span class="input-group-text bg-grey"> € </span>
        </span>
    </div>
  </div>
  <div class="col-3 text-center">
    <label class="py-0 m-0">Montant réclamation </label>
    <div class="input-group">
      <input type="text" readonly [value]="montantReclamation || 0" class="form-control text-center">
      <span class="input-group-append">
           <span class="input-group-text bg-grey"> € </span>
        </span>
    </div>
  </div>
</div>
<div class="modal-body">
  <div *ngIf="loadingResponsableForm" style="height: 310px">
    <div class="spinner"></div>
  </div>
  <div *ngIf="!loadingResponsableForm">
    <form class="" [formGroup]="responsabiliteForm" (keydown.enter)="$event.preventDefault()">
      <div class="row">
        <div class="col-12 text-center"><h4>Responsabilités</h4></div>
      </div>
      <!--INTERNE-->
      <div class="row">
        <div class="col-12 underline">
          Interne / Imputations
        </div>
      </div>
      <div class="row m-t-10 m-b-10">
        <div class="col-lg-5 col-md-11">
          <label>Responsabilité </label>
        </div>
        <div class="col-lg-3 col-md-11">
          <label>% à affecter </label>
        </div>
        <div class="col-lg-3 col-md-11">
          <label>Montant à affecter </label>
        </div>
        <div class="col-lg-1" [ngbTooltip]="litigePrescrit ? 'Vous ne pouvez pas saisir de responsabilité sur un litige prescrit': 'ajouter un responsable'">
          <button [disabled]="litigePrescrit"
                  class="m-l-5 btn btn-default" container="body" placement="left" (click)="addResponsableInterneRow()">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div formArrayName=responsabilitesInterne class="m-b-10">
        <div
          *ngFor="let responsableinterne of responsabiliteForm.get('responsabilitesInterne').controls; let indexInterne = index;">
          <div [formGroupName]="indexInterne">
            <div class="row my_row" [ngClass]="{'code-partenaires': responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value == agedissPartenaires.id}">
              <div class="col-lg-5 col-md-11">
                <div class=" form-group">
                  <ng-select class="ng-select filter"
                             [items]="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value == agedissPartenaires.id ? acteursAgedissPartenaires : acteursInterne"
                             [selectOnTab]="true"
                             bindValue="id"
                             [loading]="loadingSelecteur"
                             bindLabel="libelle"
                             [clearable]="false"
                             [searchFn]="searchActeur"
                             placeholder="choisir"
                             formControlName="responsable"
                             [readonly]="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value == agedissPartenaires.id"
                             [ngClass]="{'has-error' : responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').invalid
                             && (responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').dirty || formSubmit)}">
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                      <div><span>Title: </span><span>{{item.libelle}}</span></div>
                      <small><b>Id:</b> {{item.code}} | <b>type :</b> {{item.discr}} |
                        <span class="badge badge-green" *ngIf="item.enable">actif</span>
                        <span class="badge badge-danger" *ngIf="!item.enable">non-actif</span>
                      </small>
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').invalid && (responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').dirty || formSubmit)"
                    class="invalid-tooltip-selector">Veuillez saisir un responsable !
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-11 m-t-8">
                <div class="input-group">
                  <input type="number" step="0.01" lang="en-150" class="form-control" formControlName="pourcent"
                         [readonly]="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value == agedissPartenaires.id"
                         (change)="ratioPourcentToMontant('interne',indexInterne)">
                  <span class="input-group-append">
                     <span class="input-group-text"> % </span>
                  </span>
                </div>
              </div>
              <div class="col-lg-3 col-md-11 m-t-8">
                <div class="input-group">
                  <input *ngIf="montantValide == 0 || !montantValide" type="number"
                         ngbTooltip="Il n'y a pas de montant validé !"
                         placement="top" container="body"
                         readonly step="0.01" lang="en-150"
                         class="form-control" formControlName="montant"
                         [readonly]="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value == agedissPartenaires.id"
                         (change)="ratioMontantToPourcent('interne',indexInterne)"
                  >
                  <input *ngIf="montantValide > 0" type="number" step="0.01" lang="en-150" class="form-control"
                         formControlName="montant"
                         [readonly]="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value == agedissPartenaires.id"
                         (change)="ratioMontantToPourcent('interne',indexInterne)">
                  <span class="input-group-append">
                     <span class="input-group-text"> € </span>
                  </span>
                </div>
              </div>
              <div class="col-lg-1 col-md-1 m-t-8 text-center">
                <div
                  popoverTitle="Attention"
                  triggers="mouseenter:mouseleave"
                  placement="right"
                  container="body"
                  [ngbPopover]="'Vous ne pouvez pas supprimer cette responsabilité car elle contient des imputations traitées en comptabilité. (passer la responsabilité à 0)'">
                  <button
                    *ngIf="!responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('isDeletable').value"
                    class="btn btn-dark" disabled>
                    <i class="fa fa-minus"></i>
                  </button>
                </div>

                <div
                  popoverTitle="Attention"
                  triggers="mouseenter:mouseleave"
                  placement="right"
                  container="body"
                  [ngbPopover]="'Vous ne pouvez pas supprimer cette responsabilité générée automatiquement pour les responsables Tiers de type plateforme.'">
                  <button
                    *ngIf="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('isDeletable').value && responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value == agedissPartenaires.id"
                    class="btn btn-dark" disabled>
                    <i class="fa fa-minus"></i>
                  </button>
                </div>

                <button
                  *ngIf="responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('isDeletable').value && responsabiliteForm.get('responsabilitesInterne').at(indexInterne).get('responsable').value != agedissPartenaires.id"
                  class="btn btn-dark" (click)="deleteResponsableInterne(indexInterne)"><i
                  class="fa fa-minus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--TIERS-->
      <div class="row">
        <div class="col-12 underline">
          Tiers / Rétrocessions
        </div>
      </div>
      <div class="row m-t-10 m-b-10">
        <div class="col-lg-5 col-md-11">
          <label>Responsabilité </label>
        </div>
        <div class="col-lg-3 col-md-11">
          <label>% à affecter </label>
        </div>
        <div class="col-lg-3 col-md-11">
          <label>Montant à affecter </label>
        </div>
        <div class="col-1"
             [ngbTooltip]="litigePrescrit
                    ? 'Vous ne pouvez pas saisir de responsabilité sur un litige prescrit': 'ajouter un responsable'">
          <button [disabled]="litigePrescrit"
                  container="body" placement="left"
                  class="m-l-5 btn btn-default" (click)="addResponsableTiersRow()"><i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div formArrayName=responsabilitesTiers>
        <div
          *ngFor="let responsableTiers of  responsabiliteForm.get('responsabilitesTiers').controls; let indexTiers = index;">
          <div [formGroupName]="indexTiers">
            <div class="row">
              <div class="col-lg-5 col-md-11">
                <div class=" form-group">
                  <ng-select class="ng-select filter test-select-acteur-tiers-{{indexTiers}}"
                             [items]="acteursTiers"
                             [groupBy]="groupingHelper"
                             [selectOnTab]="true"
                             bindValue="id"
                             bindLabel="libelle"
                             [virtualScroll]="true"
                             [loading]="loadingSelecteur"
                             [clearable]="false"
                             [searchFn]="searchActeur"
                             placeholder="choisir"
                             formControlName="responsable"
                             [ngClass]="{'has-error' : responsabiliteForm.get('responsabilitesTiers').at(indexTiers).get('responsable').invalid && (responsabiliteForm.get('responsabilitesTiers').at(indexTiers).get('responsable').dirty || formSubmit)}"
                             (change)="onChangeResponsableTiers()">
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" >
                      <div><span>Title: </span><span ngbTooltip="{{item.libelle}}">{{item.libelle}}</span></div>
                      <small><b>Id:</b> {{item.code}} | <b>type :</b> {{item.point_retrait ? 'Pick & Go' : item.discr}} |
                        <span class="badge badge-green" *ngIf="item.enable">actif</span>
                        <span class="badge badge-danger" *ngIf="!item.enable">non-actif</span>
                      </small>
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="responsabiliteForm.get('responsabilitesTiers').at(indexTiers).get('responsable').invalid && (responsabiliteForm.get('responsabilitesTiers').at(indexTiers).get('responsable').dirty || formSubmit)"
                    class="invalid-tooltip-selector">Veuillez saisir un responsable !
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-11 m-t-8">
                <div class="input-group">
                  <input type="number" step="0.01" lang="en-150"
                         class="form-control test-pourcent-acteur-tiers-{{indexTiers}}"
                         formControlName="pourcent"
                         (change)="ratioPourcentToMontant('externe',indexTiers)">
                  <span class="input-group-append">
                     <span class="input-group-text"> % </span>
                  </span>
                </div>
              </div>
              <div class="col-lg-3 col-md-1 m-t-8">
                <div class="input-group">
                  <input *ngIf="montantValide == 0 || !montantValide" type="number"
                         ngbTooltip="Il n'y a pas de montant validé ! "
                         placement="top" container="body"
                         readonly step="0.01" lang="en-150"
                         class="form-control" formControlName="montant"
                         (change)="ratioMontantToPourcent('externe',indexTiers)">
                  <input *ngIf="montantValide > 0" type="number" step="0.01" lang="en-150"
                         class="form-control montant-acteur-tiers-{{indexTiers}}"
                         formControlName="montant"
                         (change)="ratioMontantToPourcent('externe',indexTiers)">
                  <span class="input-group-append">
                     <span class="input-group-text"> € </span>
                  </span>
                </div>
              </div>
              <div class="col-lg-1 col-md-1 m-t-8 text-center">
                <div
                  popoverTitle="Attention"
                  triggers="mouseenter:mouseleave"
                  placement="right"
                  container="body"
                  [ngbPopover]="'Vous ne pouvez pas supprimer cette responsabilité car elle contient des rétrocessions. (passer la responsabilité à 0)'">
                  <button
                    *ngIf="!responsabiliteForm.get('responsabilitesTiers').at(indexTiers).get('isDeletable').value"
                    class="btn btn-dark" disabled>
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
                <button
                  *ngIf="responsabiliteForm.get('responsabilitesTiers').at(indexTiers).get('isDeletable').value"
                  class="btn btn-dark" (click)="deleteResponsableTiers(indexTiers)"><i
                  class="fa fa-minus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer text-center">
  <button type="button" class="btn bg-black-lighter text-white" (click)="cancel()">Annuler</button>
  <button class="btn btn-agediss" [disabled]="disableSubmit" [ladda]="loadingSubmitBtn" data-style="zoom-out"
          (click)="submitFormResponsabilite()">{{libelleSubmit}}</button>
</div>
