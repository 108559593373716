<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       class="table table-hover table-bordered mat-elevation-z8">
  <ng-container matColumnDef="responsable">
    <th mat-header-cell  *matHeaderCellDef mat-sort-header>Responsable</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.responsable?.libelle}}">
      {{element?.responsable?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.responsable?.type_responsable?.libelle}}">
      {{element?.responsable?.type_responsable?.libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="pourcent">
    <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header>%</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.pourcent}}">
      {{(element?.pourcent/100) | percent:'1.0-2' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="montant">
    <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header>Montant</th>
    <td mat-cell *matCellDef="let element"
        data-toggle="tooltip"
        data-placement="top"
        title="{{element?.montant | currency}}">
      {{element?.montant | currency : 'EUR':'€'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="validate">
    <th mat-header-cell class="text-center"
        data-toggle="tooltip"
        data-placement="top"
        title="Validation du responsable"
        *matHeaderCellDef><i class="fa fa-user-check"></i></th>
    <td mat-cell *matCellDef="let element">
      <i *ngIf="element.date_validate && !element.is_dispute && !element.is_prescrit"
        ngbTooltip="Validée le : {{element?.date_validate | date: 'dd-MM-yyyy'}}"
        data-placement="left"
        class="fa fa-user-check text-green">
      </i>
      <i *ngIf="element.is_dispute && !element.is_prescrit"
         data-toggle="tooltip"
         data-placement="top"
         title="la responsabilité est contestée"
         class="fa fa-exclamation-triangle text-red-darker">
      </i>
      <i *ngIf="element.is_prescrit"
         data-toggle="tooltip"
         data-placement="top"
         title="la responsabilité est prescrite"
         class="far fa-calendar-times text-dark">
      </i>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>

  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>
  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns, sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.responsable.code == codePartenaires ? 'code-partenaires' : 'no-partenaires'"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>
