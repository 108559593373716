import {Component, OnDestroy, OnInit} from '@angular/core';
import {Contestation} from '../../../shared/models/api/contestation.model';
import {ContestationService} from '../../../shared/services/api/contestation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import * as Editor from '@ckeditor/ckeditor5-build-classic';
import {Conversation} from '../../../shared/models/api/conversation.model';
import {ConversationService} from '../../../shared/services/api/conversation.service';
import {PieceJointe} from '../../../shared/models/api/piece-jointe.model';
import Swal from 'sweetalert2';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import {ToastrService} from 'ngx-toastr';
import {FileService} from '../../../shared/services/file.service';
import {PieceJointeService} from '../../../shared/services/api/piece-jointe.service';
import {ModalEditResponsabiliteComponent} from '../../../components/litige/modal/modal-edit-responsabilite/modal-edit-responsabilite.component';
import {View} from '../../../shared/models/view.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {NotificationService} from '../../../shared/services/api/notification.service';
import {Application} from "../../../shared/models/api/application.model";
import {PieceJointeGed} from "../../../shared/models/api/piece-jointe-ged.model";


@Component({
  selector: 'contestation',
  templateUrl: './contestation.html',
  styleUrls: ['./contestation.scss']
})

export class ContestationPage implements OnDestroy, OnInit {

  public piecesJointes: PieceJointe[];
  public pieceJointe: PieceJointe;
  public piecesJointesGed: PieceJointeGed[];
  public pieceJointeGed: PieceJointeGed
  public contestation: Contestation;
  public conversation: Conversation;
  public application: Application;
  public contestationId: number;
  private contestationSub: Subscription;
  private conversationSub: Subscription;
  private pieceJointeSub: Subscription;
  public Editor = Editor;
  public EditorData = '';
  private activModal: NgbModalRef;
  public enableEditorSaveButton: Boolean = true;
  public loadingBtn = false;
  public loadingFile: boolean = false;
  public loading: boolean = false;
  public selectFile: PieceJointe;
  public showImage: boolean;
  public urlPreview: string;
  public loadingDownloadBtn: boolean;
  public modalUpload: NgbModalRef;
  public modalVisualisation: NgbModalRef;
  public loadingBtnAffecteMe: Boolean = false;
  public view: View;
  public loadingTable: boolean = false;
  public currentType : number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];
  public displayColumnsTableFile = [
    'libelle',
    'date',
    'typePieceJointe',
    'statut',
    'actions'];
  public EditorConfig = {
    language: 'fr', toolbar: [
      'heading',
      '|', 'bold', 'italic', 'blockQuote',
      '|', 'NumberedList', 'BulletedList',
      '|', 'Undo', 'Redo']
  };
  public conversationDirty: boolean = false;
  private pathname: string;

  constructor(private contestationSvc: ContestationService,
              private conversationSvc: ConversationService,
              private routeActive: ActivatedRoute,
              private router: Router,
              private fileSvc: PieceJointeService,
              private authSvc: AuthenticationService,
              private modalService: NgbModal,
              private notificationService: NotificationService,
              private toastr: ToastrService) {

  }


  ngOnInit() {
    this.pathname = document.location.pathname;
    this.authSvc.currentView.subscribe(x => this.view = x);
    // récupération de l'id du litige
    this.routeActive.params.subscribe((params) => {
      this.contestationId = params.id;
      this.contestationSub = this.contestationSvc.getContestation(params.id).subscribe((contestation) => {
        this.contestation = contestation;
        this.conversationSub = this.contestationSvc.getConversation(params.id).subscribe(
          (conversation) => {
            this.conversation = conversation;
          }
        );
        this.getPiecesJointe();
      }, (err) => {
        let msg = 'Vous n\'avez pas accès à cette resource.';
        if (err.error.message != null) {
          msg = err.error.message;
        }
        Swal.fire({titleText: msg})
          .then(
            () => {
              this.router.navigate(['/contestations']);
            }, () => {
            }
          ).catch(() => {
          Swal.fire('Désolé.', 'L\'application a rencontrée une erreur.');
        });
      });
      if (this.view.hasDroit('CONVERSATION_SHOW')) {
        this.conversationSub = this.contestationSvc.getConversation(params.id).subscribe(
          (conversation) => {
            this.conversation = conversation;
          }
        );
      }
      this.getPiecesJointe();

    });
  }

  ngOnDestroy(): void {
  }


  openModalModifResponsabilite() {
    const modal = this.modalService.open(ModalEditResponsabiliteComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modal.componentInstance.litigeId = this.contestation.litige.id;
    modal.componentInstance.montantReclamation = this.contestation.litige.montant_reclamation;
    modal.componentInstance.montantValide = this.contestation.litige.montant_valide;
    modal.componentInstance.libelleSubmit = 'Valider la contestation.';

    // modal.componentInstance.acteursLitige = this
    modal.result.then(
      (result) => {
        if (result) {
          this.contestationSvc.validateContestation(this.contestationId).subscribe(
            next => {
              this.contestation.statut = next;
              this.toastr.info('Contestation accepté', 'Info', {progressBar: true});
            },
            () => {
              Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', icon: 'warning', showCancelButton: false});
            }
          );
        }
      });
  }

  openModalRefuseContestation(modalName, size = null) {
    this.activModal = this.modalService.open(modalName, {size: size});
    this.activModal.result.then(
      (result) => {
        if (result) {
          this.contestation.statut = result.statut;
          this.contestation.commentaire_statut = result.commentaire_statut;
        }
      });
  }

  getPiecesJointe() {
    this.loadingTable = true;
    this.pieceJointeSub = this.contestationSvc.getPieceJointes(this.contestationId).subscribe(
      (files) => {
        this.piecesJointes = files;
        this.loadingTable = false;
      },
      () => {
        this.loadingTable = false;
      }
    );
  }

  public onChange({editor}: ChangeEvent) {
    this.EditorData = editor.getData();
    this.enableEditorSaveButton = false;
  }

  saveObjetContestation() {
    this.loadingBtn = true;
    this.enableEditorSaveButton = true;
    this.contestationSvc.updateContestation(this.contestation).subscribe(
      () => {
        this.loadingBtn = false;
        this.toastr.info('La contestation à été enregistrée', 'Info', {progressBar: true});
      }, () => {
        Swal.fire({
          title: 'Attention',
          text: 'Des modifications ont été effectuées, Etes vous sur de vouloir perdre vos modifications.',
          icon: 'warning',
          cancelButtonText: 'Non',
          confirmButtonText: 'Oui !',
          confirmButtonColor: '#aa1c1c',
          allowOutsideClick: false,
          showCancelButton: true,
          allowEscapeKey: false,
        });
        this.loadingBtn = false;
      });
  }

  // PIECES JOINTES
  download(fileId) {
    this.fileSvc.downloadFile(fileId).subscribe(res => {
        FileService.downloadDataToFile(res);
      },
      FileService.handleDownloadError
    );
  }

  openModalUpload(modal) {
    this.modalUpload = this.modalService.open(modal, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
  }

  openModalVisualisation(modal, selectFile: { id: number, filename: string, path: string }) {
    this.pieceJointe = new PieceJointe().deserialize({id: selectFile.id, libelle: selectFile.filename, path: selectFile.path});
    this.modalVisualisation = this.modalService.open(modal, {
      size: 'xl',
    });
    this.showPieceJointe(selectFile.id)
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if(this.pieceJointe.path.startsWith('../var/')) {
      const sub1 = this.fileSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.modalVisualisation.close();
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.fileSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed
          this.loadingFile = false;
          sub.unsubscribe()
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.modalVisualisation.close();
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }

  onUploaded(bool) {
    if (bool) {
      this.getPiecesJointe();
    }
    this.modalUpload.close();
  }

  affecteMe() {
    this.loadingBtnAffecteMe = true;
    this.contestationSvc.affectUserToContestation(this.contestation.id).subscribe((response) => {
      this.toastr.success('le litige vous à été affecté !', 'Enregistrement Effectué.');
      this.loadingBtnAffecteMe = false;
      this.contestation.traite_par = response.user;
      this.contestation.statut = response.statut;
    }, () => {
      this.toastr.error('Impossible de vous affecter le litige!', 'Désolé.');
      this.loadingBtnAffecteMe = false;
    });
  }
}



