import {Injectable} from '@angular/core';
import {Motif} from '../../models/api/motif.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MotifDetail} from '../../models/api/motif-detail.model';

@Injectable({
  providedIn: 'root'
})
export class MotifService {

  urlRessource = environment.url_api_litige + '/motifs';


  constructor(private http: HttpClient) {

  }

  getMotifs(): Observable<Motif[]> {
    return this.http
      .get<Motif[]>(`${this.urlRessource}`).pipe(map((observe) => observe.map(function(observe) {
        return new Motif().deserialize(observe);
      })));
  }

  getMotifsDetails(motifId: number): Observable<MotifDetail[]> {
    return this.http
      .get<MotifDetail[]>(`${this.urlRessource}/${motifId}/motifs-details`).pipe(map((observe) => observe.map(function(observe) {
        return new MotifDetail().deserialize(observe);
      })));
  }
}
