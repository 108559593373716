import {Deserializable} from './interfaces/deserializable.interface';

export class User implements Deserializable {

  static readonly CONTEXT_SIEGE = 1;
  static readonly CONTEXT_PLATEFORME = 2;
  static readonly CONTEXT_CLIENT = 3;


  id: number;
  username: string;
  roles: string[];
  email: string;
  firstname: string;
  lastname: string;
  employment?: string;
  fullname?: string;
  clients?: any[];
  comptes_clients?: any[];

  public getFullname(reverse = false) {
    if (!this.firstname && !this.lastname && this.fullname){
      return this.fullname;
    }
    if (reverse) {
      return `${this.firstname} ${this.lastname}`;
    }
    return `${this.lastname} ${this.firstname}`;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }


}
