import {Litige} from './litige.model';
import {Deserializable} from '../interfaces/deserializable.interface';
import {Statut} from './statut.model';
import {User} from "../user.model";

export class Contestation implements Deserializable {

  public id?: number;
  public statut?: Statut;
  public litige?: Litige;
  public traite_par?: User;
  public objet?: string;
  public created_at?: Date;
  public updated_at?: Date;
  public contestataire: string;
  public contestataire_id: string;
  public contestataire_libelle: string;
  public commentaire_statut: string;



  deserialize(input: any): this {
    Object.assign(this, input);
    this.statut = (input.statut) ? new Statut().deserialize(input.statut) : null;
    this.litige = (input.litige) ? new Litige().deserialize(input.litige) : null;
    this.traite_par = (input.traite_par) ? new User().deserialize(input.traite_par) : null;

    return this;
  }

}
