import {Deserializable} from '../interfaces/deserializable.interface';

export class Plateforme implements Deserializable {

  public id: number;
  public created_at?: Date;
  public updated_at?: Date;
  public code: string;
  public libelle: string;
  public point_retrait: boolean;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
