<div *ngIf="!contestation" class="spinner"></div>
<div *ngIf="contestation">
  <div class="row">
    <div class="col-3 p-t-10 text-left">
      Traité par <b *ngIf="contestation.traite_par">{{ contestation.traite_par?.getFullname()}}</b>
      <button (click)="affecteMe()" [ladda]="loadingBtnAffecteMe" data-style="zoom-in"
              *ngIf="!contestation.traite_par && view.hasDroit('POLE_LITIGE')"
              class="btn btn-xs btn-primary">m'affecter le litige
      </button>
    </div>
    <div class="col-6">
      <h1 class="page-header" style="text-align: center">Contestation n°  {{contestation.id}}
        {{contestation.contestataire | contestatairePipe | uppercase}} : {{contestation.contestataire_id}} - {{contestation.contestataire_libelle}}
      </h1>
    </div>
    <div class="col-3">
      <div class="pull-right text-right"><h2><span class="label font-weight-bold" [ngClass]="{
    'label-primary':contestation?.statut.libelle ==='A traiter',
    'label-green':contestation?.statut.libelle ==='Accepté',
    'label-danger':contestation?.statut.libelle ==='Refusé'
    }">{{contestation?.statut?.libelle}}</span></h2></div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Commande" noButton="true" class="text-center">
        <div class="panel-body text-left" style="height: 160px;">
          <div class="row">
            <div class="col-6">
              <p><strong>Donneur d'ordre :</strong> {{contestation.litige.client?.libelle}} </p>
              <p><strong>Commande : </strong>{{contestation.litige.numero_commande}}</p>
              <p><strong>N°avis : </strong>{{contestation.litige.numero_avis}}</p>
              <p><strong>Destinataire : </strong>{{contestation.litige.destinataire_nom}} </p>
            </div>
            <div class="col-6">
              <p><strong>Adresse : </strong>{{contestation.litige.destinataire_adresse}}</p>
              <p><strong>Plateforme : </strong>{{contestation.litige?.plateforme?.libelle}}</p>
              <p><strong>Ville : </strong>{{contestation.litige.destinataire_ville | uppercase}}
                , {{contestation.litige.destinataire_code_postal}}</p>
            </div>
          </div>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Statut" noButton="true" class="text-center">
        <div class="panel-body text-left" style="height: 160px;">
          <div class="row">
            <div class="col-6">
              <p><strong>Statut : </strong>{{contestation.statut.libelle}}</p>
              <p *ngIf="contestation.commentaire_statut" class="motif-refus"><strong>motif du refus
                : </strong>{{contestation.commentaire_statut}}</p>
              <p><strong>Traité par : </strong>{{contestation.traite_par?.getFullname()}}</p>
            </div>
            <div class="col-md-6">
              <button *ngIf="view.hasDroit('LITIGE_SHOW')" class="btn btn-agediss btn-block"
                      [routerLink]="['/','litiges',contestation.litige.id]">Visualiser
                le Litige
              </button>
              <button *ngIf="view.hasDroit('CONTESTATION_VALIDATE')"
                      class="btn btn-agediss btn-block"
                      [hidden]="contestation?.statut.libelle ==='Accepté' || contestation?.statut.libelle ==='Refusé'"
                      (click)="openModalModifResponsabilite()">
                Valider la contestation
              </button>
              <button *ngIf="view.hasDroit('CONTESTATION_VALIDATE')" class="btn btn-agediss btn-block"
                      [hidden]="contestation?.statut.libelle ==='Accepté' || contestation?.statut.libelle ==='Refusé'"
                      (click)="openModalRefuseContestation(modal_refuse_contestation,'lg')">Refuser la contestation
              </button>
            </div>
          </div>
        </div>
      </panel>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Réclamation" noButton="true" class="text-center" bodyClass="no-padding">
        <div class="text-left">
          <div class="row mx-1 my-3">
            <div class="col-11">
              <div class="row">
                <div class="col-md-4">
                  <label for="contestataire">Contestataire</label>
                  <input type="text" id="contestataire" [disabled]="true"
                         value="{{contestation.contestataire | contestatairePipe}}  {{contestation.contestataire_id}} "
                         class="form-control">
                </div>
                <div class="col-md-4">
                  <label for="date_contestation">Date de contestation</label>
                  <input id="date_contestation" type="text" [disabled]="true"
                         value="{{contestation.created_at | date }}"
                         class="form-control">
                </div>
                <div class="col-md-4">
                  <label for="date_maj">Date de mise à jour</label>
                  <input id="date_maj" type="text" [disabled]="true" value="{{contestation.updated_at | date}}"
                         class="form-control">
                </div>
              </div>
            </div>
            <div class="col-1">
              <div class="row">
                <div class="col-md-1">
                  <button *ngIf="view.hasDroit('CONTESTATION_EDIT')" class="btn btn-agediss m-t-25 m-b-1"
                          data-style="zoom-out" [ladda]="loadingBtn"
                          [disabled]="enableEditorSaveButton" (click)="saveObjetContestation()"
                          style="position: sticky"><i class="fa fa-save"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-md-12 p-0">
              <ckeditor [editor]="Editor"
                        [config]="EditorConfig"
                        [(ngModel)]="contestation.objet"
                        (change)="onChange($event)"></ckeditor>
            </div>
          </div>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Pièces jointes" noButton="true" class="text-center" bodyClass="no-padding">
        <div class="text-left" style="height: 311px;">
          <div class="row m-t-0">
            <div class="col-12">
              <app-table-file [displayedColumns]="displayColumnsTableFile"
                              [showAddDocumentBtn]="view.hasDroit('PIECE-JOINTE_CREATE')"
                              [showViewDocumentBtn]="view.hasDroit('PIECE-JOINTE_SHOW')"
                              [showDownloadDocumentBtn]="view.hasDroit('PIECE-JOINTE_DOWNLOAD')"
                              [showDeleteDocumentBtn]="view.hasDroit('PIECE-JOINTE_DELETE')"
                              [data]="piecesJointes"
                              (onSelectFile)="openModalVisualisation(modal_visualisation,$event)"
                              [loading]="loadingTable" (onAdd)="openModalUpload(modal_file_uploader)">

              </app-table-file>
            </div>
          </div>
        </div>
      </panel>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 ">
      <panel *ngIf="view.hasDroit('CONVERSATION_SHOW')" variants="danger" title="Conversation" noButton="true"
             class="text-center" bodyClass="no-padding">
        <div *ngIf="!conversation" class="spinner"></div>
        <!-- begin widget-chat -->
        <app-dialog-conversation [conversation]="conversation" (onChange)="this.conversationDirty = true"></app-dialog-conversation>
      </panel>
    </div>
  </div>


  <ng-template #modal_file_uploader let-modal>
    <div class="modal-body">
      <FileUploader [idContestation]="contestation.id" (return)="modalUpload.close()"
                    (uploaded)="onUploaded($event)"></FileUploader>
    </div>
  </ng-template>

  <ng-template #modal_visualisation let-modal>
    <app-file-preview-modal [pieceJointe]="pieceJointe" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile" (canceled)="modal.close($event)"></app-file-preview-modal>
  </ng-template>

</div>

<!--modal refus de la contestation-->
<ng-template #modal_refuse_contestation let-modal>
  <app-refuse-contestation [contestationId]="contestation.id" (submited)="modal.close($event)"
                           (canceled)="modal.close($event)"></app-refuse-contestation>
</ng-template>
<!--fin modal refus de la contestation -->
