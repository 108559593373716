<div class="row">
  <div class="col-12">
    <form [formGroup]="conversationForm">
      <div class="row form-group" *ngIf="conversationFor == null">
        <label class="col-md-5 col-form-label text-center">Ouvrir un conversation avec : </label>
        <div class="col-md-7">
          <ng-select class="ng-select filter"
                     [items]="contextIds"
                     [selectOnTab]="true"
                     [clearable]="false"
                     [loading]="loadingContexts"
                     placeholder="CHOISIR"
                     (change)="onSelectContestataire($event)"
                     bindValue="code"
                     bindLabel="libelle"
                     formControlName="contextId">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>{{item.libelle}}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button class="btn bg-black-lighter text-white m-r-5" (click)="canceled.emit()">Annuler</button>
          <button class="btn btn-agediss" data-style="zoom-in" [ladda]="loadingContexts"
                  (click)="submitFormAddConversation()">Ouvrir
          </button>
        </div>
      </div>
    </form>
  </div>
</div>



