import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {ContestationService} from "../../../../shared/services/api/contestation.service";
import {ToastrService} from "ngx-toastr";
import {Contestation} from "../../../../shared/models/api/contestation.model";
import {Subscription} from "rxjs";
import {Statut} from "../../../../shared/models/api/statut.model";


@Component({
  selector: 'app-refuse-contestation',
  templateUrl: './refuse-contestation.component.html',
  styleUrls: ['./refuse-contestation.component.scss']
})
export class RefuseContestationComponent implements OnInit {

  public contestationForm: FormGroup;
  public contestations: Contestation[]
  public formSubmit = false;
  public loading = false;
  public loadingBtn = false;
  public subscriptions: Subscription[] = [];

  @Input() contestationId: number;

  @Output() submited: EventEmitter<Statut> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  constructor(private contestationSvc: ContestationService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.contestationForm = this.formBuilder.group( {
      commentaire_statut:['', Validators.required]
    })
  }

  submitFormRefuseContestation() {
    this.formSubmit = true;
    if (this.contestationForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const values = this.contestationForm.value;
    this.subscriptions.push(this.contestationSvc.refusContestation(this.contestationId, values).subscribe(
      next => {
        this.loadingBtn = false;
        this.toastr.info('Contestation refusée', 'Info', {progressBar: true});
        this.submited.emit(next);
      },
      () => {
        Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', icon:'warning', showCancelButton: false});
        this.loadingBtn = false;
      }
    ));
  }

  cancel() {
    let formIsDirty = false;
    // vérification que le formulaire n'a pas des données non sauvegardées.
    // Si oui on averti l'utilisateur avec un sweetalert. Sinon on émet l'évènement d'annulation
    Object.keys(this.contestationForm.controls).forEach(key => {
      if (this.contestationForm.controls[key].dirty) {
        formIsDirty = true;
        Swal.fire({
          title: 'Attention',
          text: 'Des modifications on été effectuées, Etes-vous sur de vouloir perdre vos modifications ?',
          icon: 'warning',
          cancelButtonText: 'Non',
          confirmButtonText: 'Oui !',
          confirmButtonColor: '#aa1c1c',
          allowOutsideClick: false,
          showCancelButton: true,
          allowEscapeKey: false,
        }).then(
          (choice) => {
            if (choice.value) {
              this.canceled.emit();
            }
          },
        );
        return;
      }
    });
    if (!formIsDirty) {
      this.canceled.emit();
    }
  }

}
