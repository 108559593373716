import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Litige} from '../../../../shared/models/api/litige.model';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalImputationComponent} from "../../modal/modal-imputation/modal-imputation.component";


@Component({
  selector: 'app-table-imputation',
  templateUrl: './table-imputation.component.html',
  styleUrls: ['./table-imputation.component.scss']
})
export class TableImputationComponent implements OnInit, OnChanges {

  public dataSorted: MatTableDataSource<Litige>;
  @Input() data: Litige[];
  @Input() displayedColumns: string[] = [
    'id',
    'numeroAvis',
    'client',
    'numeroCommande',
    'destinataireNom',
    'plateforme',
    'statut',
    'traitePar',
    'montantValide',
    'imputer',
  ];
  @Input() noRecordText = 'Aucun litige';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = null;
  @Output() submited: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  highlightedRows: any = [];


  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  openModalModifResponsabilite(litige: Litige) {

    //todo: créer sa propre modal Component
    const modal = this.modalService.open(ModalImputationComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modal.componentInstance.litigeId = litige.id;
    modal.result.then(value => {
      if (value == true) {
        this.submited.emit(value)
      }
    });
  }

}
