<div class="modal-header text-center">
    <h4 class="modal-title w-100">Modification du litige : {{litige.id}}</h4>
</div>
<div class="modal-body">
    <div *ngIf="loading" style="height: 50px" class="row col-12">
        <span class="spinner"></span>
    </div>
    <form *ngIf="!loading" class="" [formGroup]="formGroup">

        <div class="row">
            <div class="col-6">
                <div class="row form-group">
                    <label class="col-md-5 col-form-label" id="plateforme">Plateforme </label>
                    <div class="col-md-7" style="padding:0">
                        <ng-select [items]="plateformes"
                                   [selectOnTab]="true"
                                   bindValue="id"
                                   bindLabel="libelle"
                                   [clearable]="false"
                                   labelForId="plateforme"
                                   placeholder="Choisir"
                                   formControlName="plateforme">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row form-group">
                    <label class="col-md-5 col-form-label">Référence Complémentaire </label>
                    <input  type="text" class="form-control col-md-7"
                           formControlName="referenceComplementaire">
                </div>
            </div>
        </div>
        <div *ngIf="litige.type_litige.code != 'B2C'">
            <div class="row">
                <div class="col-6">
                    <!-- montant réclamation-->
                    <div class="row form-group">
                        <label class="col-md-5 col-form-label">BL </label>
                        <input type="text" class="form-control col-md-7"
                               formControlName="numeroAvis">
                    </div>
                </div>
                <div class="col-6">
                    <!-- montant réclamation-->
                    <div class="row form-group">
                        <label class="col-md-5 col-form-label">Numéros Commande </label>
                        <input type="text" class="form-control col-md-7"
                               formControlName="numeroCommande">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="row form-group">
                        <label class="col-md-5 col-form-label">Nom destinataire </label>
                        <input type="text" class="form-control col-md-7"
                               formControlName="destinataireNom">
                    </div>
                </div>
                <div class="col-6">
                    <div class="row form-group">
                        <label class="col-md-5 col-form-label">Ville </label>
                        <input type="text" class="form-control col-md-7"
                               formControlName="destinataireVille">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="row form-group">
                        <label class="col-md-5 col-form-label">Adresse </label>
                        <input type="text" class="form-control col-md-7"
                               formControlName="destinataireAdresse">
                    </div>
                </div>
                <div class="col-6">
                    <div class="row form-group">
                        <label class="col-md-5 col-form-label">Code Postal </label>
                        <input type="text" class="form-control col-md-7"
                               formControlName="destinataireCodePostal">
                    </div>
                </div>
            </div>
        </div>


    </form>
</div>
<div class="modal-footer text-center">
    <input class="btn btn-grey" type="submit" value="Annuler" (click)="activModal.close()">
    <input class="btn btn-agediss" type="submit" value="Envoyer" [ladda]="loadingSubmitBtn" data-style="zoom-in"
           (click)="submitForm()">
</div>
