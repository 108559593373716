import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Conversation} from '../../../../shared/models/api/conversation.model';
import {FormControl, FormGroup} from '@angular/forms';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {ContestationService} from '../../../../shared/services/api/contestation.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-conversation',
  templateUrl: './add-conversation.component.html',
  styleUrls: ['./add-conversation.component.scss']
})
export class AddConversationComponent implements OnInit {

  @Output() submitted: EventEmitter<Conversation> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Input() idLitige: number = null;
  @Input() idContestation: number = null;
  @Input() conversationFor ?: string = null;
  @Input() contextId ?: string | number = null;

  public contextIds: { libelle: string, code: string, type: string }[] = [];
  public conversationForm = new FormGroup({
    context: new FormControl(null),
    contextId: new FormControl(null)
  });

  public loadingContexts: boolean = false;

  constructor(
    private litigeSvc: LitigeService,
    private contestationSvc: ContestationService,
    private toastSvc: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadingContexts = true;
    this.litigeSvc.getLitige(this.idLitige,).subscribe((litige) => {
      console.log(this.conversationFor);
      if (this.conversationFor) {
        if (this.conversationFor == 'PF') {
          if (!this.contextId) {
            this.toastSvc.error('Code plateforme absent', 'Erreur');
            this.canceled.emit();
          }
          this.conversationForm.get('context').setValue('PF|LI');
          this.conversationForm.get('contextId').setValue(this.contextId);
        } else if (this.conversationFor == 'DO') {
          this.conversationForm.get('context').setValue('DO|LI');
          this.conversationForm.get('contextId').setValue(litige.client.code);
        }
        this.loadingContexts = false;
      } else {
        this.contextIds.push({libelle: litige.client.libelle, code: litige.client.code, type: 'DO|LI'});
        this.litigeSvc.getResponsabilites(this.idLitige).subscribe(responsabilites => {
          if (responsabilites.length == 0) {
            if (this.contextIds.length == 0) {
              this.toastSvc.error('Impossible de créer une contestation à ce stade', 'Erreur');
              this.canceled.emit();
            } else {
              this.toastSvc.warning('Aucune responsabilités trouvées pour ce litige', 'Attention');
            }
            this.loadingContexts = false;
          } else {
            responsabilites.forEach(res => {
              this.contextIds.push({
                libelle: `${res.responsable.libelle} | ${res.responsable.code}`,
                code: res.responsable.code,
                type: 'PF|LI'
              });
            });
            this.loadingContexts = false;
          }
          this.contextIds = this.contextIds.slice();
        });
      }
    });
  }

  submitFormAddConversation() {
    // si on est en présence d'un id de litige
    if (this.idLitige) {
      const sub = this.litigeSvc.postConveration(this.idLitige, this.conversationForm.value).subscribe((conversation) => {
        this.submitted.emit(conversation);
        this.toastSvc.success('Conversation Créée', 'Info', {progressBar: true});

      });
      return;
    }
    // si on est en présence d'un id de contestation
    if (this.idContestation) {
      const sub = this.contestationSvc.postConveration(this.idContestation, this.conversationForm.value).subscribe((conversation) => {
        this.submitted.emit(conversation);
        this.toastSvc.success('Conversation Créée', 'Info', {progressBar: true});

      });
      return;
    }
  }

  onSelectContestataire(val) {
    if (val) {
      this.conversationForm.get('context').setValue(val.type);
    }
  }
}
