<!-- DEBUT du contexte B2C-->
<div class="row mb-2">
    <div class="col-md-2"></div>
    <div class="col-md-4 text-left"><label> Rechercher un client </label></div>
    <div class="col-md-4">
        <ng-select [items]="listClients"
                   bindValue="id"
                   bindLabel="libelle"
                   [selectOnTab]="true"
                   [loading]="loadClient"
                   [clearable]="false"
                   (change)="initForm($event)"
                   placeholder="Choisir">
        </ng-select>
        <div class="invalid-tooltip-selector" *ngIf="form.get('client').invalid && submited">
            Veuillez sélectionner un client !
        </div>
    </div>
    <div class="col-md-2">
        <input type="submit" class="btn btn-agediss" [ladda]="loadingSubmitBtn" data-style="zoom-in"
               (click)="submit()" value="Enregistrer"/>
    </div>
</div>
<!-- FIN du contexte B2C-->


