import {Deserializable} from './interfaces/deserializable.interface';

export class View implements Deserializable {
  public context: number | string;
  public contextId?: number | string;
  public applications: Array<{ libelle: string, name: string, droits: string[], subContextIds: string[] }>;
  public libelle: string;
  public droits: string[];
  public subContextIds: string[];

  public hasDroit(droit: string) {
    return !!this.droits.find(d => d == droit);
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
