<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading" >
  <div  class="modal-header">
    <h4 class="modal-title"> litige n° {{justificatif.litige.id}} - Validation du justificatif
      n° {{justificatifId}}</h4>
    <button *ngIf="annuaireItem" class="btn btn-agediss" [ladda]="" (click)="openAnnuaire(modal_show_annuaire)">Fiche client</button>
  </div>
  <div class="modal-body">
    <form [formGroup]="justificatifForm">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-md-11">
              <app-file-preview [pieceJointe]="justificatif" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile"></app-file-preview>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row form-group">
            <label class="col-md-3 col-form-label" id="typesPieceJointe">Type </label>
            <div class="col-md-5">
              <ng-select [items]="typePieceJointes"
                         [selectOnTab]="true"
                         bindValue="id"
                         bindLabel="libelle"
                         [clearable]="false"
                         labelForId="typesPieceJointe"
                         placeholder="Choisir"
                         formControlName="typePieceJointe">
              </ng-select>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-3 col-form-label" id="statuts">Statut justificatif</label>
            <div class="col-md-5">
              <ng-select [items]="statuts"
                         [selectOnTab]="true"
                         bindValue="id"
                         bindLabel="libelle"
                         [clearable]="false"
                         labelForId="statuts"
                         placeholder="Choisir"
                         formControlName="statut">
              </ng-select>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-4 col-form-label">Montant réclamation :</label>
            <div class="col-md-2">
              <p class="col-form-label"> {{justificatif.litige.montant_reclamation}} €</p>
            </div>
            <div class="col-md-6">
              <div class="text-warning col-form-label" *ngIf="montantTotal > justificatif.litige.montant_reclamation">
                <i class="fa fa-exclamation-triangle"></i> Le montant validé est supérieur !
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-4 col-form-label">Montant déjà validé :</label>
            <div class="col-md-2">
              <p class="col-form-label"> {{justificatif.litige.montant_valide}} €</p>
            </div>
            <div *ngIf="justificatif" class="col-md-1 col-form-label text-center rounded bg-grey-transparent-2"
                 triggers="hover"
                 ngbPopover="{{ justificatif.litige.montant_valide }} + {{ box.value ? box.value : 0 }} = {{ montantTotal ? montantTotal : justificatif.litige.montant_valide }} €">
              <i class="fa fa-calculator"></i>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-4 col-form-label">limite de responsabilité :</label>
            <div class="col-md-2">
              <p class="col-form-label"> {{justificatif.litige.montant_limit_responsabilite}} €</p>
            </div>
            <div class="col-md-6">
              <div class="text-warning col-form-label" *ngIf="montantTotal > justificatif.litige.montant_limit_responsabilite">
                <i class="fa fa-exclamation-triangle"></i> Le montant validé est supérieur !
              </div>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-3 col-form-label">Montant </label>
            <div class="col-md-5">
              <input id="montant" step="0.01" lang="en-150" onClick="this.select();" #box (keyup)="checkMontant(box.value)"
                     [ngClass]="{'is-invalid' : justificatifForm.get('montant').invalid && (justificatifForm.get('montant').dirty || formSubmit) }"
                     type="number" class="form-control" formControlName="montant">
              <div
                    *ngIf="justificatifForm.get('montant').invalid && (justificatifForm.get('montant').dirty || formSubmit)"
                    class="invalid-tooltip">Veuillez saisir un montant (numérique) avec 2 décimales maximum !
            </div>
            </div>
          </div>
          <div class="row form-group">
            <label for="commentaire" class="col-md-5 col-form-label">Commentaire</label>
            <div class="col-md-12">
            <textarea id="commentaire" class="form-control" rows="4"
                      formControlName="commentaire"></textarea>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-5 col-form-label">Autres justificatifs pour ce litige</label>
            <div class="col-md-12">
              <app-table-justificatif [displayedColumns]="displayedColumns"
                                      [data]="justificatifs"></app-table-justificatif>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row ">
      <div class="col-12 text-right">
        <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">Annuler</button>
        <button [ladda]="loadingBtn" class="btn btn-agediss" (click)="updateJustificatif()">Enregistrer</button>
      </div>
    </div>
  </div>
</div>

<ng-template #modal_show_annuaire let-modal>
  <app-annuaire-editor [itemClientId]="justificatif.litige?.client?.id" [viewOnly]="true"></app-annuaire-editor>
</ng-template>


