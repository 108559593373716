import {Deserializable} from './interfaces/deserializable.interface';
import {User} from './user.model';

export class AgedissErrorBody implements Deserializable{
  erreur : any;
  user ?: User;
  date ?: Date;

  deserialize(input: any): this {
    return Object.assign(this,input);
  }
}
