import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Swal from "sweetalert2";
import {Subscription} from "rxjs";
import {PieceJointe} from "../../../../shared/models/api/piece-jointe.model";
import {PieceJointeService} from "../../../../shared/services/api/piece-jointe.service";
import {FileService} from "../../../../shared/services/file.service";
import {PieceJointeGed} from "../../../../shared/models/api/piece-jointe-ged.model";


@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
  @Input() pieceJointe: PieceJointe = null;
  @Input() piecesJointesGed: PieceJointeGed[] = null;
  @Input() pieceJointeGed: PieceJointeGed = null;
  @Input() width: string = "500px";
  @Input() height: string = "625px";
  @Input() urlPreview: string | void;
  @Input() showImage = false;
  @Input() loadingPage : Boolean = false;
  @Input() currentType : number =  null;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public loadingDownloadBtn = false;
  public subscriptions: Subscription[] = [];
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  constructor(private pieceJointeSvc: PieceJointeService) {

  }


  ngOnInit() {
  }

  download(fileId) {
    this.loadingDownloadBtn = true;
    this.pieceJointeSvc.downloadFile(fileId).subscribe((res) => {
        this.loadingDownloadBtn = false;

        FileService.downloadDataToFile(res);
      },
      (error) => {
        FileService.handleDownloadError(this.pieceJointe.filename);
        this.loadingDownloadBtn = false
      }
    );
  }

  openLink(url){
    window.open(url, "_blank");
  }

  cancel() {
    this.canceled.emit();
  }
}

