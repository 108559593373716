<div class="error">
  <div class="error-code m-b-10">404</div>
  <div class="error-content">
    <div class="error-message">Page introuvable</div>
    <div class="error-desc m-b-30">
      La page demandé n'existe pas dans l'application litige. <br />
    </div>
    <div>
      <a routerLink="/" class="btn btn-agediss-inverse p-l-20 p-r-20">Retourner à l'accueil.</a>
    </div>
    <br/>
    <div>
      <img src="../../../../assets/img/logo.svg" alt="Logo" class="logo-img">
      <h3 class="text-white">Litige</h3>
    </div>
  </div>
</div>
