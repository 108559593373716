<div class="error">
  <div class="error-code m-b-10">503</div>
  <div class="error-content">
    <div class="error-message">Maintenance en cours [fin prévue : {{dateFin}}] </div>
    <div class="error-desc m-b-30">
     Merci de réssayer ultérieument.
    </div>

    <div>
      <a href="" class="btn btn-agediss-inverse p-l-20 p-r-20">rafraichir la page.</a>
    </div>
    <br/>


    <div>
      <img src="../../../../assets/img/logo.svg" alt="Logo" class="logo-img">
      <h3 class="text-white">Litige</h3>
    </div>
  </div>
</div>
