<!-- begin breadcrumb -->
<ol class="breadcrumb pull-right">
  <li class="breadcrumb-item"><a href="javascript:">Contestations</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header">Encours des Contestations</h1>
<!-- end page-header -->

<div class="alert alert-light fade show">
  <app-filter-contestation (submitFilter)="onSubmit($event)"
                           (onLoad)="onSubmit($event)"
                           [displayInput]="displayInputFilter" [pageName]="'contestation'"></app-filter-contestation>
</div>

<!-- begin table "contestation DO" -->
<div class="row" *ngIf="view.context != 2">
  <div class="col-12">
    <panel title="Réclamation des donneurs d'ordres" noButton="true" bodyClass="no-padding" class="text-center">
      <div [ngStyle]="{'height: 600px': view.context != 1,'height: 300px': view.context == 1}" class="overflow-scroll">
        <app-table-contestation [displayedColumns]="displayedColumns" [loading]="loadingTableReclmationDO" [data]="reclamationDO" (onSorted)="onSortDO($event)"></app-table-contestation>
      </div>
    </panel>
  </div>
</div>
<!-- end processing table "contestation DO" -->


<!-- begin table "contestation PF" -->
<div class="row" *ngIf="view.context != 3">
  <div class="col-sm-12">
    <panel title="Réclamation plateformes" noButton="true" bodyClass="no-padding" class="text-center">
      <div style="overflow: hidden" [ngStyle]="{'height: 600px': view.context != 1,'height: 300px': view.context == 1}" class="overflow-scroll">
        <app-table-contestation [displayedColumns]="displayedColumns" [loading]="loadingTableReclmationPF" [data]="reclamationPF" (onSorted)="onSortPF($event)"></app-table-contestation>
      </div>
    </panel>
  </div>
</div>
<!-- end processing table "contestation PF" -->

