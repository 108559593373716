import {Deserializable} from '../interfaces/deserializable.interface';

export class TypeArticle implements Deserializable {

  public id?: number;
  public created_at?: Date;
  public updated_at?: Date;
  public code?: string;
  public libelle?: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
