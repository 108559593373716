import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Litige} from '../../../../shared/models/api/litige.model';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from "../../../../animations";
import {ModalImputationComponent} from "../../../imputation/modal/modal-imputation/modal-imputation.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalRetrocessionComponent} from "../../../retrocession/modal/modal-retrocession/modal-retrocession.component";
import { MatPaginator } from "@angular/material/paginator";
import {View} from "../../../../shared/models/view.model";
import {decimalDigest} from '@angular/compiler/src/i18n/digest';

@Component({
  selector: 'app-table-stat-pf',
  templateUrl: './table-stat-pf.component.html',
  styleUrls: ['./table-stat-pf.component.scss'],
  animations: [tableAnimation],
})
export class TableStatPfComponent implements OnInit, OnChanges {

  expandedElement: Litige | null;

  @Input() data: [];
  @Input() noRecordText = 'Aucune données';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = null;
  @Input() hiddenColumns: string[] = [];
  @Input() sortingAPI: Boolean = true;
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() showPagination: boolean = false;
  @Input() displayedColumns: string[] = [
    'responsable',
    'type',
    'a_traiter',
    'en_cours',
    'eligible',
    'a_valider',
    'contestation_en_cours',
    'attente_approbation',
    'total',
  ];
  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  public innerWidth: number;
  public view: View;
  public dataSource: any;
  // public dataSorted: MatTableDataSource<Litige>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<Litige>(this.data);
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    if (this.sortingAPI == true){
      this.onSorted.emit(sort);
    }else {
      const data = this.data.slice();
      if (!sort.active || sort.direction === '') {
        this.dataSource = new MatTableDataSource(data);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        return;
      }
      this.dataSource = new MatTableDataSource(data.sort(
        (a, b) => {
          const isAsc = sort.direction === 'asc';
          if (sort.active in a) {
            return DatatableHelper.compareString(a[sort.active], b[sort.active], isAsc);
          }
          return 0;
        }
        ));
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }



}
