<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">

  <!--ligne header-->
  <div class="row">
    <div class="col-3"></div>
    <div class=" col-6">
      <h1 class="page-header p-t-5 text-center font-weight-bold">Pièce n°  {{facture.id}} / numéro :
         {{facture.numero_interne}}</h1>
    </div>
    <div class="col-3 text-right">
      <h2>
        <span class="label font-weight-bold " [ngClass]="facture?.statut_bap == 'BAP' ? 'label-green' : 'label-danger'">{{facture?.statut_bap}}</span>
      </h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Information pièce comptable" noButton="true" class="text-center">
        <div class="panel-body text-left" style="">
          <div class="row">
            <div class="col-6">
              <p><strong>Numéro interne :</strong> {{facture?.numero_interne}} </p>
              <p><strong>Numéro externe DO :</strong> {{facture?.numero_client}} </p>
              <p><strong>Type : </strong>{{facture?.type_facture?.libelle}} </p>
              <p><strong>Statut : </strong>{{facture?.statut_bap}}</p>
            </div>
            <div class="col-6">
              <p><strong>Montant Total : </strong> {{facture.montant | currency : 'EUR':'€' }}
              </p>

              <p><strong>Date mise en BAP : </strong>
              <p class="" *ngIf="!view.hasDroit('SET_DATE_BAP')">
                {{facture?.date_bap | date: 'dd-MM-yyyy'}}
              </p>

              <div class="input-group" *ngIf="view.hasDroit('SET_DATE_BAP')">
                <input name="datepicker"
                       ngbDatepicker
                       class="input form-control text-left"
                       #datepicker="ngbDatepicker"
                       [autoClose]="true"
                       [displayMonths]="1"
                       (dateSelect)="changeDateBap($event)"
                       placeholder="dd-MM-yyyy"
                       autocomplete="off"
                       outsideDays="hidden"
                       (click)="openDatePicker()"
                       [(ngModel)]="dateBap"
                       >
                <div class="input-group-append">
                  <button class="btn btn-grey-agediss"
                          (click)="dateBapFacture()"
                          [ladda]="loadingValideBap"
                          type="button">
                    <i class="fa fa-check"></i>
                  </button>
                </div>
              </div>

              <div *ngIf="view.hasDroit('SET_DATE_BAP')">
                <button class="btn btn-agediss btn-sm mt-1"
                        ngbTooltip="Retirer la date de mise en BAP"
                        (click)="resetBap()"
                        [ladda]="loadingRemoveBap"
                        [hidden]="!enableSupBap"
                        type="button">
                  <i class="fa fa-times"></i> Supprimer date BAP
                </button>
              </div>

            </div>
          </div>
        </div>
      </panel>
    </div>
    <!--ligne information document-->
    <div class="col-md-12 col-lg-6 ligne-litige">
      <panel variants="danger" title="Documents joints" noButton="true" bodyClass="no-padding">
        <div class="overflow-scroll text-center" style="height: 204px;">
        <app-table-file ></app-table-file>
        </div>
      </panel>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 ligne-litige">
      <panel variants="danger" title="Litiges affectés à la pièce comptable : {{litiges.length}}" noButton="true" class="text-center" bodyClass="no-padding">
        <div style="height: 300px" class="overflow-scroll">
        <app-table-litige [data]="litiges" [displayedColumns]="displayedColumnsLitiges" ></app-table-litige>
        </div>
      </panel>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Note" noButton="true" class="text-left" bodyClass="no-padding">
        <div>
          <button class="btn btn-agediss rounded-0 m-0 py-2"
                  [disabled]="!enableEditorSaveButton"
                  [ladda]="loaderNote" data-style="zoom-in"
                  (click)="saveNotes()" style="position: sticky;float:right;z-index: 10">
            <i class="fa fa-save"></i></button>
          <ckeditor [editor]="Editor" [config]="EditorConfig" [(ngModel)]="facture.notes"
                    (change)="onNotesChange($event)">
          </ckeditor>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Pièces comptables associées" noButton="true" class="text-center"
             bodyClass="no-padding">
        <div class="overflow-scroll" style="height: 200px;">
          <app-table-imputation-litige [data]="imputationsAvoir" [loading]="loadingPiecesComptable" [displayedColumns]="displayedColumnsAvoir" [noRecordText]="noRecordTextPieceCOmptableAssocie" [visueFacture]="true" ></app-table-imputation-litige>
        </div>
      </panel>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6">
      <panel variants="danger" title="Imputations analytiques" noButton="true" class="text-center" bodyClass="no-padding">
        <div class="overflow-scroll" style="height: 350px;">
          <app-table-imputation-facture [loading]="loadingImputation" [data]="imputations"></app-table-imputation-facture>
        </div>
      </panel>
    </div>
  </div>

</div>
