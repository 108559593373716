import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Client} from './client.model';
import {Statut} from './statut.model';
import {Plateforme} from './plateforme.model';
import {Flux} from './flux.model';
import {Deserializable} from '../interfaces/deserializable.interface';
import {Origine} from './origine.model';
import {User} from '../user.model';
import {TypeApproche} from './type-approche.model';
import {MotifDetail} from './motif-detail.model';
import {InstructionRetour} from './instruction-retour.model';
import {Responsable} from './responsable.model';
import {Facture} from './facture.model';
import {TypeLitige} from "./type-litige.model";
import {Contestation} from "./contestation.model";
import {Societe} from "./societe.model";
import {Acteur} from "./acteur.model";

export class Litige implements Deserializable {

  public id?: number;
  public client?: Client;
  public societe?: Acteur;
  public plateforme?: Plateforme;
  public point_retrait?: Plateforme;
  public traite_par?: User;
  public statut?: Statut;
  public flux?: Flux;
  public origine?: Origine;
  public type_approche?: TypeApproche;
  public motif_detail?: MotifDetail;
  public numero_avis: string;
  public numero_commande?: string;
  public destinataire_nom?: string;
  public destinataire_adresse?: string;
  public destinataire_code_postal?: string;
  public destinataire_ville?: string;
  public destinataire_tel_fixe?: string;
  public destinataire_tel_portable?: string;
  public client_name?: string;
  public volume_total?: number;
  public poidsTotal?: number;
  public date_rdv?: Date | NgbDateStruct;
  public entite?: number;
  public instruction_retour?: InstructionRetour;
  public statut_frais_transport?: Statut;
  public statut_facturation?: Statut;
  public factures ?: Facture[];
  public note?: string;
  public created_at?: string | Date;
  public is_reparable: boolean = false;
  public montant_reparation: number ;
  public is_dedomagement: boolean = false;
  public montant_dedomagement: number;
  public has_assurance: boolean = false;
  public montant_assurance: number;
  public montant_reclamation: number;
  public montant_valide: number;
  public montant_facture: number;
  public montant_limit_responsabilite: number;
  public perimetre_responsabilite?: string;
  public sens_responsabilite?: string;
  public date_dts?: string | Date;
  public taux_dts?: number;
  public montant: number;
  public detail_ligne: string;
  public acteurs: Responsable[];
  public date_litige: string | Date;
  public commentaire_statut: string;
  public numeroFactureRetrocession?: string;
  public type_litige ?: TypeLitige;
  public reference_complementaire?: string;
  public anomalie_id?: string;
  public libelle_anomalie?: string;
  public no_rule : boolean = false;
  public date_prescrit ?: Date;
  public contestation?: Contestation[];
  public nb_contestations?: number;
  public selected?: boolean;
  public added?: boolean;
  affected: boolean;



  deserialize(input: any): this {
    Object.assign(this, input);
    this.client = (input.client) ? new Client().deserialize(input.client) : null;
    this.societe = (input.societe) ? new Acteur().deserialize(input.societe) : null;
    this.plateforme = (input.plateforme) ? new Plateforme().deserialize(input.plateforme) : null;
    this.traite_par = (input.traite_par) ? new User().deserialize(input.traite_par) : null;
    this.statut = (input.statut) ? new Statut().deserialize(input.statut) : null;
    this.flux = (input.flux) ? new Flux().deserialize(input.flux) : null;
    this.origine = (input.origine) ? new Origine().deserialize(input.origine) : null;
    this.type_approche = (input.type_approche) ? new TypeApproche().deserialize(input.type_approche) : null;
    this.motif_detail = (input.motif_detail) ? new MotifDetail().deserialize(input.motif_detail) : null;
    this.instruction_retour = (input.instruction_retour) ? new InstructionRetour().deserialize(input.instruction_retour) : null;
    this.type_litige = (input.type_litige) ? new TypeLitige().deserialize(input.type_litige) : null;
    this.note = (this.note) ? this.note : '';

    return this;
  }
}
