<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center">Modification de la pièce jointe {{ pieceJointe.id }}</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="pieceJointeForm">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-md-11">
              <app-file-preview [pieceJointe]="pieceJointe" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile"></app-file-preview>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row form-group">
            <label class="col-md-3 col-form-label">Nom </label>
            <div class="col-md-5">
              <input type="text" class="form-control" formControlName="name" disabled>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-3 col-form-label" id="typesPieceJointe">Type </label>
            <div class="col-md-5">
              <ng-select [items]="typePieceJointes"
                         [selectOnTab]="true"
                         bindValue="id"
                         bindLabel="libelle"
                         [clearable]="false"
                         labelForId="typesPieceJointe"
                         placeholder="Choisir"
                         formControlName="typePieceJointe">
              </ng-select>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-3 col-form-label">Caché </label>
            <div class="col-md-5">
              <input type="checkbox" class="form-control" style="width:auto!important;" formControlName="hidden">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-5">
    <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">Annuler</button>
    <button [ladda]="loadingBtn" class="btn btn-agediss" (click)="updatePieceJointe()">Enregistrer</button>
  </div>
</div>


