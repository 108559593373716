<div class="row mb-2">
  <div class="col-md-4"></div>
  <div class="col-md-4 text-center"><h1 class="page-header">Factures</h1></div>
</div>

<div class="alert alert-light fade show">
  <app-filter-litige (submitFilter)="onSubmit($event)" (onLoad)="onSubmit($event)" [displayInput]="displayInputFilter" [pageName]="'litiges' "></app-filter-litige>
</div>

<div class="row">
  <div class="col-12">
    <panel noButton="true" bodyClass="no-padding" class="text-center">
      <div class="row" header>
        <div class="offset-2 col-8"><h5 class="p-t-5 text-white"><i>Factures : <span
          *ngIf="factures">{{factures.length}}</span> </i></h5></div>
      </div>
      <div style="height: 700px" class="overflow-scroll">
        <app-table-facture [loading]="loadingTableFactures" [data]="factures"></app-table-facture>
      </div>
    </panel>
  </div>
</div>

