import {Deserializable} from '../interfaces/deserializable.interface';
import {Litige} from "./litige.model";
import {Contestation} from "./contestation.model";
import {User} from "../user.model";
import {Message} from "./message.model";

export class Conversation implements Deserializable {
  public id: number;
  public litige: Litige;
  public contestation: Contestation;
  public message: Message;
  public contexte: string;
  public context_id: string;
  public context_libelle: string;
  public created_at: Date;
  public updated_at: Date;
  public deleted_at: Date;

  public pending: boolean = false; // utiliser pour les loaders


  deserialize(input: any): this {
    Object.assign(this, input);
    this.litige = (input.litige) ? new Litige().deserialize(input.litige) : null;
    this.contestation = (input.contestation) ? new Contestation().deserialize(input.contestation) : null;
    this.message = (input.message) ? new Message().deserialize(input.message) : null;
    return this;
  }
}
