import {Injectable} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {LogService} from '../services/log.service';
import {AgedissError} from '../models/agediss-error.model';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private logSvc: LogService,
    private router: Router,
    private toastSvc: ToastrService,
    private modalService: NgbModal
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log(window.location.protocol)
    if( !request.url.match('/^http/'))
    request = request.clone({
      url: window.location.protocol+ "//"+request.url
    });

    console.log(request.url);
    return next.handle(request)
      .pipe(
        catchError(err => {
            // si on est en présence d'une erreur de type AgedissError c'est que l'erreur vient du controle de l'api (Interceptor)
            if (err.error instanceof AgedissError) {
              this.logSvc.logError(err.error);
              return next.handle(request);
            }

            switch (err.status) {
              case 400 :
                HttpErrorInterceptor.handleStatus400(err, request, next);
                break;
              case 404 :
                HttpErrorInterceptor.handleStatus404(err, request, next);
                break;
              case 401 :
                this.handleStatus401(err, request, next);
                break;
              case 500 :
                this.handleStatus500(err, request, next);
                break;
            }

            return next.handle(request);
          }
        )
      );
  }

  private static handleStatus404(err: any, request: HttpRequest<any>, next: HttpHandler) {

    switch (err.error.code) {
      case 2602 :
        Swal.fire({text: `l'url api suivante ${err.url} n'existe pas`, titleText: 'Attention', icon: 'warning'});
        break;// l'url n'existe pas
      case 2102 :
        return next.handle(request);

      default:
        return next.handle(request);
    }
  }


  private handleStatus401(err: any, request: HttpRequest<any>, next: HttpHandler) {
    switch (err.error.code) {
      case 2400 :
        alert('Vous n\'avez pas accès à l\'application litige, vous allez etre redirigé vers la liste des applications');
        return of(this.authenticationService.redirecToSso());
      case 2200 :
        return next.handle(request);
      default:
        if (err.error && err.error.message === 'Bad credentials') {
          return of(this.authenticationService.logout());
        } else {
          return next.handle(request);
        }
    }
  }

  private static handleStatus400(err: any, request: HttpRequest<any>, next: HttpHandler) {
    switch (err.error.code) {
      case 2100 :
        return next.handle(request); // TODO voir si on peut généralisé l'interception des erreurs avec le code 2100 "erreur saisie formulaire"
      default:
        return next.handle(request);
    }
  }

  private handleStatus500(err: any, request: HttpRequest<any>, next: HttpHandler) {
    console.log(err);
    switch (err.error.code) {
      case 2100 :
        return next.handle(request);
      case 1 :
        this.toastSvc.error('Une erreur c\'est produite merci de contacter le service informatique', 'Désolé');
        this.modalService.dismissAll();
        this.router.navigate(['/']);
        return next.handle(request);
      default:
        return next.handle(request);
    }
  }
}
