import {Deserializable} from '../interfaces/deserializable.interface';
import {Contestation} from "./contestation.model";
import {Litige} from './litige.model';
import {TypePieceJointe} from './type-piece-jointe.model';
import {DetailPieceJointe} from "./detail-piece-jointe.model";
import {CompteClient} from './compte-client.model';

export class PieceJointe implements Deserializable {

  public id?: number;
  public filename?: string;
  public litige?: Litige;
  public contestation?: Contestation;
  public type_piece_jointe?: TypePieceJointe;
  public detail_piece_jointe?: DetailPieceJointe;
  public compte_client?: CompteClient;
  public path?: string;
  public created_at?: Date| string;
  public updated_at?: Date| string;
  public libelle?: string;
  public format?: string;
  public downloadable?: boolean;
  public watchable?: boolean;
  public deletable?: boolean;
  public pending?: boolean;
  public is_validated?: boolean;
  public hidden?: boolean;


  constructor() {
    this.downloadable = true;
    this.watchable = true;
    this.deletable = true;
  }

  deserialize(input: any): this {

    Object.assign(this, input);
    this.litige = (input.litige) ? new Litige().deserialize(input.litige) : null;
    this.type_piece_jointe = (input.type_piece_jointe) ? new TypePieceJointe().deserialize(input.type_piece_jointe) : null;
    this.detail_piece_jointe = (input.detail_piece_jointe) ? new TypePieceJointe().deserialize(input.detail_piece_jointe) : null;
    this.compte_client = (input.compte_client) ? new CompteClient().deserialize(input.compte_client) : null;
    return this;
  }

}
