import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Litige} from '../../../../shared/models/api/litige.model';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../../animations';
import {ModalImputationComponent} from '../../../imputation/modal/modal-imputation/modal-imputation.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalRetrocessionComponent} from '../../../retrocession/modal/modal-retrocession/modal-retrocession.component';
import { MatPaginator } from '@angular/material/paginator';
import {View} from '../../../../shared/models/view.model';
import {AppEvent, EventQueueService} from '../../../../shared/services/event-queue.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-table-litige',
  templateUrl: './table-litige.component.html',
  styleUrls: ['./table-litige.component.scss'],
  animations: [tableAnimation],
})
export class TableLitigeComponent implements OnInit, OnChanges {

  expandedElement: Litige | null;

  @Input() data: Litige[];
  @Input() noRecordText = 'Aucun litige';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = null;
  @Input() hiddenColumns: string[] = [];
  @Input() sortingAPI = true;
  @Input() length: number;
  @Input() pageSize = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() showPagination = false;
  @Input() showInfosBtn = false;
  @Input() displayedColumns: string[] = [
    'id',
    'numeroAvis',
    'type',
    'client',
    'referenceComplementaire',
    'numeroCommande',
    'destinataireNom',
    'plateforme',
    'flux',
    'date',
    'traitePar',
    'statut',
    'responsables',
    'retroceder'
  ];

  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() submited: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelectContestation: EventEmitter<{id, index}> = new EventEmitter<{id, index}>();
  @Output() onSelectValidation: EventEmitter<{id}> = new EventEmitter<{id}>();
  @Output() linesToDo: EventEmitter<number> = new EventEmitter<number>();

  public innerWidth: number;
  public view: View;
  public dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public nbRetrocessionsToProcess = 0;

  private eventSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private eventQueue: EventQueueService
  ) {
  }

  ngOnInit() {
    this.eventSubscription = this.eventQueue.on(AppEvent.EventType.START_RETROCESSIONS).subscribe(event => {
      this.doRetrocessions();
    });

    this.dataSource = new MatTableDataSource<Litige>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        const index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.paginator.firstPage();
      this.dataSource.sort = this.sort;
      this.nbRetrocessionsToProcess = 0;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.paginator = this.paginator;
        this.paginator.firstPage();
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    if (this.sortingAPI === true){
      this.onSorted.emit(sort);
    }else {
      const data = this.data.slice();
      if (!sort.active || sort.direction === '') {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        return;
      }
      this.dataSource = new MatTableDataSource(data.sort(
        (a, b) => {
          const isAsc = sort.direction === 'asc';
          if (sort.active in a) {
            return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
          }
          return 0;
        }
        ));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }


  openModalModifResponsabilite(litige: Litige) {

    const modal = this.modalService.open(ModalImputationComponent, {
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });

    modal.componentInstance.litigeId = litige.id;
    modal.result.then(value => {
      if (value === true) {
        this.submited.emit(value);
      }
    });
  }

  openModalRetocessionResponsabilite(litige: Litige) {

    const modal = this.modalService.open(ModalRetrocessionComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modal.componentInstance.litigeId = litige.id;
    modal.componentInstance.numeroAvis = litige.numero_avis;
    modal.componentInstance.nomDO = litige.client.libelle;
    modal.result.then(value => {
      if (value === true) {
        this.submited.emit(value);
      }
    });
  }

  addRemoveAll() {
    const select = !this.data[0].added;
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].added = select;
    }
    this.linesToDo.emit(select ? this.data.length : 0);
    console.log('addRemoveAll', this.data.filter(d => d.added === true).length);
  }

  addLine(litige: Litige) {
    litige.added = true;
    this.linesToDo.emit(++this.nbRetrocessionsToProcess);
  }

  removeLine(litige: Litige) {
    litige.added = false;
    this.linesToDo.emit(--this.nbRetrocessionsToProcess);
  }

  doRetrocessions() {
    this.nextRetrocession(this.data.filter(d => d.added === true), 0);
  }

  nextRetrocession(retros, index: number) {
    if (retros.length === 0){
      return;
    }
    const modal = this.modalService.open(ModalRetrocessionComponent, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modal.componentInstance.litigeId = retros[index].id;
    modal.componentInstance.numeroAvis = retros[index].numero_avis;
    modal.componentInstance.nomDO = retros[index].client.libelle;
    modal.result.then(value => {
      if (index === retros.length - 1){
        this.submited.emit(value);
      }
      else {
        this.nextRetrocession(retros, ++index);
      }
    });
  }

  formatListResponsable(responsables){
    let libelle = '';
    if (!responsables || !Array.isArray(responsables)){
      return libelle;
    }
    libelle = '<ul class="list-group">';
    responsables.forEach(resp => {
      libelle += '<li class=\'list-group-item\'>' + resp + '</li>';
    });
    libelle += '</ul>';
    return libelle;
  }
}
