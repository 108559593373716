import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {Article} from '../../../../shared/models/api/article.model';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {ArticleService} from "../../../../shared/services/api/article.service";
import {Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-table-article',
  templateUrl: './table-article.component.html',
  styleUrls: ['./table-article.component.scss']
})
export class TableArticleComponent implements OnInit, OnChanges {

  @Input() data: Article[];
  @Input() displayedColumns: string[] = ['reference', 'libelle', 'nb_colis', 'volume', 'poids', 'options'];
  @Input() noRecordText = 'Aucun article';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() showViewArticle: boolean = false;
  @Input() showDeleteArticle: boolean = false;
  @Input() showEditArticle: boolean = false;
  @Input() showAddArticle: boolean = false;

  @Output() selectShowArticle: EventEmitter<{id : number, index:number}> = new EventEmitter<{id : number, index:number}>();
  @Output() selectAddArticle: EventEmitter<void> = new EventEmitter();
  @Output() selectEditArticle: EventEmitter<{id : number, index:number}> = new EventEmitter<{id : number, index:number}>();

  public dataSorted: MatTableDataSource<Article>;
  private subscription: Subscription;

  constructor(private modalService: NgbModal,
              private articleSvc: ArticleService,
              private toastr: ToastrService) {

  }

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    this.dataSorted = new MatTableDataSource([]);

    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  getNbColis(){
    let colis = 0
    this.dataSorted.data.forEach(article => {
      colis += article.nb_colis;
    })
    return colis
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }


  selectAjoutArticle() {
    this.selectAddArticle.emit();
  }

  selectShowArticleId(id:number, index: number) {
    this.selectShowArticle.emit({id,index});
  };

  selectEditArticleId(id:number, index: number) {
    this.selectEditArticle.emit({id,index});
  };

  delete(articleId, index) {

    Swal.fire({
      title: 'Attention !',
      html: 'supprimer l\'article ' + this.dataSorted.data[index].libelle + '<br/><br/> Attention cette action ne supprime pas les articles dans la reverse.\n' +
        'Veuillez penser à modifier le colisage du module reverse svp.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui !'

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSorted.data[index].pending = true;

        //service pour supprimer le fichier
        this.subscription = this.articleSvc.delete(articleId).subscribe(
          () => {
            this.toastr.info('le fichier à été supprimé');
            this.dataSorted.data[index].pending = false;
            this.dataSorted.data.splice(index, 1);
            this.dataSorted = new MatTableDataSource(this.dataSorted.data);
          },
          () => {
            this.dataSorted.data[index].pending = false;
            this.toastr.error('l\'article ' +  this.dataSorted.data[index].libelle +' n\'à pas pu etre supprimé');
          },
        );
      }

    });
  }
}
