import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUtil} from '../../utils/http.util';

@Injectable({
  providedIn: 'root'
})
export class AdminService {


  urlRessourceAdmin = environment.url_api_litige + '/admin';


  constructor(
    private http: HttpClient) {
  }


  downloadFichierDesregles(): Observable<{ filename: string, data: any }> {
    return this.http
      .get(this.urlRessourceAdmin + '/download-regles',
        {
          responseType: 'blob',
          observe: 'response'
        }
      ).pipe(map(res => {
        return {
          filename: HttpUtil.extractFilenameInContentDisposition(res.headers.get('content-disposition')),
          data: res.body,
        };
      }));
  }

  downloadErrorLogImportExcel(): Observable<{ filename: string, data: any }> {
    return this.http
      .get(this.urlRessourceAdmin + '/log-errors-import',
        {
          responseType: 'blob',
          observe: 'response'
        }
      ).pipe(map(res => {
        if(!res.headers.get('content-disposition')){
          throw new Error("L'api ne renvoi pas l'entete 'content-disposition'.");
        }
        return  {
          filename: HttpUtil.extractFilenameInContentDisposition(res.headers.get('content-disposition')),
          data: res.body,
        };
      }));
  }

}
