<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="date" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">




    <ng-container matColumnDef="idPieceJointe">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">N° Facture</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.id}}" class="px-10">
            {{element?.id}}
        </td>
    </ng-container><ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">DO</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.piece_jointe?.compte_client?.libelle}}" class="px-10">
            {{element?.piece_jointe?.compte_client?.libelle}}
        </td>
    </ng-container>
    <ng-container matColumnDef="typeFacture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.type_facture?.libelle}}">
            {{element?.type_facture?.libelle}}
        </td>
    </ng-container>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            class="text-nowrap"
            ngbTooltip="{{element?.date | date :'dd-MM-yyyy'}}">
            {{element?.date | date :'dd-MM-yyyy'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="numeroInterne">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Numéro Interne</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.numero_interne}}">
            {{element?.numero_interne}}
        </td>
    </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.statut?.libelle}}">
      {{element?.statut?.libelle }}
    </td>
  </ng-container>
    <ng-container matColumnDef="montant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant total à affecter</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.montant | currency: 'EUR':'€'}}">
            {{element?.montant | currency: 'EUR':'€'}}
        </td>
    </ng-container>

  <ng-container matColumnDef="dateFinAttente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de fin</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.date_fin_attente | date :'dd-MM-yyyy'}}">
      {{element?.date_fin_attente | date :'dd-MM-yyyy'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="motifAttente">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Motif</th>
    <td mat-cell *matCellDef="let element"
        class="ellipsis"
        placement="top"
        container="body"
        ngbTooltip="{{element?.motif_attente}}">
      {{element?.motif_attente}}
    </td>
  </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
      <td mat-cell *matCellDef="let element; let i = dataIndex">
        <button ngbTooltip="Valider la facture"
                placement="top"
                container="body"
                class="btn btn-rounded btn-sm btn-info mr-1"
                (click)="editFacture(element.id)">
          <i class="fa fa-pencil-alt"></i>
        </button>

        <button *ngIf="!caseAffectations" [ladda]="element.pending" data-style="zoom-in"
                ngbTooltip="Supprimer la facture"
                placement="top"
                container="body"
                class="btn btn-rounded btn-sm btn-agediss mr-1"
                (click)="deleteFacture(element.id, i)">
          <i class="fa fa-trash"></i>
        </button>

        <button *ngIf="temporaireBtn"
                ngbTooltip="Mise en attente"
                placement="top"
                container="body"
                class="btn btn-rounded btn-sm btn-warning"
                (click)="factureTemporaire(element.id, i)">
          <i class="fa fa-hourglass-start"></i>
        </button>
      </td>
    </ng-container>
  <ng-container matColumnDef="modifier">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Modifier montant</th>
    <td class="text-center" mat-cell *matCellDef="let element;">
        <button class="btn btn-sm btn-info text-white" (click)="actionModal(element)">
          Affecter
        </button>
    </td>
  </ng-container>
    <ng-container matColumnDef="affecter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td class="text-center" mat-cell *matCellDef="let element;">
            <div *ngIf="element?.has_litige">
                <button class="btn btn-sm btn-info text-white" (click)="actionModal(element)">
                    Affecter
                </button>
            </div>
            <div *ngIf="!element?.has_litige">
                <button class="btn btn-grey text-white" disabled>
                    Pas de litige
                </button>
            </div>
    </ng-container>
    <ng-container matColumnDef="valider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td class="text-center" mat-cell *matCellDef="let element" (click)="actionModal(element)">
            <a class="btn btn-info text-white">
                Valider
            </a>
        </td>
    </ng-container>
    <ng-container matColumnDef="noData">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
    </ng-container>
    <ng-container matColumnDef="loading">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

    </ng-container>

    <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
        class="table-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-footer-row *matFooterRowDef="['noData']"
        [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
    <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>
