<div class="row m-0">
  <div class="col-12 px-4 py-2">
    <div class="input-group">
      <div class="input-group-prepend "><span class="input-group-text input-group-border-right">Rechercher</span></div>
      <input matInput (keyup)="applyFilter($event.target.value)" class="pl-2 form-control form-control-filter"
             placeholder="numéro de commande, client (DO), plateforme, n°litige ...">
    </div>
  </div>
</div>

<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped table-bordered mat-elevation-z8 text-center">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell class="align-middle f-s-15" *matHeaderCellDef style="width: 100px;"> Date</th>
    <td mat-cell class="text-left align-middle" *matCellDef="let element">
      <div [style.color]="element.viewed ? '#6c757d' : '#343a40'">
        <div>{{element.created_at | date : 'dd MMM'}}</div>
        <div>{{element.created_at | date : 'H:mm'}}</div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="content">
    <th mat-header-cell class="align-middle f-s-15" *matHeaderCellDef> Notification</th>
    <td mat-cell *matCellDef="let element" class="text-left" [ngClass]="{'check-notif': element.viewed }"
        placement="top"
        container="body"
        ngbTooltip="{{element.numero_avis}}">
      <div>
        <span *ngIf="!element.adresse_url" [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.fr}}</span>
        <a class="text-decoration-none" *ngIf="element.adresse_url" [routerLink]="element.adresse_url"
           [style.color]="element.viewed ? '#6c757d' : '#343a40'">{{element.libelles.fr}}</a>
      </div>
      <div class="label {{element.emetteur_context | contextPipe}}"
           [style.background]="element.viewed ? '#fa908e' : '#dc3545'"
           *ngIf="element.emetteur_context == 1">
        par {{element.emetteur_libelle}}
      </div>
      <div class="label {{element.emetteur_context | contextPipe}}"
           [style.background]="element.viewed ? '#75c376' : '#28a745'"
           *ngIf="element.emetteur_context == 3">
        par le {{element.emetteur_libelle}}
      </div>
      <div class="label {{element.emetteur_context | contextPipe}}"
           [style.background]="element.viewed ? '#F9C375' : '#ffc107'"
           *ngIf="element.emetteur_context == 4">
        par {{element.emetteur_libelle}}
      </div>
      <div class="label {{element.emetteur_context | contextPipe}}"
           [style.background]="element.viewed ? '#59a9ff' : '#007bff'" *ngIf="element.emetteur_context == 2">
        par la {{element.emetteur_libelle}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th class="text-center align-middle" mat-header-cell *matHeaderCellDef="let element" style="width: 100px;">
      <div *ngIf="!showBtn && notifLength > 0">
        <button class="btn btn-rounded btn-sm bg-transparent text-grey mr-1"
                (click)="onCheckAllNotif(element)"><i class="fa fa-check-double"></i></button>
      </div>
      <div *ngIf="showBtn && notifLength > 0">
        <button class="btn btn-rounded btn-sm btn-green text-white mr-1"
                (click)="onCheckAllNotif(element)"><i class="fa fa-check-double"></i></button>
        <button [ladda]="loadingBtn" data-style="zoom-in" class="btn btn-rounded btn-sm btn-agediss mr-1"
                (click)="onDeleteAllNotif(element)"><i
          class="fa fa-trash"></i></button>
      </div>
    </th>
    <td class="text-center align-middle cursor-pointer" mat-cell *matCellDef="let element; let i = dataIndex">
      <button class="btn btn-rounded btn-sm mr-1"
              [style.background]="element.viewed ? 'green' : 'transparent'"
              [style.color]="element.viewed ? 'white' : 'grey'"
              (click)="selectCheckNotifId(element.id,i)"><i class="fa fa-check"></i></button>
      <button *ngIf="element.viewed" [ladda]="element.pending" data-style="zoom-in"
              ngbTooltip="Supprimer le fichier."
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1" (click)="selectDeleteNotifId(element.id,i)"><i
        class="fa fa-trash"></i></button>
    </td>
  </ng-container>


  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>
