import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  /**
   * On check si la le valeur existe dans le localstorage ou si il est vide
   * @param filterForm
   * @param name
   * @param field
   * @param typeInteger
   */
  onCheckValueLocalStorage(filterForm, name: string, field: string, typeInteger : boolean = true) {
    if (!localStorage.getItem(name) || localStorage.getItem(name) == '') {
      localStorage.removeItem(name);
    } else {
      if(typeInteger == false){
        filterForm.get(field).patchValue(localStorage.getItem(name));
      }else{
        filterForm.get(field).patchValue(+localStorage.getItem(name));
      }
    }
  }

  /**
   * Statut GRC
   * @param filterForm
   * @param value1
   * @param value2
   */
  onSelectStatutDefault(filterForm, value1: string, value2: string) {
    filterForm.get('statut').patchValue([value1, value2]);
  }

  /**
   * On récupère la valeur dans le localstorage et on créé un tableau à l'aide de la function split() pour la recherche multiple
   */
  onGetMultipleValue(filterForm, name: string, field: string, isInteger: boolean = true) {
    if (!localStorage.getItem(name) || localStorage.getItem(name) == '') {
      localStorage.removeItem(name);
    } else {
      const l = localStorage.getItem(name);
      if (!!l) {
        const ls = l.split(',');
        if (!!ls) {
          if (isInteger) {
            const integers = [];
            for (const item of ls) {
              integers.push(parseInt(item, 10));
            }
            filterForm.get(field).patchValue(integers);
          } else {
            filterForm.get(field).patchValue(ls);
          }
        }
      }
    }
  }

  /**
   * On enregistre les info du filter dans le localstorage
   * @param filterForm
   * @param name
   * @param field
   */
  onSaveInLocalstorage(filterForm, name, field) {
    if (filterForm.get(field).value !== null) {
      localStorage.setItem(name, filterForm.get(field).value);
    } else {
      localStorage.removeItem(name);
    }
  }

  /**
   * on récupère la date dans le localstorage.
   * si elle est null, on envoie la date du jour.
   * @param filterForm
   * @param name
   * @param field
   */
  getDateLocalstorage(filterForm, name, field) {
    if (filterForm.get(field).value !== null) {
      localStorage.setItem(name, filterForm.get(field).value);
    } else {
      localStorage.setItem(name, new Date().toString());
    }
  }

  /**
   *  On convertit la date enregistrée dans le localstorage pour l'utiliser dans les paramètres de l'url
   */
  convertDateLocalstorageForUrl(filterForm, name, field) {
    let date = localStorage.getItem(name);
    if (date === null) {
      return;
    }
    let newDate = new Date(date);
    let dateFormat = newDate.toISOString();
    filterForm.get(field).patchValue(dateFormat);
  }

  /**
   * on convertit la date enrgistrée dans le localStorage, pour l'aficher dans l'input
   * @param date
   */
  convertDateLocalstorageForShowInput(date: string) {
    const d = new Date(date);
    return <NgbDateStruct> {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
  }

  /**
   *
   * @param date
   * @param days
   */
  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  intervalDate(minDateValue, maxDateValue){
    let minDate = new Date(minDateValue);
    let maxDate = new Date(maxDateValue);
    return Math.floor((maxDate.getTime() - minDate.getTime()) / 1000 / 60 / 60 / 24);
  }

}
