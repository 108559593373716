<div class="modal-header text-center">
  <h4 class="modal-title w-100">Edition affectation de la facture</h4>
</div>
<div *ngIf="loading" style="height: 70vh" class="row col-12">
  <span class="spinner"></span>
</div>

<form class="" *ngIf="!loading" [formGroup]="factureForm">
  <div class="modal-body" style="height: 70vh">
    <div class="row" style="max-width: 100%">
      <div class="col-md-4">
        <app-file-preview [pieceJointe]="facture.piece_jointe" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile"></app-file-preview>
      </div>
      <div class="col-md-8">
        <div class="row ligne-search">
          <div class="col-md-7">
            <div *ngIf="disableSearch"> La facture n'a pas encore été imputée en compta merci d'attendre.</div>
            <mat-form-field *ngIf="!disableSearch" class="example-full-width">
              <input matInput placeholder="rechercher (min 4 chiffres)" aria-label="State" [matAutocomplete]="auto"
                     [formControl]="searchLitige">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Recherche en cours ...</mat-option>
                <ng-container *ngIf="!isLoading && filteredItems.length == 0">
                  <mat-option [value]="null">Aucun résultat</mat-option>
                </ng-container>
                <ng-container *ngIf="!isLoading && filteredItems.length > 0">
                  <mat-option *ngFor="let item of filteredItems" [value]="item.numero_avis" [disabled]="item.affected">
                    <div *ngIf="item.affected" >
                      <mat-checkbox [checked]="true" disabled="true">
                                   <span ngbTooltip="Litige déja affecté à la facture"
                                         placement="bottom"
                                         container="body"
                                   ><div>
                            ID : {{item.id}} [ {{item.type_litige.libelle}} ]
                            BL {{item.numero_avis}} - {{item.destinataire_nom }} |
                            montant: {{item.montant_valide | currency :'EUR': '€'}}
                          </div></span>
                      </mat-checkbox>
                    </div>
                    <div *ngIf="!item.affected" (click)="optionClicked($event, item);">
                      <mat-checkbox [checked]="item.selected" (change)="toggleSelection(item)"
                                    (click)="$event.stopPropagation()">
                                   <span><div>
                            ID : {{item.id}} [ {{item.type_litige.libelle}} ]
                            BL {{item.numero_avis}} - {{item.destinataire_nom }} |
                            montant: {{item.montant_valide | currency :'EUR': '€'}}
                          </div></span>
                      </mat-checkbox>
                    </div>
                  </mat-option>
                </ng-container>

              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-4" style="overflow-y: auto">
            <mat-chip-list
              class="example-chip">
              <mat-chip
                class="example-box"
                *ngFor="let item of selectedItems">
                {{item.numero_avis}}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-1">
            <button class="m-t-10 btn btn-default"
                    placement="top"
                    container="body"
                    *ngIf="!disableSearch"
                    [autoClose]="false" triggers="manual"
                    #t="ngbTooltip"
                    ngbTooltip="Cliquer ici pour ajouter les BL"
                    [ngClass]="{'attention':selectedItems.length > 0}" (click)="addBl()"><i class="fa fa-plus"></i></button>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-md-12">
            <i class="fa fa-exclamation-triangle text-orange-darker"></i> Veuillez lire attentivement le process pour la
            ré-affectation de la facture <a target="_blank" href="#"><i class="fa fa-file-alt"></i></a>
          </div>
        </div>
        <div *ngIf="showTable === true">
          <div class="row mb-1">
            <div class="col-md-3">
              <!--{{facture.client.libelle}}-->
              <label> </label>
            </div>
            <div class="col-md-2">
              <label>Montant :</label>
            </div>
            <div class="col-md-2">
              <label>Validé</label>
            </div>
            <div class="col-md-2">
              <label>Facturé</label>
            </div>
            <div class="col-md-2">
              <label>Ecart</label>
            </div>
          </div>
          <div formArrayName="litiges" style="overflow: scroll;overflow-x: hidden;;height: 200px">
            <div *ngFor="let element of factureForm.get('litiges').controls; let indexLitige = index;">
              <div [formGroupName]="indexLitige">
                <div class="row mb-1">
                  <div class="col-md-5">
                    <!--{{facture.client.libelle}}-->
                    <label style="font-size: 12px">BL {{element.get('numeroAvis').value}}
                      - {{element.get('destinataireNom').value}}
                      - {{element.get('client').value}}</label>
                  </div>
                  <div class="col-md-2">
                    <input type="number" lang="en-150" class="form-control" [readOnly]="true"
                           formControlName="montantValider"
                           [step]="0.01"/>
                  </div>
                  <div class="col-md-2">
                    <input type="number" lang="en-150" class="form-control"
                           (change)="sommeMontantFacture(indexLitige)"
                           formControlName="montantAffecter"
                           [readOnly]="element.get('nonTraite').value"
                           [step]="0.01"
                           placement="top"
                           container="body"
                           [disableTooltip]="!element.get('nonTraite').value"
                           ngbTooltip="L'affectation n'a pas encore été imputée en compta merci d'attendre ou transférer la avec le bouton sur la droite."
                           [ngClass]="{'is-invalid' : element.get('montantAffecter').value == 0 || element.get('nonTraite').value}"/>
                    <div *ngIf="element.get('nonTraite').value" class="invalid-tooltip" style="padding: unset">
                    </div>

                  </div>
                  <div class="col-md-2">
                    <input type="number" lang="en-150" [readOnly]="true" class="form-control"
                           formControlName="ecart"/>

                  </div>
                  <div class="col-md-1">
                    <button *ngIf="element.get('newLine').value" class="btn btn-dark" (click)="removeBL(indexLitige)"><i
                      class="fa fa-minus"></i>
                    </button>
                    <button *ngIf="!element.get('newLine').value" class="btn btn-dark" (click)="openModalReAffectationFacture(indexLitige)"
                            container="body"
                            ngbTooltip="Transférer l'imputation à un autre Litige"><i
                      class="fa fa-exchange-alt"></i>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <!--</div>-->
          <!--FIN Boucle *NgFor sur les litiges affecter a cette facture-->

          <div class="row mb-1 mt-3">
            <div class="col-md-2">
            </div>
            <div class="col-md-3">
              <label class="col-md-8">Total </label>
            </div>
            <div class="col-md-2 ">
              <input type="number" lang="en-150" class="form-control" value="{{totalMontantValider }}"
                     readonly
                     [step]="0.01"/>
            </div>
            <div class="col-md-2 ">
              <input type="number" lang="en-150" class="form-control" value="{{totalMontantFacture}}"
                     [step]="0.01"/>
            </div>

          </div>
          <div class="row mb-3 mt-3">
            <div class="col-md-3">
              <label>Montant facture </label>
            </div>
            <div class="col-md-2 text-center">
              <input lang="en-150" class="form-control" value="{{facture.montant | currency: 'EUR':'€'}}"
                     readonly/>
            </div>
            <div class="col-md-1 text-center mt-1">
              <i class="fas fa-minus fa-xs"></i>
            </div>
            <div class="col-md-2 text-center">
              <input lang="en-150" class="form-control" value="{{totalMontantFacture  | currency: 'EUR':'€'}}"
                     readonly/>
            </div>

            <div class="col-md-1 text-center mt-1">
              <i class="fas fa-equals fa-xs"></i>
            </div>
            <div class="col-md-3 ">
              <input lang="en-150" class="form-control"
                     readonly
                     value="{{totalEcart | currency: 'EUR':'€'}}"
                     [ngClass]="{'is-invalid' : BalanceValue === false}"/>
              <div *ngIf="BalanceValue === false"
                   class="invalid-tooltip" style="padding: unset">
                Vous devez affecter
                l'intégralité de la facture.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer row">

    <div class="offset-5 col-5">
      <div class="progress rounded-corner">
        <div class="progress-bar bg-red-darker" [style.width]="width2"></div>
      </div>
    </div>
    <div class="col-2 text-right">
      <button type="button" class="btn bg-black-lighter text-white m-r-10" (click)="close()">Fermer</button>
      <button [ladda]="loadingBtn" type="button" class="btn btn-agediss text-white " data-style="zoom-in"
              [disabled]="BalanceValue == false"
              (click)="submit()">Affecter
      </button>
    </div>
  </div>
</form>
