import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }


  /**
   * Cette methode prend un date string | Date en parametre et retourne
   * la date sous le format yyyy-MM-dd par default ou celon celui passé
   * en parametre.
   * @param date string
   */
  static convertStringToDateStruct(date: string) {
    const d = new Date(date);
    return <NgbDateStruct> {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
  }

  /**
   * Cette methode prend un date string | Date en parametre et retourne
   * la date sous le format yyyy-MM-dd par default ou celon celui passé
   * en parametre.
   * @param date  Date
   */
  static convertDateToDateStruct(date: any) {
    return <NgbDateStruct> {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }


}
