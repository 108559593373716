import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {FormGroup, NgModel} from "@angular/forms";

@Component({
  selector: 'app-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss']
})
export class RangeDatePickerComponent implements OnInit {

  @Input() filterForm: FormGroup;
  @Input() datemin: NgbDateStruct;
  @Input() datemax: NgbDateStruct;

  @ViewChild("d") private _input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  public hoveredDate: NgbDateStruct;
  public fromDate: any;
  public toDate: any;
  public model: NgbDateStruct;
  public now: any;
  public date: any;
  public customDateShow: any;

  constructor(private element: ElementRef,
              private renderer: Renderer2,
              private _parserFormatter: NgbDateParserFormatter,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (!(this.filterForm instanceof FormGroup)) {
      throw new Error('Vous devez passer un formGroup en parametre du composant RangeDatePickerComponent');
    }


  }

  ngAfterViewInit() {

    let parsed = '';


    this.toDate = this.datemax;this.fromDate = this.datemin;
    if (this.datemin && this.datemax) {
      parsed += this._parserFormatter.format(this.fromDate);
      parsed += ' - ' + this._parserFormatter.format(this.toDate);
      this.customDateShow = parsed;
    }
  }


  openDatePicker() {
    if (!this.fromDate) {
      this.fromDate = new Date()
    }
    if (this.fromDate && !this.toDate && this._input.isOpen()) {
      this._input.toggle();
      return;
    }
    this.reset();
    this.filterForm.controls['minDate'].setValue(null);
    this.filterForm.controls['maxDate'].setValue(null);
    this._input.toggle();
  }

  onDateSelection(date: NgbDateStruct) {

    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && this.after(date, this.fromDate)) {
      this.toDate = date;
      this._input.close();
    } else {
      this.fromDate = date;
      this.toDate = null;
    }

    if (this.fromDate) {
      parsed += this._parserFormatter.format(this.fromDate);
      this.filterForm.controls['minDate'].setValue(new Date(Date.UTC(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)));
    }

    if (this.toDate) {
      parsed += ' - ' + this._parserFormatter.format(this.toDate);
      this.filterForm.controls['maxDate'].setValue(new Date(Date.UTC(this.toDate.year, this.toDate.month - 1, this.toDate.day)));
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);

  }

  onChangeDate(inputDate) {
    if (!(typeof inputDate === 'object')) {
      this.fromDate = null;
      this.toDate = null;
      this.filterForm.controls['minDate'].setValue(null);
      this.filterForm.controls['maxDate'].setValue(null);
    }
  }


  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', null);
  }

  public isHovered =
    date => this.fromDate && !this.toDate && this.hoveredDate && this.after(date, this.fromDate) && this.before(date, this.hoveredDate);
  public isInside = date => this.after(date, this.fromDate) && this.before(date, this.toDate);
  public isFrom = date => this.equals(date, this.fromDate);
  public isTo = date => this.equals(date, this.toDate);
  public equals = (one: NgbDateStruct, two: NgbDateStruct) =>
    one && two && two.year === one.year && two.month === one.month && two.day === one.day;
  public before = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day < two.day : one.month < two.month : one.year < two.year;
  public after = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day > two.day : one.month > two.month : one.year > two.year;

}
