const pageMenus = [
  {
    icon: 'fa fa-th-large',
    title: 'Tableau de bord',
    url: '/tableau-de-bord'
  }, {
    icon: 'fa fa-search',
    title: 'Rechercher',
    url: '/recherche'
  }, {
    icon: 'fa fa-balance-scale',
    title: 'Encours des litiges',
    url: '/litiges'
  }, {
    icon: 'fa fa-frown',
    title: 'Encours des contestations',
    url: '/contestations'
  }, {
    icon: 'fa fa-file-alt',
    title: 'Validation des justificatifs',
    url: '/justificatifs',
    restrictRole: 'JUSTIFICATIF_VALIDATE',
  },
  {
    icon: 'fa fa-calculator',
    title: 'Comptabilité',
    restrictRole: 'COMPTA_MENU',
    caret:true,
    submenu: [
      // {
      //   icon: 'fa fa-calculator',
      //   title: 'Imputation en attentes',
      //   url: '/imputations'
      // },
      {
        icon: 'fa fa-calculator',
        title: 'Imputation TdB',
        url: '/imputations/tableau-de-bord'
      },
      {
        icon: 'fa fa-calculator',
        title: 'ODA TdB',
        url: '/oda',
        restrictRole: 'ODA_MENU',
      },
      {
        title: 'Rétrocessions',
        url: '/retrocessions'
      },
      {
        icon: 'fa fa-link',
        title:
          'Factures à affecter',
        url: '/factures-affecter',
        restrictRole: 'FACTURE_ATTENTE_VALIDATE'
      },
      {
        icon: 'fa fa-pause',
        title:
          'Factures à saisir',
        url: '/factures-attente',
        restrictRole: 'FACTURE_ATTENTE_SHOW'
      },
      {
        icon: 'fa fa-file-alt',
        title: 'Modification des affectations',
        url: '/affectations',
        restrictRole: 'FACTURE_REAFFECTE',
      },
      {
        icon: 'fa fa-file-alt',
        title: 'Visualisation factures',
        url: '/factures',
        restrictRole: 'FACTURE_BROWSE',
      },
    ]
  },
  {
    icon: 'fa fa-users',
    title: 'Annuaire',
    url: '/annuaire',
    restrictRole: 'ANNUAIRE_EDIT',
  },
  {
    icon: 'fa fa-address-card',
    title: 'Portefeuilles',
    url: '/portefeuilles',
    restrictRole: 'PORTEFEUILLE_EDIT',
  }
  // {
  //   icon: 'fa fa-cogs',
  //   title: 'Administration',
  //   url: '/administration'
  // }

];

export default pageMenus;
