<div class="modal-header">
  <h4 class="modal-title w-100 {{modalTitleClass}}">Ouvrir une Conversation</h4>
</div>
<div class="modal-body">
  <app-add-conversation (canceled)="close()"
                        (submitted)="submit($event)"
                        [conversationFor]="view.context == 2 ? 'PF' : view.context == 3 ? 'DO' : null"
                        [contextId]="view.context == 2 ? view.contextId : null"
                        [idLitige]="litigeId" [idContestation]="contestationId">
  </app-add-conversation>
</div>
