import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';

export class AppEvent<T> {
  static readonly EventType = {
    START_RETROCESSIONS: 'START_RETROCESSIONS',
    RELOAD_ANNUAIRE: 'RELOAD_ANNUAIRE',
    RELOAD_PORTEFEUILLES: 'RELOAD_PORTEFEUILLES',
  };

  constructor(
    public type: string,
    public payload: T
  ) {}
}


@Injectable({providedIn: 'root'})
export class EventQueueService {

  private eventBroker = new Subject<AppEvent<any>>();

  on(eventType: string): Observable<AppEvent<any>> {
    return this.eventBroker.pipe(filter(event => event.type === eventType));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBroker.next(event);
  }
}
