import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {PieceJointe} from '../../../../shared/models/api/piece-jointe.model';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {ContestationService} from '../../../../shared/services/api/contestation.service';
import {FileService} from '../../../../shared/services/file.service';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import Swal from 'sweetalert2';
import {Conversation} from "../../../../shared/models/api/conversation.model";

@Component({
  selector: 'app-table-file',
  templateUrl: './table-file.component.html',
  styleUrls: ['./table-file.component.scss']
})
export class TableFileComponent implements OnInit, OnChanges {


  public dataSorted: MatTableDataSource<PieceJointe>;
  @Input() data: PieceJointe[];
  @Input() displayedColumns: string[] = [
    'format',
    'libelle',
    'date',
    'typePieceJointe',
    'statut',
    'contestation',
    'actions',
  ];
  @Input() noRecordText = 'Aucune piece jointe';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() showAddDocumentBtn: boolean = false;
  @Input() showViewDocumentBtn: boolean = false;
  @Input() fileLitige: boolean = false;
  @Input() fileGed: boolean = false;
  @Input() showDeleteDocumentBtn: boolean = false;
  @Input() showEditDocumentBtn: boolean = false;
  @Input() showDownloadDocumentBtn: boolean = false;
  @Input() showValideJustifBtn: boolean = false;

  @Output() onAdd: EventEmitter<Conversation> = new EventEmitter();
  @Output() onSelectFile: EventEmitter<{ id: number, index: number, path: string, filename: string }> = new EventEmitter<{ id: number, index: number, path: string, filename: string }>();
  @Output() onEditFile: EventEmitter<{ id: number, index: number, path: string, filename: string }> = new EventEmitter<{ id: number, index: number, path: string, filename: string }>();
  @Output() onSelectJustif: EventEmitter<{ id: number, index: number, path: string, filename: string }> = new EventEmitter<{ id: number, index: number, path: string, filename: string }>();

  private filesSub: Subscription;


  constructor(private pieceJointeService: PieceJointeService,
              private litigeSvc: LitigeService,
              private contestationSvc: ContestationService,
              private fileSvc: FileService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
  }


  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  download(fileId) {
    this.pieceJointeService.downloadFile(fileId).subscribe(res => {
        FileService.downloadDataToFile(res);
      },
      FileService.handleDownloadError
    );
  }

  selectFile(file: PieceJointe, index: number) {
    return this.onSelectFile.emit({
      id: file.id,
      index: index,
      path: file.path,
      filename: file.libelle
    });
  }

  editFile(file: PieceJointe, index: number) {
    return this.onEditFile.emit({
      id: file.id,
      index: index,
      path: file.path,
      filename: file.libelle
    });
  }

  selectJustificatif(file: PieceJointe, index: number) {
    return this.onSelectJustif.emit({
      id: file.id,
      index: index,
      path: file.path,
      filename: file.libelle
    });
  }

  delete(fileId, index) {

    Swal.fire({
      title: 'Attention !',
      text: 'Etes-vous sur de vouloir supprimer le fichier ' + this.dataSorted.data[index].libelle,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui !'

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSorted.data[index].pending = true;

        //service pour supprimer le fichier
        this.filesSub = this.pieceJointeService.delete(fileId).subscribe(
          () => {
            this.toastr.info('le fichier à été supprimé');
            this.dataSorted.data[index].pending = false;
            this.dataSorted.data.splice(index, 1);
            this.dataSorted = new MatTableDataSource(this.dataSorted.data);
          },
          () => {
            this.dataSorted.data[index].pending = false;
            this.toastr.error('le fichier n\'à pas pu etre supprimé');
          },
        );
      }

    });
  }

}
