<!-- begin Form -->
<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
    <!-- begin Form first row -->
    <div class="row">
        <div *ngIf="!hiddenInput.includes('plateforme')" class="col-md-4 m-b-15">
            <div class="input-group-prepend"><span class="input-group-text agediss">Plateforme</span>
                <ng-select class="ng-select filter"
                           [items]="plateformes"
                           [selectOnTab]="true"
                           bindValue="id"
                           bindLabel="libelle"
                           [clearable]="false"
                           labelForId="Plateforme"
                           placeholder="Choisir"
                           formControlName="plateforme">
                </ng-select>
            </div>
        </div>
        <div *ngIf="!hiddenInput.includes('client')" class="col-md-4  m-b-15">
            <div class="input-group-prepend"><span class="input-group-text agediss">Client</span>
                <ng-select class="ng-select filter"
                           [items]="clients"
                           [selectOnTab]="true"
                           bindValue="id"
                           bindLabel="libelle"
                           [clearable]="false"
                           labelForId="client"
                           placeholder="Choisir"
                           formControlName="client">
                </ng-select>
            </div>
        </div>
        <div *ngIf="!hiddenInput.includes('statut')" class="col-md-4  m-b-15">
            <div class="input-group-prepend"><span class="input-group-text agediss">Statut</span>
                <ng-select class="ng-select filter"
                           [items]="statuts"
                           [selectOnTab]="true"
                           bindValue="id"
                           bindLabel="libelle"
                           [clearable]="false"
                           labelForId="statut"
                           placeholder="Choisir"
                           formControlName="statut">
                </ng-select>
            </div>
        </div>
        <div *ngIf="!hiddenInput.includes('date')" class="col-md-4  m-b-15">
            <div class="input-group-append">
                <input class="form-control" name="dp"
                       (click)="d.toggle();$event.stopPropagation();"
                       [autoClose]=true ngbDatepicker #d="ngbDatepicker"
                       placeholder="dd/MM/yyyy"
                       formControlName="createdAt">

                <button class="btn btn-outline-danger calendar fa fa-calendar"
                        (click)="d.toggle();$event.stopPropagation();"
                        type="button"></button>
            </div>
        </div>
        <div *ngIf="!hiddenInput.includes('traitePar') " class="col-md-4 m-b-15">
            <div class="input-group-prepend"><span class="input-group-text agediss">Assigné à</span>
                <ng-select class="ng-select filter"
                           [items]="users"
                           [selectOnTab]="true"
                           bindValue="id"
                           bindLabel="lastname"
                           [clearable]="false"
                           labelForId="user"
                           placeholder="Choisir"
                           formControlName="traitePar">
                </ng-select>
            </div>
        </div>
        <div *ngIf="!hiddenInput.includes('search')" class="col-12 ">
            <div class="input-group input-group">
                <div class="input-group-prepend"><span class="input-group-text agediss">Rechercher</span></div>
                <input class="form-control" placeholder="Recherche (Numéro de BL, commande, destinataire)"
                       formControlName="search"/>
            </div>
        </div>
    </div>
    <!-- end Form trhird row -->

    <!-- begin Form fourth row -->
    <div class="form-group row m-t-10">
        <div class="offset-5 col-2">
            <input class="btn btn-agediss btn-lg btn-block" type="submit" value="Filtrer">
        </div>
    </div>
    <!-- end Form fourth row -->


</form>
