import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {ImputationService} from '../../shared/services/api/imputation.service';
import {LitigeService} from '../../shared/services/api/litige.service';
import {Litige} from '../../shared/models/api/litige.model';
import {AppEvent, EventQueueService} from '../../shared/services/event-queue.service';

@Component({
  selector: 'retrocessions',
  templateUrl: './retrocessions.html'

})
export class RetrocessionsPage implements OnDestroy, OnInit {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public loadingTable = false;
  public litiges: Litige[];
  public linesToDo = 0;

  public displayedColumn = [
    'id',
    'numeroAvis',
    'numeroCommande',
    'destinataireNom',
    'client',
    'plateforme',
    'statut',
    'traitePar',
    'doline'
  ];
  public loadingNbLitige: boolean;
  public nbLitige = 0;

  constructor(
    private imputationSvc: ImputationService,
    private litigeSvc: LitigeService,
    private eventQueue: EventQueueService
  ) {
  }


  ngOnInit() {
  }


  ngOnDestroy(): void {
  }


  nbRetrocessionsToProcessChanges(value: number) {
    this.linesToDo = value;
  }


  retrocessionButtonClicked() {
    this.eventQueue.dispatch(new AppEvent(AppEvent.EventType.START_RETROCESSIONS, {}));
  }


  onSubmit(filter: FilterMultiCritereModel) {
    // récupère les infos du filtre
    this.filter = filter;
    this.loadData();
  }


  loadData() {
    this.loadingTable = true;
    this.loadingNbLitige = true;
    this.litigeSvc.getLitigeRetrocedable(this.filter).subscribe(litige => {
      this.litiges = litige;
      this.nbLitige = litige.length;
      this.loadingTable = false;
      this.loadingNbLitige = false;
      this.linesToDo = 0;
    });
  }
}

