import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {ActeurService} from '../../../../shared/services/api/acteur.service';
import {RetrocessionService} from '../../../../shared/services/api/retrocession.service';
import {Retrocession} from '../../../../shared/models/api/retrocession.model';

@Component({
  selector: 'app-modal-retrocession',
  templateUrl: './modal-retrocession.component.html',
  styleUrls: ['./modal-retrocession.component.scss']
})
export class ModalRetrocessionComponent implements OnInit, OnDestroy {


  @Input() litigeId: number;
  @Input() numeroAvis: string;
  @Input() nomDO: string;

  private subscriptions: Subscription[] = [];
  public loadingResponsableForm = false;
  private loadingSubmitBtn = false;
  public retrocessionForm: FormGroup;
  public retrocessions: Retrocession[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private toastSvc: ToastrService,
    private activeModal: NgbActiveModal,
    private litigesSvc: LitigeService,
    private acteurSvc: ActeurService,
    private retrocessionSvc: RetrocessionService,
  ) {
  }

  ngOnInit() {
    if (!this.litigeId) {
      throw new Error('Vous devez passer un id litige au composant ModalRetrocessionComponent');
    }
    this.loadData();
  }

  loadData() {

    const retrocessionFormArray = [];

    this.loadingResponsableForm = true;
    this.subscriptions.push(this.litigesSvc.getRetrocessionsAtraite(this.litigeId).subscribe(
      retrocessions => {
        // on formate chaques rétrocessions pour qu'elles puissent être misent dans le formArray
        retrocessions.forEach(retro => {
          retrocessionFormArray.push(new FormGroup({
            id: this.formBuilder.control(retro.id),
            discr: this.formBuilder.control(retro.responsabilite.responsable.discr),
            typeResponsable: this.formBuilder.control(retro.responsabilite.responsable.type_responsable.libelle),
            responsable: this.formBuilder.control(retro.responsabilite.responsable.libelle),
            montant: this.formBuilder.control(retro.montant),
            avoir: this.formBuilder.control(retro.avoir),
            numeroRetrocession: this.formBuilder.control(retro.numero_facture),
            date: this.formBuilder.control(retro.date_facture),
          }));
        });
        this.retrocessionForm = this.formBuilder.group({
          retrocessions: this.formBuilder.array(retrocessionFormArray),
        });
        this.loadingResponsableForm = false;
      })
    );

  }

  submitForm() {
    const formSubmitted = this.retrocessionForm.value;
    this.subscriptions.push(this.retrocessionSvc.updateRetrocession(formSubmitted).subscribe(
      retrocession => {
        this.toastSvc.success('retrocession mise à jour', 'Info', {progressBar: true});
        this.loadingSubmitBtn = false;
        this.activeModal.close(true);
      },
      error => {
        this.toastSvc.warning('erreur lors de la retrocession', 'Info', {progressBar: true});
        this.loadingSubmitBtn = false;
      }
    ));
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
