import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Facture} from '../../../../shared/models/api/facture.model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TypeFacture} from '../../../../shared/models/api/type-facture.model';
import {PieceJointe} from '../../../../shared/models/api/piece-jointe.model';
import {FactureService} from '../../../../shared/services/api/facture.service';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {PieceJointeGed} from '../../../../shared/models/api/piece-jointe-ged.model';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {ActeurService} from '../../../../shared/services/api/acteur.service';
import {Responsable} from '../../../../shared/models/api/responsable.model';
import {DateService} from '../../../../shared/services/date.service';
import {invalid} from "@angular/compiler/src/render3/view/util";

@Component({
  selector: 'app-modal-facture-temporaire',
  templateUrl: './modal-facture-temporaire.component.html',
  styleUrls: ['./modal-facture-temporaire.component.scss']
})
export class ModalFactureTemporaireComponent implements OnInit {

  @Input() pieceJointe: PieceJointe;
  @Input() piecesJointesGed: PieceJointeGed[];
  @Input() typeFacture: TypeFacture;
  @Input() disabled = false;
  @Input() factureId: number;
  @Input() index: number;
  @Output() submited: EventEmitter<boolean> = new EventEmitter();

  public motif = '';
  public endDate: string;
  public imputationDate: string;
  public imputationResponsable: string;

  public minDate;
  public maxDate;

  public facture: Facture = new Facture();
  public subscriptions: Subscription[] = [];
  public factureForm: FormGroup;
  public acteursInterne: Responsable[] = [];
  public loadingActeurs = false;
  public loadingFile = false;
  public loadingBtn = false;
  public formSubmit = false;
  public showImage: boolean;
  public urlPreview: string;
  public currentType: number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private PieceJointeSvc: PieceJointeService,
    public factureSvc: FactureService,
    private acteurSvc: ActeurService,
    private toastSvc: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadingActeurs = true;

    if (this.factureId) {
      this.factureSvc.getFacture(this.factureId).subscribe((facture) => {
        this.facture = facture;
        this.pieceJointe = this.facture.piece_jointe;
        this.showPieceJointe(this.pieceJointe.id);
        this.acteurSvc.getActeursInterne({discr: 'plateforme'}).subscribe((ai) => {
          this.acteursInterne = ai;
          this.loadingActeurs = false;
        });
      });
    } else {
      this.showPieceJointe(this.pieceJointe.id);
    }

    this.initForm();
  }

  initForm() {
    this.factureForm = new FormGroup({
      motif: new FormControl(this.motif),
      endDate: new FormControl(
        DateService.convertStringToDateStruct(this.endDate),
        [Validators.required]
      ),
      imputationDate: new FormControl(
        DateService.convertStringToDateStruct(this.imputationDate),
        [Validators.required]
      ),
      imputationResponsable: new FormControl(this.imputationResponsable, [Validators.required])
    });
  }

  searchActeur(term: string, item: Responsable) {
    term = term.toLocaleLowerCase();
    return item.libelle.toLocaleLowerCase().indexOf(term) > -1 || item.code.toLocaleLowerCase().indexOf(term) > -1;
  }

  close() {
    this.activeModal.close(false);
  }

  submit() {
    this.formSubmit = true;
    if (this.factureForm.invalid) {
      return;
    }

    this.loadingBtn = true;
    this.subscriptions.push(this.factureSvc.updateFactureTemporaire(
      this.facture,
      this.factureForm.controls.motif.value.trim(),
      this.factureForm.controls.endDate.value,
      this.factureForm.controls.imputationDate.value,
      this.factureForm.controls.imputationResponsable.value
    ).subscribe(
      (facture: any) => {
        this.toastSvc.success('Facture mise en attente', 'Info', {progressBar: true});
        this.loadingBtn = false;
        this.activeModal.close({facture, index: this.index});
      },
      message => {
        this.toastSvc.warning(message.error, 'Info', {progressBar: true});
        this.loadingBtn = false;
      }));
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if (this.pieceJointe.path.startsWith('../var/')) {
      const sub1 = this.PieceJointeSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe();
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.PieceJointeSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed;
          this.loadingFile = false;
          sub.unsubscribe();
        },
        () => {
          Swal.fire({title: 'Désolé', text: 'Une erreur est survenue', showCancelButton: false}).then(() => {
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }
  }

  get formEndDate() { return this.factureForm.get('endDate'); }
  get formImputationDate() { return this.factureForm.get('imputationDate'); }
  get formImputationResponsable() { return this.factureForm.get('imputationResponsable'); }
}
