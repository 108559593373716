import {Deserializable} from '../interfaces/deserializable.interface';
import {TypeResponsable} from './type-responsable.model';

export class Responsable implements Deserializable {

  public id: number;
  public libelle: string;
  public pourcent: string;
  public montant: string;
  public siret?: string;
  public discr?: string;
  public code ?: string;
  public created_at?: Date;
  public updated_at?: Date;
  public type_responsable ?: TypeResponsable;


  deserialize(input: any): this {
    Object.assign(this, input);
    this.type_responsable = (input.type_responsable) ? new TypeResponsable().deserialize(input.type_responsable) : null;
    return this;
  }

}
