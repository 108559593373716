import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Imputation} from "../../models/api/imputation.model";
import {Retrocession} from "../../models/api/retrocession.model";

@Injectable({
  providedIn: 'root'
})
export class  RetrocessionService {

  urlRessource = environment.url_api_litige+"/retrocessions";

  constructor(private http: HttpClient) {

  }

  updateRetrocession(retrocessions: Retrocession[]): Observable<boolean> {
    return this.http.put<boolean>(`${this.urlRessource}/update-all`, retrocessions)
      .pipe(map((observe) =>  {
        return true;
      }))
  }


}
