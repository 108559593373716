<!-- bopenModalAddConversationegin page-header -->
<h1 class="page-header">Facture en attente</h1>
<!-- end page-header -->

<!-- begin table "pieceJointes" -->
<div class="row">
  <div class="col-12">
    <panel title="Facture en attente" noButton="true" bodyClass="no-padding" class="text-center">
      <div class="col-12 text-center mb-2 mt-2">
        <button class="btn btn-agediss" *ngIf="view.hasDroit('PIECE-JOINTE_CREATE')" (click)="openModalUploader(modal_file_uploader)">Ajouter des documents
        </button>
      </div>
      <div style="height: 75vh" class="overflow-scroll">
        <app-table-facture-attente [loading]="loadingTable"
                                   [data]="pieceJointes"
                                   [showDeleteFactureBtn]="view.hasDroit('FACTURE_DELETE')"
                                   [showEditFactureBtn]="view.hasDroit('FACTURE_EDIT')"
                                   (selectShowFacture)="openModalAffectationFacture($event)"
        ></app-table-facture-attente>
      </div>
    </panel>
  </div>
  <!-- end processing table "pieceJointes" -->
</div>


<ng-template #modal_file_uploader let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Ajouter un document.</h4>
  </div>
  <div class="modal-body">
    <FileUploader (return)="modalUpload.close()"
                  [contextClient]="true"
                  (uploaded)="onUploaded()"></FileUploader>
  </div>
</ng-template>






