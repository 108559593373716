<!-- begin page-header -->
<div class="row mb-2">
  <div class="col-md-4"></div>
  <div class="col-md-4 text-center"><h1 class="page-header">Recherche multi-critères</h1></div>
  <div class="col-md-2"></div>
  <div class="col-md-2 text-right"><button *ngIf="view.hasDroit('LITIGE_CREATE')"  class="btn btn-green btn-sm " (click)="openModalCreateLitige()">Nouveau litige</button></div>
</div>
<!-- end page-header -->

<!-- begin Form -->
<div class="alert alert-light fade show">
  <app-filter-litige (submitFilter)="onSubmit($event)"  (onLoad)="onLoadFilter($event)" [pageName]="'multicritere'"></app-filter-litige>
</div>

<!-- begin table "A traiter" -->
<div class="row">
  <div class="col-sm-12">
    <panel noButton="true" bodyClass="no-padding" class="text-center">
      <div class="row" header>
        <div class="col-12"><h5 class="p-t-5 text-white"><i>Resultats : <span *ngIf="!loadingNbLitige" >{{nbLitige}}</span> <i *ngIf="loadingNbLitige" class="fa fa-xs fa-spinner"></i></i></h5></div>
      </div>
      <div class="p-0">
        <app-table-litige [showPagination]="true" [pageSize]="10" [pageSizeOptions]="limits" [showInfosBtn]="true" [hiddenColumns]="['retroceder']" [data]="litiges" [loading]="loading"
                          (onSorted)="onSort($event)"></app-table-litige>
      </div>
    </panel>
  </div>
</div>
<!-- end processing table "A traiter" -->

