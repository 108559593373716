import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Imputation} from "../../models/api/imputation.model";

@Injectable({
  providedIn: 'root'
})
export class  ImputationService {

  urlRessource = environment.url_api_litige+"/imputations";

  constructor(private http: HttpClient) {

  }

  updateImputations(imputations: Imputation[]): Observable<Imputation> {
    return this.http.put<Imputation>(`${this.urlRessource}/update-all`, imputations)
      .pipe(map((observe) =>  {
        return new Imputation().deserialize(observe);
      }))
  }

  checkImputationsToCompta(line: {facture_id,date_litige,acteur_id}): Observable<void> {
    return this.http.put<void>(`${this.urlRessource}/check-to-compta`, line)
  }

  uncheckImputationsToCompta(line: {facture_id,date_litige,acteur_id}): Observable<void> {
    return this.http.put<void>(`${this.urlRessource}/uncheck-to-compta`, line)
  }


}
