import {Injectable} from '@angular/core';
import {Plateforme} from '../../models/api/plateforme.model';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlateformeService {

  urlRessource = environment.url_api_litige+"/plateformes";
  constructor(private http: HttpClient) {

  }

  getPlateformes(): Observable<Plateforme[]> {
    return this.http
      .get<Plateforme[]>(`${this.urlRessource}`).pipe(map((observe) => observe.map(function (observe) {
        return new Plateforme().deserialize(observe);
      })))
  }


}
