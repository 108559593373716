<!-- begin page-header -->
<div class="row mb-2">
  <div class="col-md-4"></div>
  <div class="col-md-4 text-center"><h1 class="page-header">Tableau de bord des ODA</h1></div>
</div>
<!-- end page-header -->

<div class="alert alert-light fade show">
  <div class="row">
    <div class="col-4">Cochez les lignes que vous souhaitez exporter.</div>
    <div class="col-4 text-center">
      <button *ngIf="view.hasDroit('ODA_CHECK')" (click)="downloadLines()" [disabled]="nbLineSelected === 0" [ladda]="loadingDownload"
              class="btn btn-agediss btn-lg" data-style="zoom-in">Exporter les {{nbLineSelected}} lignes
      </button>
    </div>
    <div class="col-4 text-right">
      <button (click)="toggleDoneLine()" class="btn btn-agediss">
        {{textBtnToggleDonneLine}}
      </button>
    </div>
  </div>
</div>
<div class="alert alert-light fade show">
  <div *ngIf="loading" style="height: 50em">
    <span class="spinner"></span>
  </div>
  <div *ngIf="!loading">
    <table class="table table-hover table-striped table-bordered">
      <tr class="text-center">
        <th>Date litige</th>
        <th>Facture</th>
        <th>Debit</th>
        <th>Credit</th>
        <th>Commentaire</th>
        <th>Analytique</th>
        <th>
          <button (click)="addRemoveAll()" class="btn btn-info" ngbTooltip="Tout selectionner"><i
            class="fa fa-check-double"></i></button>
        </th>
      </tr>
      <tbody>
      <tr *ngFor="let item of odas">
        <td>{{item.litige.date_litige | date : 'dd-MM-yyyy'}}</td>
        <td>{{item.facture.numero_interne}}</td>
        <td>{{!item.sens_comptable ? item.montant : null}}</td>
        <td>{{item.sens_comptable ? item.montant : null}}</td>
        <td>{{item.commentaire}}</td>
        <td>{{item.code_analytique}}</td>
        <td class="text-center">
          <button (click)="addLine(item)" *ngIf="!item.added" class="btn btn-icon btn-info"><i
            class="fa fa-2x fa-square"></i></button>
          <button (click)="removeLine(item)" *ngIf="item.added" class="btn btn-icon btn-green"><i
            class="fa fa-2x fa-check-square "></i></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
<!-- end processing table "A traiter" -->

