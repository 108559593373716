import {Deserializable} from '../interfaces/deserializable.interface';
import {Litige} from './litige.model';
import {Colis} from './colis.model';
import {TypeArticle} from './type-article.model';

export class Article implements Deserializable {

  public id: number;
  public litige: Litige;
  public colis: Colis[];
  public poids: number;
  public volume: number;
  public reference: string;
  public created_at: Date;
  public updated_at: Date;
  public type_article?: TypeArticle;
  public sous_type_article?: string;
  public um_type?: string;
  public libelle: string;
  public nb_colis: number;
  public pending: boolean;

  constructor() {
    this.pending = false;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    this.type_article = (input.type_article) ? new TypeArticle().deserialize(input.type_article): null;
    return this;
  }

}
