<!-- begin page-header -->
<h1 class="page-header">TEST

</h1>
<!-- end page-header -->

<div class="row">
  <div class="col-12">
<!--    <mat-form-field class="example-full-width">-->
<!--      <mat-label>Litiges</mat-label>-->
<!--      <input type="text"-->
<!--             placeholder="Rechercher"-->
<!--             aria-label="litiges"-->
<!--             matInput-->
<!--             [formControl]="searchLitige"-->
<!--             [matAutocomplete]="auto">-->
<!--      <mat-autocomplete #auto="matAutocomplete">-->
<!--        <mat-option *ngFor="let item of filteredOptions | async" [value]="item">-->
<!--                            <span md-highlight-text="searchText"><div>-->
<!--                            ID : {{item.id}} [ {{item.type_litige.libelle}} ]-->
<!--                            BL {{item.numero_avis}} - {{item.destinataire_nom }} |-->
<!--                            montant: {{item.montant_valide | currency :'EUR': '€'}}-->
<!--                          </div></span>-->
<!--          {{item}}-->
<!--        </mat-option>-->
<!--      </mat-autocomplete>-->
<!--    </mat-form-field>-->

    <mat-form-field class="example-full-width">
      <input matInput placeholder="rechercher (min 4 chiffres)" aria-label="State" [matAutocomplete]="auto" [formControl]="searchMoviesCtrl">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngIf="isLoading" class="is-loading">Recherche en cours ...</mat-option>
        <ng-container *ngIf="!isLoading && filteredItems.length == 0">
          <mat-option [value]="null">Aucun résultat</mat-option>
        </ng-container>
        <ng-container *ngIf="!isLoading && filteredItems.length > 0">
          <mat-option>
            <mat-checkbox [checked]="isAllSelected" (change)="toggleSelectAll()" (click)="$event.stopPropagation()">
              Select All
            </mat-checkbox>
          </mat-option>
          <mat-option *ngFor="let item of filteredItems" [value]="item.numero_avis">
            <div (click)="optionClicked($event, item);">
              <mat-checkbox [checked]="item.selected" (change)="toggleSelection(item)" (click)="$event.stopPropagation()">
                                   <span><div>
                            ID : {{item.id}} [ {{item.type_litige.libelle}} ]
                            BL {{item.numero_avis}} - {{item.destinataire_nom }} |
                            montant: {{item.montant_valide | currency :'EUR': '€'}}
                          </div></span>
              </mat-checkbox>
            </div>
          </mat-option>
        </ng-container>

      </mat-autocomplete>
    </mat-form-field>

    <br>

    <ng-container *ngIf="errorMsg; else elseTemplate">
      {{errorMsg}}
    </ng-container>
    <ng-template #elseTemplate>
      <h5 style="margin-top: 150px;">Selected Value:</h5>
      <ul>
        <li *ngFor="let item of selectedItems">{{item.numero_avis}}</li>
      </ul>
    </ng-template>

  </div>
</div>




