import {Component, Input, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Litige} from '../../../shared/models/api/litige.model';
import {LitigeService} from '../../../shared/services/api/litige.service';
import {StatService} from '../../../shared/services/api/stat.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-plateforme-view',
  templateUrl: './plateforme-view.component.html',
  styleUrls: ['./plateforme-view.component.scss']
})
export class PlateformeViewComponent implements OnInit {

  public litiges: Litige[];
  public hiddenColumns = ['instruction', 'evenement', 'approche', 'entite'];
  public loading: boolean = false;
  public nbLitigeATraiter?: number = null;
  public nbContestationsPlateforme: number;
  @Input() contextId: number;
  public subscriptions: Subscription[] = [];

  constructor(private litigeSvc: LitigeService, private statSvc: StatService) {
  }

  ngOnInit() {
    this.loading = true;

    this.subscriptions.push(this.statSvc.getNbLitige(<FilterMultiCritereModel> {
      statutCode: ['LIT_3', 'LIT_5'],
      responsable_alternative :true
    }).subscribe((nbLitige) => {
      this.nbLitigeATraiter = nbLitige;
    }));

    this.subscriptions.push(this.statSvc.getNbContestation(<FilterMultiCritereModel> {
      contestataire: 'PF',
      statutCode: 'CON_0'
    }).subscribe((nbContestations) => {
      this.nbContestationsPlateforme = nbContestations;
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s=>{s.unsubscribe()})
  }

}

