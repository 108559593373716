import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {share, tap} from "rxjs/operators";
import {HttpUtil} from "../utils/http.util";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpCacheInterceptor implements HttpInterceptor {
  public cache: Map<string, HttpResponse<any>> = new Map()
  public lastTimeCache: Date

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let atMoment = new Date();
    if (!this.lastTimeCache) {
      this.lastTimeCache = new Date();
    }
    if(this.timeDifference(atMoment, this.lastTimeCache) > 120){
      this.removeCacheByUrl('litige');
      this.removeCacheByUrl('contestation');
      this.lastTimeCache = new Date();
    }

    if (HttpUtil.getUrlWithoutHost(req.url) == 'sso/token/refresh' || HttpUtil.getUrlWithoutHost(req.url) == 'notifications' || req.headers.get("nocache")) {
      return next.handle(req);
    }
    if (req.method !== "GET") {
      this.removeCacheByUrl(req.url)
      return next.handle(req)
    }

    if (req.headers.get("reset")) {
      this.cache.delete(req.urlWithParams)
    }

    const cachedResponse: HttpResponse<any> = this.cache.get(req.urlWithParams)
    if (cachedResponse) {
      return of(cachedResponse.clone())
    } else {
      return next.handle(req).pipe(
        tap(stateEvent => {
          if (stateEvent instanceof HttpResponse) {
            this.cache.set(req.urlWithParams, stateEvent.clone())
          }
        })
      ).pipe(share())
    }
  }

  removeCacheByUrl(url) {
    const urlParts = HttpUtil.getUrlWithoutHost(url).split('/')
    this.cache.forEach((v, key) => {
      urlParts.forEach((value) => {
        if (key.match(new RegExp(value.slice(0, -1), 'gi'))) {
          this.cache.delete(key);
        }
      })
    })
  }

  timeDifference(date1, date2) {
    let difference = date1.getTime() - date2.getTime();
    return Math.floor(difference / 1000);
  }
}
