<div class="widget-chat widget-chat-rounded text-left">

  <div class="overflow-scroll" ngx-auto-scroll style="height: 302px;">
    <div *ngIf="pending" class="spinner"></div>
    <div *ngIf="!pending && messages.length === 0" class="text-center">Aucun messages</div>
    <div *ngIf="!pending && messages.length > 0 " style="min-height: 250px;position: relative">
      <div *ngFor=" let message of messages;"
           class="widget-chat-body"
           data-scrollbar="true"
           data-height="235px"
      >
        <!-- Chatbox côté gauche si le user actuel correspond à l'expéditeur -->
        <div *ngIf="currentUser.username == message.username"
             class="widget-chat-item with-media left">
          <div class="widget-chat-info">
            <div class="widget-chat-info-container">
              <div
                class="widget-chat-name text-primary">{{currentUser.getFullname()}}</div>
              <div class="widget-chat-message"> {{message.corps}}
                <a *ngIf="ShowEdit(message.created_at)" (click)="onUpdate(message)" class="text-black-lighter"
                   style="float: right;  cursor: pointer;">
                  <i *ngIf="!message.pending" class="fa fa-pen"></i>
                  <i *ngIf="message.pending" class='fas fa-spin fa-circle-notch'></i>
                </a>
              </div>
              <br>
              <div class="widget-chat-time">{{message.created_at | date :'short' }}</div>
            </div>
          </div>
        </div>

        <!-- Chatbox côté droit si le user du message correspond au destinataires -->
        <div *ngIf="currentUser.username != message.username"
             class="widget-chat-item with-media right">
          <div class="widget-chat-info">
            <div class="widget-chat-info-container">
              <div
                class="widget-chat-name text-primary">{{message.expediteur}}</div>
              <div class="widget-chat-message"> {{message.corps}}<br>
                <div class="widget-chat-time text-right">{{message.created_at | date :'short'}}</div>
              </div>
            </div>
          </div>
          <!-- end widget-chat-item -->
        </div>

      </div>
    </div>
  </div>
  <div class="widget-input widget-input-rounded">

    <div class="widget-input-container p-l-5 p-r-5">
      <div class="widget-input-icon"
           data-toggle="tooltip"
           data-placement="top"
           title="Rafraichir la conversation">
        <button class="btn btn-agediss " (click)="refreshConversation()">
          <i class="fa fa-circle-notch"></i>
        </button>
      </div>
      <div class="widget-input-box">
        <input type="text" class="form-control form-control-sm" (keydown.enter)="onSubmit()"
               [(ngModel)]="msg.corps" [disabled]="msg.pending"
               placeholder="écrire votre message..."/>
      </div>
      <div class="widget-input-icon">
        <button class="btn btn-agediss " (click)="onSubmit()">
          <i class="fa fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
    <!-- end widget-input -->
  </div>
</div>
