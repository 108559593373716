import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from "../../../../animations";
import {Facture} from "../../../../shared/models/api/facture.model";
import {Subscription} from "rxjs";
import Swal from "sweetalert2";
import {FactureService} from "../../../../shared/services/api/facture.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-table-facture-affecter',
  templateUrl: './table-facture-affecter.component.html',
  styleUrls: ['./table-facture-affecter.component.scss'],
  animations: [tableAnimation],
})
export class TableFactureAffecterComponent implements OnInit, OnChanges {

  public dataSorted: MatTableDataSource<Facture>;
  @Input() data: Facture[];
  @Input() caseAffectations: Boolean = false;
  expandedElement: Facture | null;
  @Input() displayedColumns: string[] = [
    'idPieceJointe',
    'client',
    'typeFacture',
    'date',
    'numeroInterne',
    'montant',
    'options',
    'affecter',
  ];
  @Input() temporaireBtn = true;
  @Input() noRecordText = 'Aucune facture à affecter';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = null;
  @Input() hiddenColumns: string[] = [];
  @Output() Affecter: EventEmitter<any> = new EventEmitter();
  @Output() selectEditFacture: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();
  @Output() setFactureTemporaire: EventEmitter<{id: number, index: number}> = new EventEmitter<{id: number, index: number}>();
  public subsriptions: Subscription[] = [];

  public innerWidth: number;
  private subscription: Subscription;


  constructor(private factureSvc: FactureService,
              private toastr: ToastrService) {
  }


  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      });
    }
  }


  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  editFacture(id: number) {
    this.selectEditFacture.emit({id});
  }

  factureTemporaire(id: number, index: number) {
    this.setFactureTemporaire.emit({id, index});
  }

  deleteFacture(factureId: number, index) {

    Swal.fire({
      title: 'Attention !',
      text: 'Etes-vous sur de vouloir supprimer la facture ? ',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui !'

    }).then((result) => {
      if (result.value) {
        // affiche le chargement
        this.dataSorted.data[index].pending = true;

        // service pour supprimer le fichier
        this.subscription = this.factureSvc.delete(factureId).subscribe(
          () => {
            this.toastr.info('la facture à été supprimé');
            this.dataSorted.data[index].pending = false;
            this.dataSorted.data.splice(index, 1);
            this.dataSorted = new MatTableDataSource(this.dataSorted.data);
          },
          () => {
            this.dataSorted.data[index].pending = false;
            this.toastr.error('la facture n\'à pas pu être supprimé');
          },
        );
      }

    });
  };


  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  actionModal(litige) {
    this.Affecter.emit(litige);
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable(event) {
    this.innerWidth = event;
    if (this.innerWidth <= 500) {
      this.displayedColumns = [
        'client',
        'typeFacture',
        'date',
        'numeroInterne',
        'montant',
        'affecter',
      ];
    } else if (this.innerWidth <= 768) {
      this.displayedColumns = [
        'id',
        'numeroAvis',
        'client',
        'date',
        'numeroCommande',
        'traitePar',
        'destinataireNom',
        'plateforme',
      ];
    } else if (this.innerWidth <= 1024) {
      this.displayedColumns = [
        'client',
        'typeFacture',
        'date',
        'numeroInterne',
        'montant',
        'affecter',
      ];
    } else if (this.innerWidth <= 1300) {
      this.displayedColumns = [
        'client',
        'typeFacture',
        'date',
        'numeroInterne',
        'montant',
        'affecter',
      ];
    }
  }
}
