import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../../environments/environment';
import {TypeLitige} from "../../models/api/type-litige.model";
import {HttpUtil} from "../../utils/http.util";


@Injectable({
  providedIn: 'root'
})
export class TypeLitigeService {


  urlRessource = environment.url_api_litige + "/type-litige";

  constructor(private http: HttpClient) {

  }

  getTypeLitige(filter: any = null): Observable<TypeLitige[]> {
    return this.http
      .get<TypeLitige[]>(`${this.urlRessource}`,{params: HttpUtil.convertObjectToHttpParams(filter)}).pipe(map((observe) => observe.map(function (observe) {
        return new TypeLitige().deserialize(observe);
      })))
  }

}
