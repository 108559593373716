import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from "../../../../animations";
import {Facture} from "../../../../shared/models/api/facture.model";

@Component({
  selector: 'app-table-facture',
  templateUrl: './table-facture.component.html',
  styleUrls: ['./table-facture.component.scss'],
  animations: [tableAnimation],
})
export class TableFactureComponent implements OnInit, OnChanges {

  public dataSorted: MatTableDataSource<Facture>;
  @Input() data: Facture[];
  @Input() displayedColumns: string[] = [
    'idFacture',
    'client',
    'filename',
    'typeFacture',
    'numeroInterne',
    'numeroClient',
    'statutBap',
  ];
  @Input() noRecordText = 'Aucune facture';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;
  @Input() height = 0;
  @Input() hiddenColumns: string[] = [];

  @Output() selectShowFacture: EventEmitter<any> = new  EventEmitter();


  constructor() {}
  ngOnInit() {

    this.dataSorted = new MatTableDataSource(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }


  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    console.log(changes);
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSorted = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

}
