<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       matSortActive="id" matSortDirection="desc"
       class="table table-hover table-striped table-bordered mat-elevation-z8 text-center">
  <ng-container matColumnDef="checkbox">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td class="align-middle" mat-cell *matCellDef="let element"><input type="checkbox" id="{{element.id}}"></td>
  </ng-container>
  <ng-container matColumnDef="reference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Réf</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.reference}} </td>
  </ng-container>
  <ng-container matColumnDef="libelle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Libellé</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.libelle}} </td>
  </ng-container>
  <ng-container matColumnDef="nb_colis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Colis</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.nb_colis}} </td>
  </ng-container>
  <ng-container matColumnDef="volume">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Volume</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.volume}} </td>
  </ng-container>
  <ng-container matColumnDef="poids">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Poids</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.poids}} </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef class="text-center">
      <button *ngIf="showAddArticle"  ngbTooltip="Ajouter un article"
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss"
              (click)="selectAjoutArticle()">
        <i class="fa fa-plus"></i>
      </button>
    </th>
    <td mat-cell *matCellDef="let element; let i = index" class="with-img align-middle text-nowrap">
      <button *ngIf="showViewArticle" ngbTooltip="Visualiser l'article"
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1"
              (click)="selectShowArticleId(element.id, i)">
        <i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showEditArticle" ngbTooltip="Editer l'article"
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss mr-1"
              (click)="selectEditArticleId(element.id, i)">
        <i class="fa fa-pencil-alt"></i>
      </button>
      <button *ngIf="showDeleteArticle" [ladda]="element.pending" data-style="zoom-in"
              ngbTooltip="Suprimer l'article"
              placement="top"
              container="body"
              class="btn btn-rounded btn-sm btn-agediss"
              (click)="delete(element.id,i)">
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </ng-container>


  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>

  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <ng-container matColumnDef="total_articles">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="(displayedColumns.length/2)" class="sticky-footer">
      <i class="fa fa-shopping-cart"></i> {{dataSorted.data.length}} articles
    </td>
  </ng-container>

  <ng-container matColumnDef="total_colis">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="(displayedColumns.length/2)" class="sticky-footer">
      <i class="fa fa-archive"></i> {{getNbColis()}} colis
    </td>
  </ng-container>

  <tr mat-header-row style="height: 10px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['total_articles','total_colis']; sticky: true" [hidden]="loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>

</table>


