import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PieceJointeService} from '../../../shared/services/api/piece-jointe.service';
import {TypePieceJointe} from '../../../shared/models/api/type-piece-jointe.model';
import Swal from 'sweetalert2';
import {ClientService} from '../../../shared/services/api/client.service';
import {CompteClient} from '../../../shared/models/api/compte-client.model';
import {HttpCacheInterceptor} from '../../../shared/interceptors/http-cache.interceptor';
import {HttpRequest} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {urlWithProtocol} from "../../../shared/utils/util";


@Component({
  selector: 'FileUploader',
  templateUrl: './fileUploader.component.html',
  styleUrls: ['./fileUploader.component.scss']
})

export class FileUploaderComponent implements OnChanges, OnDestroy {

  public uploader: FileUploader;
  public hasBaseDropZoneOver = false;
  public URL: string;
  public disabledButton: Boolean = false;
  public loading = false;
  public typePiecesJointes: TypePieceJointe[] = [];
  public compteClients: CompteClient[] = [];
  public idClient: number;


  @Input() modal: NgbModal;
  @Input() showReturnButton = true;
  @Input() contextClient: Boolean = false;
  @Input() idContestation: number;
  @Input() idLitige: number;
  @Output() uploaded = new EventEmitter<boolean>();
  @Output() return = new EventEmitter<void>();

  public laddaSentButton: Boolean = false;
  private sub: Subscription[] = [];

  constructor(private authSvc: AuthenticationService,
              private toastr: ToastrService,
              private pieceJointeService: PieceJointeService,
              private  clientsService: ClientService, private cacheInterceptor: HttpCacheInterceptor) {
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  ngOnInit() {

    if (this.contextClient == true) {
      this.loading = true;
      this.sub.push(this.clientsService.getCompteClients().subscribe(value => {
        this.compteClients = value;
        this.loading = false;
      }));

      this.sub.push(this.pieceJointeService.getTypePieceJointe(null, true).subscribe(
        typePiecesJointes => {
            this.typePiecesJointes = typePiecesJointes;
        },
        () => {
          Swal.fire({title: 'désolé', text: 'l\'outil d\'import de fichiers à rencontré une erreur.'}).then(() => {
            this.return.emit();
          });
        }
      ));

    } else {
      const sub = this.pieceJointeService.getTypePieceJointe().subscribe(
        typePiecesJointes => {
          this.typePiecesJointes = typePiecesJointes;
        },
        () => {
          Swal.fire({title: 'désolé', text: 'l\'outil d\'import de fichiers à rencontré une erreur.'}).then(() => {
            this.return.emit();
          });
        }
      );
    }

    this.routeApiUploader();
  }

  handleEventUploader() {


    // Apres avoir ajouter le fichier dans la queue de l'uploader
    this.uploader.onAfterAddingFile = (item: FileItem | any) => {
      const name = item.file.name;
      const split = name.split('.');
      item.formData.extention = '.' + split[split.length - 1];
      item.formData.name = name.replace(item.formData.extention, '');
      // set la valeur par default pour le ng-select si il n'y a qu'un seul type de pièce jointe
      if (this.typePiecesJointes.length === 1) {
        item.formData.typePieceJointe = this.typePiecesJointes[0].id;
      }

    };

    // Avant l'uploade vers le serveur
    this.uploader.onBeforeUploadItem = (fileItem: FileItem | any) => {
      this.laddaSentButton = true;
      this.disabledButton = true;
      fileItem.isError = true;
      fileItem.file.name = fileItem.formData.name + fileItem.formData.extention;
      fileItem.withCredentials = false;
      return false;
    };


    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      if (fileItem.formData.typePieceJointe) {
        form.append('typePieceJointe', fileItem.formData.typePieceJointe ? fileItem.formData.typePieceJointe : null);
      }
      if (fileItem.formData.name) {
        form.append('filename', fileItem.formData.name);
      }
      form.append('hidden', !!fileItem.formData.hidden ? fileItem.formData.hidden : false);
    };

    this.uploader.onCompleteAll = () => {
      if (this.uploader.queue.length === 0) {
        this.uploaded.emit(true);
      }
      this.laddaSentButton = false;
    };

    // uploader reussi
    this.uploader.onSuccessItem = ((item: FileItem): any => {
      this.removeFileFromQueue(item.file.name);
      this.cacheInterceptor.removeCacheByUrl(this.URL);
      this.toastr.success('Le fichier ' + item.file.name + ' à été envoyé.', 'Envoi terminé');
    });


    // erreur dans n'importe quels evenement ci-dessus.
    this.uploader.onErrorItem = ((item: FileItem, response: string): any => {
      item.progress = 0;
      const defaultMsg = 'Le fichier' + item.file.name + ' à rencontrer un probleme lors de l\'envoi, merci de rééssayer ultérieurement.';
      try {
        const rep = JSON.parse(response);
        if (rep.code) {
          switch (rep.code) {
            case 2101 :
              Swal.fire({
                title: 'Attention !',
                text: 'Vous devez choisir un type pour le fichier ' + item.formData.name,
                icon: 'warning'
              });
              break;
            case 2700 :
              Swal.fire({title: 'Attention !', text: rep.message, icon: 'warning'});
              break;
            default:
              Swal.fire({title: 'Désolé !', text: defaultMsg, icon: 'error'});
          }
        } else {
          Swal.fire({title: 'Désolé !', text: defaultMsg, icon: 'error'});
        }
      } catch (e) {
        Swal.fire({title: 'Désolé !', text: defaultMsg, icon: 'error'});
      }
      this.disabledButton = false;
      this.laddaSentButton = false;

      item.isCancel = false;
      item.isReady = false;
      item.isUploaded = false;

    });


  }

  removeFileFromQueue(label: string) {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i].file.name === label) {
        this.uploader.queue[i].remove();
        return;
      }
    }
  }

  ngOnChanges() {


  }

  changeSelect(event) {
    this.idClient = event.id;
    this.routeApiUploader();
    this.handleEventUploader();
  }

  routeApiUploader() {
    if (this.idLitige != undefined || this.idLitige != null) {
      this.URL = urlWithProtocol(environment.url_api_litige + '/litiges/' + this.idLitige + '/upload');
    }
    if (this.idContestation != undefined || this.idContestation != null) {
      this.URL = urlWithProtocol(environment.url_api_litige + '/contestations/' + this.idContestation + '/upload');
    }
    if (this.idClient != undefined || this.idClient != null) {
      this.URL = urlWithProtocol(environment.url_api_litige + '/comptes-clients/' + this.idClient + '/upload');
    }

    this.authSvc.getToken().subscribe(tokens => {
      this.uploader = new FileUploader({
        url: this.URL + '?_taged_view=' + tokens.tokenView,
      });
      this.uploader.authToken = 'Bearer ' + tokens.token;
      this.handleEventUploader();
    });
  }


  sentAllFile() {
    this.uploader.uploadAll();
  }

  ngOnDestroy(): void {
    this.sub.forEach(sub => {
      if (sub instanceof Subscription) {
        sub.unsubscribe();
      }
    });
  }


}

