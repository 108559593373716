import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
// Recherche MultiCritere
// Home
import {TableauDeBord} from './pages/tableau-de-bord/tableau-de-bord';
// Login
import {AuthGuard} from './shared/guards/auth.guard';
import {LitigesPage} from './pages/litiges/litiges';
import {LitigePage} from './pages/litiges/show/litige';
import {MultiCriterePage} from './pages/multiCritere/multiCritere';
import {ContestationsPage} from './pages/contestations/contestations';
import {ContestationPage} from './pages/contestations/show/contestation';
import {JustificatifsPage} from './pages/justificatifs/justificatifs.component';
import {Page404Page} from './pages/http/page404/page404';
import {Page401Page} from './pages/http/page401/page401';
import {Page503Page} from './pages/http/page503/page503';
import {Page500Page} from './pages/http/page500/page500';
import {AdminDashbord} from './pages/admin/admin-dashbord';
import {FacturesAttentePage} from './pages/factures/attente/facturesAttente';
import {FacturesAffecterPage} from './pages/factures/affecter/facturesAffecter';
import {ImputationsPage} from './pages/imputations/imputations';
import {RetrocessionsPage} from './pages/retrocession/retrocessions';
import {ImputationTableauDeBord} from './pages/imputations/tableau-de-bord/imputation-tableau-de-bord';
import {OdaTableauDeBord} from './pages/oda/tableau-de-bord/oda-tableau-de-bord';
import {AffectationsPage} from './pages/affectations/affectations';
import {Test} from './pages/test/test';
import {FacturesPageComponent} from './pages/factures/factures/factures';
import {FacturePage} from './pages/factures/facture/facture';
import {AnnuairePageComponent} from './pages/annuaire/annuaire';
import {PortefeuillesPageComponent} from './pages/portefeuilles/portefeuilles';


const routes: Routes = [
  {
    path: '', canActivate: [AuthGuard], children:
      [
        {path: '', redirectTo: 'tableau-de-bord', pathMatch: 'full'},
        {path: 'tableau-de-bord', component: TableauDeBord, data: {title: 'Tableau de bord'}},
      ]
  },
  {
    path: 'litiges', canActivate: [AuthGuard], children: [
      {path: '', component: LitigesPage, data: {title: 'Encours litiges'}},
      {path: ':id', component: LitigePage, data: {title: 'litige'}},
    ]
  },
  {
    path: 'contestations', canActivate: [AuthGuard], children: [

      {path: '', component: ContestationsPage, data: {title: 'Encours contestations'}},
      {path: ':id', component: ContestationPage, data: {title: 'contestation '}},
    ]
  },
  {
    path: 'justificatifs', canActivate: [AuthGuard], children: [

      {path: '', component: JustificatifsPage, data: {title: 'Validation des justificatifs'}},
    ]
  },
  {
    path: 'factures', canActivate: [AuthGuard], children: [
      {path: '', component: FacturesPageComponent, data: {title: 'Factures'}},
      {path: ':id', component: FacturePage, data: {title: 'Visualisation factures'}},
    ]
  },
  // {
  //   path: 'factures-affecter', canActivate: [AuthGuard], children: [
  //
  //     {path: '', component: FacturesAffecterPage, data: {title: 'Factures à Affecter'}},
  //   ]
  // },
  {
    path: 'factures-affecter', canActivate: [AuthGuard], children: [

      {path: '', component: FacturesAffecterPage, data: {title: 'Factures à Affecter'}},
    ]
  },
  {
    path: 'factures-attente', canActivate: [AuthGuard], children: [

      {path: '', component: FacturesAttentePage, data: {title: 'Factures en attentes'}},
    ]
  },
  {
    path: 'affectations', canActivate: [AuthGuard], children: [

      {path: '', component: AffectationsPage, data: {title: 'Modification des affectations'}},
    ]
  },
  {
    path: 'administration', canActivate: [AuthGuard], children: [

      {path: '', component: AdminDashbord, data: {title: 'Admin - tableau de bord'}},
    ]
  },
  {
    path: 'imputations', canActivate: [AuthGuard], children: [

      {path: '', component: ImputationsPage, data: {title: 'Imputation'}},
      {path: 'tableau-de-bord', component: ImputationTableauDeBord, data: {title: 'imputation '}},
    ]
  },
  {
    path: 'retrocessions', canActivate: [AuthGuard], children: [

      {path: '', component: RetrocessionsPage, data: {title: 'Rétrocessions'}},
    ]
  },
  {
    path: 'oda', canActivate: [AuthGuard], children: [

      {path: '', component: OdaTableauDeBord, data: {title: 'ODA'}},
    ]
  },
  {
    path: 'annuaire', canActivate: [AuthGuard], children: [
      {path: '', component: AnnuairePageComponent, data: {title: 'Annuaire'}},
    ]
  },
  {
    path: 'portefeuilles', canActivate: [AuthGuard], children: [
      {path: '', component: PortefeuillesPageComponent, data: {title: 'Portefeuilles'}},
    ]
  },
  {
    path: 'test', canActivate: [AuthGuard], children: [
      {path: '', component: Test, data: {title: 'test'}},
    ]
  },
  {path: 'recherche', component: MultiCriterePage, data: {title: 'MultiCritere'}},
  {path: 'not-found', component: Page404Page},
  {path: 'not-authorize', component: Page401Page},
  {path: 'maintenance', component: Page503Page},
  {path: 'error', component: Page500Page},
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule {

}
