import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {HttpUtil} from '../../utils/http.util';
import {InstructionRetour} from '../../models/api/instruction-retour.model';

@Injectable({
  providedIn: 'root'
})
export class InstructionRetourService {

  urlRessource = environment.url_api_litige + '/instruction-retour';

  constructor(private http: HttpClient) {

  }

  getInstructionsRetour(filter: any = null): Observable<InstructionRetour[]> {
    return this.http
      .get<InstructionRetour[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((observe) => observe.map(function(observe) {
        return new InstructionRetour().deserialize(observe);
      })));
  }

  getInstructionsRetourByLitige(litigeId:number): Observable<InstructionRetour[]> {
    return this.http
      .get<InstructionRetour[]>(`${this.urlRessource}-litige/${litigeId}`)
      .pipe(map((observe) => observe.map(function(observe) {
        return new InstructionRetour().deserialize(observe);
      })));
  }

}
