import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AnnuaireService} from '../../../shared/services/api/annuaire.service';
import {AnnuaireItem} from '../../../shared/models/api/annuaire.model';
import {Client} from '../../../shared/models/api/client.model';
import {Plateforme} from '../../../shared/models/api/plateforme.model';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppEvent, EventQueueService} from '../../../shared/services/event-queue.service';
import Swal from 'sweetalert2';
import {CompteClient} from '../../../shared/models/api/compte-client.model';
import {Statut} from '../../../shared/models/api/statut.model';
import {User} from '../../../shared/models/user.model';
import {Template, generate} from '@pdfme/generator';
import * as pdfTemplate from '../../../../assets/fiche_client_template.json';


@Component({
  selector: 'app-annuaire-editor',
  templateUrl: './annuaire-editor.html',
  styleUrls: ['./annuaire-editor.scss']
})
export class AnnuaireEditorComponent implements OnDestroy, OnInit, OnChanges {

  public loadingAnnuaire = true;
  public savingItem = false;
  public deletingItem = false;
  public annuaireItem: AnnuaireItem;
  public typesClientOptions = [
    'Fabricant',
    'Revendeur',
    'Transporteur'
  ];
  public justificatifsOptions = [
    'Facture de vente',
    'Facture d\'achat',
    'Capture d\'écran prix de vente',
    'Capture d\'écran prix d\'achat',
    'Fichier excel'
  ];
  public typologiesProduitsOptions = [
    'Aménagement extérieur',
    'Canapé / Fauteuil',
    'Cuisine', 'Décoration',
    'Electroménager / Hifi',
    'Fourniture de bureau',
    'Jardinage / Outillage',
    'Literie',
    'Matériel de sport intérieur',
    'Menuiserie',
    'Meuble en kit',
    'Meuble (monté)',
    'Plan de travail',
    'Plomberie',
    'Sport et loisirs'
  ];
  public savMulticolisOptions = [
    'Oui',
    'Non',
    'Sur demande'
  ];
  public limiteResponsabiliteOptions = [
    'Contrat Type France',
    'Contrat Type Belgique',
    'Convention CMR'
  ];
  public documentTransportOptions = [
    'CMR',
    'Rapport d\'arrivage'
  ];

  public annuaireForm: FormGroup;
  public contactsFormArray: FormArray;
  public typologiesFormArray: FormArray;

  @Input() itemId: any;
  @Input() itemClientId: any;
  @Input() clientsSelect: Client[];
  @Input() compteClientsSelect: CompteClient[];
  @Input() plateformesSelect: Plateforme[];
  @Input() fraisDeTransportSelect: Statut[];
  @Input() viewOnly = false;
  @Input() context = User.CONTEXT_SIEGE;

  constructor(
    private annuaireSvc: AnnuaireService,
    private toastSvc: ToastrService,
    private eventQueue: EventQueueService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (!!changes.itemId) {
      if (this.itemId !== 'group' && this.itemId !== 'client' && this.itemId !== null) {
        if (this.itemId !== this.annuaireItem?.id) {
          this.loadAnnuaireItem(this.itemId);
        }
      }
      else {
        this.loadingAnnuaire = false;
        this.annuaireItem = new AnnuaireItem();
        this.initForm();
      }
    }
    if (!!changes.itemClientId) {
      this.loadAnnuaireItemByClient(this.itemClientId);
    }
  }

  ngOnDestroy(): void {
  }


  // TODO: Si vous modifiez le modèle, pensez à modifier l'export()
  initForm() {
    this.contactsFormArray = new FormArray([]);
    this.typologiesFormArray = new FormArray([]);
    this.annuaireForm = new FormGroup({
      client: new FormControl(this.annuaireItem.client?.id),
      compte_client: new FormControl(this.annuaireItem.compte_client?.id),
      plateforme: new FormControl(this.annuaireItem.plateforme?.id),

      // config
      typeClient: new FormControl(this.annuaireItem.config?.typeClient),
      modeDeSuivi: new FormControl(this.annuaireItem.config?.modeDeSuivi),
      commercial: new FormControl(this.annuaireItem.config?.commercial),
      assistantCommercial: new FormControl(this.annuaireItem.config?.assistantCommercial),
      international: new FormControl(this.annuaireItem.config?.international),

      contacts: this.contactsFormArray,
      typologiesProduits: this.typologiesFormArray,

      conditionsPriseEnCharge: new FormGroup({
        taux: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.taux),
        limiteResponsabilite: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.limiteResponsabilite),
        justificatif: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.justificatif),
        commentaire: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.commentaire),
        fraisTransport: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fraisTransport),
        savMulticolis: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.savMulticolis),
        fluxAller: new FormGroup({
          reception: new FormGroup({
            approche: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxAller?.reception?.approche),
            document: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxAller?.reception?.document),
            delaiReserves: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxAller?.reception?.delaiReserves),
          }),
          livraison: new FormGroup({
            delaiReserves: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxAller?.livraison?.delaiReserves),
            commentaire: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxAller?.livraison?.commentaire),
          }),
        }),
        fluxRetour: new FormGroup({
          retour: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxRetour?.retour),
          document: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxRetour?.document),
          delaiRetour: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxRetour?.delaiRetour),
          delaiReserves: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxRetour?.delaiReserves),
          commentaire: new FormControl(this.annuaireItem.config?.conditionsPriseEnCharge?.fluxRetour?.commentaire),
        })
      }),

      transfertPropriete: new FormGroup({
        transfert: new FormControl(this.annuaireItem.config?.transfertPropriete?.transfert),
        commentaire: new FormControl(this.annuaireItem.config?.transfertPropriete?.commentaire)
      }),

      particularites: new FormControl(this.annuaireItem.config?.particularites)
    });

    if (!!this.annuaireItem.config?.contacts) {
      this.annuaireItem.config.contacts.forEach(contact => {
        this.contactsFormArray.push(new FormGroup({
          nom: new FormControl(contact.nom),
          fonction: new FormControl(contact.fonction),
          telephone: new FormControl(contact.telephone),
          email: new FormControl(contact.email)
        }));
      });
    }

    if (!!this.annuaireItem.config?.typologiesProduits) {
      this.annuaireItem.config.typologiesProduits.forEach(typologie => {
        this.typologiesFormArray.push(new FormGroup({
          nom: new FormControl(typologie.nom),
          commentaire: new FormControl(typologie.commentaire)
        }));
      });
    }
  }

  addContact() {
    this.contactsFormArray.push(new FormGroup({
      nom: new FormControl(''),
      fonction: new FormControl(''),
      telephone: new FormControl(''),
      email: new FormControl('')
    }));
    this.annuaireForm.markAsDirty();
  }

  removeContact(index: number) {
    Swal.fire({
      title: 'Attention !',
      text: 'Supprimer ce contact ?',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer',
      icon: 'warning',
    }).then(
      result => {
        if (result.value) {
          this.contactsFormArray.removeAt(index);
          this.annuaireForm.markAsDirty();
        }
      });
  }

  addTypologie() {
    this.typologiesFormArray.push(new FormGroup({
      nom: new FormControl(),
      commentaire: new FormControl('')
    }));
    this.annuaireForm.markAsDirty();
  }

  removeTypologie(index: number) {
    Swal.fire({
      title: 'Attention !',
      text: 'Supprimer cette typologie de produit ?',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer',
      icon: 'warning',
    }).then(
      result => {
        if (result.value) {
          this.typologiesFormArray.removeAt(index);
          this.annuaireForm.markAsDirty();
        }
      });
  }

  getItemFromForm() {
    const item = new AnnuaireItem();
    item.id = this.annuaireItem.id;
    item.client = {id: this.annuaireForm.value.client};
    item.compte_client = {id: this.annuaireForm.value.compte_client};
    item.plateforme = {id: this.annuaireForm.value.plateforme};
    item.config = JSON.parse(JSON.stringify(this.annuaireForm.value));
    // on purge item.config des valeurs indésirables
    delete item.config.client;
    delete item.config.compte_client;
    delete item.config.plateforme;

    return item;
  }

  submit() {
    this.savingItem = true;
    const item = this.getItemFromForm();

    if (!!item.id) {
      this.updateAnnuaireItem(item);
    } else {
      this.createAnnuaireItem(item);
    }
  }

  updateAnnuaireItem(item: AnnuaireItem) {
    item.client = this.annuaireItem.client;
    item.compte_client = this.annuaireItem.compte_client;
    this.annuaireSvc.updateAnnuaireItem(item).subscribe(obj => {
      this.annuaireItem = obj;
      this.savingItem = false;
      this.annuaireForm.markAsPristine();
    }, error => {
      this.toastSvc.error('Une erreur est survenue', 'Desolé');
      this.savingItem = false;
    });
  }

  createAnnuaireItem(item: AnnuaireItem) {
    if (!item.client.id && !item.compte_client.id) {
      Swal.fire('Attention', 'Vous devez sélectionner un <b>Client</b> ou un <b>Groupe clients</b>.', 'warning');
      this.savingItem = false;
      return;
    }

    this.annuaireSvc.createAnnuaireItem(item).subscribe(obj => {
      this.annuaireItem = obj;
      this.savingItem = false;
      this.annuaireForm.markAsPristine();
      this.eventQueue.dispatch(new AppEvent(AppEvent.EventType.RELOAD_ANNUAIRE, {currentItemId: this.annuaireItem.id}));
    }, error => {
      this.toastSvc.error('Une erreur est survenue', 'Desolé');
      this.savingItem = false;
    });
  }

  loadAnnuaireItem(id: number) {
    if (!id) {
      return;
    }
    this.loadingAnnuaire = true;
    this.annuaireSvc.getAnnuaireItem(id).subscribe(item => {
      this.annuaireItem = item;
      this.initForm();
      this.loadingAnnuaire = false;
    });
  }

  loadAnnuaireItemByClient(id: number) {
    if (!id) {
      return;
    }
    this.loadingAnnuaire = true;
    this.annuaireSvc.getAnnuaireItemByClient(id).subscribe(item => {
      this.annuaireItem = item;
      this.initForm();
      this.loadingAnnuaire = false;
    });
  }

  deleteItem() {
    Swal.fire({
      title: 'Attention !',
      text: 'Souhaitez-vous supprimer cette fiche ? Cette action ne peut pas être annulée.',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer',
      icon: 'warning',
    }).then(
      result => {
        if (result.value) {
          this.doDelete();
        }
      });
  }

  doDelete() {
    this.deletingItem = true;
    this.annuaireSvc.deleteAnnuaireItem(this.annuaireItem).subscribe(() => {
      this.eventQueue.dispatch(new AppEvent(AppEvent.EventType.RELOAD_ANNUAIRE, {}));
      this.deletingItem = false;
    }, error => {
      this.toastSvc.error('Une erreur est survenue', 'Desolé');
      this.deletingItem = false;
    });
  }

  /**
   * Le template est construit sur https://pdfme.com/template-design
   * Pour modifier le template :
   *   - Bouton "Load Template" => charger le fichier src/assets/fiche_client_template.json
   *   - Pour changer l'image de fond => bouton "Change BasePDF" (l'image de fond est le fichier
   *           src/assets/fiche_client.pdf, fabriquée à partir de captures d'écran retouchées avec GIMP)
   *   - Modifier les champs
   *   - Bouton "Download Template" => enregistrer sous src/assets/fiche_client_template.json
   */
  export() {
    const item = this.getItemFromForm();
    const client = item.compte_client?.id
      ? this.compteClientsSelect.find(cc => cc.id === item.compte_client?.id)?.libelle
      : this.clientsSelect.find(c => c.id === item.client?.id)?.libelle;
    const values = {
      titre: client || '',
      client: client || '',
      plateforme: this.plateformesSelect.find(p => p.id === item.plateforme?.id)?.libelle || '',
      typeClient: item.config.typeClient || '',
      modeDeSuivi: item.config.modeDeSuivi || '',
      commercial: item.config.commercial || '',
      assistantCommercial: item.config.assistantCommercial || '',
      international: item.config.international ? 'OUI' : 'NON',
      taux: '' + (item.config.conditionsPriseEnCharge.taux || ''),
      limiteResponsabilite: item.config.conditionsPriseEnCharge.limiteResponsabilite || '',
      justificatif: item.config.conditionsPriseEnCharge.justificatif || '',
      commentairePriseEnCharge: item.config.conditionsPriseEnCharge.commentaire || '',
      fraisDeTransport: item.config.conditionsPriseEnCharge.fraisTransport || '',
      savMulticolis: item.config.conditionsPriseEnCharge.savMulticolis || '',
      receptionApproche: item.config.conditionsPriseEnCharge.fluxAller.reception.approche || '',
      receptionDoc: item.config.conditionsPriseEnCharge.fluxAller.reception.document || '',
      receptionDelai: item.config.conditionsPriseEnCharge.fluxAller.reception.delaiReserves || '',
      livraisonDelai: item.config.conditionsPriseEnCharge.fluxAller.livraison.delaiReserves || '',
      livraisonCommentaire: item.config.conditionsPriseEnCharge.fluxAller.livraison.commentaire || '',
      reverseFlux: item.config.conditionsPriseEnCharge.fluxRetour.retour || '',
      reverseDelaiReverse: item.config.conditionsPriseEnCharge.fluxRetour.delaiRetour || '',
      reverseDoc: item.config.conditionsPriseEnCharge.fluxRetour.document || '',
      reverseDelai: item.config.conditionsPriseEnCharge.fluxRetour.delaiReserves || '',
      reverseCommentaire: item.config.conditionsPriseEnCharge.fluxRetour.commentaire || '',
      transfert: item.config.transfertPropriete.transfert ? 'OUI' : 'NON',
      transfertCommentaire: item.config.transfertPropriete.commentaire || '',
      particularites: item.config.particularites || ''
    };
    for (let i = 0; i < item.config.contacts.length && i < 3;  i++) {
      const c = item.config.contacts[i];
      values[`contact${i + 1}Nom`] = c.nom || '';
      values[`contact${i + 1}Fonction`] = c.fonction || '';
      values[`contact${i + 1}Email`] = c.email || '';
      values[`contact${i + 1}Telephone`] = c.telephone || '';
    }
    for (let i = 0; i < item.config.typologiesProduits.length && i < 3;  i++) {
      const c = item.config.typologiesProduits[i];
      values[`typologiesProduits${i + 1}Nom`] = c.nom || '';
      values[`typologiesProduits${i + 1}Commentaire`] = c.commentaire || '';
    }
    const inputs = [values];
    // @ts-ignore
    const template: Template = pdfTemplate;
    generate({ template, inputs }).then((pdf) => {
      const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      window.open(URL.createObjectURL(blob));
    });
  }

  get clientName() {
    if (!!this.annuaireItem && !!this.annuaireItem.client && !!this.annuaireItem.client.libelle) {
      return this.annuaireItem.client.libelle;
    }
    if (!!this.annuaireItem && !!this.annuaireItem.compte_client && !!this.annuaireItem.compte_client.libelle) {
      return this.annuaireItem.compte_client.libelle;
    }
    return '';
  }

  get itemModeDeSuivi(): any {
    return this.annuaireForm.value.modeDeSuivi;
  }

  get itemParticularites(): any {
    return this.annuaireForm.value.particularites;
  }

  get itemJustificatifCommentaire(): any {
    return this.annuaireForm.value.conditionsPriseEnCharge.commentaire;
  }

  get itemTransfertProprieteCommentaire(): any {
    return this.annuaireForm.value.transfertPropriete.commentaire;
  }

  get itemReverseCommentaire(): any {
    return this.annuaireForm.value.conditionsPriseEnCharge.fluxRetour.commentaire;
  }

  get itemAllerCommentaire(): any {
    return this.annuaireForm.value.conditionsPriseEnCharge.fluxAller.livraison.commentaire;
  }

  get contextSiege(): boolean {
    return Number(this.context) === User.CONTEXT_SIEGE;
  }
}
