import {Injectable} from '@angular/core';
import {Litige} from '../../models/api/litige.model';
import {FilterMultiCritereModel} from '../../models/filters/filterMultiCritere.model';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpUtil} from '../../utils/http.util';
import {Contestation} from '../../models/api/contestation.model';
import {Conversation} from '../../models/api/conversation.model';
import {Evenement} from '../../models/api/evenement.model';
import {PieceJointe} from '../../models/api/piece-jointe.model';
import {Article} from '../../models/api/article.model';
import {Responsabilite} from '../../models/api/responsabilite.model';
import {Statut} from '../../models/api/statut.model';
import {User} from '../../models/user.model';
import {Imputation} from '../../models/api/imputation.model';
import {Retrocession} from '../../models/api/retrocession.model';
import {Oda} from '../../models/api/oda.model';


@Injectable({
  providedIn: 'root'
})
export class LitigeService {

  urlRessource = environment.url_api_litige + '/litiges';

  constructor(private  http: HttpClient) {
  }

  /**
   *
   * @param filter
   */
  getLitiges(filter: FilterMultiCritereModel = null): Observable<Litige[]> {
    return this.http
      .get<Litige[]>(this.urlRessource, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((litiges) => litiges.map((litige) => {
        return new Litige().deserialize(litige);
      })));
  }

  getLitigesContestationATraiter(filter: FilterMultiCritereModel = null): Observable<Litige[]> {
    return this.http
      .get<Litige[]>(this.urlRessource, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((litiges) => litiges.map((litige) => {
        return new Litige().deserialize(litige);
      })));
  }

  getNbLitiges(filter: FilterMultiCritereModel = null): Observable<number> {
    return this.http.get<number>(`${this.urlRessource}-nb`, {params: HttpUtil.convertObjectToHttpParams(filter)});
  }


  getLitigesAAffecter(filter: FilterMultiCritereModel = null): Observable<Litige[]> {
    return this.http
      .get<Litige[]>(`${this.urlRessource}-a-affecter`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((litiges) => litiges.map((litige) => {
        return new Litige().deserialize(litige);
      })));
  }


  /**
   *
   * @param id
   */
  getLitige(id: number): Observable<Litige> {
    return this.http
      .get<Litige>(`${this.urlRessource}/${id}`)
      .pipe(map((litige) => {
        return new Litige().deserialize(litige);
      }));
  }

  /**
   *
   * @param litigeId
   */
  getContestations(litigeId: number): Observable<Contestation[]> {
    return this.http
      .get<Contestation[]>(`${this.urlRessource}/${litigeId}/contestations`)
      .pipe(map((contestations) => contestations.map((contestation) => {
        return new Contestation().deserialize(contestation);
      })));
  }

  /**
   *
   * @param litigeId
   */
  getResponsabilites(litigeId: number): Observable<Responsabilite[]> {
    return this.http
      .get<Responsabilite[]>(`${this.urlRessource}/${litigeId}/responsabilites`)
      .pipe(map((responsabilites) => responsabilites.map((responsabilite) => {
        return new Responsabilite().deserialize(responsabilite);
      })));
  }

  /**
   *
   * @param litigeId
   *
   */
  getArticles(litigeId: number): Observable<Article[]> {
    return this.http
      .get<Article[]>(`${this.urlRessource}/${litigeId}/articles`)
      .pipe(map((articles) => articles.map((article) => {
        return new Article().deserialize(article);
      })));
  }

  /**
   *
   * @param litigeId
   *
   * @param article
   */

  addArticle(litigeId: number, article: Article): Observable<any> {
    return this.http.post<{ message: string, next: boolean }>(`${this.urlRessource}/${litigeId}/articles`, article)
      .pipe(map((response) => {
        return new Article().deserialize(response);
      }));
  }

  /**
   *
   * @param litigeId
   */
  getConversations(litigeId: number): Observable<Conversation[]> {
    return this.http
      .get<Conversation[]>(`${this.urlRessource}/${litigeId}/conversations`)
      .pipe(map((conversations) => conversations.map((conversation) => {
        return new Conversation().deserialize(conversation);
      })));
  }

  /**
   *
   * @param litigeId
   */
  getEvenements(litigeId: number): Observable<Evenement[]> {
    return this.http
      .get<Evenement[]>(`${this.urlRessource}/${litigeId}/evenements`)
      .pipe(map((evenements) => evenements.map((evenement) => {
        return new Evenement().deserialize(evenement);
      })));
  }

  /**
   *
   * @param id
   */
  getPieceJointes(id: number): Observable<PieceJointe[]> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessource}/${id}/piece-jointes`,{headers: new HttpHeaders().set('nocache', 'true')})
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      })));
  }

  /**
   *
   * @param litige
   */
  updateLitige(litige: Litige): Observable<any> {
    return this.http.put(`${this.urlRessource}/${litige.id}`, litige)
      .pipe(map((litige) => {
        return new Litige().deserialize(litige);
      }));
  }

  /**
   *
   * @param id litigeId
   * @param filter
   */
  getJustificatifs(id: number, filter: any): Observable<PieceJointe[]> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessource}/${id}/justificatifs`, {params: HttpUtil.convertObjectToHttpParams(filter),headers: new HttpHeaders().set('nocache', 'true')})
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointe) => {
        return new PieceJointe().deserialize(pieceJointe);
      })));
  }



  /**
   * Cette methode permet de mettre à jour les responsabilité d'un litige
   * @param litigeId
   * @param responsabilites[]
   */
  updateResponsabilites(litigeId: number, responsabilites: {
    responsabilitesInterne: Responsabilite[],
    responsabilitesTiers: Responsabilite[]
  }): Observable<Responsabilite[]> {
    return this.http.put<Responsabilite[]>(`${this.urlRessource}/${litigeId}/responsabilites`, responsabilites)
      .pipe(map((responsabilites) => responsabilites.map((responsabilite) => {
          return new Responsabilite().deserialize(responsabilite);
        }
      )));
  }

  postConveration(id: number, conv: Conversation): Observable<Conversation> {
    return this.http
      .post<Conversation>(`${this.urlRessource}/${id}/conversation`, conv)
      .pipe(map((conversation) => {
        return new Conversation().deserialize(conversation);
      }));
  }

  /**
   * Cette methode permet de mettre a jour l'instruction de retour (seulement) d'un litige
   * @param litigeId
   * @param instructionRetourId
   */
  updateInstruction(litigeId: number, instructionRetourId: number): Observable<any> {
    return this.http.put(`${this.urlRessource}/${litigeId}/instruction`, {instruction: instructionRetourId});
  }

  addContestation(litigeId: number, contestation: Contestation): Observable<Contestation> {
    return this.http.post<Contestation>(`${this.urlRessource}/${litigeId}/contestation`, contestation)
      .pipe(map((contestation) => {
        return new Contestation().deserialize(contestation);
      }));
  }

  getWorkflowStatuts(litigeId: number) {
    return this.http.get<Statut[]>(`${this.urlRessource}/${litigeId}/check-workflow`)
      .pipe(map((statuts) => statuts.map((statut) => {
          return new Statut().deserialize(statut);
        }
      )));
  }

  changeStatut(litigeId: number, statutId, commentaire): Observable<Litige> {
    return this.http.put<Litige>(`${this.urlRessource}/${litigeId}/update-statut`, {statutId, commentaire})
      .pipe(map(litige => new Litige().deserialize(litige)));
  }

  /**
   * cette requete permet d'affecter un utilisateur au litige passé en parametre.
   * **** ATTENTION ** Si aucun utilisateur est passé en paramètre, l'utilisateur qui effectue la requete ce verra
   * affecter le litige.
   *    *
   * @param litigeId
   * @param userId
   */
  affectUserToLitige(litigeId: number, userId?: number): Observable<{ user: User, statut: Statut }> {
    return this.http.put<{ user: User, statut: Statut }>(`${this.urlRessource}/${litigeId}/affecte-user`, {userId})
      .pipe(map(response => {
        return {
          user: new User().deserialize(response.user),
          statut: new Statut().deserialize(response.statut)
        };

      }));
  }

  /**
   *
   * @param filter
   */
  getLitigeImputable(filter: FilterMultiCritereModel = null): Observable<Litige[]> {
    return this.http
      .get<Litige[]>(`${this.urlRessource}-imputables`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((litiges) => litiges.map((litige) => {
        return new Litige().deserialize(litige);
      })));
  }

  /**
   *
   * @param filter
   */
  getLitigeRetrocedable(filter: FilterMultiCritereModel = null): Observable<Litige[]> {
    return this.http
      .get<Litige[]>(`${this.urlRessource}-retrocessions`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((litiges) => litiges.map((litige) => {
        return new Litige().deserialize(litige);
      })));
  }

  getRetrocessions(litigeId): Observable<Retrocession[]> {
    return this.http
      .get<Retrocession[]>(`${this.urlRessource}/${litigeId}/retrocessions`)
      .pipe(map((retrocessions) => retrocessions.map((retrocession) => {
        return new Retrocession().deserialize(retrocession);
      })));
  }

  getRetrocessionsAtraite(litigeId): Observable<Retrocession[]> {
    return this.http
      .get<Retrocession[]>(`${this.urlRessource}/${litigeId}/retrocessions-a-traiter`)
      .pipe(map((retrocessions) => retrocessions.map((retrocession) => {
        return new Retrocession().deserialize(retrocession);
      })));
  }

  postLitige(litige: Litige): Observable<number> {
    return this.http.post<number>(`${this.urlRessource}/ajouter`, litige);
  }

  getImputations(litigeId, filter: any = {}): Observable<Imputation[]> {
    return this.http
      .get<Imputation[]>(`${this.urlRessource}/${litigeId}/imputations`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((imputations) => imputations.map((imputation) => {
        return new Imputation().deserialize(imputation);
      })));
  }

  getOda(litigeId): Observable<Oda[]> {
    return this.http
      .get<Oda[]>(`${this.urlRessource}/${litigeId}/odas`)
      .pipe(map((odas) => odas.map((oda) => {
        return new Oda().deserialize(oda);
      })));
  }

  caculateLimitResponsability(litigeId, params): Observable<number> {
    return this.http.get<number>(`${this.urlRessource}/${litigeId}/limite-responsabilite`,
      {params: HttpUtil.convertObjectToHttpParams(params)});
  }

  /**
   *
   * @param litige
   */
  validatePlatforme(litige: Litige): Observable<any> {
    return this.http.put(`${this.urlRessource}/${litige.id}/validate`, litige)
      .pipe(map((litige) => {
        return new Litige().deserialize(litige);
      }));
  }
}
