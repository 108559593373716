import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Article} from '../../../../shared/models/api/article.model';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {Responsabilite} from '../../../../shared/models/api/responsabilite.model';
import {Acteur} from '../../../../shared/models/api/acteur.model';

@Component({
  selector: 'app-table-responsability',
  templateUrl: './table-responsability.component.html',
  styleUrls: ['./table-responsability.component.scss']
})
export class TableResponsabilityComponent implements OnInit, OnChanges {

  public codePartenaires = Acteur.CODE_AGEDISS_PARTENAIRES;
  public dataSorted: MatTableDataSource<Responsabilite>;
  @Input() data: Responsabilite[];
  @Input() hiddenColumns: string[] = [];
  @Input() displayedColumns: string[] = ['responsable','type','pourcent','montant','validate'];
  @Input() noRecordText = 'Aucune responsabilité';
  @Input() loadingText = 'Chargement en cours <i class=\'fas fa-spin fa-circle-notch\'></i>';
  @Input() loading = false;

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

}
