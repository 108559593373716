import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Litige} from '../../shared/models/api/litige.model';
import {LitigeService} from '../../shared/services/api/litige.service';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';

import {Observable, of} from "rxjs";
import {count, debounceTime, finalize, map, startWith, switchMap, tap} from "rxjs/operators";
import {FormControl} from "@angular/forms";


@Component({
  selector: 'test',
  templateUrl: './test.html'
})

export class Test implements OnInit {
  searchMoviesCtrl = new FormControl();
  filteredMovies: any;
  isLoading = false;
  errorMsg: string;
  displayFn: any;
  litiges: Litige[] = [];
  private changeCallback: Function;
  private touchedCallback: Function;
  isAllSelected = false;
  selectedItems: any[] = new Array<any>();
  filteredItems: any[] = [];
  public selected?: boolean

  constructor(
    private litigeSvc: LitigeService,
    private cd: ChangeDetectorRef,
  ) {
    if (this.selected === undefined) this.selected = false;
  }

  optionClicked(event: Event, item: Litige) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item);
      // this.changeCallback( this.selectedItems );
    } else {
      const i = this.selectedItems.findIndex(value => value.numero_avis === item.numero_avis);
      this.selectedItems.splice(i, 1);
    }

  }

  ngOnInit() {
    this.searchMoviesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap((t) => {
          this.errorMsg = "";
          this.filteredMovies = [];
        }),
        switchMap((value) => {
          if (!value || value.length < 4) {
            console.log("value",value);
            return of([])
          }
            this.isLoading = true;
            return this.litigeSvc.getLitiges(<FilterMultiCritereModel>{search: value})
              .pipe(
                map((litiges) => litiges.map((litige) => {
                  // on regarde si le bl à déja été selectionné
                  litige.selected = false;
                  console.log(this.selectedItems);
                  const lit = this.selectedItems.find(i => {
                    return i.numero_avis == litige.numero_avis
                  })
                  console.log(lit)
                  if (lit) {
                    litige.selected = true;
                  }
                  return litige;
                })),
                finalize(() => {
                  this.isLoading = false
                }),
              )
          }
        )
      )
      .subscribe(data => {
        this.filteredItems = data
      });
  }

  toggleSelectAll() {
    this.isAllSelected = !this.isAllSelected;
    let len = this.filteredItems.length;
    if (this.isAllSelected) {
      for (let i = 0; i++; i < len)
        this.filteredItems[i].selected = true;
      // this.selectedItems = data;
      this.selectedItems = this.filteredItems;
      // for ( let i=0; i++; i<len )
      //   this.items[i].selected = true;
      // this.filteredItems. = [...this.items];
      // this.changeCallback( this.selectedItems );
      this.cd.markForCheck();
      // this.itemControl.updateValueAndValidity();
    } else {
      this.selectedItems = [];
      // for ( let i=0; i++; i<len )
      // this.items[i].selected = false;

    }
    // this.changeCallback( this.selectedItems );
  }

  registerOnChange(fn: Function) {
    this.changeCallback = fn;
  }

  registerOnTouched(fn: Function) {
    this.touchedCallback = fn;
  }
}
