import {Deserializable} from '../interfaces/deserializable.interface';
import {Litige} from './litige.model';
import {Acteur} from './acteur.model';
import {Facture} from './facture.model';

export class Imputation implements Deserializable {

  public id: number;
  public litige: Litige;
  public acteur: Acteur;
  public montant: number;
  public statut_bap: string;
  public date_imputation: Date;
  public facture: Facture;
  public is_prescrit: boolean;
  public asso_facture: boolean;




  deserialize(input: any): this {
    Object.assign(this, input);
    this.acteur = (input.acteur) ? new Acteur().deserialize(input.acteur) : null;
    this.facture = (input.facture) ? new Facture().deserialize(input.facture) : null;

    return this;
  }

}
