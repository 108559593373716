import {Deserializable} from '../interfaces/deserializable.interface';
import {CompteClient} from './compte-client.model';
import {Origine} from './origine.model';

export class Client implements Deserializable {

  public id?: number;
  public groupe: string;
  public created_at: Date;
  public updated_at: Date;
  public code: string;
  public libelle: string;
  public compte_client?: CompteClient;


  deserialize(input: any): this {
    Object.assign(this, input);
    this.compte_client =  (input.compte_client) ? new CompteClient().deserialize(input.compte_client) : null;
    return this;
  }

}
