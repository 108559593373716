import {Component, OnDestroy, OnInit} from '@angular/core';
import {FactureService} from '../../shared/services/api/facture.service';
import {Facture} from '../../shared/models/api/facture.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalAttenteFactureComponent} from '../../components/facture/modal/modal-attente-facture/modal-attente-facture.component';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {FieldParametersModel} from '../../shared/models/filters/field-parameters.model';
import {ModalAffecterEditableFactureComponent} from '../../components/facture/modal/modal-affecter-editable-facture/modal-affecter-editable-facture.component';


@Component({
  selector: 'affectations',
  templateUrl: './affectations.html'

})
export class AffectationsPage implements OnDestroy, OnInit {

  public loadingTable = false;
  public displayedColumns = [
    'idPieceJointe',
    'client',
    'typeFacture',
    'date',
    'numeroInterne',
    'statut',
    'montant',
    'options',
    'modifier',
  ];
  public context = 'affecter';
  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public factures: Facture[];
  private activModal: NgbModalRef;

  public displayInputFilter = [
    new FieldParametersModel().deserialize({
      input: 'motif',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'comptesClients',
      show: true
    }),
    new FieldParametersModel().deserialize({
      input: 'plateforme',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'responsable',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'statut',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'origine',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'client',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'date',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'typeLitige',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'searchFacture',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'traitePar',
      show: false
    }),
    new FieldParametersModel().deserialize({
      input: 'pole',
      show: false
    })
  ];


  constructor(private factureSvc: FactureService, private modalSvc: NgbModal, ) {
  }


  ngOnInit() {
  }


  ngOnDestroy(): void {
  }


  openModalAffectationFacture(event) {
    this.activModal = this.modalSvc.open(ModalAffecterEditableFactureComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.componentInstance.factureId = event.id;
    this.activModal.result.then(
      (result) => {
        this.loadData();
      }, reason => {
      });
  }


  openModalEditFacture(event) {
    this.activModal = this.modalSvc.open(ModalAttenteFactureComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });
    this.activModal.componentInstance.factureId = event.id;
    this.activModal.componentInstance.disabled = true  ;
    this.activModal.result.then(
      (result) => {
        this.loadData();
      }, reason => {
      });
  }


  onAffected(bool) {
    if (bool == true) {
      this.loadData();
    }
  }


  loadData() {
    this.loadingTable = true;
    const sub = this.factureSvc.getFacturesAffecterEditable(this.filter).subscribe(factures => {
      this.factures = factures;
      this.loadingTable = false;
      sub.unsubscribe();
    });
  }


  onSubmit(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.loadData();
  }
}

