<!-- begin page-header -->
<div class="row mb-2">
  <div class="col-md-4"></div>
  <div class="col-md-4 text-center"><h1 class="page-header">Tableau de bord des imputations</h1></div>
</div>
<!-- end page-header -->

<!-- begin Form -->
<div class="alert alert-light fade show">
  <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-5 p-t-5">
        <div class="input-group input-group">
          <div class="input-group-prepend"><span
            class="input-group-text agediss">Rechercher facture (numero interne) : </span></div>
          <input class="form-control" placeholder="202001.."
                 formControlName="numeroInterne"/>
        </div>
      </div>
      <div class="col-2 text-right p-t-10">
        Voir imputations déja traitées :
      </div>
      <div class="col-1 p-t-10">
        <div class="switcher">
          <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" formControlName="isDone">
          <label for="switcher_checkbox_1"></label>
        </div>
      </div>
      <div class="col-4">
        <input class="btn btn-agediss btn-lg btn-block" type="submit" value="Filtrer / retirer les lignes traitées">
      </div>
    </div>
  </form>
</div>
<div class="alert alert-light fade show m-b-10">
  <div class="row">
    <div class="col-2 ">Numéro facture</div>
    <div class="col-10">
      <div class="row">
        <div class="col-2 ">Date litige</div>
        <div class="col-10">
          <div class="row">
            <div class="col-5 ">Plateforme imputable</div>
            <div class="col-5">Montant</div>
            <div class="col-2">Traité</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" style="height: 50em">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="alert alert-light fade show m-b-5"  [ngStyle]="{ 'background-color': (getType(item.key) === 'AVOIR') ? '#99e4e6':'white'}" style="box-shadow: 0 2px 5px black;"
       *ngFor="let item of factures | keyvalue; let i = index;">
    <div class="row">
      <div class="col-2 p-t-10" style="border-right: solid 1px black">
       <p> {{item.key}}</p>
        <p> {{getType(item.key)}}</p>
      </div>
      <div class="col-10">
        <div class="row" *ngFor="let row of item.value | keyvalue; last as isLast1;">
          <div class="col-2 p-t-10" [ngClass]="{'bottom-line ': getLenght(item.value) > 1 && !isLast1}"
               style="border-right: solid 1px black">{{row.key }}</div>
          <div class="col-10">
            <div class="row p-5" *ngFor="let line of row.value; last as isLast2;"
                 [ngClass]="{'bottom-line ': (row.value.length > 1 &&  !isLast2) ||(getLenght(item.value) > 1 && !isLast1)}">
              <div class="col-5 p-t-5">{{line.acteur}}</div>
              <div class="col-5 p-t-5">{{line.total_imputer | currency : 'EUR': '€'}}</div>
              <div class="col-2">
                <button *ngIf="!line.done_date" class="btn btn-icon btn-info" data-style="zoom-in" (click)="checkImputation(line)" [ladda]="line.loading"><i class="fa fa-2x fa-square"></i></button>
                <button *ngIf="line.done_date" class="btn btn-icon btn-green"  data-style="zoom-in" (click)="uncheckImputation(line)" [ladda]="line.loading" ><i class="fa fa-2x fa-check-square "></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- end processing table "A traiter" -->

