<div class="alert alert-light fade show">
  <app-filter-litige (submitFilter)="onSubmit($event)" [displayInput]="displayInputFilter" (onLoad)="onSubmit($event)" [pageName]="'justificatifs'"></app-filter-litige>
</div>


<div class="row">
  <div class="col-12">
    <panel title="Validation des justificatifs"  noButton="true" bodyClass="no-padding" class="text-center">
      <div class="col-12">
        <h5 class="p-t-5">
          <i>
            <span *ngIf="showRefused">Justificatifs refusés :</span>
            <span *ngIf="!showRefused">Justificatifs à valider :</span>
            <span *ngIf="!loadingNbJustificatif && !loadingTableJustificatifs">
              {{nbFilteredJustificatif}} <span *ngIf="!showRefused">({{nbJustificatif}} au total)</span>
            </span>
            <i *ngIf="loadingNbJustificatif || loadingTableJustificatifs" class="fas fa-spin fa-circle-notch"></i>
          </i>
        </h5>
      </div>
      <div class="row form-group mt-2">
        <label class="col-md-3 col-form-label">Afficher les justificatifs refusés</label>
        <div class="switcher">
          <input type="checkbox" name="showRefused"
                 [value]="showRefused"
                 (click)="switcher()"
                 id="showRefused">
          <label for="showRefused"></label>
        </div>
      </div>
      <div style="height:  75vh;" class="overflow-scroll">
        <app-table-justificatif [disableColumns]="disableColumns"
                                (openLitige)="openModalLitige($event)"
                                [showValideJustifBtn]="view.hasDroit('JUSTIFICATIF_VALIDATE')"
                                (selectJustif)="openModalAffectationPieceJointe(modal_validation_justificatifs, 'xl', $event)"
                                [loading]="loadingTableJustificatifs" [data]="pieceJointes"></app-table-justificatif>
      </div>
    </panel>
  </div>
</div>

<!--modal validation des justificatifs-->
<ng-template #modal_validation_justificatifs let-modal>
  <app-validation-justificatf [justificatifId]="selectedJustifId" (submited)="modal.close($event)"
                              (canceled)="modal.close($event)"></app-validation-justificatf>
</ng-template>
<!--fin modal validation des justificatifs -->


<!--modal validation des justificatifs-->
<ng-template #modal_litige let-modal>

  <div class="modal-header text-center">
    <h4 class="modal-title  w-100">Aperçu du litige : {{litigeSelectId}}</h4>
  </div>
  <div class="modal-body text-center">
    <litige [litigeId]="litigeSelectId"></litige>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn  bg-black-lighter text-white" (click)="modalPreviewLitige.close()">Fermer</button>
  </div>

</ng-template>
<!--fin modal validation des justificatifs -->
