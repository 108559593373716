import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '../../../shared/models/user.model';
import {LitigeService} from '../../../shared/services/api/litige.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {Subscription} from 'rxjs';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {Client} from '../../../shared/models/api/client.model';
import {Plateforme} from '../../../shared/models/api/plateforme.model';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {Motif} from '../../../shared/models/api/motif.model';
import {Statut} from '../../../shared/models/api/statut.model';
import {Origine} from '../../../shared/models/api/origine.model';
import {MotifService} from '../../../shared/services/api/motif.service';
import {ClientService} from '../../../shared/services/api/client.service';
import {OrigineService} from '../../../shared/services/api/origine.service';
import {PlateformeService} from '../../../shared/services/api/plateforme.service';
import {PoleService} from '../../../shared/services/api/pole.service';
import {StatutService} from '../../../shared/services/api/statut.service';
import {UserService} from '../../../shared/services/api/user.service';
import {RangeDatePickerComponent} from '../../shared/range-date-picker/range-date-picker.component';
import {CompteClient} from '../../../shared/models/api/compte-client.model';
import {Responsable} from '../../../shared/models/api/responsable.model';
import {ActeurService} from '../../../shared/services/api/acteur.service';
import {TypeLitigeService} from '../../../shared/services/api/typeLitige.service';
import {TypeLitige} from '../../../shared/models/api/type-litige.model';
import {View} from '../../../shared/models/view.model';
import {FilterService} from '../../shared/filter.service';
import {Pole} from '../../../shared/models/api/pole.model';

@Component({
  selector: 'app-filter-litige',
  templateUrl: './filter-litige.component.html',
  styleUrls: ['./filter-litige.component.scss']
})
export class FilterLitigeComponent implements OnInit, OnDestroy {

  @ViewChild('inputDate') inputDate: RangeDatePickerComponent;

  @Input() displayInput: FieldParametersModel[] = [];
  @Input() pageName: String;

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public hiddenInput = [];
  public motifs: Motif[];
  public clients: Client[];
  public origines: Origine[];
  public plateformes: Plateforme[];
  public statuts: Statut[];
  public responsable: Responsable[];
  public typeLitige: TypeLitige[];
  public pole: Pole[];
  public users: User[];
  public loadingBtn = false;
  public subscriptions: Subscription[] = [];
  public compteClients: CompteClient[];
  public filterForm = new FormGroup({
    plateforme: new FormControl(),
    client: new FormControl(),
    comptesClients: new FormControl(),
    pole: new FormControl(),
    origine: new FormControl(),
    statut: new FormControl(),
    motif: new FormControl(),
    minDate: new FormControl(),
    maxDate: new FormControl(),
    dateLitige: new FormControl(),
    createdAt: new FormControl(),
    responsable: new FormControl(),
    typeLitige: new FormControl(),
    traitePar: new FormControl(),
    searchFacture: new FormControl(),
    search: new FormControl()
  });
  public loadPlateformes = false;
  public loadClient = false;
  public loadGroup = false;
  public loadOrigines = false;
  public loadUsers = false;
  public loadStatut = false;
  public loadResponsable = false;
  public loadTypeLitige = false;
  public loadPole = false;
  public loadMotif = false;
  public view: View;

  public minDate;
  public maxDate;


  constructor(private litigesSvc: LitigeService, private authSvc: AuthenticationService,
              private motifSvc: MotifService, private clientSvc: ClientService,
              private origineSvc: OrigineService, private plateformeSvc: PlateformeService,
              private poleSvc: PoleService, private acteurSvc: ActeurService,
              private statutSvc: StatutService, private userSvc: UserService,
              private  typeLitigeSvc: TypeLitigeService, private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.loadPlateformes = true;
    this.loadClient = true;
    this.loadGroup = true;
    this.loadOrigines = true;
    this.loadUsers = true;
    this.loadStatut = true;
    this.loadResponsable = true;
    this.loadTypeLitige = true;
    this.loadPole = true;
    this.loadMotif = true;

    this.authSvc.currentView.subscribe(x => this.view = x);

    this.subscriptions.push(this.statutSvc.getStatuts(StatutService.LITIGE).subscribe(value => {
      this.statuts = value;
      this.loadStatut = false;
    }));
    if (!this.displayInput.find(t => t.input === 'motif') || this.displayInput.find(t => t.input === 'motif').show) {
      this.subscriptions.push(this.motifSvc.getMotifs().subscribe(
        value => {
          this.motifs = value;
          this.loadMotif = false;
        },
        error => this.handleErrorApi(error, this.motifs)
      ));
    }

    if (this.view.context == '2') {

      this.hiddenInput = ['plateforme', 'pole', 'origine', 'traitePar', 'typeLitige', 'searchFacture'];
      if (!this.displayInput.find(t => t.input === 'client') || this.displayInput.find(t => t.input === 'client').show) {
        this.subscriptions.push(this.clientSvc.getClients().subscribe(value => {
          this.clients = value;
          this.loadClient = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'typeLitige') || this.displayInput.find(t => t.input === 'typeLitige').show) {
        this.subscriptions.push(this.clientSvc.getCompteClients().subscribe(value => {
          this.compteClients = value;
          this.loadGroup = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'responsable') || this.displayInput.find(t => t.input === 'responsable').show) {
        this.subscriptions.push(this.acteurSvc.getActeursTiers({discr: 'sous_traitant'}).subscribe(value => {
          this.responsable = value;
          this.loadResponsable = false;
        }));
      }

    } else {
      if (!this.displayInput.find(t => t.input === 'client') || this.displayInput.find(t => t.input === 'client').show) {
        this.subscriptions.push(this.clientSvc.getClients().subscribe(value => {
          this.clients = value;
          this.loadClient = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'comptesClients') || this.displayInput.find(t => t.input === 'comptesClients').show) {
        this.subscriptions.push(this.clientSvc.getCompteClients().subscribe(value => {
          this.compteClients = value;
          this.loadGroup = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'plateforme') || this.displayInput.find(t => t.input === 'plateforme').show) {
        this.subscriptions.push(this.plateformeSvc.getPlateformes().subscribe(value => {
          this.plateformes = value;
          this.loadPlateformes = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'origine') || this.displayInput.find(t => t.input === 'origine').show) {
        this.subscriptions.push(this.origineSvc.getOrigines().subscribe(value => {
          this.origines = value;
          this.loadOrigines = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'responsable') || this.displayInput.find(t => t.input === 'responsable').show) {
        this.subscriptions.push(this.acteurSvc.getActeursAll().subscribe(value => {
          this.responsable = value;
          this.loadResponsable = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'typeLitige') || this.displayInput.find(t => t.input === 'typeLitige').show) {
        this.subscriptions.push(this.typeLitigeSvc.getTypeLitige().subscribe(value => {
          this.typeLitige = value;
          this.loadTypeLitige = false;
        }));
      }
      if (!this.displayInput.find(t => t.input === 'traitePar') || this.displayInput.find(t => t.input === 'traitePar').show) {
        this.subscriptions.push(this.poleSvc.getPoles().subscribe(value => {
        this.pole = value;
        this.loadPole = false;
      }));
        this.subscriptions.push(this.userSvc.getUsers().subscribe(value => {
        this.users = value;
        this.loadUsers = false;
      })); }
    }

    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput, this.hiddenInput);
    this.getValueLocalstorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  onSubmit() {
    // récupère les infos du filtre
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'searchFilter', 'search');

    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'clientFilter', 'client');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'plateformeFilter', 'plateforme');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'comptesClientsFilter', 'comptesClients');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'poleFilter', 'pole');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'origineFilter', 'origine');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'statutFilter', 'statut');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'motifFilter', 'motif');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'dateLitigeFilter', 'dateLitige');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'createdAtFilter', 'createdAt');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'responsableFilter', 'responsable');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'typeLitigeFilter', 'typeLitige');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'traiteParFilter', 'traitePar');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'searchFactureFilter', 'searchFacture');

    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'minDateFilter', 'minDate');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, this.pageName + 'maxDateFilter', 'maxDate');

    this.filter = this.filterForm.getRawValue();
    this.submitFilter.emit(this.filter);
  }


  getValueLocalstorage() {
    this.filterForm.get('search').setValue(localStorage.getItem('searchFilter'));

    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'clientFilter', 'client');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'plateformeFilter', 'plateforme');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'comptesClientsFilter', 'comptesClients');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'poleFilter', 'pole', false);
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'origineFilter', 'origine');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'statutFilter', 'statut');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'motifFilter', 'motif');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, this.pageName + 'dateLitigeFilter', 'dateLitige');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, this.pageName + 'createdAtFilter', 'createdAt');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'responsableFilter', 'responsable');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'typeLitigeFilter', 'typeLitige');
    this.filterSvc.onGetMultipleValue(this.filterForm, this.pageName + 'traiteParFilter', 'traitePar');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, this.pageName + 'searchFactureFilter', 'searchFacture');

    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, this.pageName + 'minDateFilter', 'minDate');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, this.pageName + 'maxDateFilter', 'maxDate');

    this.onDateLocalstorage(this.pageName + 'minDateFilter', this.pageName + 'maxDateFilter');
  }

  /**
   *   on affiche la date enregistrée dans le localstorage, dans l'input
   */
  onDateLocalstorage(minDateFilterName, maxDateFilterName) {
    if (localStorage.getItem(minDateFilterName)) {
      this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
    }
    if (localStorage.getItem(maxDateFilterName)) {
      this.maxDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(maxDateFilterName));
    }
  }

  handleErrorApi(error, val) {
    val = null;
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  reset(): void {
    this.filterForm.reset();
    this.inputDate.reset();
  }


}
