<table mat-table [dataSource]="dataSorted"
       class="table table-hover table-striped table-bordered mat-elevation-z8 text-center">
  <ng-container matColumnDef="checkbox">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="align-middle" mat-cell *matCellDef="let element">{{element.id}}</td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td class="align-middle" mat-cell *matCellDef="let element">{{element.created_at | date: 'dd-MM-yyyy'}}</td>
  </ng-container>
  <ng-container matColumnDef="facture">
    <th mat-header-cell *matHeaderCellDef>Facture</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.facture.numero_interne}} </td>
  </ng-container>
  <ng-container matColumnDef="commentaire">
    <th mat-header-cell *matHeaderCellDef>Commentaire</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      {{element.commentaire}}
    </td>
  </ng-container>
  <ng-container matColumnDef="debit">
    <th mat-header-cell *matHeaderCellDef>Debit</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{!element.sens_comptable ? element.montant: null}} </td>
  </ng-container>
  <ng-container matColumnDef="credit">
    <th *matHeaderCellDef mat-header-cell>Credit</th>
    <td *matCellDef="let element" class="align-middle" mat-cell>  {{element.sens_comptable ? element.montant : null}}  </td>
  </ng-container>
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code analytique</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.code_analytique}} </td>
  </ng-container>

  <ng-container matColumnDef="done_date">
    <th mat-header-cell *matHeaderCellDef>Traitée</th>
    <td class="align-middle" mat-cell *matCellDef="let element"> {{element.done_date | date : 'dd-MM-yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>

  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 10px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>


