<div>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text agediss">Date</span>
    </div>
    <input
        #myRangeInput
        class="form-control"
        placeholder="mm/dd/yyyy - mm/dd/yyyy"
        name="dp"
        ngbDatepicker
        [dayTemplate]="t"
        (click)="openDatePicker()"
        [autoClose]="false"
        [displayMonths]="2"
        #d="ngbDatepicker"
        [(ngModel)]="date"
        [value]="customDateShow"
        (ngModelChange)="onChangeDate($event)"
        autocomplete="off"/>
    <ng-template #t let-date="date" let-focused="focused">
            <span class="custom-day"
                  [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (click)="onDateSelection(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
            {{ date.day }}
            </span>
    </ng-template>
    <div class="input-group-append">
      <button class="btn btn-outline-danger" (click)="openDatePicker()" type="button"><i class="fa fa-calendar"></i>
      </button>
    </div>
  </div>
</div>

