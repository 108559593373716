import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Facture} from '../../../../shared/models/api/facture.model';
import {of, Subscription} from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FactureService} from '../../../../shared/services/api/facture.service';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {Litige} from "../../../../shared/models/api/litige.model";
import {formatDate} from "@angular/common";
import {debounceTime, finalize, map, switchMap, tap} from "rxjs/operators";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {LitigeService} from "../../../../shared/services/api/litige.service";

@Component({
  selector: 'app-modal-attente-facture',
  templateUrl: './modal-reaffecter-facture.component.html',
  styleUrls: ['./modal-reaffecter-facture.component.scss']
})
export class ModalReaffecterFacture implements OnInit {


  @Input() Indexlitige: any;
  @Input() facture: Facture;
  @Input() listLitiges: Litige[] = [];
  @Input() listLitigesBackup: Litige[] = [];
  @Output() newAffectation: EventEmitter<Litige> = new EventEmitter();

  public subscriptions: Subscription[] = [];
  public loading: Boolean = false;
  public loadingBtn: Boolean = false;
  public showReaffectation: Boolean = false;
  public showDelete: Boolean = false;
  public switchAffectationForm: FormGroup;
  public listLitigesAffecter: Litige[] = [];

  searchLitige = new FormControl();
  isLoading = false;
  errorMsg: string;
  displayFn: any;
  isAllSelected = false;
  selectedItems: any[] = new Array<any>();
  filteredItems: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public factureSvc: FactureService,
    private toastSvc: ToastrService,
    public litigesSvc: LitigeService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.initSearchLitigeForm()

  }


  initSearchLitigeForm() {
    this.searchLitige.valueChanges
      .pipe(
        debounceTime(500),
        tap((t) => {
          this.errorMsg = "";
          this.filteredItems = [];
        }),
        switchMap((value) => {
            if (!value || value.length < 4) {
              return of([])
            }
            this.isLoading = true;
            return this.litigesSvc.getLitigesAAffecter(<FilterMultiCritereModel>{search: value})
              .pipe(
                map((litiges) => litiges.map((litige) => {
                  // on check les litige déja affectés.
                  const litigeHold = this.listLitigesAffecter.find(i => {
                    return i.numero_avis == litige.numero_avis
                  });

                  litige.affected = !!litigeHold;
                  // on regarde si le bl à déja été selectionné
                  litige.selected = false;
                  const lit = this.selectedItems.find(i => {
                    return i.numero_avis == litige.numero_avis
                  })
                  if (lit) {
                    litige.selected = true;
                  }
                  return litige;
                })),
                finalize(() => {
                  this.isLoading = false
                }),
              )
          }
        )
      )
      .subscribe(data => {
        this.filteredItems = data
      }, error => {
        this.filteredItems = []
      });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  close() {
    this.activeModal.close(false);
  }

  submit() {
    this.loadingBtn = true;
    const values = this.switchAffectationForm.value;


    //cas reafecation
    if (this.showReaffectation) {

      this.factureSvc.updateFactureReaffecter(this.facture, values.indexPreviousLitige, values.newLitiges.id).subscribe(values => {
        this.toastSvc.success('Affectation à la facture réussie', 'Info', {progressBar: true});
        this.loadingBtn = false;
        this.activeModal.close(true);
      }, e => {
        console.error(e);
        this.toastSvc.warning('Affectation à la facture échouée', 'Info', {progressBar: true});
        this.loadingBtn = false;
      });

    }
    //cas supppresion
    if (this.showDelete) {

    }

  }

  reaffectation() {
    this.showReaffectation = true;
    this.showDelete = false;

  }

  delete() {
    this.showReaffectation = false;
    this.showDelete = true;
  }

  initForm(litige) {
    this.switchAffectationForm = this.formBuilder.group({
      indexPreviousLitige: new FormControl(this.Indexlitige),
      newLitiges: new FormControl(litige),
    });

    this.loading = false;
  }


  groupValueFn = (_: string, children: any[]) => ({
    name: children[0].date_litige != undefined ? formatDate(children[0].date_litige, 'MMMM y', 'fr') : 'SANS DATE LITIGE',
    total: children.length
  });
}
