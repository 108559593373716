import {Injectable} from '@angular/core';
import {AnnuaireItem} from '../../models/api/annuaire.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AnnuaireService {

  urlRessource = environment.url_api_litige + '/annuaire-items';

  constructor(private  http: HttpClient) {
  }


  getAnnuaire(): Observable<AnnuaireItem[]> {
    return this.http.get<AnnuaireItem[]>(this.urlRessource)
      .pipe(map((items) => items.map((item) => {
        return new AnnuaireItem().deserialize(item);
      })));
  }

  getAnnuaireItem(id: number): Observable<AnnuaireItem> {
    return this.http.get<AnnuaireItem>(`${this.urlRessource}/${id}`)
      .pipe(map((item) => {
        return new AnnuaireItem().deserialize(item);
      }));
  }

  getAnnuaireItemByClient(id: number): Observable<AnnuaireItem> {
    return this.http.get<AnnuaireItem>(`${this.urlRessource}?client=${id}`)
      .pipe(map((item) => {
        return new AnnuaireItem().deserialize(item);
      }));
  }

  createAnnuaireItem(item: AnnuaireItem): Observable<any> {
    return this.http.post(`${this.urlRessource}`, item)
      .pipe(map((ret) => {
        return new AnnuaireItem().deserialize(ret);
      }));
  }

  updateAnnuaireItem(item: AnnuaireItem): Observable<any> {
    return this.http.put(`${this.urlRessource}/${item.id}`, item)
      .pipe(map((ret) => {
        return new AnnuaireItem().deserialize(ret);
      }));
  }

  deleteAnnuaireItem(item: AnnuaireItem): Observable<any> {
    return this.http.delete(`${this.urlRessource}/${item.id}`);
  }
}
