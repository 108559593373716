import {Injectable} from '@angular/core';
import {Statut} from '../../models/api/statut.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpUtil} from '../../utils/http.util';


@Injectable({
  providedIn: 'root'
})
export class StatutService {


  static CONTEXTE = ['LITIGE', 'CONTESTATION', 'FACTURATION', 'JUSTIFICATIF', 'FRAISTRANSPORT'];
  static LITIGE = 'LITIGE';
  static CONTESTATION = 'CONTESTATION';
  static FACTURATION = 'FACTURATION';
  static JUSTIFICATIF = 'JUSTIFICATIF';
  static FRAIS_TRANSPORT = 'FRAISTRANSPORT';

  urlRessource = environment.url_api_litige + '/statuts';

  constructor(private http: HttpClient) {

  }

  /**
   * retourne les statuts en fonction du context passé en parametre
   */
  getStatuts(context: string): Observable<Statut[]> {
    if (StatutService.CONTEXTE.indexOf(context) === -1) {
      throw new Error('Le contexte ' + context + ' n\'existe pas dans les statuts ');
    }
    return this.http
      .get<Statut[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams({context})})
      .pipe(map((observe) => observe.map(o => new Statut().deserialize(o))));
  }

}
