<!--suppress ALL -->
<div class="modal-header text-center">
  <h4 class="modal-title w-100">Reaffectation de la facture </h4>
</div>

<div class="modal-body">
  <div *ngIf="loading" class="row col-12" style="height: 310px">
    <span class="spinner"></span>
  </div>
  <div *ngIf="!loading" style="height: 310px">
    <div class="row col-12">
      Voulez vous reaffecter la facture de ce litige ?
    </div>
    <div class="row col-6">
      <button (click)="reaffectation()" class="btn bg-black-lighter text-white" type="button">Reaffectation</button>
      <!--      <button (click)="delete()" class="btn bg-black-lighter text-white" type="button">Suppression</button>-->
    </div>

    <div *ngIf="showReaffectation" class="row ligne-search">
      <div class="col-md-12">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="rechercher (min 4 chiffres)" aria-label="State" [matAutocomplete]="auto"
                 [formControl]="searchLitige">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngIf="isLoading" class="is-loading">Recherche en cours ...</mat-option>
            <ng-container *ngIf="!isLoading && filteredItems.length == 0">
              <mat-option [value]="null">Aucun résultat</mat-option>
            </ng-container>
            <ng-container *ngIf="!isLoading && filteredItems.length > 0">
              <mat-option *ngFor="let item of filteredItems" [value]="item.numero_avis" [disabled]="item.affected">
                <div *ngIf="item.affected">
                  <span ngbTooltip="Litige déja affecté à la facture"
                        placement="right"
                        container="body"><div>
                            ID : {{item.id}} [ {{item.type_litige.libelle}} ]
                            BL {{item.numero_avis}} - {{item.destinataire_nom }} |
                            montant: {{item.montant_valide | currency :'EUR': '€'}}
                          </div>
                  </span>
                </div>
                <div *ngIf="!item.affected" (click)="initForm(item);">
                  <span>
                    <div>
                            ID : {{item.id}} [ {{item.type_litige.libelle}} ]
                            BL {{item.numero_avis}} - {{item.destinataire_nom }} |
                            montant: {{item.montant_valide | currency :'EUR': '€'}}
                          </div>
                  </span>

                </div>
              </mat-option>
            </ng-container>

          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <!--    <div class="row col-12 mt-3">-->
    <!--        <div class="col-md-11">-->
    <!--          <ng-select [items]="listLitiges"-->
    <!--                     [ngModel]="value"-->
    <!--                     [selectOnTab]="true"-->
    <!--                     bindValue="id"-->
    <!--                     bindLabel="numero_avis"-->
    <!--                     [clearable]="false"-->
    <!--                     [groupBy]="groupByFn"-->
    <!--                     [groupValue]="groupValueFn"-->
    <!--                     [selectableGroup]="true"-->
    <!--                     [selectableGroupAsModel]="false"-->
    <!--                     labelForId="litige"-->
    <!--                     (change)="initForm($event)"-->
    <!--                     placeholder="Choisir">-->
    <!--            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">-->
    <!--              <div>ID : {{item.id}} [ {{item.type_litige.libelle}} ] BL {{item.numero_avis}} - {{item.destinataire_nom }} | montant-->
    <!--                : {{item.montant_valide | currency :'EUR': '€'}}</div>-->
    <!--            </ng-template>-->
    <!--          </ng-select>-->
    <!--        </div>-->
    <!--      </div>-->
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" class="btn bg-black-lighter text-white" type="button">Fermer</button>
  <button (click)="submit()" [ladda]="loadingBtn" class="btn btn-agediss text-white" data-style="zoom-in"
          type="button">Enregistrer
  </button>
</div>
