<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="id" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">


  <ng-container matColumnDef="responsable">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">Nom</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.responsable}}" class="px-10 text-left">{{element.responsable}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.discr}}">
      {{element?.discr}}
    </td>
  </ng-container>
  <ng-container matColumnDef="a_traiter">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>A traiter</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.a_traiter}}">
      {{element?.a_traiter}}
    </td>
  </ng-container>
  <ng-container matColumnDef="en_cours">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>En cours</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.en_cours}}">
      {{element?.en_cours}}
    </td>
  </ng-container>
  <ng-container matColumnDef="eligible">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Eligible</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.eligible}}">
      {{element?.eligible}}
    </td>
  </ng-container>
  <ng-container matColumnDef="attente_approbation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>En attente d'appobation</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.attente_approbation}}">
      {{element?.attente_approbation}}
    </td>
  </ng-container>
  <ng-container matColumnDef="a_valider">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>NB litiges à analyser par la PF</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.a_valider}}">
      {{element?.a_valider}}
    </td>
  </ng-container>
  <ng-container matColumnDef="contestation_en_cours">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>NB contestations en cours</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.contestation_en_cours}}">
      {{element?.contestation_en_cours}}
    </td>
  </ng-container>
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Total</th>
    <td mat-cell *matCellDef="let element"
        placement="top"
        container="body"
        ngbTooltip="{{element?.total}}">
      {{element?.total}}
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>

<mat-paginator *ngIf="showPagination" [length]="dataSource.data.length" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
