import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Message} from "../../models/api/message.model";
import {Conversation} from "../../models/api/conversation.model";

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  urlRessource = environment.url_api_litige + "/conversations";


  constructor(private http: HttpClient) {
  }

  getConversation(id: number): Observable<Conversation> {
    return this.http
      .get<Conversation>(`${this.urlRessource}/${id}`)
      .pipe(map((conversation) => {
        return new Conversation().deserialize(conversation);
      }));
  }

  getConversationMessages(id: number): Observable<Message[]> {
    return this.http
      .get<Message[]>(`${this.urlRessource}/${id}/messages`)
      .pipe(map((messages) => messages.map(message => {
        return new Message().deserialize(message);
      })));
  }


  postMessages(id: number): Observable<Message> {
    return this.http
      .get<Message>(`${this.urlRessource}/${id}/messages`)
      .pipe(map((message) => {
        return new Message().deserialize(message);
      }));
  }

  deleteConversation(id: number) {
    return this.http.delete<Conversation>(`${this.urlRessource}/${id}`)
      .pipe(map((conversation) => {
      return new Conversation().deserialize(conversation);
    }));;
  }


}


