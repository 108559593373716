<table mat-table [dataSource]="dataSorted" (matSortChange)="sortData($event)" matSort
       matSortActive="id" matSortDirection="desc"
       class="table table-responsive table-hover table-striped table-bordered mat-elevation-z8 text-center">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            placement="top"
            container="body"
            ngbTooltip="Numéro du litige">
            N°
        </th>
        <td mat-cell *matCellDef="let element" class="p-0"
            placement="top"
            container="body"
            ngbTooltip="voir le litige {{element?.id}}">
      <span>
        <a [routerLink]="['/','litiges',element.id]" class="m-0 btn btn-info">
      {{element.id}} - <i class="fa fa-eye"></i>
        </a>
      </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="numeroAvis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> BL</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.numero_avis}}">
            {{element?.numero_avis}}
        </td>
    </ng-container>
    <ng-container matColumnDef="numeroCommande">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Commande</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.numero_commande}}">
            {{element?.numero_commande}}
        </td>
    </ng-container>
    <ng-container matColumnDef="destinataireNom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinataire</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.destinataire_nom}}">
            {{element?.destinataire_nom}}
        </td>
    </ng-container>
    <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.client?.libelle}}">
        </td>
    </ng-container>
    <ng-container matColumnDef="plateforme">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plateforme</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.plateforme?.libelle}}">
            {{element?.plateforme?.libelle}}
        </td>
    </ng-container>
    <ng-container matColumnDef="statut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.statut?.libelle}}">
            {{element?.statut?.libelle}} <i *ngIf="element.is_prescrit" ngbTooltip="Prescrit" class="fa fa-exclamation-triangle text-red-darker"></i>
        </td>
    </ng-container>
    <ng-container matColumnDef="traitePar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Traité par</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.traite_par?.getFullname()}}">
            {{element?.traite_par?.getFullname()}} </td>
    </ng-container>
    <ng-container matColumnDef="montantValide">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Montant facturer DO</th>
        <td mat-cell *matCellDef="let element"
            placement="top"
            container="body"
            ngbTooltip="{{element?.montant_valide}}">
            {{element?.montant_valide}}
        </td>
    </ng-container>
    <ng-container matColumnDef="imputer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imputer</th>
        <td class="text-center" mat-cell *matCellDef="let element">
            <a class="btn btn-icon btn-info" (click)="openModalModifResponsabilite(element)">
                <i class="fa fa-eye"></i>
            </a>
        </td>
    </ng-container>
    <ng-container matColumnDef="noData">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="noRecordText"></td>
    </ng-container>
    <ng-container matColumnDef="loading">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" [innerHTML]="loadingText"></td>
    </ng-container>

    <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="loading"></tr>
    <tr mat-footer-row *matFooterRowDef="['noData']"
        [hidden]="dataSorted && dataSorted.data.length > 0 || loading"></tr>
    <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>

</table>
<!-- [style.background]="row.is_prescrit ? 'lightblue' : ''"-->
