import {Deserializable} from '../interfaces/deserializable.interface';


export class AnnuaireItem implements Deserializable {

  public id?: number;
  public client;
  public compte_client;
  public plateforme;
  public config;


  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
